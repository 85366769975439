import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { actionGetEventSessions } from "../../../store/services/eventService";
import { getEventId } from "../../helper/common-function";
import dayjs from "dayjs";
import { MdOutlineWatchLater } from "react-icons/md";

const SessionCalenderView = ({
  showModal,
  selectedDay,
  showEditSessionModal,
}) => {
  const calendarRef = useRef(null);
  const dispatch = useDispatch();
  const { getEventSessionsLoader, allEventSessionsData, eventsOverview } =
    useSelector((state) => state.events);

  const eventId = getEventId();
  const allEventSessionsDataCustom = [];

  const combineDateTime = (date, time) => {
    const dateTimeString = `${date}T${time}`;
    const dateTime = dayjs(dateTimeString);

    const isoString = dateTime.toISOString();

    return isoString;
  };

  allEventSessionsData &&
    allEventSessionsData.map((data) => {
      return allEventSessionsDataCustom.push({
        title: data.session_name,
        start: combineDateTime(data.session_date, data.session_start_time),
        end: combineDateTime(data.session_date, data.session_end_time),
        extendedProps: data,
      });
    });

  useEffect(() => {
    dispatch(actionGetEventSessions({ id: eventId }));
  }, [eventId]);

  const calculateTimeDifference = (data) => {
    const start = dayjs(`${data.session_date}T${data.session_start_time}`);
    const end = dayjs(`${data.session_date}T${data.session_end_time}`);

    const duration = end.diff(start, "second");
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };

  useEffect(() => {
    if (selectedDay && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView("timeGridDay", selectedDay);
    }
  }, [selectedDay]);

  const renderEventContent = (eventInfo) => {
    const { session_name, session_location } = eventInfo.event.extendedProps;
    return (
      <div className="flex items-center justify-between">
        <div>
          <span className="ml-2 font-popinsMedium">
            {session_name} , {session_location}
          </span>
        </div>
        <div className="">
          <span className="flex items-center gap-x-2 mr-2 font-popinsMedium text-xs text-brand">
            <MdOutlineWatchLater />
            {calculateTimeDifference(eventInfo.event.extendedProps)}
          </span>
        </div>
      </div>
    );
  };

  const handleDateClick = (info) => {
    const date = dayjs(info.date);
    const formattedDate = date.format("YYYY-MM-DD");
    const formattedTime = date.format("HH:mm");

    showModal(formattedDate, formattedTime);
  };

  return (
    <Spin spinning={getEventSessionsLoader}>
      <FullCalendar
        ref={calendarRef}
        viewClassNames="full-calendar"
        headerToolbar={false}
        plugins={[timeGridPlugin, interactionPlugin]}
        initialView="timeGridDay"
        initialDate={selectedDay}
        events={allEventSessionsDataCustom}
        eventContent={renderEventContent}
        dateClick={handleDateClick}
        eventClick={(e) => {
          const eventSessionId = e.event._def.extendedProps.event_session_id;
          showEditSessionModal(eventSessionId);
        }}
        allDaySlot={false}
        //  className="custom-fullcalendar"
        eventBackgroundColor="#F5F7FF"
        eventBorderColor="#6883FD"
        eventTextColor="black"
        slotDuration="00:10:00"
        scrollTime="00:00:00"
        //timeZone="Asia/Kolkata"
      />
    </Spin>
  );
};

export default SessionCalenderView;
