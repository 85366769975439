import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Table,
  Typography,
  Select,
} from "antd";
import * as XLSX from "xlsx";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import plusIcon from "../../../assets/img/add-button.svg";
import deletevent from "../../../assets/img/deleteevent.svg";
import producttype from "../../../assets/img/producttype.svg";
import businessPartner from "../../../assets/img/businessPartner.svg";
import editedevent from "../../../assets/img/editedevent.svg";
import editevent from "../../../assets/img/editevent.svg";
import SortIcon from "../../common/SortIcon";
import {
  actionAddBusinessPartnerTypeMaster,
  actionDeleteBusinessPartnerTypeMaster,
  actionEditBusinessPartnerTypeMaster,
  actionGetBusinessPartnerTypeMaster,
  actionGetSingleBusinessPartnerTypeMaster,
} from "../../../store/services/generalMasterService";
import { isValidSearchString } from "../../helper/common-function";
import MasterSearchbar from "../../common/MasterSearchbar";
import TextArea from "antd/es/input/TextArea";

const BusinessPartnerType = ({ setSelectItem }) => {
  const [editableForm] = Form.useForm();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    addBusinessPartnerTypeLoader,
    deleteBusinessPartnerTypeLoader,
    updateBusinessPartnerTypeMasterLoader,
    getBusinessPartnerTypeLoader,
    businessPartnerTypeData,
    businessPartnerTypeDetail,
  } = useSelector((state) => state.generalMaster);
  const [search, setSearch] = useState("");
  const [edited, setEdited] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isEditing = (record) =>
    record.business_partner_type_master_id === editingKey;

  useEffect(() => {
    dispatch(actionGetBusinessPartnerTypeMaster({ search }));
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "number" ? (
        <Input />
      ) : dataIndex === "description" ? (
        <Input
          className="!indent-1"
          style={{ textIndent: 4 }}
          maxLength={255}
          showCount
        />
      ) : (
        <Input variant="borderless" />
      );
    return (
      <td {...restProps} className="input-height">
        {editing ? (
          <Form.Item
            className="mb-0 editable-order"
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: title === "Business Type Name" ? true : false,
                message: `${title} is required.`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: false, tooltip: true }}
            className="px-[11px]"
          >
            {children}
          </Typography.Paragraph>
        )}
      </td>
    );
  };

  const edit = (record) => {
    dispatch(
      actionGetSingleBusinessPartnerTypeMaster(
        record.business_partner_type_master_id
      )
    );
    setEdited(true);
  };

  useEffect(() => {
    if (businessPartnerTypeDetail && edited) {
      editableForm.setFieldsValue({
        business_partner_type_name:
          businessPartnerTypeDetail.business_partner_type_name,
        description: businessPartnerTypeDetail.description,
      });
      setEditingKey(businessPartnerTypeDetail.business_partner_type_master_id);
    }
  }, [businessPartnerTypeDetail]);

  const save = async (id) => {
    try {
      const row = await editableForm.validateFields();
      const { business_partner_type_name, description } = row;
      const req = { business_partner_type_name, description };
      dispatch(
        actionEditBusinessPartnerTypeMaster({
          id,
          req,
          setEditingKey,
          setEdited,
        })
      );
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "srno",
      key: "1",
      width: 100,
      width: "12%",
      editable: false,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Business Type Name",
      dataIndex: "business_partner_type_name",
      key: "2",
      width: "22%",
      editable: true,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "3",
      width: "48%",
      editable: true,
      ellipsis: true,
      render: (data) => (data ? data : "-"),
      sorter: (a, b) => a.description - b.description,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Action",
      key: "4",
      width: 200,
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <div className="flex items-center ">
            {editable ? (
              <div className="flex items-center">
                {updateBusinessPartnerTypeMasterLoader[
                  record.business_partner_type_master_id
                ] ? (
                  <LoadingOutlined />
                ) : (
                  <Typography.Link
                    onClick={() => save(record.business_partner_type_master_id)}
                  >
                    <img src={editedevent} alt="" />
                  </Typography.Link>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-x-2 justify-center">
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => edit(record)}
                >
                  <img src={editevent} alt="" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              <Popconfirm
                okButtonProps={{
                  loading:
                    deleteBusinessPartnerTypeLoader[
                      record.business_partner_type_master_id
                    ],
                }}
                description="Are you sure you want to delete?"
                onConfirm={() =>
                  dispatch(
                    actionDeleteBusinessPartnerTypeMaster({
                      id: record.business_partner_type_master_id,
                      setEditingKey,
                    })
                  )
                }
              >
                <img src={deletevent} alt="" className="cursor-pointer" />
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    console.log({ col });
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleAdd = (values) => {
    dispatch(actionAddBusinessPartnerTypeMaster({ values, handleCancel }));
  };

  const handleSearch = (e) => {
    if (isValidSearchString(e.target.value)) {
      setSearch(e.target.value);
      if (e.target.value === "") {
        dispatch(
          actionGetBusinessPartnerTypeMaster({
            search: e.target.value,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search && isValidSearchString(e.target.value)) {
      dispatch(
        actionGetBusinessPartnerTypeMaster({
          search: e.target.value,
        })
      );
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetBusinessPartnerTypeMaster({
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  const getData = async (data) => {
    const newData =
      (await data) &&
      data.length > 0 &&
      data.map((d) => ({
        "Business Partner Type": d.business_partner_type_name
          ? d.business_partner_type_name
          : "-",
        Description: d.description ? d.description : "-",
      }));
    return newData;
  };

  const exportAllToExcel = async () => {
    if (
      !businessPartnerTypeData ||
      (businessPartnerTypeData && businessPartnerTypeData.length === 0)
    ) {
      return;
    }
    const data = await getData(businessPartnerTypeData);
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Business Partner Type");
    XLSX.writeFile(workbook, "business-partner-type.xlsx");
  };

  return (
    <div className="event-input">
      <div className="flex justify-between mt-[15px]">
        <h1 className="text-[#6883FD] text-[20px] font-medium">
          Business Partner Type
        </h1>
        <div>
          <Button
            onClick={showModal}
            className="root-btn text-white hover:text-white py-5 flex-center radius"
            icon={<Image src={plusIcon} alt="Plus Icon" preview={false} />}
          >
            Add Business Partner Type
          </Button>
          <Modal
            centered
            onOk={() => handleCancel()}
            onCancel={() => handleCancel()}
            footer={false}
            width={500}
            title=""
            open={isModalOpen}
          >
            <div className="flex">
              <img src={businessPartner} alt="" />
              <h1 className="text-[#6883FD] text-[20px]  ml-[8px] mt-[5px] font-medium">
                Business Partner Type
              </h1>
            </div>
            <Form
              form={form}
              onFinish={handleAdd}
              layout="vertical"
              className="mt-[20px] mb-0"
            >
              <Form.Item
                name="business_partner_type_name"
                label="Business partner type"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please input business partner type name!",
                  },
                ]}
              >
                <Input
                  rows={3}
                  style={{ background: "white" }}
                  placeholder="Enter Business Partner Type"
                  className="!indent-1 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                className="mb-[12px]"
                size="small"
              >
                <Input.TextArea
                  rows={3}
                  showCount
                  style={{ background: "white", resize: "none" }}
                  maxLength={255}
                  placeholder="Enter Description"
                  className="!indent-1 !pt-1 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>

              <div className="flex justify-center mt-[40px]">
                <Form.Item className="mb-0">
                  <Button
                    onClick={handleCancel}
                    type="button"
                    className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      loading={addBusinessPartnerTypeLoader}
                      type="primary"
                      className="root-btn min-h-[45px] min-w-[120px]"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
      <div className="pt-[25px]">
        <MasterSearchbar
          handleSearch={handleSearch}
          handleBlurAndPressEnter={handleBlurAndPressEnter}
          importRoute={"/master-data/business-partner-type-import"}
          search={search}
          onClickDownloadMenu={exportAllToExcel}
        />
      </div>

      <div className="pt-5 pb-[75px]">
        <Form form={editableForm} component={false}>
          <Table
            scroll={{ y: 500 }}
            pagination={false}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            onChange={handleSortChange}
            loading={getBusinessPartnerTypeLoader}
            bordered
            dataSource={businessPartnerTypeData}
            rowKey="business_partner_type_master_id"
            columns={mergedColumns}
            rowClassName="editable-row"
          />
        </Form>
      </div>
    </div>
  );
};

export default BusinessPartnerType;
