import { GoogleOAuthProvider } from "@react-oauth/google";
import { ConfigProvider, Spin } from "antd";
import { useMemo } from "react";
import "react-international-phone/style.css";
import { useDispatch, useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { Toaster } from "sonner";
import "./App.css";
import { router } from "./config/router";
import setAuthToken from "./config/setAuthToken";
import { authMe } from "./store/services/authService";

function App() {
  const token = localStorage.getItem("crmWebToken");
  const dispatch = useDispatch();
  const { userLoader } = useSelector((state) => state.auth);

  useMemo(() => {
    if (token) {
      setAuthToken(token);
      dispatch(authMe());
    }
  }, [token, dispatch]);

  const themeStyle = {
    token: {
      fontFamily: "Popins",
      borderRadius: 5,
      colorPrimary: "#6883FD",
    },
    components: {
      Collapse: { headerBg: "white" },
      Button: { defaultShadow: "" },
      Table: {
        cellPaddingBlock: 8,
      },
      Checkbox: {
        colorPrimary: "#152778",
        colorPrimaryHover: "#152778",
        colorPrimaryBorder: "#d9d9d9",
      },
    },
  };

  return (
    <ConfigProvider theme={themeStyle}>
      <style>
        {`
          .ant-table-container .ant-table-body,
          .ant-table-container .ant-table-content {
            scrollbar-width: thin;
            scrollbar-color: ##b4b4b4 #f0f0f0 transparent;
            scrollbar-gutter: stable;
          }
        `}
      </style>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Toaster
          visibleToasts={3}
          toastOptions={{
            classNames: {
              error: "my-toast-error",
              success: "my-toast-success",
              warning: "my-toast-warning",
              info: "my-toast-info",
              closeButton:
                "absolute -translate-x-1/2 -translate-y-1/2 left-[94%] right-0 top-1/2 cancel-button-style",
            },
          }}
          position="bottom-left"
          reverseOrder={false}
          expand={true}
          // theme="system"
          duration={4000}
          richColors={true}
          closeButton={true}
        />
        {userLoader ? (
          <div className="flex items-center h-screen justify-center">
            <Spin />
          </div>
        ) : (
          <RouterProvider router={router} />
        )}
      </GoogleOAuthProvider>
    </ConfigProvider>
  );
}

export default App;
