import { createSlice } from "@reduxjs/toolkit";
import {
  actionClearAllExpenseVouchers,
  actionDeleteExpenseVoucher,
  actionGetAllExpenseVouchers,
  actionGetCurrencyExchangeMaster,
  actionGetExpenseVouchers,
  actionGetExpenseVoucherDetails,
  actionAddExpenseVoucher,
  actionUpdateExpenseVoucher,
  actionChangeExpenseVoucherStatus,
  actionGetExpenseItemsBySupplierName,
  actionGetCollectionManagement,
  actionClearAllCollectionManagement,
  actionGetAllCollectionManagement,
  actionAddCollectionManagement,
  actionDeleteCollectionManagement,
  actionGetCollectionManagementDetails,
  actionUpdateCollectionManagement,
} from "../services/financeService";

const financeSlice = createSlice({
  name: "finance",
  initialState: {
    getExpenseVouchersLoader: false,
    getAllExpenseVouchersLoader: false,
    getExpenseVoucherDetailLoader: false,
    addExpenseVoucherLoader: false,
    updateExpenseVoucherLoader: false,
    deleteExpenseVoucherLoader: false,
    updateExpenseVoucherLoader: false,
    changeStatusLoader: false,
    expenseVoucherData: [],
    allExpenseVoucherData: [],
    expenseVoucherDetailData: "",
    expenseVoucherDataCount: "",
    supplierData: [],
    getSupplierLoader: false,

    // collection management
    getCollectionManagementLoader: false,
    getAllCollectionManagementLoader: false,
    addCollectionManagementLoader: false,
    deleteCollectionManagementLoader: false,
    getCollectionManagementDetailLoader: false,
    updateCollectionManagmentLoader: false,
    collectionManagementData: [],
    allCollectionManagements: [],
    collectionManagementDetails: {},
    allCollectionManagementCount: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get Expense Vouchers reducers
      .addCase(actionGetExpenseVouchers.pending, (state) => {
        state.getExpenseVouchersLoader = true;
      })
      .addCase(actionGetExpenseVouchers.fulfilled, (state, action) => {
        state.getExpenseVouchersLoader = false;
        state.expenseVoucherData = action.payload.data;
        state.expenseVoucherDataCount = action.payload.total_records;
      })
      .addCase(actionGetExpenseVouchers.rejected, (state) => {
        state.getExpenseVouchersLoader = false;
      })

      //get single expense voucher start here
      .addCase(actionGetExpenseVoucherDetails.pending, (state, action) => {
        state.getExpenseVoucherDetailLoader = true;
      })
      .addCase(actionGetExpenseVoucherDetails.fulfilled, (state, action) => {
        state.getExpenseVoucherDetailLoader = false;
        state.expenseVoucherDetailData = action.payload.data;
      })
      .addCase(actionGetExpenseVoucherDetails.rejected, (state, action) => {
        state.getExpenseVoucherDetailLoader = false;
      })

      .addCase(actionAddExpenseVoucher.pending, (state) => {
        state.addExpenseVoucherLoader = true;
      })
      .addCase(actionAddExpenseVoucher.fulfilled, (state, action) => {
        state.addExpenseVoucherLoader = false;

        if (
          !state.expenseVoucherData ||
          !Array.isArray(state.expenseVoucherData)
        ) {
          state.expenseVoucherData = [action.payload];
        } else {
          state.expenseVoucherData.unshift(action.payload);
        }
      })
      .addCase(actionAddExpenseVoucher.rejected, (state) => {
        state.addExpenseVoucherLoader = false;
      })

      .addCase(actionUpdateExpenseVoucher.pending, (state) => {
        state.updateExpenseVoucherLoader = true;
      })
      .addCase(actionUpdateExpenseVoucher.fulfilled, (state, action) => {
        state.updateExpenseVoucherLoader = false;

        /*   state.expenseVoucherData = state.expenseVoucherData.map((item) =>
          item.expense_voucher_id === action.payload.expense_voucher_id
            ? { ...item, ...action.payload }
            : item
        ); */
      })
      .addCase(actionUpdateExpenseVoucher.rejected, (state) => {
        state.updateExpenseVoucherLoader = false;
      })

      // Clear All Expense Vouchers Data
      .addCase(actionClearAllExpenseVouchers.fulfilled, (state, action) => {
        state.allExpenseVoucherData = action.payload;
      })
      // actionGetAllExpenseVouchers State
      .addCase(actionGetAllExpenseVouchers.pending, (state) => {
        state.getAllExpenseVouchersLoader = true;
      })
      .addCase(actionGetAllExpenseVouchers.fulfilled, (state, action) => {
        state.getAllExpenseVouchersLoader = false;
        state.allExpenseVoucherData = action.payload.data;
      })
      .addCase(actionGetAllExpenseVouchers.rejected, (state) => {
        state.getAllExpenseVouchersLoader = false;
      })

      .addCase(actionDeleteExpenseVoucher.pending, (state) => {
        state.deleteExpenseVoucherLoader = true;
      })
      .addCase(actionDeleteExpenseVoucher.fulfilled, (state, action) => {
        state.deleteExpenseVoucherLoader = false;
        state.expenseVoucherData = state.expenseVoucherData.filter(
          (item) => item.expense_voucher_id !== action.payload
        );
      })
      .addCase(actionDeleteExpenseVoucher.rejected, (state) => {
        state.deleteExpenseVoucherLoader = false;
      })

      .addCase(actionChangeExpenseVoucherStatus.pending, (state) => {
        state.changeStatusLoader = true;
      })
      .addCase(actionChangeExpenseVoucherStatus.fulfilled, (state, action) => {
        const { ids, status } = action.payload;
        state.changeStatusLoader = false;
        state.expenseVoucherData = state.expenseVoucherData.map((item) =>
          ids.includes(item.expense_voucher_id)
            ? { ...item, status: status }
            : item
        );

        if (
          state.expenseVoucherDetailData &&
          ids.includes(state.expenseVoucherDetailData.expense_voucher_id)
        ) {
          state.expenseVoucherDetailData = {
            ...state.expenseVoucherDetailData,
            status: status,
          };
        }
      })
      .addCase(actionChangeExpenseVoucherStatus.rejected, (state) => {
        state.changeStatusLoader = false;
      })

      .addCase(actionGetExpenseItemsBySupplierName.pending, (state) => {
        state.getSupplierLoader = true;
      })
      .addCase(
        actionGetExpenseItemsBySupplierName.fulfilled,
        (state, action) => {
          state.getSupplierLoader = false;
          state.supplierData = action.payload.data;
        }
      )
      .addCase(actionGetExpenseItemsBySupplierName.rejected, (state) => {
        state.getSupplierLoader = false;
      })

      // collection starts

      .addCase(actionGetCollectionManagement.pending, (state) => {
        state.getCollectionManagementLoader = true;
      })
      .addCase(actionGetCollectionManagement.fulfilled, (state, action) => {
        state.getCollectionManagementLoader = false;
        state.collectionManagementData = action.payload.data;
        state.allCollectionManagementCount = action.payload.total_records;
      })
      .addCase(actionGetCollectionManagement.rejected, (state) => {
        state.getCollectionManagementLoader = false;
      })

      // clear all data
      .addCase(actionClearAllCollectionManagement.pending, (state, action) => {
        state.allCollectionManagements = action.payload;
      })

      .addCase(actionGetAllCollectionManagement.pending, (state) => {
        state.getAllCollectionManagementLoader = true;
      })
      .addCase(actionGetAllCollectionManagement.fulfilled, (state, action) => {
        state.getAllCollectionManagementLoader = false;
        state.allCollectionManagements = action.payload.data;
      })
      .addCase(actionGetAllCollectionManagement.rejected, (state) => {
        state.getAllCollectionManagementLoader = false;
      })

      .addCase(actionAddCollectionManagement.pending, (state) => {
        state.addCollectionManagementLoader = true;
      })

      .addCase(actionAddCollectionManagement.fulfilled, (state, action) => {
        state.addCollectionManagementLoader = false;
        if (
          !state.collectionManagementData ||
          !Array.isArray(state.collectionManagementData)
        ) {
          state.collectionManagementData = [action.payload];
        } else {
          state.collectionManagementData.unshift(action.payload);
        }

        state.allCollectionManagementCount =
          state.allCollectionManagementCount + 1;
      })
      .addCase(actionAddCollectionManagement.rejected, (state) => {
        state.addCollectionManagementLoader = false;
      })

      .addCase(actionDeleteCollectionManagement.pending, (state) => {
        state.deleteCollectionManagementLoader = true;
      })
      .addCase(actionDeleteCollectionManagement.fulfilled, (state, action) => {
        state.deleteCollectionManagementLoader = false;
        state.collectionManagementData = state.collectionManagementData.filter(
          (item) => item.collection_management_id !== action.payload
        );
      })
      .addCase(actionDeleteCollectionManagement.rejected, (state) => {
        state.deleteCollectionManagementLoader = false;
      })

      //get single collection management start here
      .addCase(actionGetCollectionManagementDetails.pending, (state) => {
        state.getCollectionManagementDetailLoader = true;
      })
      .addCase(
        actionGetCollectionManagementDetails.fulfilled,
        (state, action) => {
          state.getCollectionManagementDetailLoader = false;
          state.collectionManagementDetails = action.payload.data;
        }
      )
      .addCase(actionGetCollectionManagementDetails.rejected, (state) => {
        state.getCollectionManagementDetailLoader = false;
      })

      // update collection management
      .addCase(actionUpdateCollectionManagement.pending, (state) => {
        state.updateCollectionManagmentLoader = true;
      })
      .addCase(actionUpdateCollectionManagement.fulfilled, (state, action) => {
        state.updateCollectionManagmentLoader = false;
        state.collectionManagementData = state.collectionManagementData.map(
          (item) =>
            item.collection_management_id ===
            action.payload.collection_management_id
              ? { ...item, ...action.payload }
              : item
        );
      })
      .addCase(actionUpdateCollectionManagement.rejected, (state) => {
        state.updateCollectionManagmentLoader = false;
      });
  },
});
export default financeSlice.reducer;
