import React, { useState } from "react";
import plusIcon from "../../assets/img/add-button.svg";
import leftArrow from "../../assets/img/leftArrowPrimary.svg";
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  AlertOutlined,
} from "@ant-design/icons";
import xlsxIcon from "../../assets/img/xlsx-icon.svg";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Table,
  Divider,
  Image,
  Row,
  Statistic,
  Typography,
  Alert,
  ConfigProvider,
} from "antd";
// import CustomUploadDragger from "../../common/CustomUploadDragger";
import CustomUploadDragger from "./CustomUploadDragger";
import { toast } from "sonner";
import axios from "axios";
import { BASE_URL } from "../../config/web-config";
import { formatTitle } from "../helper/common-function";
import { useNavigate } from "react-router-dom";

const CustomImportComp = ({
  setSelectItem = null, // Optional function for setting the selected item or navigating back
  backText = "product", // Text for the back button
  text = "file", // Title for the page
  downloadUrl = "material-master/import-excel-format", // URL for downloading the format
  uploadUrl = "material-master/import",
  hasItems = false,
  gif = true,
  ...props
}) => {
  const fullUploadUrl = `${BASE_URL}/${uploadUrl}`;
  const fullDownloadUrl = `${BASE_URL}/${downloadUrl}`;
  const navigate = useNavigate();
  const [isDownloading, setIsDownloading] = useState(false);
  const [data, setData] = useState({});
  const [isUploading, setUploading] = useState(false);

  // Handle file format download
  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      const res = await axios.get(fullDownloadUrl, {
        responseType: "blob",
      });

      if (res.status === 200) {
        const blob = res.data;

        const downloadUrl = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `${text}-data-format.xlsx`;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      } else if (res.status === 404) {
        toast.error("File not found");
      } else {
        toast.error("Failed to download file");
        throw new Error(`API Error: ${res.statusText}`);
      }
    } catch (error) {
      console.error("Error downloading:", error);
      toast.error("Failed to download");
    } finally {
      setIsDownloading(false);
    }
  };

  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      setUploading(true);
      const response = await axios.post(fullUploadUrl, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("crmWebToken")}`,
        },
        timeout: 60000,
      });
      const { data, importedData, validationErrors } = response.data;

      const successfulCount = importedData.length;
      const failedCount = validationErrors.length;

      if (failedCount > 0) {
        toast.error(
          `${failedCount} item(s) could not be imported. Please check the details and try again.`
        );
      } else {
        toast.success(`Successfully imported ${successfulCount} entries.`);
      }

      setData(response.data);
    } catch (error) {
      console.error("Upload failed:", error.toJSON ? error.toJSON() : error);
      toast.error(
        error?.response?.data?.message || error.message || "Network Error"
      );
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="event-input overflow-y-auto overflow-x-hidden">
      <div className="flex justify-between my-[15px]">
        <div
          onClick={() => {
            navigate && navigate(-1);
          }}
          className="flex items-center gap-x-2 cursor-pointer"
        >
          <Image
            src={leftArrow}
            alt="image"
            height={18}
            className="cursor-pointer mr-2"
            width={18}
            preview={false}
          />
          <h1 className="text-[#6883FD] text-[20px] font-medium">
            Import{" "}
            {text === "hsn-sac-code"
              ? "HSN/SAC Code"
              : text
                  .replace(/-/g, " ")
                  .replace(/\b\w/g, (char) => char.toUpperCase())}
          </h1>
        </div>
        <div>
          <Button
            onClick={handleDownload}
            loading={isDownloading}
            className="root-btn text-white hover:text-white py-5 flex-center radius"
            icon={<Image src={plusIcon} alt="Plus Icon" preview={false} />}
          >
            Download Excel Format
          </Button>
        </div>
      </div>
      <CustomUploadDragger
        gif={gif}
        gapY={2}
        image={xlsxIcon}
        title={
          <div className="flex-col flex items-center justify-center gap-y-1">
            <p className="text-[#7C7C7C] font-popinsSemiBold text-xl">
              Upload Your file here
            </p>
            <p className="text-[#7C7C7C] font-popinsRegular text-xs">
              or Drag and Drop it here{" "}
            </p>
          </div>
        }
        containerHeight={300}
        imageHW={50}
        accept=".xlsx,.xls,.csv"
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();

          const files = e.dataTransfer.files;

          handleUpload(files[0]);
        }}
        onChange={async (e) => {
          const file = e.target.files[0];
          if (file) {
            await handleUpload(file);
          }
        }}
        loading={isUploading}
      />

      {data && Object.keys(data).length > 0 && (
        <ImportResults hasItems={hasItems} text={text} importResult={data} />
      )}
    </div>
  );
};

export default CustomImportComp;

// Component for displaying import results
function ImportResults({ importResult, text, hasItems }) {
  const {
    data = [],
    importedData = [],
    validationErrors = [],
  } = importResult || {};

  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [showItems, setShowItems] = useState({}); // State to track which items to show

  // Prepare the data for the main table
  const combinedData = data.map((item, index) => ({
    ...item,
    index: index + 1,
    validationErrors: validationErrors
      .filter((err) => err.index === index)
      .map((item, idx) => ({ ...item, index: item.index + 1 })),
  }));

  const scrollToImportedData = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const expandable = {
    /*  expandedRowRender: (record) => {
      const { validationErrors } = record;

      if (Array.isArray(validationErrors) && validationErrors.length > 0) {
        const errorRows = validationErrors.flatMap((errorGroup, groupIndex) => {
          console.log(errorGroup);
          if (
            Array.isArray(errorGroup.errors) &&
            errorGroup.errors.length > 0
          ) {
            return errorGroup.errors.map((errorMessage) => ({
              index: errorGroup?.index,
              error: errorMessage,
            }));
          } else {
            return errorGroup.error;
          }
        });

        return (
          <Table
            bordered
            columns={[
              {
                title: "Error",
                dataIndex: "error",
                key: "error",
                render: (text) => <Alert message={text} type="error" />,
              },
            ]}
            dataSource={errorRows}
            pagination={false}
            rowKey="index"
          />
        );
      }

      // If there are no validation errors, return null or a default message
      return <Alert type="error" message="No validation errors found." />;
    }, */

    expandedRowRender: (record) => {
      const { validationErrors } = record;

      if (Array.isArray(validationErrors) && validationErrors.length > 0) {
        // Map through all error groups
        return validationErrors.map((errorGroup, groupIndex) => {
          // Check if errorGroup has multiple errors
          if (
            Array.isArray(errorGroup.errors) &&
            errorGroup.errors.length > 0
          ) {
            // If there are multiple errors, map them to errorRows
            const errorRows = errorGroup.errors.map(
              (errorMessage, errorIndex) => ({
                index: `${groupIndex}-${errorIndex}`, // Create a unique index for each error
                error: errorMessage,
              })
            );

            // Render the errors in a table
            return (
              <Table
                key={groupIndex}
                bordered
                columns={[
                  {
                    title: "Error",
                    dataIndex: "error",
                    key: "error",
                    render: (text) => <Alert message={text} type="error" />,
                  },
                ]}
                dataSource={errorRows}
                pagination={false}
                rowKey="index"
              />
            );
          } else {
            return (
              <Alert
                key={groupIndex}
                type="error"
                message={errorGroup.error || "Unknown error"}
              />
            );
          }
        });
      }

      return <Alert type="error" message="No validation errors found." />;
    },
    rowExpandable: (record) => record.validationErrors.length > 0,
    onExpand: (expanded, record) => {
      if (expanded) {
        setExpandedRowKey(record.index);
      } else {
        setExpandedRowKey(null);
      }
    },
    columnWidth: "2%",
    // expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
    defaultExpandAllRows: true,

    showExpandColumn: true,
  };

  console.log(data[0]);

  const columns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      width: 100,
      render: (text, record, index) => index + 1,
    },
    ...Object.keys(data[0]).map((key) => ({
      title: formatTitle(key),
      dataIndex: key,
      key: key,
      width: 200,
      ellipsis: true,
      render: (text, record) => {
        if (key === "items" && Array.isArray(record.items)) {
          return <span>{record.items.length} items</span>;
        }
        return text || "-";
      },
    })),
  ];

  return (
    <div style={{ padding: "24px 0" }}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={8}>
          <Card>
            <Statistic
              title="Total Records"
              value={data.length}
              prefix={<InfoCircleOutlined style={{ color: "#1890ff" }} />}
            />
          </Card>
        </Col>
        <Col xs={24} sm={8}>
          {/* <a href="#imported-data"> */}
          <div
            onClick={() => scrollToImportedData("imported-data")}
            style={{ cursor: "pointer" }}
          >
            <Card>
              <Statistic
                title="Imported Records"
                value={importedData.length}
                prefix={<CheckCircleOutlined style={{ color: "#52c41a" }} />}
              />
            </Card>
          </div>
        </Col>
        <Col xs={24} sm={8}>
          <div
            onClick={() => scrollToImportedData("validation-errors")}
            style={{ cursor: "pointer" }}
          >
            <Card>
              <Statistic
                title="Validation Errors"
                value={validationErrors.length}
                prefix={<AlertOutlined style={{ color: "#f5222d" }} />}
              />
            </Card>
          </div>
        </Col>
      </Row>

      <Card style={{ marginTop: "16px" }} id="imported-data">
        <Typography.Title level={4}>{text} Details</Typography.Title>
        <Typography.Text type="secondary">
          Details of the {text}
        </Typography.Text>
        <Divider />

        <ConfigProvider
          theme={{
            components: {
              Table: {
                cellPaddingBlock: 8,
                algorithm: true,
              },
              Alert: {
                defaultPadding: "4px 12px",
              },
            },
          }}
        >
          {combinedData && combinedData.length > 0 ? (
            <Table
              scroll={{
                x: "max-content",
                y: 400,
              }}
              dataSource={combinedData}
              columns={columns}
              rowKey={"index"}
              pagination={false}
              bordered
              expandable={expandable}
              rowClassName={(record) =>
                record.validationErrors.length > 0 ? "error-row" : ""
              }
            />
          ) : (
            <h1 className="text-center text-base font-popinsSemiBold">
              No imported Data Found
            </h1>
          )}
        </ConfigProvider>
      </Card>
    </div>
  );
}
