import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/web-config";
import { toast } from "sonner";
import { handleErrorMessage } from "../../components/helper/common-function";
import { handleGetResponse } from "./commonService";

//request for quotation
//get All request for quotation
export const actionGetRequestForQuotation = createAsyncThunk(
  "actionGetRequestForQuotation",
  async ({ offset, search, orderByColumn, order }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/request-for-quotation?limit=10&offset=${offset}${
          search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${
          order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionGetAllRequestForQuotation = createAsyncThunk(
  "actionGetAllRequestForQuotation",
  () => {
    return handleGetResponse(`request-for-quotation?getall=YES`);
  }
);

//single request for quotation
export const actionGetSingleRequestForQuotation = createAsyncThunk(
  "actionGetSingleRequestForQuotation",
  async ({ id }) => {
    return handleGetResponse(`request-for-quotation/${id}`);
  }
);

//add request for quotation
export const actionAddRequestForQuotation = createAsyncThunk(
  "actionAddRequestForQuotation",
  async ({ req, navigate }, { dispatch }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/request-for-quotation`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate(-1);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionDeleteRequestForQuotation = createAsyncThunk(
  "actionDeleteRequestForQuotation",
  async (
    { requstForQuotationId, handleCloseDeleteModal },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/request-for-quotation/${requstForQuotationId}`
      );
      const { status, message: customMsg } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCloseDeleteModal && handleCloseDeleteModal();
        return requstForQuotationId;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// update request for quotation
export const actionUpdateRequestForQuotation = createAsyncThunk(
  "actionUpdateRequestForQuotation",
  async ({ id, req, navigate }, { rejectWithValue }) => {
    try {
      const res = await axios.patch(
        `${BASE_URL}/request-for-quotation/${id}`,
        req
      );
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate(-1);
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionClearAllRequestForQuotation = createAsyncThunk(
  "actionClearAllRequestForQuotation",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

//change status
export const actionUpdateRequestForQuotationStatus = createAsyncThunk(
  "actionUpdateRequestForQuotationStatus",
  async ({ id, req }, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/request-for-quotation/${id}`,
        req
      );
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        // navigate && navigate(-1);
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
