import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  ConfigProvider,
  Dropdown,
  Image,
  Modal,
  Pagination,
  Popconfirm,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import closeIcon from "../../assets/img/close-icon.svg";
import taskIcon from "../../assets/img/task-icon.svg";
import {
  actionClearAllTasks,
  actionDeleteTasks,
  actionGetAllTasks,
  actionGetTaskDetail,
  actionGetTasks,
  actionUpdateTask,
} from "../../store/services/taskService";
import CustomInputWithButtons from "../common/CustomInputWithButtons ";
import PageTitle from "../common/PageTitle";
import SortIcon from "../common/SortIcon";
import CreateTask from "./CreateTask";
import { actionGetTaskStatusMaster } from "../../store/services/generalMasterService";
import { getPriorityColor, getTaskStatusColor } from "../helper/statusUtils";
import { actionGetCompanyAllUsers } from "../../store/services/usersService";
import { getFirstLetterCapital, getFullName } from "../helper/common-function";

const htmlToPlainText = (html) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = html;

  let plainText = tempElement.textContent || tempElement.innerText;

  const entities = {
    "&lt;": "<",
    "&gt;": ">",
    "&amp;": "&",
    "&quot;": '"',
    "&apos;": "'",
    "&nbsp;": " ",
  };

  for (const entity in entities) {
    plainText = plainText.replace(new RegExp(entity, "g"), entities[entity]);
  }

  plainText = plainText.trim();

  return plainText;
};

const Task = () => {
  const offsetRef = useRef(0);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [exportAll, setExportAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [createdBy, setCreatedBy] = useState([]);

  const {
    tasks,
    getTasksLoader,
    getAllTasksLoader,
    allTasks,
    alltasksCount,
    getTaskDetailLoader,
    deleteTaskLoader,
    updateTaskLoader,
  } = useSelector((state) => state.tasks);

  const { companyAllUsers, getCompanyAllUserLoader } = useSelector(
    (state) => state.users
  );

  const { getTaskStatusMasterLoader, taskStatusMasterData } = useSelector(
    (state) => state.generalMaster
  );

  const getApiCall = (
    offset = 0,
    search = "",
    orderByColumn = "",
    order = "",
    task_assignee_user = [],
    created_by = []
  ) => {
    dispatch(
      actionGetTasks({
        offset,
        orderByColumn,
        order,
        search,
        task_assignee_user,
        created_by,
      })
    );
  };

  useEffect(() => {
    dispatch(actionGetTaskStatusMaster());
    dispatch(actionGetCompanyAllUsers());
  }, []);

  useEffect(() => {
    getApiCall(offsetRef.current);
  }, [dispatch]);

  const handleChangeStatus = (key, id) => {
    const selected = taskStatusItems.find(
      (item) => item.value === parseInt(key.key)
    );

    const req = {
      task_status_id: selected.value,
    };

    dispatch(
      actionUpdateTask({
        id,
        req,
      })
    );
    console.log({ selected, id });
  };

  const priorityItems = [
    {
      key: "1",
      label: "Low",
      value: "Low",
    },
    {
      key: "2",
      label: "Normal",
      value: "Normal",
    },
    {
      key: "3",
      label: "High",
      value: "High",
    },
    {
      key: "4",
      label: "Highest",
      value: "Highest",
    },
    {
      key: "5",
      label: "Lowest",
      value: "Lowest",
    },
  ];

  const users =
    companyAllUsers && companyAllUsers.length > 0
      ? companyAllUsers.map((data) => ({
          text: (
            <span>
              <Avatar
                src={data.profile_pic}
                style={{
                  verticalAlign: "middle",
                }}
                className=" mr-2"
              />

              {getFullName(data.first_name, data.last_name)}
            </span>
          ),
          value: data.id,
        }))
      : [];

  const columns = [
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text, data) => (
        <Typography.Paragraph
          title={text}
          ellipsis={{ rows: 1 }}
          style={{
            width: 200,
          }}
          onClick={() => {
            setUpdateId(data.task_id);
            setIsModalOpen(true);
            dispatch(actionGetTaskDetail(data.task_id));
          }}
          className="text-brand cursor-pointer !mb-0"
        >
          {text ? text : "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Due Date & Time",
      dataIndex: "due_date",
      key: "due_date",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text) => (text ? dayjs(text).format("DD-MM-YYYY HH:mm A") : "-"),
    },
    {
      title: "Status",
      dataIndex: "task_status_master",
      key: "task_status",
      sorter: true,
      sortIcon: () => <SortIcon />,

      width: 200,
      render: (text, data) => {
        const task_status_master = data.task_status_master || {};
        const status = task_status_master.status || "";
        return status ? (
          <div id="dropw">
            {
              <Dropdown
                menu={{
                  items: taskStatusItems.filter(
                    (item) => item.label.toLowerCase() !== status.toLowerCase()
                  ),
                  onClick: (key) => handleChangeStatus(key, data.task_id),
                }}
                placement="bottom"
                overlayClassName="status-dropdown"
                arrow={{
                  pointAtCenter: true,
                }}
                dropdownRender={(menu) => (
                  <div className="status-dropdown-container">{menu}</div>
                )}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Tag
                    color={getTaskStatusColor(text?.status)}
                    // className="py-1 px-2.5 text-sm font-popinsRegular"
                  >
                    {text?.status?.charAt(0).toUpperCase() +
                      text?.status.slice(1).toLowerCase()}
                  </Tag>
                </a>
              </Dropdown>
            }
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Priority",
      dataIndex: "task_priority",
      key: "task_priority",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 100,
      render: (text, data) => {
        return text ? (
          <Dropdown
            menu={{
              items: priorityItems.filter(
                (item) =>
                  item.value.toLowerCase() !== data.task_priority.toLowerCase()
              ),
              onClick: (key) => {
                const selected = priorityItems.find(
                  (item) => item.key === key.key
                );
                console.log({ priorityItems });
                const req = {
                  task_priority: selected.value,
                };

                dispatch(
                  actionUpdateTask({
                    id: data.task_id,
                    req,
                  })
                );
              },
            }}
            placement="bottom"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Tag
                color={getPriorityColor(text)}
                // className="py-1 px-2.5 text-sm font-popinsRegular"
              >
                {text}
              </Tag>
            </a>
          </Dropdown>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Reminder",
      dataIndex: "is_reminder",
      key: "is_reminder",
      width: 120,
      render: (text) => (
        <span className={`${text ? "text-green-600" : "text-red-600"}`}>
          {text ? "ON" : "OFF"}
        </span>
      ),
    },
    {
      title: "Reminder On",
      dataIndex: "reminder_on_date",
      key: "reminder_on_date",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text, data) =>
        data.is_reminder
          ? `${dayjs(data.reminder_on_date).format("DD-MM-YYYY")} ${dayjs(
              data.reminder_on_time,
              "HH:mm"
            ).format("h:mm A")}`
          : "-",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: true,
      sortIcon: () => <SortIcon />,
      // ellipsis: true,
      width: 200,
      render: (text, data) =>
        text ? (
          <Typography.Paragraph
            title={htmlToPlainText(text)}
            ellipsis={{ rows: 1 }}
            style={{ width: 200 }}
            className="!mb-0"
          >
            {htmlToPlainText(text)}
          </Typography.Paragraph>
        ) : (
          "-"
        ),
    },

    {
      title: "Assigned Users",
      dataIndex: "task_assignee_user",
      key: "task_assignee_user",
      filteredValue: selectedUsers,
      filters: users,
      width: 250,
      render: (assignees) => (
        <Avatar.Group
          size={30}
          max={{
            count: 3,
            style: {
              color: "white",
              backgroundColor: "#6883FD",
              cursor: "pointer",
              fontSize: 15,
            },
            popover: { trigger: "click" },
          }}
        >
          {assignees && assignees.length > 0
            ? assignees.map((item, index) => (
                <Tooltip
                  key={item.assigned_comany_users?.id}
                  title={item.assigned_comany_users?.full_name}
                >
                  <Avatar
                    key={index}
                    src={item.assigned_comany_users?.profile_pic}
                  />
                </Tooltip>
              ))
            : "-"}
        </Avatar.Group>
      ),
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      filters: users,
      filteredValue: createdBy,
      width: 200,
      ellipsis: true,
      render: (text) => (
        <div className="flex items-center">
          <Avatar
            style={{
              verticalAlign: "middle",
            }}
            src={text.profile_pic}
            size={30}
          />
          <p className="ml-2">{getFirstLetterCapital(text?.full_name)}</p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "8",
      align: "center",
      width: 100,
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () => {
                    setUpdateId(data.task_id);
                    setIsModalOpen(true);
                    dispatch(actionGetTaskDetail(data.task_id));
                  },
                },
                {
                  label: (
                    <Popconfirm
                      okButtonProps={{
                        loading: deleteTaskLoader,
                      }}
                      placement="topLeft"
                      title="Delete"
                      description={`Are you sure to delete this Task?`}
                      onConfirm={() => {
                        dispatch(actionDeleteTasks(data.task_id));
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <div
                        className="flex items-center"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <DeleteOutlined
                          style={{ fontSize: 12, color: "#ff4d4f" }}
                        />
                        <span
                          style={{
                            marginLeft: 8,
                            color: "#ff4d4f",
                          }}
                        >
                          Delete
                        </span>
                      </div>
                    </Popconfirm>
                  ),
                  key: "Delete",

                  onClick: () => {},
                },
              ],
            }}
          >
             <div className="flex justify-center items-center">
                <EllipsisOutlined />
              </div>
          </Dropdown>
        </>
      ),
    },
  ];

  const taskStatusItems = useMemo(() => {
    return taskStatusMasterData && taskStatusMasterData.length > 0
      ? taskStatusMasterData.map((data) => ({
          key: data.task_status_id,
          label: data.status,
          value: data.task_status_id,
        }))
      : [];
  }, [taskStatusMasterData]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setUpdateId("");
  };

  const handleRefresh = () => {
    setSearch(null);
    offsetRef.current = 0;
    setSelectedUsers([]);
    setCreatedBy([]);
    getApiCall(offsetRef.current);
  };

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      // Call Action
      getApiCall(
        offsetRef.current,
        e.target.value,
        "",
        "",
        selectedUsers,
        createdBy
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      getApiCall(
        offsetRef.current,
        e.target.value,
        "",
        "",
        selectedUsers,
        createdBy
      );
    }
  };

  const exportAllToExcel = async () => {
    const data =
      (await allTasks) &&
      allTasks.length > 0 &&
      allTasks.map((data) => ({
        "Task Owner": data?.created_by?.full_name
          ? data?.created_by?.full_name
          : "-",
        Title: data.name ? data.name : "-",
        "Due Date & Time": data.due_date ? data.due_date : "-",
        Status:
          data.task_status_master && data.task_status_master?.status
            ? data.task_status_master?.status
            : "-",
        Priority: data.task_priority ? data.task_priority : "-",
        "Assigned Users":
          data.task_assignee_user && data.task_assignee_user.length > 0
            ? data.task_assignee_user
                .map(
                  (com) =>
                    com.assigned_comany_users &&
                    com.assigned_comany_users?.full_name
                )
                .join(", ")
            : "-",
        "Reminder On": data.is_reminder
          ? data.reminder_on_date &&
            data.reminder_on_time &&
            `${data.reminder_on_date} ${data.reminder_on_time}`
          : "-",
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet2");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "Tasks.xlsx");
    dispatch(actionClearAllTasks({ setExportAll }));
  };

  useEffect(() => {
    if (exportAll && allTasks && allTasks.length > 0) {
      exportAllToExcel();
    }
  }, [allTasks]);

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllTasks());
    setExportAll(true);
  };

  const handleSortChange = (pagination, filters, sorter) => {
    console.log({ filters });
    const sorterParams = {
      field: sorter.key,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    setSelectedUsers(filters.task_assignee_user);
    setCreatedBy(filters.created_by || []);

    getApiCall(
      offsetRef.current,
      search,
      sorterParams.field,
      sorterParams.order,
      filters.task_assignee_user,
      filters.created_by
    );
  };

  return (
    <>
      <section className="main-wrapper">
        <PageTitle
          image={taskIcon}
          title="Task Information"
          buttonText="Add Task"
          handleClick={handleOpenModal}
          type="btn"
        />

        <CustomInputWithButtons
          handleRefresh={handleRefresh}
          handleSearch={handleSearch}
          handleBlurAndPressEnter={handleBlurAndPressEnter}
          searchValue={search}
          onClickDownloadMenu={onClickDownloadMenu}
        />
        <ConfigProvider
          theme={{
            components: {
              Checkbox: {
                colorPrimary: "#6883FD",
                colorPrimaryHover: "#6883FD",
              },
            },
          }}
        >
          <Table
            sticky
            rowKey="task_id"
            scrollToFirstRowOnChange={true}
            sortDirections={["ascend", "descend"]}
            onChange={handleSortChange}
            bordered
            scroll={{
              x: "max-content",
              y: window.innerWidth < 1500 ? "49vh" : "61vh",
            }}
            pagination={false}
            columns={columns}
            dataSource={(tasks || []).slice(0, 10)}
            loading={getTasksLoader || getAllTasksLoader || updateTaskLoader}
          />
        </ConfigProvider>
        <div className="flex justify-end mt-3">
          <Pagination
            hideOnSinglePage
            responsive={true}
            current={offsetRef.current + 1}
            className="flex items-center"
            showSizeChanger={false}
            total={alltasksCount}
            onChange={(e) => {
              offsetRef.current = e - 1;
              getApiCall(
                offsetRef.current,
                search,
                "",
                "",
                selectedUsers,
                createdBy
              );
            }}
          />
        </div>
      </section>

      <Modal
        width={1200}
        height={600}
        loading={getTaskDetailLoader}
        className="add-task-modal"
        closeIcon={<Image preview={false} src={closeIcon} alt="close-icon" />}
        title={null}
        open={isModalOpen}
        style={{ overflowX: "none", top: 20 }}
        footer={false}
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
        destroyOnClose={handleCloseModal}
      >
        <CreateTask
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          updateId={updateId}
          setUpdateId={setUpdateId}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
    </>
  );
};

export default Task;
