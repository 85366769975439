import { LoadingOutlined } from "@ant-design/icons";
import { Button, Image, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import success from "../../assets/img/complete.svg";
import otpImage from "../../assets/img/otp.svg";
import rightArrow from "../../assets/img/right-arrow-primary.svg";
import {
  actionContinueToLogin,
  actionLoginOTP,
  actionReSendOTPEmail,
  actionReSendOTPMobile,
  actionVerifyOTPEmail,
  actionVerifyOTPMobile,
  actionVerifyOtpLogin,
} from "../../store/services/authService";
import OtpInput from "../common/OtpInput";

const OtpVerfication = () => {
  const dispatch = useDispatch();
  const {
    continueLoader,
    verifyEmailOtpLoader,
    verifyLoginLoader,
    verifyPhoneOtpLoader,
  } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  let isFromSignUp = state === "fromRegister" ? true : false;

  const [PhoneOtp, setPhoneOtp] = useState("");
  const [EmailOtp, setEmailOtp] = useState("");
  const [phoneTimer, setPhoneTimer] = useState(0);
  const [emailTimer, setEmailTimer] = useState(0);

  const d = localStorage.getItem("userData");
  const data = JSON.parse(d);

  const [verified, setVerifed] = useState({
    is_mobile_verified: 0,
    is_email_verified: 0,
  });

  useEffect(() => {
    if (!data) {
      navigate("/login");
    }
  }, [data, navigate]);

  const onFinishPhone = (e) => {
    e.preventDefault();
    const req = { auth_key_phone: PhoneOtp, user_id: data.id };
    dispatch(actionVerifyOTPMobile({ req, setVerifed, setPhoneOtp }));
  };

  const onFinishEmail = (e) => {
    e.preventDefault();
    const req = { auth_key_email: EmailOtp, user_id: data.id };
    dispatch(actionVerifyOTPEmail({ req, setVerifed, setEmailOtp }));
  };

  const onFinishLogin = (e) => {
    e.preventDefault();
    const req = { auth_key: PhoneOtp, user_id: data.id };
    dispatch(actionVerifyOtpLogin({ req, navigate, setPhoneOtp }));
  };

  function hideAndAdd(phoneNumber) {
    // Convert number to string and trim any extra spaces
    phoneNumber = phoneNumber.toString().trim();

    let countryCode = "";
    let numberPart = "";

    if (phoneNumber.startsWith("+")) {
      countryCode = phoneNumber.substring(0, 3); // Extract country code
      numberPart = phoneNumber.substring(3).trim(); // Extract remaining number
    } else {
      // If the input doesn't start with '+', handle as plain number
      countryCode = phoneNumber.substring(0, 2); // Extract country code
      numberPart = phoneNumber.substring(2).trim(); // Extract remaining number
    }

    // Perform masking
    let maskedNumber = "";

    if (numberPart) {
      // Ensure there are at least 5 digits to mask
      if (numberPart.length >= 5) {
        const startMaskIndex = Math.floor((numberPart.length - 5) / 2);
        maskedNumber =
          numberPart.substring(0, startMaskIndex) +
          "*****" +
          numberPart.substring(startMaskIndex + 5);
      } else {
        maskedNumber = numberPart;
      }
    }

    const maskedPhoneNumber = countryCode + " " + maskedNumber;
    return maskedPhoneNumber;
  }

  const newSendTo =
    state && state.type === "PHONE"
      ? data.phoneNum
        ? hideAndAdd(data.phoneNum)
        : state.sendTo.slice(0, 2) +
          state.sendTo.slice(2).replace(/.(?=...)/g, "*")
      : state && state.sendTo;

  const newPhone =
    data &&
    data.phone &&
    data.phone.slice(0, 2) + data.phone.slice(2).replace(/.(?=.{3})/g, "*");

  const handleResendOtp = () => {
    const req = {
      type: state.type,
      send_to: state.sendTo,
      country_code: state.type === "PHONE" ? state.iso : "",
    };
    dispatch(
      actionLoginOTP({
        req,
        navigate,
        setTimer: setPhoneTimer,
        phone: state.type === "PHONE" ? state.phoneNum : "",
      })
    );
  };

  return (
    <div className="register-container   relative">
      <div className="title mb-4 pt-20 flex flex-col justify-center items-center gap-y-2 ">
        <p className="xl:text-[30px] text-secondary font-popinsSemiBold  lg:text-[26px]  md:text-2xl text-xl">
          OTP Verification
        </p>
        <p className="text-[#676767] lg:text-xl mb-3 md:text-base text-sm">
          OTP Should Received In mobile number & Email
        </p>

        {isFromSignUp ? (
          <>
            {/* Mobile OTP */}
            <div
              style={{ minHeight: 180, height: 180, flexGrow: 1 }}
              className="border flex items-center justify-center rounded-lg h-full border-[#E8E8E8]  w-full"
            >
              <div className="w-[20%] grow h-full rounded-r-none rounded-[10px] flex items-center justify-center  box-bg">
                <Image preview={false} src={otpImage} alt="Otp Image" />
              </div>

              <form
                name="normal_login"
                className="login-form px-3 sm:w-[80%] sm:px-7 py-4 "
                onSubmit={onFinishPhone}
              >
                <p className="lg:text-[16px] text-sm pb-4 sm:pl-0 pl-3">
                  Enter OTP Code sent to&nbsp; +
                  {data && data.phone_country_code} &nbsp;
                  {data && newPhone}
                </p>
                <div className="flex items-center justify-start w-full gap-x-3  otp-input-con ">
                  <div className=" flex items-center justify-between w-9/12">
                    <OtpInput onOtpChange={(otp) => setPhoneOtp(otp)} />
                  </div>

                  <div className="w-[25%]">
                    {!verified.is_mobile_verified ? (
                      <Button
                        disabled={
                          PhoneOtp.length !== 4 || verified.is_mobile_verified
                        }
                        loading={verifyPhoneOtpLoader}
                        type="primary"
                        className="filled-s-button text-sm  border-none rounded-[10px] font-popinsMedium tracking-wider min-h-[45px]"
                        // block
                        htmlType="submit"
                      >
                        Verify
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        className="success-btn flex-center gap-x-2 px-3 text-sm  border-none rounded-[10px] font-popinsMedium tracking-wider min-h-[45px]"
                        block
                        htmlType="button"
                      >
                        <img src={success} height={20} alt="Verified Icon" />{" "}
                        Verified
                      </Button>
                    )}
                  </div>
                </div>

                <div className="text-center flex items-center justify-center pt-4 gap-2">
                  <p className="2xl:text-base text-sm  ">
                    Didn’t receive OTP code?
                  </p>
                  {phoneTimer === 0 ? (
                    <p
                      onClick={() =>
                        !verified.is_mobile_verified &&
                        dispatch(
                          actionReSendOTPMobile({
                            user_id: data.id,
                            setPhoneTimer,
                          })
                        )
                      }
                      className={`2xl:text-base ${
                        verified.is_mobile_verified
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                      } text-sm  ${
                        verified.is_mobile_verified
                          ? "text-gray"
                          : "text-primary hover:text-primary"
                      }`}
                    >
                      Resend Code
                    </p>
                  ) : (
                    <p className="2xl:text-base text-sm text-primary">
                      Resend in {phoneTimer}s
                    </p>
                  )}
                </div>
              </form>
            </div>

            {/* Email OTP */}
            <div
              style={{ minHeight: 180, height: 180, flexGrow: 1 }}
              className="border flex items-center justify-center rounded-lg h-full border-[#E8E8E8]  w-full"
            >
              <div className="w-[20%] grow h-full rounded-r-none rounded-[10px] sm:flex hidden items-center justify-center  box-bg">
                <Image preview={false} src={otpImage} alt="Otp Image" />
              </div>

              <form
                name="email_form"
                className="login-form px-3 sm:w-[80%] sm:px-7 py-4 "
                onSubmit={onFinishEmail}
              >
                <p className="lg:text-[16px] text-sm pb-4 sm:pl-0 pl-3">
                  Enter OTP Code sent to&nbsp; {data && data.email}
                </p>
                <div className="flex items-center justify-start gap-x-3 w-full  otp-input-con">
                  <div className=" flex items-center justify-between w-9/12">
                    <OtpInput onOtpChange={(otp) => setEmailOtp(otp)} />
                  </div>

                  <div className="w-[25%]">
                    {!verified.is_email_verified ? (
                      <Button
                        disabled={
                          EmailOtp.length !== 4 || verified.is_email_verified
                        }
                        loading={verifyEmailOtpLoader}
                        type="primary"
                        className="filled-s-button text-sm  border-none rounded-[10px] font-popinsMedium tracking-wider min-h-[45px]"
                        // block
                        htmlType="submit"
                      >
                        Verify
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        className="success-btn flex-center gap-x-2 text-sm  border-none rounded-[10px] font-popinsMedium tracking-wider min-h-[45px]"
                        block
                        htmlType="button"
                      >
                        <img alt="Success" src={success} /> Verified
                      </Button>
                    )}
                  </div>
                </div>
                <div className="text-center flex items-center justify-center pt-4 gap-2">
                  <p className="2xl:text-base text-sm  ">
                    Didn’t receive OTP code?
                  </p>
                  {emailTimer === 0 ? (
                    <p
                      onClick={() =>
                        !verified.is_email_verified &&
                        dispatch(
                          actionReSendOTPEmail({
                            user_id: data.id,
                            setEmailTimer,
                          })
                        )
                      }
                      className={`2xl:text-base
                         ${
                           verified.is_email_verified
                             ? "cursor-not-allowed"
                             : "cursor-pointer"
                         }
                        text-sm  ${
                          verified.is_email_verified
                            ? "text-gray"
                            : "text-primary hover:text-primary"
                        }`}
                    >
                      Resend Code
                    </p>
                  ) : (
                    <p className="2xl:text-base text-sm text-primary">
                      Resend in {emailTimer}s
                    </p>
                  )}
                </div>
              </form>
            </div>
          </>
        ) : (
          <div
            style={{ minHeight: 180, height: 180, flexGrow: 1 }}
            className="border flex items-center justify-center rounded-lg h-full border-[#E8E8E8]  w-full"
          >
            <div className="w-[20%] grow h-full rounded-r-none rounded-[10px] sm:flex hidden items-center justify-center  box-bg">
              <Image preview={false} src={otpImage} alt="Otp Image" />
            </div>

            <form
              name="email_form"
              className="login-form w-full px-3 sm:w-[80%] sm:px-7 py-4 "
              onSubmit={
                state && state.page
                  ? (e) => {
                      e.preventDefault();
                      const req = {
                        auth_key_phone: PhoneOtp,
                        user_id: data.id,
                      };
                      dispatch(
                        actionVerifyOTPMobile({ req, setVerifed, setPhoneOtp })
                      );
                    }
                  : onFinishLogin
              }
            >
              <p className="lg:text-[16px] text-sm pb-4 sm:pl-0 pl-3">
                Enter OTP Code sent to&nbsp; {newSendTo}
              </p>
              <div className="flex items-center justify-start gap-x-3 w-full  otp-input-con">
                <div className=" flex items-center justify-between w-9/12">
                  <OtpInput onOtpChange={(otp) => setPhoneOtp(otp)} />
                </div>

                <div className="w-[25%]">
                  {!verified.is_mobile_verified ? (
                    <Button
                      disabled={PhoneOtp.length !== 4}
                      loading={verifyLoginLoader}
                      type="primary"
                      className="filled-s-button sm:text-sm text-xs  border-none rounded-[10px] font-popinsMedium tracking-wider min-h-[45px]"
                      // block
                      htmlType="submit"
                    >
                      Verify
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      className="success-btn flex-center gap-x-2 text-sm  border-none rounded-[10px] font-popinsMedium tracking-wider min-h-[45px]"
                      block
                      htmlType="button"
                    >
                      <img alt="Success" src={success} /> Verified
                    </Button>
                  )}
                </div>
              </div>
              <div className="text-center flex items-center justify-center pt-4 gap-2">
                <p className="2xl:text-base text-sm  ">
                  Didn’t receive OTP code?
                </p>
                {phoneTimer === 0 ? (
                  <p
                    onClick={handleResendOtp}
                    className={`2xl:text-base
                      ${
                        verified.is_mobile_verified ||
                        verified.is_email_verified
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                      }
                     text-sm   ${
                       verified.is_mobile_verified || verified.is_email_verified
                         ? "text-gray"
                         : "text-primary hover:text-primary"
                     }`}
                  >
                    Resend Code
                  </p>
                ) : (
                  <p className="2xl:text-base w-[120px] text-sm text-primary">
                    Resend in {phoneTimer}s
                  </p>
                )}
              </div>
            </form>
          </div>
        )}

        <div
          className={`flex continue-typo items-center  w-full ${
            verified.is_mobile_verified ? "justify-end" : "justify-between"
          }`}
        >
          {!verified.is_mobile_verified ? (
            state && state.page ? (
              <p
                onClick={() => navigate("/change-mobile")}
                className="cursor-pointer 2xl:text-lg flex-center gap-x-3 xl:text-base text-sm text-primary hover:text-primary"
              >
                Change Mobile No
                <Image preview={false} src={rightArrow} alt="Right Image" />
              </p>
            ) : !verified.is_mobile_verified && !verified.is_email_verified ? (
              <p
                onClick={() =>
                  navigate(
                    isFromSignUp
                      ? "/register"
                      : state && state.page
                      ? "/change-mobile"
                      : "/sign-otp",
                    {
                      state: { wrongDetails: true },
                    }
                  )
                }
                className="cursor-pointer 2xl:text-lg xl:text-base text-sm text-primary hover:text-primary"
              >
                Entered wrong Details?
              </p>
            ) : (
              ""
            )
          ) : (
            ""
          )}

          {verified.is_email_verified && verified.is_mobile_verified ? (
            <Typography.Text
              disabled={continueLoader}
              onClick={() =>
                dispatch(
                  actionContinueToLogin({ req: { user_id: data.id }, navigate })
                )
              }
              className="2xl:text-lg xl:text-base    text-sm flex-center gap-x-2 cursor-pointer text-primary hover:text-primary"
            >
              Continue
              {continueLoader ? (
                <LoadingOutlined color="#adb5bd" size={"medium"} />
              ) : (
                <Image preview={false} src={rightArrow} alt="Right Image" />
              )}
            </Typography.Text>
          ) : !isFromSignUp && verified.is_mobile_verified ? (
            <Typography.Text
              disabled={continueLoader}
              onClick={() =>
                dispatch(
                  actionContinueToLogin({ req: { user_id: data.id }, navigate })
                )
              }
              className="2xl:text-lg xl:text-base    text-sm flex-center gap-x-2 cursor-pointer text-primary hover:text-primary"
            >
              Continue
              {continueLoader ? (
                <LoadingOutlined color="#adb5bd" size={"medium"} />
              ) : (
                <Image preview={false} src={rightArrow} alt="Right Image" />
              )}
            </Typography.Text>
          ) : undefined}
        </div>
      </div>
    </div>
  );
};

export default OtpVerfication;
