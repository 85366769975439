import React, { useState, useEffect, useMemo } from "react";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import { useDispatch, useSelector } from "react-redux";
import { actionGetAllProductsMaster } from "../../store/services/productMasterService";

const DebounceSelect = ({ debounceTimeout = 800, ...props }) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const { getAllProductsLoader, productAllData } = useSelector(
    (state) => state.productMaster
  );

  // Memoizing the debounced function to avoid unnecessary recalculations
  const debounceFetcher = useMemo(() => {
    const loadOptions = async (value) => {
      if (!value) return; // Avoid calling API on empty input

      setFetching(true);
      // Dispatch the action to get products based on the search term
      dispatch(actionGetAllProductsMaster({ search: value }));
    };

    return debounce(loadOptions, debounceTimeout);
  }, [debounceTimeout, dispatch]);

  // Use useEffect to update options when productAllData changes
  useEffect(() => {
    if (productAllData?.length) {
      setOptions(
        productAllData.map((item) => ({
          label: item.material_name,
          value: item.material_master_id,
          base_unit_of_measure: item?.base_unit_of_measure,
        }))
      );
    } else {
      setOptions([]); // Clear options if no products are found
    }
    setFetching(false);
  }, [productAllData]);

  // Handling the state loading based on product data
  const loading = getAllProductsLoader || fetching;

  return (
    <Select
      filterOption={false}
      onSearch={(value) => debounceFetcher(value)}
      notFoundContent={loading ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
};

export default DebounceSelect;
