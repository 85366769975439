import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import plusIcon from "../../../assets/img/add-button.svg";
import deletevent from "../../../assets/img/deleteevent.svg";
import hsnsaccode from "../../../assets/img/hsn-sac-code.svg";
import editedevent from "../../../assets/img/editedevent.svg";
import editevent from "../../../assets/img/editevent.svg";
import {
  actionAddHsnSacCodeMaster,
  actionClearAllHSnCodes,
  actionDeleteHsnSacCodeMaster,
  actionEditHsnSacMaster,
  actionGetAllHsnSacMaster,
  actionGetHsnSacMaster,
  actionGetSingleHsnSacCodeMaster,
} from "../../../store/services/productMasterService";
import SortIcon from "../../common/SortIcon";
import { isValidSearchString } from "../../helper/common-function";
import * as XLSX from "xlsx";
import MasterSearchbar from "../../common/MasterSearchbar";
import TextArea from "antd/es/input/TextArea";

const HsnSacCode = ({ setSelectItem }) => {
  const [editableForm] = Form.useForm();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    hsnSacCodeData,
    getSingleHsnSacCodeData,
    allHsnSacCodeData,
    getHsnSacLoader,
    getAllHsnSacLoader,
    addHsnSacCodeLoader,
    deleteHsnSacCodeLoader,
    updateHsnSacCodeLoader,
    hsnCodeDataCount,
  } = useSelector((state) => state.productMaster);
  const offsetRef = useRef(0);
  const [search, setSearch] = useState("");
  const [edited, setEdited] = useState(false);
  const [exportAll, setExportAll] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isEditing = (record) => record.hsn_code_master_id === editingKey;

  useEffect(() => {
    dispatch(actionGetHsnSacMaster());
  }, [dispatch]);

  const handleAddHsnSac = (values) => {
    dispatch(actionAddHsnSacCodeMaster({ values, handleCancel }));
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    // const inputNode =
    //   inputType === "number" ? <Input /> : <Input variant="borderless" />;
    const inputNode =
      inputType === "number" ? (
        <Input />
      ) : dataIndex === "description" ? (
        <Input maxLength={255} showCount />
      ) : (
        <Input variant="borderless" />
      );
    return (
      <td {...restProps} className="input-height">
        {editing ? (
          <Form.Item
            className="mb-0 editable-order"
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: title === "HSN/SAC Code" ? true : false,
                message: ``,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: false, tooltip: true }}
            className="px-[11px]"
          >
            {children}
          </Typography.Paragraph>
        )}
      </td>
    );
  };

  const edit = (record) => {
    dispatch(actionGetSingleHsnSacCodeMaster(record.hsn_code_master_id));
    setEdited(!edited);
  };

  useEffect(() => {
    if (getSingleHsnSacCodeData && edited) {
      editableForm.setFieldsValue({
        hsn_code: getSingleHsnSacCodeData.hsn_code,
        description: getSingleHsnSacCodeData.description,
      });
      setEditingKey(getSingleHsnSacCodeData.hsn_code_master_id);
    }
  }, [getSingleHsnSacCodeData]);

  const save = async (id) => {
    try {
      const row = await editableForm.validateFields();
      const { hsn_code, description } = row;
      const req = { hsn_code, description };
      dispatch(actionEditHsnSacMaster({ id, req, setEditingKey, setEdited }));
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "srno",
      key: "1",
      width: "10%",
      editable: false,
      render: (text, record, index) => {
        const pageSize = 10;
        return offsetRef.current * pageSize + index + 1;
      },
    },
    {
      title: "HSN/SAC Code",
      dataIndex: "hsn_code",
      key: "2",
      width: "22%",
      editable: true,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "3",
      width: "50%",
      editable: true,
      ellipsis: true,
      render: (data) => (data ? data : "-"),
      sorter: true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Action",
      key: "4",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <div className="flex items-center ">
            {editable ? (
              <div className="flex items-center">
                {updateHsnSacCodeLoader[record.hsn_code_master_id] ? (
                  <LoadingOutlined />
                ) : (
                  <Typography.Link
                    onClick={() => save(record.hsn_code_master_id)}
                  >
                    <img src={editedevent} alt="" />
                  </Typography.Link>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-x-2 justify-center">
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => edit(record)}
                >
                  <img src={editevent} alt="" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              <Popconfirm
                okButtonProps={{
                  loading: deleteHsnSacCodeLoader[record.hsn_code_master_id],
                }}
                description="Are you sure you want to delete?"
                onConfirm={() =>
                  dispatch(
                    actionDeleteHsnSacCodeMaster({
                      id: record.hsn_code_master_id,
                      setEditingKey,
                    })
                  )
                }
              >
                <img src={deletevent} alt="" className="cursor-pointer" />
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "hsn_code" || col.dataIndex === "description"
            ? "text"
            : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleSearch = (e) => {
    if (isValidSearchString(e.target.value)) {
      offsetRef.current = 0;
      setSearch(e.target.value);
      if (e.target.value === "") {
        dispatch(
          actionGetHsnSacMaster({
            search: e.target.value,
            offset: offsetRef.current,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search && isValidSearchString(e.target.value)) {
      offsetRef.current = 0;
      dispatch(
        actionGetHsnSacMaster({
          search: e.target.value,
          offset: offsetRef.current,
        })
      );
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetHsnSacMaster({
        offset: offsetRef.current,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  const getData = async (data) => {
    console.log(data);
    const newData =
      (await data) &&
      data.length > 0 &&
      data.map((d) => ({
        "HSN/SAC Code": d.hsn_code ? d.hsn_code : "-",
        Description: d.description ? d.description : "-",
      }));
    return newData;
  };

  const exportToExcel = async (items, type = "") => {
    if (!items || (items && items.length === 0)) {
      return;
    }
    const data = await getData(items);
    // console.log({ data });
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "HSN-SAC Code");
    XLSX.writeFile(workbook, "hsn-sac-code.xlsx");
    type &&
      type === "all" &&
      dispatch(actionClearAllHSnCodes({ setExportAll }));
  };

  const onClickDownloadMenu = ({ key }) => {
    console.log({ key });
    if (key === "1") {
      exportToExcel(hsnSacCodeData);
    } else {
      dispatch(actionGetAllHsnSacMaster());
      setExportAll(true);
    }
  };

  useEffect(() => {
    if (exportAll && allHsnSacCodeData && allHsnSacCodeData.length > 0) {
      exportToExcel(allHsnSacCodeData, "all");
    }
  }, [allHsnSacCodeData]);

  const exportAllToExcel = async () => {
    const data = await getData(hsnSacCodeData);
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "HSN-SAC Code");
    XLSX.writeFile(workbook, "hsn-sac-code.xlsx");
  };

  return (
    <div className="event-input">
      <div className="flex justify-between mt-[15px]">
        <h1 className="text-[#6883FD] text-[20px] font-medium">HSN/SAC Code</h1>
        <div>
          <Button
            onClick={showModal}
            className="root-btn text-white hover:text-white py-5 flex-center radius"
            icon={<Image src={plusIcon} alt="Plus Icon" preview={false} />}
          >
            Add HSN/SAC Code
          </Button>
          <Modal
            centered
            onOk={() => handleCancel()}
            onCancel={() => handleCancel()}
            footer={false}
            width={500}
            title=""
            open={isModalOpen}
          >
            <div className="flex">
              <img src={hsnsaccode} alt="" />
              <h1 className="text-[#6883FD] text-[20px]  ml-[8px] mt-[5px] font-medium">
                HSN/SAC Code
              </h1>
            </div>
            <Form
              form={form}
              onFinish={handleAddHsnSac}
              layout="vertical"
              className="mt-[20px] mb-0"
            >
              <Form.Item
                name="hsn_code"
                label="HSN/SAC Code"
                className="mb-[12px]"
                normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please input hsn/sac code!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter HSN/SAC Code"
                  className="!indent-1 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: false,
                    message: "Please input hsn/sac description",
                  },
                ]}
              >
                <Input.TextArea
                  rows={3}
                  showCount
                  style={{ background: "white", resize: "none" }}
                  maxLength={255}
                  placeholder="Enter Description"
                  className="!indent-1 !pt-1 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>

              <div className="flex justify-center mt-[40px]">
                <Form.Item className="mb-0">
                  <Button
                    onClick={handleCancel}
                    type="button"
                    className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      loading={addHsnSacCodeLoader}
                      type="primary"
                      className="root-btn min-h-[45px] min-w-[120px]"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
      <div className="pt-[25px]">
        <MasterSearchbar
          isPagination={true}
          handleSearch={handleSearch}
          handleBlurAndPressEnter={handleBlurAndPressEnter}
          importRoute={"/master-data/hsn-sac-code-import"}  
          search={search}
          onClickDownloadMenu={onClickDownloadMenu}
        />
      </div>

      <div className="pt-5 pb-[40px]">
        <Form form={editableForm} component={false}>
          <Table
            scroll={{ y: 500 }}
            pagination={false}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            onChange={handleSortChange}
            loading={getHsnSacLoader || getAllHsnSacLoader}
            bordered
            dataSource={(hsnSacCodeData || []).slice(0, 10)}
            rowKey="hsn_code_master_id"
            columns={mergedColumns}
            rowClassName="editable-row"
          />
        </Form>
      </div>

      <div className="flex justify-between ">
        <p className="text-[15px] font-popinsMedium">
          Total : {hsnCodeDataCount ? hsnCodeDataCount : 0}
        </p>
        <Pagination
          hideOnSinglePage
          responsive={true}
          current={offsetRef.current + 1}
          className="flex items-center"
          showSizeChanger={false}
          total={hsnCodeDataCount}
          onChange={(e) => {
            offsetRef.current = e - 1;
            dispatch(
              actionGetHsnSacMaster({
                offset: offsetRef.current,
                search,
              })
            );
          }}
        />
      </div>
    </div>
  );
};

export default HsnSacCode;
