// src/utils/statusUtils.js
export const getTaskStatusColor = (status) => {
  switch (status) {
    case "In Progress":
      return "orange";
    case "Completed":
      return "green";
    case "Deferred":
      return "blue";
    case "Waiting for input":
      return "purple";
    case "Not Started":
      return "gray";
    default:
      return "default";
  }
};

export const getPriorityColor = (priority) => {
  switch (priority) {
    case "Highest":
      return "red";
    case "High":
      return "orange";
    case "Normal":
      return "default";
    case "Low":
      return "yellow";
    case "Lowest":
      return "green";
    default:
      return "red";
  }
};

export const getBeatPlanStatusColor = (priority) => {
  switch (priority) {
    case "Active":
      return "green";
    case "Inactive":
      return "red";
    default:
      return "black";
  }
};

export const getFeedBackStatusColor = (status) => {
  switch (status) {
    case "Pending":
      return "red";
    case "Reviewed":
      return "orange";
    case "In Progress":
      return "blue";
    case "Resolved":
      return "green";
    default:
      return "default";
  }
};
