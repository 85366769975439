import { useSelector } from "react-redux";

const usePermission = () => {
  const { currentCompanyUser } = useSelector((state) => state.auth);

  const hasPermission = (resource, action) => {
    if (
      !currentCompanyUser ||
      !currentCompanyUser.roleprofile ||
      !currentCompanyUser.roleprofile.role_profile ||
      !currentCompanyUser.roleprofile.role_profile.permission_data
    ) {
      return false;
    }
    return currentCompanyUser.roleprofile.role_profile.permission_data.some(
      (p) =>
        p.permissions.resources === resource && p.permissions.actions === action
    );
  };

  return { hasPermission };
};

export default usePermission;
