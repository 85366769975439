import { CloseOutlined, LockOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Image, Input, Modal, Row, Tree } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import parent from "../../../assets/img/parent.svg";
import { getAllKeys } from "../../helper/common-function";
import { actionDeleteRole } from "../../../store/services/settingService";

const DeleteRoleForm = ({ handleCancel, deleteData }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { roleHierachy, deleteRoleLoader } = useSelector(
    (state) => state.setting
  );

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [parentRole, setParentRole] = useState("");
  const [modalParent, setModalParent] = useState(false);
  const [treeData, setTreeData] = useState([]);

  const onFinish = (values) => {
    const req = {
      id: deleteData.key,
      transfer_id: values.title,
    };
    dispatch(actionDeleteRole({ req, handleCancel, form }));
  };

  const transformData = (data, isRoot = true) => {
    return data.map((item) => ({
      title: item.name,
      key: item.id,
      children: item.children ? transformData(item.children, false) : [],
      disabled: isRoot,
    }));
  };

  const handleDisable = (id, data) => {
    const newData = JSON.parse(JSON.stringify(data)); // Deep copy the data
    newData.forEach((node) => disableNode(node, id));
    setTreeData(newData);
  };

  const disableNode = (node, id) => {
    if (node.key === id) {
      node.disabled = true;
      node.children.forEach((child) => disableAllDescendants(child));
    } else {
      node.children.forEach((child) => disableNode(child, id));
    }
  };

  const disableAllDescendants = (node) => {
    node.disabled = true;
    node.children.forEach((child) => disableAllDescendants(child));
  };

  useEffect(() => {
    if (roleHierachy && roleHierachy.length > 0) {
      const transformedData = transformData(roleHierachy);
      handleDisable(deleteData.key, transformedData);
      setExpandedKeys(getAllKeys(transformedData));
    }
  }, [roleHierachy, deleteData]);

  const roleData = [];
  roleHierachy &&
    roleHierachy.length > 0 &&
    roleHierachy.forEach((role) => {
      roleData.push({
        label: role.name,
        value: role.id,
      });
    });

  const handleModalClose = () => {
    setModalParent(false);
    form.resetFields();
    setParentRole("");
  };

  const resetTreeData = () => {
    if (roleHierachy && roleHierachy.length > 0) {
      const transformedData = transformData(roleHierachy);
      setTreeData(transformedData);
      setExpandedKeys(getAllKeys(transformedData));
    }
  };

  return (
    <section className="main-section profile-card">
      <div className="mb-4">
        <p className="mt-2 font-popinsMedium text-lg">Delete Role</p>
        <p className="text-sm  mt-2 text-[#7c7c7c] max-w-[65%]">
          Before deleting, you must transfer users and subordinates with this
          role to a new role.
        </p>
        <p className="my-6 font-popinsRegular">Transfer Users and Sub-roles:</p>
      </div>
      <Form
        layout="vertical"
        name="basic"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row>
          <div className="flex items-center text-muted w-full">
            <p className="font-popinsMedium text-md min-w-36">
              Role to be Deleted
            </p>
            <Input
              className="w-full"
              value={deleteData?.title}
              suffix={<LockOutlined />}
              disabled
            />
          </div>
          <div className="flex items-center text-muted w-full mt-4">
            <p className="font-popinsMedium text-md min-w-36">
              Transfer To Role
            </p>
            <Form.Item
              className="w-full"
              name="transfer_id"
              rules={[
                {
                  required: true,
                  message: "Please Select Role!",
                },
              ]}
            >
              <Input
                value={parentRole}
                onChange={() => console.log("hello")}
                allowClear
                style={{ cursor: "pointer" }}
                className="cursor-pointer "
                onClick={() => setModalParent(true)}
                placeholder="Select Parent Role"
                readOnly
                suffix={
                  !form.getFieldValue("transfer_id") ? (
                    <Image preview={false} src={parent} alt="parent user" />
                  ) : (
                    <CloseOutlined
                      onClick={() => {
                        form.resetFields(["transfer_id"]);
                        form.resetFields(["title"]);
                        setParentRole("");
                      }}
                    />
                  )
                }
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              hidden
              label="Role Name"
              name="title"
              rules={[
                {
                  required: false,
                  message: "Please Enter Role Name!",
                },
              ]}
            >
              <Input placeholder="Enter Role Name" />
            </Form.Item>
          </div>
          <Col
            span={24}
            className="flex items-center my-4 justify-end gap-x-10"
          >
            <Form.Item>
              <Button
                onClick={() => {
                  handleCancel();
                  resetTreeData();
                }}
                type="button"
                className="gray-button text-white hover:text-white  min-h-[45px] min-w-[120px]"
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                loading={deleteRoleLoader}
                type="primary"
                danger
                className="min-h-[45px] min-w-[120px]"
                htmlType="submit"
              >
                Transfer and Delete
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Modal
        title={
          <p className="font-popinsRegular tracking-wide text-lg">Roles List</p>
        }
        width={700}
        style={{ top: 20 }}
        centered
        open={modalParent}
        onOk={() => handleModalClose()}
        onCancel={() => handleModalClose()}
        footer={false}
      >
        <Tree
          className="py-4"
          expandedKeys={expandedKeys}
          showLine
          showIcon={false}
          onExpand={(keys) => setExpandedKeys(keys)}
          treeData={treeData}
          titleRender={(nodeData, index) => {
            return (
              <div
                onClick={() => {
                  form.setFieldsValue({
                    transfer_id: nodeData.title,
                    title: nodeData.key,
                  });
                  setModalParent(false);
                  setParentRole(nodeData.title);
                }}
                className="hover:bg-transparent"
              >
                {nodeData.title}
              </div>
            );
          }}
        />
      </Modal>
    </section>
  );
};

export default DeleteRoleForm;
