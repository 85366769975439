import { Avatar, Button, Card, Empty, Image, Table, Tag, Timeline } from "antd";
import Typography from "antd/es/typography/Typography";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import expensefirstdetail from "../../assets/img/expensefirstdetail.svg";
import historyexpense from "../../assets/img/history-expense.svg";
import leftArrowPrimary from "../../assets/img/leftArrowPrimary.svg";
import {
  actionDeleteSalesOrder,
  actionGetSalesOrderDetails,
} from "../../store/services/salesService";
import { getSOStatusClass, getStatusClass } from "./SalesOrders";
import { getFirstLetterCapital } from "../helper/common-function";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import DeleteModal from "../common/DeleteModal";

const ViewSalesOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {};
  const dispatch = useDispatch();
  const [selectItem, setSelectItem] = useState("items");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const {
    getSalesOrderDetailLoader,
    salesOrderDetails,
    deleteSalesOrderLoader,
  } = useSelector((state) => state.sales);
  const { currentCompanyUser } = useSelector((state) => state.auth);

  if (!id && !getSalesOrderDetailLoader) {
    return <Empty description={"No data found"} />;
  }

  useEffect(() => {
    if (id) {
      dispatch(actionGetSalesOrderDetails(id));
    }
  }, [id, dispatch]);

  console.log({ salesOrderDetails });

  const columns = [
    {
      title: "Material",
      dataIndex: "material_code",
      key: "material_code",
      fixed: "left",
      ellipsis: true,
      render: (text) => {
        return text && text ? text?.material_name : <span>-</span>;
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      ellipsis: true,
    },
    {
      title: "Unit of Measure",
      dataIndex: "unit_of_measure",
      key: "unit_of_measure",

      ellipsis: true,
      render: (text, data) => {
        return <>{text?.code ? text?.code : "-"}</>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 250,
      render: (status) => {
        return status ? (
          <Tag
            color={getSOStatusColor(status)}
            // className="py-1 px-2.5 text-sm font-popinsRegular"
          >
            {status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
          </Tag>
        ) : (
          "-"
        );
      },
    },
  ];

  const items = [
    {
      label: "Items",
      key: "items",
    },
    {
      label: "History",
      key: "history",
    },
  ];

  const showTabs = () => {
    switch (selectItem) {
      case "items":
        return (
          <>
            <Table
              className="mt-4 expense-items-table"
              sticky
              rowKey="sales_order_item_id"
              scrollToFirstRowOnChange={true}
              sortDirections={["ascend", "descend"]}
              bordered
              // virtual
              scroll={{
                y: 600,
              }}
              pagination={{
                hideOnSinglePage: true,
              }}
              columns={columns}
              loading={getSalesOrderDetailLoader}
              dataSource={salesOrderDetails.items}
            />
          </>
        );

      case "history":
        return (
          <>
            <div className="mt-8 remove-bg-dot">
              <Timeline
                key="sales_order_log_id"
                className="remove-bg-dot w-[35%]"
                items={
                  salesOrderDetails.sales_order_logs &&
                  salesOrderDetails.sales_order_logs.length > 0
                    ? salesOrderDetails.sales_order_logs.map((logItem) => ({
                        key: logItem.sales_order_log_id,
                        dot: (
                          <Avatar
                            src={logItem.created_by.profile_pic}
                            alt="history expense"
                            className="border-[2px] border-[#6883fd4f]"
                          />
                        ),
                        children: (
                          <>
                            <div className="ml-2">
                              <p className="text-[#7C7C7C] text-[15px] ml-2">
                                <span className="font-popinsSemiBold">
                                  {logItem.created_by.full_name ?? "-"}
                                </span>{" "}
                                <span>
                                  {dayjs(logItem.created_at).format(
                                    "DD-MM-YYYY"
                                  )}
                                </span>
                              </p>

                              <span className="text-[15px] ">
                                {logItem.log ?? "-"}
                              </span>
                            </div>
                          </>
                        ),
                      }))
                    : [
                        {
                          children: (
                            <p className="text-[#7C7C7C] text-[15px] text-center">
                              No Logs Found
                            </p>
                          ),
                        },
                      ]
                }
              />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const getStatusStyles = (status) => {
    switch (status?.toLowerCase()) {
      case "submitted":
        return "border-yellow-500 text-yellow-500 bg-yellow-50";
      case "approved":
        return "border-green-500 text-green-500 bg-green-50";
      case "unsubmitted":
        return "border-gray-500 text-gray-500 bg-gray-50";
      case "rejected":
        return "border-red-500 text-red-500 bg-red-50";
      case "reimbursed":
        return "border-blue-500 text-blue-500 bg-blue-50";
      case "pending approval":
        return "border-orange-500 text-orange-500 bg-orange-50";
      case "awaiting approval":
        return "border-purple-500 text-purple-500 bg-purple-50";
      default:
        return "border-gray-300 text-white-500 bg-white";
    }
  };

  // Function to get color based on so-status
  const getSOStatusColor = (status) => {
    switch (status) {
      case "PENDING":
        return "orange";
      case "DELIVERED":
        return "green";
      case "PARTIALLY DELIVERED":
        return "default";
      default:
        return "default";
    }
  };

  // Function to get color based on status
  const getStatusColor = (status) => {
    switch (status) {
      case "PENDING":
        return "orange";
      case "DONE":
        return "green";
      case "FAILED":
        return "red";
      default:
        return "default";
    }
  };

  return (
    <>
      <section className="main-section px-4 custom-color" id="overview">
        <div className="flex items-center justify-between gap-x-5">
          <div
            className="flex items-center gap-x-3 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <div className="rounded-[10px] flex items-center">
              <Image
                src={leftArrowPrimary}
                alt="image"
                height={20}
                width={20}
                preview={false}
              />
            </div>
            <h1 className="lg:text-lg text-base text-primary/90">
              Sales Orders
            </h1>
          </div>
          {/* <span
            className={`${getStatusClass(
              salesOrderDetails?.status
            )} border-[0.5px] py-1 rounded-[5px] px-2.5 text-sm`}
          >
            {salesOrderDetails?.status?.charAt(0).toUpperCase() +
              salesOrderDetails?.status?.slice(1).toLowerCase()}
          </span> */}
          {/* <Tag color={getStatusColor(salesOrderDetails?.status)} className="py-1 px-2.5 text-sm font-popinsRegular">
            {salesOrderDetails?.status
              ?.charAt(0)
              .toUpperCase() +
              salesOrderDetails?.status
                ?.slice(1)
                .toLowerCase()}
          </Tag> */}
          <div className="space-x-3">
            <Button
              onClick={() => navigate("/update-sales-order", { state: id })}
              type="primary"
              className="root-btn min-h-[45px] min-w-[120px]"
              htmlType="submit"
            >
              <EditOutlined /> Edit
            </Button>
            <Button
              onClick={() => {
                setIsDeleteModalOpen((prev) => !prev);
              }}
              className="delete-btn min-h-[45px] min-w-[110px]"
            >
              <DeleteOutlined /> Delete
            </Button>
          </div>
        </div>
        <div className="mt-4">
          <Card className="">
            <div className="flex justify-between items-center">
              <div>
                <div className="flex bg-white flex-col items-start gap-y-2">
                  <div className="flex flex-col  pr-5 py-1 items-center">
                    <div className="flex flex-col  text-center">
                      <p className="text-base font-popinsMedium text-black flex">
                        Sales Order Number:
                        <span className="text-[#7C7C7C] ml-2">
                          {salesOrderDetails.sales_order_number ?? "-"}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col  pr-5 py-1 items-center">
                    <div className="flex flex-col  text-center">
                      <p className="text-base font-popinsMedium text-black flex">
                        SAP Status:
                        <Tag
                          color={getStatusColor(salesOrderDetails?.status)}
                          // className="ml-2 py-1 px-2.5 text-sm font-popinsRegular"
                        >
                          {salesOrderDetails?.status?.charAt(0).toUpperCase() +
                            salesOrderDetails?.status?.slice(1).toLowerCase()}
                        </Tag>
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col  pr-5 py-1 items-center">
                    <div className="flex flex-col  text-center">
                      <p className="text-base font-popinsMedium text-black flex">
                        Sales Order Status:
                        <Tag
                          color={getSOStatusColor(salesOrderDetails?.so_status)}
                          // className="ml-2 py-1 px-2.5 text-sm font-popinsRegular"
                        >
                          {salesOrderDetails?.so_status
                            ?.charAt(0)
                            .toUpperCase() +
                            salesOrderDetails?.so_status
                              ?.slice(1)
                              .toLowerCase()}
                        </Tag>
                      </p>
                    </div>
                  </div>

                  {/* <div className="border-l border-[#DDDAE9] h-5"></div> */}
                  <div className=" flex  gap-x-3 py-1 items-center">
                    <div className="flex flex-col  text-center">
                      <p className="text-base font-popinsMedium text-black flex">
                        Date:
                        <span className="text-[#7C7C7C] ml-2">
                          {" "}
                          {salesOrderDetails?.created_at
                            ? dayjs(salesOrderDetails.created_at).format(
                                "DD-MM-YYYY"
                              )
                            : "-"}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className=" flex  gap-x-3 py-1 items-center">
                    <div className="flex flex-col  text-center">
                      <p className="text-base font-popinsMedium text-black flex">
                        Business Partner / Customer:
                        <span className="text-[#7C7C7C] ml-2">
                          {" "}
                          {salesOrderDetails.customer?.business_partner_name ??
                            "-"}
                        </span>
                      </p>
                    </div>
                  </div>
                  {/* <div className="border-l border-[#DDDAE9] h-5"></div> */}
                  <div className="flex  gap-x-3 py-1 items-center">
                    <div className="flex flex-col  text-center">
                      <p className="text-base font-popinsMedium text-black flex">
                        Ship to Party:
                        <span className="text-[#7C7C7C] ml-2">
                          {salesOrderDetails.ship_to_party &&
                          salesOrderDetails.ship_to_party?.business_partner_name
                            ? salesOrderDetails.ship_to_party
                                ?.business_partner_name
                            : "-"}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="flex  gap-x-3 py-1 items-center">
                    <div className="flex flex-col  text-center">
                      <p className="text-base font-popinsMedium text-black flex">
                        SAP Sales Order Number:
                        <span className="text-[#7C7C7C] ml-2">
                          {salesOrderDetails.sap_sales_order_number
                            ? salesOrderDetails.sap_sales_order_number
                            : "-"}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="mt-4 flex items-center">
          <p className="text-base font-popinsMedium text-black text-[15px]">
            Note:
          </p>
          <span className="text-[#7C7C7C] ml-2 text-[15px]">
            {salesOrderDetails.note && salesOrderDetails.note.trim() !== ""
              ? salesOrderDetails.note
              : "-"}
          </span>
        </div>

        <div className="mt-4">
          <div className="flex flex-col border border-[#DDDAE9] rounded-[10px] bg-white mt-5 w-[35%] px-6">
            <div className="flex items-center justify-center mt-2">
              {items.map(({ key, label }) => (
                <div
                  key={key}
                  className={`flex-1 text-center text-[15px] font-medium cursor-pointer  ${
                    selectItem === key
                      ? "text-blue-500 border-b-2 border-blue-500"
                      : "text-black"
                  }`}
                  onClick={() => setSelectItem(key)}
                >
                  <h1 className="mb-[7px]">{label}</h1>
                </div>
              ))}
            </div>
          </div>
          <div>{showTabs()}</div>
        </div>
        <DeleteModal
          title="Are you sure you want to delete this sales order?"
          description="All the associations will be removed for this Collection"
          loading={deleteSalesOrderLoader}
          onCancel={() => {
            setIsDeleteModalOpen(false);
          }}
          onConfirm={() =>
            dispatch(
              actionDeleteSalesOrder({
                id,
                setIsDeleteModalOpen,
                navigate,
              })
            )
          }
          isVisible={isDeleteModalOpen}
        />
      </section>
    </>
  );
};

export default ViewSalesOrder;
