// TODO: Leads Start

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/web-config";
import { handleGetResponse } from "./commonService";
import { toast } from "sonner";
import { handleErrorMessage } from "../../components/helper/common-function";

// Action to get Leads
export const actionGetLeads = createAsyncThunk(
  "actionGetLeads",
  async ({ offset, search, orderByColumn, order }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/lead?limit=10&offset=${offset}${
          search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${
          order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action to clear all Leads
export const actionClearAllLeads = createAsyncThunk(
  "actionClearAllLeads",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

// Action to get All Leads
export const actionGetAllLeads = createAsyncThunk(
  "actionGetAllLeads",
  async () => {
    return handleGetResponse(`lead?getall=YES`); // Modify endpoint as needed
  }
);

// Action to get Single Lead data
export const actionGetLeadDetail = createAsyncThunk(
  "actionLeadDetail",
  async (id) => {
    return handleGetResponse(`lead/${id}`); // Modify endpoint as needed
  }
);

// Action to add Lead
export const actionAddLead = createAsyncThunk(
  "actionAddLead",
  async ({ req, navigate }) => {
    try {
      const response = await axios.post(`${BASE_URL}/lead`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/lead-management");
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action to delete Lead
export const actionDeleteLead = createAsyncThunk(
  "actionDeleteLead",
  async ({ id, handleCloseDeleteModal }) => {
    try {
      const response = await axios.delete(`${BASE_URL}/lead/${id}`);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCloseDeleteModal && handleCloseDeleteModal();
        return id;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action to Update Lead
export const actionUpdateLead = createAsyncThunk(
  "actionUpdateLead",
  async ({ id, req, navigate }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/lead/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/lead-management");
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Change Status
export const actionLeadChangeStatus = createAsyncThunk(
  "actionLeadChangeStatus",
  async ({ id, values, handleCancelLeadStatusModal }) => {
    try {
      const res = await axios.put(`${BASE_URL}/lead/${id}`, values);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancelLeadStatusModal && handleCancelLeadStatusModal();
        return data;
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Convert leads to opportunity
export const actionConvertLeadToOpportunity = createAsyncThunk(
  "actionConvertLeadToOpportunity",
  async (id) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/lead/convert-to-opportunity/${id}`
      );
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        return data;
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//TODO: Leads End
