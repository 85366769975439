import { Button, Card, Checkbox, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import lock from "../../assets/img/lock.svg";
import { actionCreateAccount } from "../../store/services/authService";
import {
  actionGetCountries,
  actionGetStates,
} from "../../store/services/locationService";
import PhoneInputComponent from "../common/PhoneInputComponent";

const CreateAccount = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(true);
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const { createAccountLoader } = useSelector((state) => state.auth);
  const { countries, states, cities, getCountryLoader, statesLoader } =
    useSelector((state) => state.location);
  const [value, setValue] = useState({
    fullPhoneNumber: "",
    iso: "in",
    phone: "",
  });
  const location = useLocation();

  const {
    user_email,
    user_first_Name,
    user_last_Name,
    user_token,
    user_fullname,
  } = location && location.state ? location.state : {};

  useEffect(() => {
    location &&
      location.state &&
      form.setFieldsValue({
        first_name: user_first_Name,
        last_name: user_last_Name,
      });
  }, [location, form, user_first_Name, user_last_Name]);

  useEffect(() => {
    if (!location.state) {
      navigate("/login");
    }
  }, [location.state, navigate]);

  const handleChange = (valid) => {
    setIsValid(valid);
  };

  const onFinish = (values) => {
    const valid =
      value && value.phone && value.phone.length < 15 ? true : false;
    const { terms_and_condition, ...items } = values;

    if (valid) {
      setIsValid(true);
      const req = {
        ...items,
        phone: value.phone,
        country_code: value.iso,
        social_type: "GOOGLE",
        token: user_token,
      };
      dispatch(
        actionCreateAccount({
          values: req,
          navigate,
          phone: value.fullPhoneNumber,
        })
      );
    } else {
      setIsValid(false);
    }
  };

  const onFinishFailed = ({ values, errorFields }) => {
    const valid =
      value && value.phone && value.phone.length < 15 ? true : false;
    if (valid) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  useEffect(() => {
    dispatch(actionGetCountries());
  }, [dispatch]);

  useEffect(() => {
    if (countries && countries.length) {
      const india = countries.find((country) => country.iso2 === "IN");
      if (india) {
        form.setFieldsValue({ country_id: india.id });
        dispatch(actionGetStates(india.id));
      }
    }
  }, [countries, dispatch, form]);

  const handleGetState = (val, data) => {
    dispatch(actionGetStates(data.value));
    form.setFieldsValue({
      state_id: null,
    });
  };
  const country =
    (countries &&
      countries.length &&
      countries?.map(({ name, iso2, id, ...data }) => ({
        ...data,
        label: name,
        value: id,
        country_code: iso2,
        id: id,
      }))) ||
    [];

  const state =
    (states &&
      states.length &&
      states?.map(({ name, state_code, id, ...data }) => ({
        ...data,
        label: name,
        value: id,
        state_code,
      }))) ||
    [];

  return (
    <div className="register-container relative pt-6">
      <Card
        className=" relative my-auto  2xl:max-w-2xl md:max-w-[61%]  w-[95%] mx-auto border rounded-[20px]"
        title={
          <div className="py-[20px]">
            <div className="flex gap-x-4 justify-between">
              <div className="">
                <div className="relative w-[65px] h-[65px]  flex justify-center items-center text-3xl  bg-secondary rounded-full text-white">
                  {user_first_Name
                    ? user_first_Name?.charAt(0).toUpperCase()
                    : user_last_Name?.charAt(0).toUpperCase()}
                  <div
                    style={{ boxShadow: "0px 0px 4px 0px #00000040" }}
                    className="px-5 py-2 absolute -right-5 -top-2 z-40 rounded-[18px] bg-white/90 shadow-2xl"
                  >
                    <img src={lock} alt="lock" />
                  </div>
                </div>
              </div>
              <div className="flex-col flex gap-y-1 pl-5">
                <h2 className="lg:text-[20px] whitespace-normal text-[15px] sm:text-[16px] ">
                  Welcome you {user_fullname}
                </h2>
                <p className="lg:text-[15px] sm:text-[15px] text-xs whitespace-normal leading-6 text-[#6C6C6C] ">
                  A new CRM Harbour account will be created for the email
                  address {user_email}
                </p>
              </div>
            </div>
          </div>
        }
      >
        <Form onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
          <Row gutter={18}>
            <Col xs={24} md={12}>
              <Form.Item
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: "Please input your First name!",
                  },
                ]}
              >
                <Input placeholder="First Name" className="py-[10px]" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                name="last_name"
                className=""
                rules={[
                  { required: true, message: "Please input your Last Name!" },
                ]}
              >
                <Input placeholder="Last Name" className="py-[10px]" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12} className="mb-1">
              <PhoneInputComponent
                value={value.fullPhoneNumber}
                onChange={handleChange}
                setIsValid={setIsValid}
                isValid={isValid}
                setValue={setValue}
              />
            </Col>
            <Col xs={24} md={12} className="" />

            <Col xs={24} md={12}>
              <Form.Item
                name="country_id"
                className=""
                rules={[{ required: true, message: "Please select country!" }]}
              >
                <Select
                  allowClear
                  showSearch
                  loading={getCountryLoader}
                  onSelect={handleGetState}
                  placeholder="Select Country"
                  className=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={country}
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              md={12}
              className="sm:pt-6 md:pt-0 pt-7 rounded-[20px]"
            >
              <Form.Item
                name="state_id"
                className=""
                rules={[{ required: true, message: "Please Select State!" }]}
              >
                <Select
                  allowClear
                  showSearch
                  // onSelect={handleGetState}
                  placeholder="Select State"
                  optionFilterProp="children"
                  className=""
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={state}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <p className="pt-6 md:text-[14px] sm:text-[12px] text-[12px]">
                Your data will be stored in india data center.
              </p>

              <Form.Item
                name="terms_and_conditions"
                className="mt-2"
                style={{
                  paddingTop: "10px",
                }}
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              "You must agree to the Terms of Service and Privacy Policy."
                            )
                          ),
                  },
                ]}
              >
                <Checkbox className="text-sm font-medium my-2">
                  <p className="md:text-[14px] sm:text-[12px] text-[11px]">
                    I agree to the{" "}
                    <span className="text-blue-400 ">Terms of Service</span> and{" "}
                    <span className="text-blue-400">Privacy policy </span>
                    of CRM Harbour
                  </p>
                </Checkbox>
              </Form.Item>

              <Form.Item>
                <Button
                  loading={createAccountLoader}
                  // disabled={!checked ? true : false}
                  type="primary"
                  className="filled-s-button my-3 text-sm min-h-[40px]  border-none rounded-[10px]"
                  htmlType="submit"
                >
                  Create Account
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {/*    <Link to="/change-mobile">Change Mobile?</Link> */}
      </Card>
    </div>
  );
};

export default CreateAccount;
