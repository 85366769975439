import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Flex,
  Form,
  Image,
  Modal,
  Pagination,
  Popconfirm,
  Select,
  Table,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import plusIcon from "../../../assets/img/add-button.svg";
import user from "../../../assets/img/user-img.svg";
import {
  actionDeleteRoleProfile,
  actionGetAllRoleProfile,
  actionGetRoleProfile,
} from "../../../store/services/settingService";
import CustomInputWithButtons from "../../common/CustomInputWithButtons ";
import SortIcon from "../../common/SortIcon";
import { getFormattedDateTime } from "../../helper/common-function";

const RoleProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteData, setDeleteData] = useState(false);
  const [form] = Form.useForm();
  const offsetRef = useRef(0);
  const [search, setSearch] = useState("");
  const [sortedInfo, setSortedInfo] = useState({});

  const {
    roleProfile,
    roleProfileCounts,
    getRoleProfileLoader,
    deleteRoleProfileLoader,
    roleAllProfile,
  } = useSelector((state) => state.setting);

  const initialColumns = [
    {
      title: "Profile Name",
      key: "1",
      dataIndex: "name",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 250,
      sortDirections: ["ascend", "descend"],
      render: (text, data) => (
        <div
          className="flex items-center cursor-pointer"
          onClick={() =>
            navigate("/module-permissions", {
              state: { roleProfileId: data.id },
            })
          }
        >
          <p className="ml-2 text-primary">
            {data.name.charAt(0).toUpperCase() + data.name.slice(1)}
          </p>
        </div>
      ),
    },
    {
      title: "Profile Description",
      dataIndex: "description",
      key: "2",
      sorter: true,
      sortIcon: () => <SortIcon />,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: "Created At",
      key: "3",
      dataIndex: "created_at",
      width: 250,
    },
    // {
    //   title: "Last Modified atModified By",
    //   dataIndex: "modified_by",
    //   key: "4",
    //   width: 250,
    // },

    {
      title: "Action",
      fixed: "right",
      align: "",
      hidden: false,
      width: 100,
      align: "center",
      key: "action",
      render: (item) => {
        return (
          <Dropdown
            disabled={item.name === "BASIC" || item.name === "ADMINISTRATOR"}
            id="custom-drop"
            trigger={"click"}
            menu={{
              items: [
                {
                  label: (
                    <span
                      onClick={() =>
                        navigate("/update-role-profile", {
                          state: { profileId: item.id },
                        })
                      }
                    >
                      Edit
                    </span>
                  ),
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () => {
                    navigate("/update-role-profile", {
                      state: { profileId: item.id },
                    });
                  },
                },

                item.name !== "BASIC" &&
                  item.name !== "BASIC" /* {
                    label: <span>Delete</span>,
                    key: "Delete",
                    icon: <DeleteOutlined />,
                    danger: true,
                    onClick: () => setDeleteData(item),
                  }, */ && {
                    label: <span>Delete</span>,
                    key: "Delete",
                    icon: <DeleteOutlined />,
                    danger: true,
                    onClick: () => {
                      setDeleteData(item);
                      // dispatch(actionGetCompanyAllUsers());
                    },
                  },
              ],
              // onClick: () => handleMenuClick(roleProfileData),
            }}
            className="disable-button"
          >
             <div className="flex justify-center items-center">
                <EllipsisOutlined />
              </div>
          </Dropdown>
        );
      },
    },
  ];

  const [columns, setColumns] = useState(initialColumns);
  const defaultCheckedList = columns.map((item) => item.key);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  useEffect(() => {
    dispatch(actionGetRoleProfile({ offset: offsetRef.current, search }));
    dispatch(actionGetAllRoleProfile({ offset: offsetRef.current, search }));
  }, [dispatch]);

  const roleProfileData =
    roleProfile &&
    roleProfile.length > 0 &&
    roleProfile.map((profile, index) => ({
      key: profile.id,
      name: profile && profile.name != null ? profile.name : "-",
      id: profile.id,
      description:
        profile && profile.description != null ? profile.description : "-",
      created_at: profile.created_at
        ? getFormattedDateTime(profile.created_at)
        : "-",
      modified_by:
        profile && profile.modified_by !== null
          ? getFormattedDateTime(profile.updated_at)
          : "-",
    }));

  const roleSelectData =
    roleAllProfile &&
    roleAllProfile.length > 0 &&
    roleAllProfile
      .filter((d) => d.id !== deleteData?.id)
      .map((profile, index) => ({
        label:
          profile && profile.name != null
            ? profile.name.charAt(0).toUpperCase() +
              profile.name.slice(1).toLowerCase()
            : "-",
        value: profile.id,
      }));

  const handleFilter = () => {
    console.log("Filter button clicked!");
  };

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      dispatch(
        actionGetRoleProfile({
          offset: offsetRef.current,
          search: e.target.value,
        })
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      setSearch(e.target.value);
      dispatch(
        actionGetRoleProfile({
          offset: offsetRef.current,
          search: e.target.value,
        })
      );
    }
  };

  const handleRefresh = () => {
    setSearch(null);
    dispatch(
      actionGetRoleProfile({
        offset: offsetRef.current,
      })
    );
  };

  const handleDownload = () => {
    console.log("Download page");
  };

  const handleClearData = () => {
    setDeleteData(false);
    form.resetFields();
  };

  const newColumns = columns.map((item) => ({
    ...item,
    hidden: item.title === "Action" ? false : !checkedList.includes(item.key),
  }));

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetRoleProfile({
        offset: offsetRef.current,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  return (
    <section className="main-wrapper">
      <div className="mb-4">
        <Flex gap="middle" justify="space-between">
          <div className="text-brand flex items-center gap-x-3">
            <Image
              src={user}
              alt="image"
              height={20}
              width={20}
              preview={false}
            />
            <h1 className="lg:text-lg text-base  font-popinsRegular">
              Role Profile
            </h1>
          </div>

          <Link to={"/add-role-profile"}>
            <Button
              className="root-btn text-white hover:text-white py-5 flex-center radius"
              icon={<Image src={plusIcon} alt="Plus Icon" preview={false} />}
            >
              New Profile
            </Button>
          </Link>
        </Flex>
        <p className="text-sm text-[#7c7c7c] max-w-[65%]">
          Default Role Profiles with predefined permissions are established
          within the system. These profiles are automatically assigned to newly
          created roles, streamlining the initial setup process for user
          assignments.
        </p>
      </div>
      <CustomInputWithButtons
        icons={false}
        handleFilter={handleFilter}
        handleRefresh={handleRefresh}
        handleDownload={handleDownload}
        columns={columns}
        setColumns={setColumns}
        setCheckedList={setCheckedList}
        checkedList={checkedList}
        handleSearch={handleSearch}
        handleBlurAndPressEnter={handleBlurAndPressEnter}
        searchValue={search}
      />
      {/*       <div className="table-wrapper py-4">
       */}
      <Table
        sortDirections={["ascend", "descend"]}
        onChange={handleSortChange}
        loading={getRoleProfileLoader}
        bordered
        scroll={{
          x: "max-content",
          y: window.innerWidth < 1500 ? "49vh" : "61vh",
        }}
        pagination={false}
        // columns={newColumns}
        columns={newColumns}
        dataSource={checkedList.length <= 0 ? [] : roleProfileData}
      />
      <div className="flex justify-end mt-3">
        <Pagination
          hideOnSinglePage
          responsive={true}
          current={offsetRef.current + 1}
          className="flex items-center"
          showSizeChanger={false}
          total={roleProfileCounts}
          onChange={(e) => {
            offsetRef.current = e - 1;
            dispatch(
              actionGetRoleProfile({ offset: offsetRef.current, search })
            );
          }}
        />
        {/* </div> */}
      </div>
      <Modal
        title={
          <p className="font-popinsRegular tracking-wide text-lg">
            Delete Profile
          </p>
        }
        centered
        open={deleteData}
        width={700}
        footer={false}
        onOk={handleClearData}
        onCancel={handleClearData}
      >
        <p className="text-[#505050] py-5 font-popinsRegular text-sm">
          If the deleted user has integrated any additional applications with
          this CRM account, those integrations will be disabled
        </p>

        <div className="mt-3 ml-6 flex w-full items-center">
          <Form
            validateMessages={false}
            form={form}
            className=" w-full"
            layout="horizontal"
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={(val) => {
              const req = { ...val, id: deleteData && deleteData?.id };
              dispatch(actionDeleteRoleProfile({ req, handleClearData }));
            }}
            autoComplete="off"
          >
            <Form.Item
              className="w-1/2"
              label="Transfer user’s to"
              name="transfer_id"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                loading={getRoleProfileLoader}
                disabled={getRoleProfileLoader}
                placeholder="Select Role Profile"
                optionFilterProp="children"
                className="role-select"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={roleSelectData}
              />
            </Form.Item>

            <div className="flex items-center mt-8  justify-center w-full gap-x-6">
              <Form.Item className="mb-0">
                <Button
                  onClick={handleClearData}
                  type="button"
                  className="gray-button gray-hover text-white hover:text-white  min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item className="mb-0">
                <Button
                  loading={deleteRoleProfileLoader}
                  type="primary"
                  className="danger-btn  min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  Transfer and Delete
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>
    </section>
  );
};

export default RoleProfile;
