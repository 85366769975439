import { createSlice } from "@reduxjs/toolkit";
import { actionAddContact, actionClearAllContacts, actionDeleteContact, actionGetAllContacts, actionGetContacts, actionGetContactDetail, actionUpdateContact } from "../services/contactService";

const contactSlice = createSlice({
    name:"contact",
    initialState : {
        addContactLoader:false,
        getContactsLoader:false,
        contactsDataCount: "",
        contactsData: [],
        allContactsData: [],
        getAllContactsLoader:false,
        getContactDetailLoader:false,
        contactDetailData: "",
        deleteContactLoader:false,
        updateContactLoader:false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        // add contact State
      .addCase(actionAddContact.pending, (state) => {
        state.addContactLoader = true;
      })
      .addCase(actionAddContact.fulfilled, (state, action) => {
        state.addContactLoader = false;
      })
      .addCase(actionAddContact.rejected, (state) => {
        state.addContactLoader = false;
      })

      // Get Contacts state
      .addCase(actionGetContacts.pending, (state) => {
        state.getContactsLoader = true;
      })
      .addCase(actionGetContacts.fulfilled, (state, action) => {
        state.getContactsLoader = false;
        state.contactsData = action.payload?.data;
        state.contactsDataCount = action.payload?.total_records;
      })
      .addCase(actionGetContacts.rejected, (state) => {
        state.getContactsLoader = false;
      })

      // Clear All Contacts Data State
      .addCase(actionClearAllContacts.fulfilled, (state, action) => {
        state.allContactsData = action.payload;
      })

       // Get All Contacts State
       .addCase(actionGetAllContacts.pending, (state) => {
        state.getAllContactsLoader = true;
      })
      .addCase(actionGetAllContacts.fulfilled, (state, action) => {
        state.getAllContactsLoader = false;
        state.allContactsData = action.payload.data;
      })
      .addCase(actionGetAllContacts.rejected, (state) => {
        state.getAllContactsLoader = false;
      })

      // Get single contact detail State
      .addCase(actionGetContactDetail.pending, (state) => {
        state.getContactDetailLoader = true;
      })
      .addCase(actionGetContactDetail.fulfilled, (state, action) => {
        state.getContactDetailLoader = false;
        state.contactDetailData = action.payload.data;
      })
      .addCase(actionGetContactDetail.rejected, (state) => {
        state.getContactDetailLoader = false;
      })

      // Delete contact state
      .addCase(actionDeleteContact.pending, (state) => {
        state.deleteContactLoader = true;
      })
      .addCase(actionDeleteContact.fulfilled, (state, action) => {
        state.deleteContactLoader = false;

        state.contactsData = state.contactsData.filter(
          (contact) => contact.contact_id !== action.payload
        );
      })
      .addCase(actionDeleteContact.rejected, (state) => {
        state.deleteContactLoader = false;
      })

       // Update contact state
       .addCase(actionUpdateContact.pending, (state) => {
        state.updateContactLoader = true;
      })
      .addCase(actionUpdateContact.fulfilled, (state, action) => {
        state.updateContactLoader = false;
      })
      .addCase(actionUpdateContact.rejected, (state) => {
        state.updateContactLoader = false;
      });
    }
});
export default contactSlice.reducer;