import plusIcon from "../../../assets/img/add-button.svg";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Autocomplete,
  GoogleMap,
  Marker,
  useJsApiLoader,
  LoadScript,
} from "@react-google-maps/api";
import {
  Button,
  Checkbox,
  ConfigProvider,
  Dropdown,
  Flex,
  Image,
  Form,
  Input,
  Modal,
  Pagination,
  Spin,
  Table,
  Tooltip,
  Typography,
  Select,
  Row,
  Col,
} from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SiGooglemaps } from "react-icons/si";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import businessPartner from "../../../assets/img/businessPartner.svg";
import {
  actionClearAllBusinessPartner,
  actionDeleteBusinessPartner,
  actionGetAllBusinessPartner,
  actionGetBusinessPartner,
  actionGetSingleBusinessPartner,
  actionUpdateBulkBusinessPartner,
  actionUpdateBusinessPartnerLatLng,
} from "../../../store/services/masterDataService";
import CustomInputWithButtons from "../../common/CustomInputWithButtons ";
import PageTitle from "../../common/PageTitle";
import SortIcon from "../../common/SortIcon";
import { GOOGLE_MAP_KEY } from "../../../config/web-config";
import {
  actionGetAllBeatMaster,
  actionGetBusinessPartnerTypeMaster,
} from "../../../store/services/generalMasterService";

const BusinessPartners = () => {
  const [search, setSearch] = useState("");
  const [exportAll, setExportAll] = useState(false);
  const [viewBusinessPartnerModalOpen, setViewBusinessPartnerModalOpen] =
    useState(false);
  const [businessPartnerId, setBusinessPartnerId] = useState("");
  const [deleteBusinessPartnerModalOpen, setDeleteBusinessPartnerModalOpen] =
    useState(false);
  const [locationInput, setLocationInput] = useState("");

  const [form] = Form.useForm();
  const offsetRef = useRef(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    getBusinessPartnerLoader,
    businessPartnerData,
    getAllBusinessPartnerLoader,
    allBusinessPartnerData,
    businessPartnersCount,
    deleteBusinessPartnerLoader,
    getBusinessPartnerDetailLoader,
    businessPartnerDetail,
    updateBusinessPartnerLatlngLoader,
    updateBulkBusinessPartnerLoader,
  } = useSelector((state) => state.masterData);

  const { businessPartnerTypeData, getBusinessPartnerTypeLoader } = useSelector(
    (state) => state.generalMaster
  );

  const { allBeatMasterData, getAllBeatMasterLoader } = useSelector(
    (state) => state.generalMaster
  );

  const [selectLocationModalOpen, setSelectLocationModalOpen] = useState(false);

  const defaultCenter = { lat: 40.748817, lng: -73.985428 };
  const [center, setCenter] = useState(defaultCenter);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(actionGetBusinessPartner({ offset: offsetRef.current, search }));
  }, []);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const setLocationFromCoordinates = async (lat, lng) => {
    const placeName = await reverseGeocode(lat, lng);
    if (placeName) {
      setLocationInput(placeName);
    } else {
      setLocationInput("");
    }
  };

  useEffect(() => {
    if (selectLocationModalOpen && businessPartnerDetail) {
      setSelectedLocation({
        lat: parseFloat(businessPartnerDetail.latitude),
        lng: parseFloat(businessPartnerDetail.longitude),
      });
    }
  }, [selectLocationModalOpen, businessPartnerDetail]);

  console.log({ selectedLocation });

  useEffect(() => {
    if (businessPartnerDetail && businessPartnerId) {
      const newLocation = {
        lat: businessPartnerDetail.latitude
          ? parseFloat(businessPartnerDetail.latitude)
          : defaultCenter.lat,
        lng: businessPartnerDetail.longitude
          ? parseFloat(businessPartnerDetail.longitude)
          : defaultCenter.lng,
      };
      setSelectedLocation(newLocation);
      setCenter(newLocation);
      setLocationFromCoordinates(newLocation.lat, newLocation.lng);
    }
  }, [selectLocationModalOpen, businessPartnerDetail]);

  useEffect(() => {
    if (businessPartnerId && viewBusinessPartnerModalOpen) {
      dispatch(actionGetSingleBusinessPartner(businessPartnerId));
    }
  }, [viewBusinessPartnerModalOpen, businessPartnerId]);

  const columns = [
    {
      title: "Business Partner Name",
      key: "1",
      width: 200,
      ellipsis: true,
      dataIndex: "business_partner_name",
      sorter: true,
      sortIcon: () => <SortIcon />,
      fixed: "left",
      render: (text, data) => (
        <div
          onClick={() => {
            navigate("/view-business-partner", {
              state: { id: data.business_partner_id },
            });
          }}
          className="flex text-brand items-center cursor-pointer"
        >
          {text ?? "-"}
        </div>
      ),
    },
    {
      title: "Business Partner Type",
      key: "2",
      width: 200,
      ellipsis: true,
      dataIndex: "business_partner_type",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{text?.business_partner_type_name ?? "-"}</span>
      ),
    },
    {
      title: "Email",
      key: "3",
      width: 200,
      ellipsis: true,
      dataIndex: "email",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text ?? "-"}</span>,
    },
    {
      title: "Mobile",
      key: "4",
      width: 200,
      ellipsis: true,
      dataIndex: "mobile",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text ? text : "-"}</span>,
    },
    {
      title: "Address 1",
      key: "5",
      width: 200,
      dataIndex: "address_1",
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      // render: (text) => <span className="ml-2">{text ?? "-"}</span>,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2 !mb-0"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Address 2",
      key: "6",
      width: 200,
      dataIndex: "address_2",
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2 !mb-0"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },

    {
      title: "Country",
      key: "10",
      ellipsis: true,
      width: 200,
      dataIndex: "country",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text?.name || "-"}</span>,
    },
    {
      title: "State",
      key: "9",
      ellipsis: true,
      width: 200,
      dataIndex: "state",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text?.name || "-"}</span>,
    },
    {
      title: "City",
      key: "7",
      // width: "15%",
      width: 200,
      ellipsis: true,
      dataIndex: "city",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text ?? "-"}</span>,
    },
    {
      title: "Pin Code",
      key: "8",
      width: 200,
      ellipsis: true,
      dataIndex: "zip_code",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },

    {
      title: "View on Map",
      key: "8",
      width: 200,
      ellipsis: true,
      dataIndex: "zip_code",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => {
        const handleNavigate = () => {
          const latitude = data.latitude;
          const longitude = data.longitude;

          if (latitude && longitude) {
            const googleMapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(
              latitude
            )},${encodeURIComponent(longitude)}`;
            window.open(googleMapsUrl, "_blank");
          } else {
            console.error("Latitude or Longitude not available");
          }
        };

        if (!data.latitude || !data.longitude) {
          return <span>-</span>;
        }

        return (
          <span onClick={handleNavigate} className="cursor-pointer text-brand">
            View on Map
          </span>
        );
      },
    },

    {
      title: "Beat",
      key: "9",
      ellipsis: true,
      width: 200,
      dataIndex: "beat_master",
      // sorter: (a, b) => true,
      // sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text?.beat_name || "-"}</span>,
    },
    {
      title: "ERP Code",
      key: "11",
      ellipsis: true,
      width: 200,
      dataIndex: "erp_code",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "GST Number",
      key: "11",
      ellipsis: true,
      width: 200,
      dataIndex: "gst_number",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "PAN Card No",
      key: "12",
      ellipsis: true,
      width: 200,
      dataIndex: "pan_card_no",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "MSME Number",
      key: "13",
      ellipsis: true,
      width: 200,
      dataIndex: "msme_number",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "FSSAI",
      key: "14",
      ellipsis: true,
      width: 200,
      dataIndex: "fssai",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "CIN No",
      key: "15",
      ellipsis: true,
      width: 200,
      dataIndex: "cin_no",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Type of Company",
      key: "16",
      ellipsis: true,
      width: 200,
      dataIndex: "type_of_company",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">
          {text?.company_type !== null ? text?.company_type : "-"}
        </span>
      ),
    },
    {
      title: "Industry",
      key: "17",
      ellipsis: true,
      width: 200,
      dataIndex: "industry",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text?.industry || "-"}</span>,
    },
    {
      title: "Company Size",
      key: "18",
      ellipsis: true,
      width: 200,
      dataIndex: "company_size",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Revenue",
      key: "19",
      ellipsis: true,
      width: 200,
      dataIndex: "revenue",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Sales Volume",
      key: "20",
      ellipsis: true,
      width: 200,
      dataIndex: "sales_volume",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Agreement Duration",
      key: "21",
      ellipsis: true,
      width: 200,
      dataIndex: "agreement_duration",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Commission Structure",
      key: "22",
      ellipsis: true,
      width: 200,
      dataIndex: "commission_structure",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Terms and Conditions",
      key: "23",
      ellipsis: true,
      width: 200,
      dataIndex: "terms_and_conditions",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2 !mb-0"
        >
          {text || "-"}
        </Typography.Paragraph>
      ) /*  <span className="ml-2">{text || "-"}</span> */,
    },
    {
      title: "Feedback Mechanism",
      key: "24",
      ellipsis: true,
      width: 200,
      dataIndex: "feedback_mechanism",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2 !mb-0"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Renewal Process",
      key: "25",
      ellipsis: true,
      width: 200,
      dataIndex: "renewal_process",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2 !mb-0"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Termination Conditions",
      key: "26",
      ellipsis: true,
      width: 200,
      dataIndex: "termination_conditions",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2 !mb-0"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Bank Name",
      key: "27",
      ellipsis: true,
      width: 200,
      dataIndex: "bank_name",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Branch Name",
      key: "28",
      ellipsis: true,
      width: 200,
      dataIndex: "branch_name",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Account Type",
      key: "29",
      ellipsis: true,
      width: 200,
      dataIndex: "account_type",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "IFSC",
      key: "30",
      ellipsis: true,
      width: 200,
      dataIndex: "ifsc",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Account Number",
      key: "31",
      ellipsis: true,
      width: 200,
      dataIndex: "account_number",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Primary Contact Name",
      key: "38",
      ellipsis: true,
      width: 200,
      dataIndex: "primary_contact_name",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Primary Contact Email",
      key: "39",
      ellipsis: true,
      width: 200,
      dataIndex: "primary_contact_email",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Primary Contact Phone",
      key: "40",
      ellipsis: true,
      width: 200,
      dataIndex: "primary_contact_phone",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Secondary Contact Name",
      key: "41",
      ellipsis: true,
      width: 200,
      dataIndex: "secondary_contact_name",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Secondary Contact Email",
      key: "42",
      ellipsis: true,
      width: 200,
      dataIndex: "secondary_contact_email",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Secondary Contact Phone",
      key: "43",
      ellipsis: true,
      width: 200,
      dataIndex: "secondary_contact_phone",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      key: "action",
      width: 100,
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () =>
                    navigate("/update-business-partner", {
                      state: { businessPartnerId: data.business_partner_id },
                    }),
                },
                {
                  label: <span style={{ color: "#ff4d4f" }}>Delete</span>,
                  key: "Delete",
                  icon: (
                    <DeleteOutlined
                      style={{ fontSize: 12, color: "#ff4d4f" }}
                    />
                  ),
                  onClick: () => {
                    setDeleteBusinessPartnerModalOpen(true);
                    setBusinessPartnerId(data.business_partner_id);
                  },
                },
                {
                  label: <span>Configure Location</span>,
                  key: "config_map",
                  icon: <SiGooglemaps color="gray" />,
                  onClick: () => {
                    setSelectLocationModalOpen(true);
                    setBusinessPartnerId(data.business_partner_id);
                    dispatch(
                      actionGetSingleBusinessPartner(data.business_partner_id)
                    );
                  },
                },
              ].filter(Boolean),
            }}
          >
             <div className="flex justify-center items-center">
                <EllipsisOutlined />
              </div>
          </Dropdown>
        </>
      ),
    },
  ];

  const beatOptions =
    allBeatMasterData &&
    allBeatMasterData.length > 0 &&
    allBeatMasterData.map((data) => ({
      label: data.beat_name,
      value: data.beat_master_id,
    }));

  const businessPartnerTypeOptions = useMemo(() => {
    return businessPartnerTypeData && businessPartnerTypeData.length > 0
      ? businessPartnerTypeData.map((data) => ({
          key: data.business_partner_type_master_id,
          label: data.business_partner_type_name,
          value: data.business_partner_type_master_id,
        }))
      : [];
  });

  const handleRefresh = () => {
    setSearch(null);
    dispatch(
      actionGetBusinessPartner({
        offset: offsetRef.current,
      })
    );
  };

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      dispatch(
        actionGetBusinessPartner({
          offset: offsetRef.current,
          search: e.target.value,
        })
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      dispatch(
        actionGetBusinessPartner({
          offset: offsetRef.current,
          search: e.target.value,
        })
      );
    }
  };

  const exportAllToExcel = async () => {
    const data =
      (await allBusinessPartnerData) &&
      allBusinessPartnerData.length > 0 &&
      allBusinessPartnerData.map((item) => ({
        "Business Partner Name": item.business_partner_name ?? "-",
        "Business Partner Type":
          item.business_partner_type?.business_partner_type_name ?? "-",
        Email: item.email ?? "-",
        Mobile: item.mobile ?? "-",
        "Address 1": item.address_1 ?? "-",
        "Address 2": item.address_2 ?? "-",
        Country: item.country?.name ?? "-",
        State: item.state?.name ?? "-",
        City: item.city ?? "-",
        "Pin Code": item.zip_code ?? "-",
        Beat: item.beat_master ?? "-",
        "ERP Code": item.erp_code ?? "-",
        "GST Number": item.gst_number ?? "-",
        "PAN Card No": item.pan_card_no ?? "-",
        "MSME Number": item.msme_number ?? "-",
        FSSAI: item.fssai ?? "-",
        "CIN No": item.cin_no ?? "-",
        "Type of Company":
          item &&
          item.type_of_company != null &&
          item.type_of_company.company_type
            ? item.type_of_company.company_type
            : "-",
        Industry: item.industry?.industry ?? "-",
        "Company Size": item.company_size ?? "-",
        Revenue: item.revenue ?? "-",
        "Sales Volume": item.sales_volume ?? "-",
        "Agreement Duration": item.agreement_duration ?? "-",
        "Commission Structure": item.commission_structure ?? "-",
        "Terms and Conditions": item.terms_and_conditions ?? "-",
        "Feedback Mechanism": item.feedback_mechanism ?? "-",
        "Renewal Process": item.renewal_process ?? "-",
        "Termination Conditions": item.termination_conditions ?? "-",
        "Bank Name": item.bank_name ?? "-",
        "Branch Name": item.branch_name ?? "-",
        "Account Type": item.account_type ?? "-",
        IFSC: item.ifsc ?? "-",
        "Account Number": item.account_number ?? "-",
        "Primary Contact Name": item.primary_contact_name ?? "-",
        "Primary Contact Email": item.primary_contact_email ?? "-",
        "Primary Contact Phone": item.primary_contact_phone ?? "-",
        "Secondary Contact Name": item.secondary_contact_name ?? "-",
        "Secondary Contact Email": item.secondary_contact_email ?? "-",
        "Secondary Contact Phone": item.secondary_contact_phone ?? "-",
      }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet2");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "business-partner.xlsx");
    dispatch(actionClearAllBusinessPartner({ setExportAll }));
  };

  useEffect(() => {
    if (
      exportAll &&
      allBusinessPartnerData &&
      allBusinessPartnerData.length > 0
    ) {
      exportAllToExcel();
    }
  }, [allBusinessPartnerData]);

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllBusinessPartner());
    setExportAll(true);
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetBusinessPartner({
        offset: offsetRef.current,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  const handleCloseDeleteModal = () => {
    setBusinessPartnerId("");
    setDeleteBusinessPartnerModalOpen(false);
  };

  const onLoad = (autoC) => setAutocomplete(autoC);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();

      if (place && place.geometry) {
        setSelectedLocation({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
        setLocationInput(place.formatted_address);
      } else {
        setSelectedLocation(null);
        setLocationInput("");
      }
    }
  };

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setSelectedLocation({ lat, lng });
  };

  const reverseGeocode = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAP_KEY}` // Replace GOOGLE_MAP_KEY with your actual API key
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        return data.results[0].formatted_address;
      } else {
        console.error("Geocoding failed:", data);
        return null;
      }
    } catch (error) {
      console.error("Error fetching geocode:", error);
      return null;
    }
  };

  const handleClose = () => {
    setLocationInput("");
    setSelectedLocation(null);
    setSelectLocationModalOpen(false);
    setBusinessPartnerId("");
  };

  const handleMarkerDragEnd = useCallback(async (event) => {
    const newLocation = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };

    setSelectedLocation(newLocation);

    const placeName = await reverseGeocode(newLocation.lat, newLocation.lng);

    if (placeName) {
      setLocationInput(placeName);
    } else {
      setLocationInput("Unknown location");
    }
  }, []);

  const handleFinish = () => {
    if (selectedLocation.lat && selectedLocation.lng) {
      const req = {
        latitude: selectedLocation.lat ? selectedLocation.lat.toString() : null,
        longitude: selectedLocation.lng
          ? selectedLocation.lng.toString()
          : null,
      };
      console.log({ selectedLocation });
      dispatch(
        actionUpdateBusinessPartnerLatLng({
          id: businessPartnerId,
          req,
          handleClose,
        })
      );
    }
  };

  const rowSelection = {
    selectedRowKeys,
    columnWidth: 70,
    onChange: onSelectChange,
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <section className="main-wrapper">
        <Flex gap="middle" justify="space-between" className="mb-4">
          <div className="text-brand flex items-center gap-x-3">
            <Image
              src={businessPartner}
              alt="image"
              height={20}
              width={20}
              preview={false}
            />

            <h1 className="lg:text-lg text-base  font-popinsRegular">
              Business Partners
            </h1>
          </div>
          <div className="flex items-center gap-x-4">
            {selectedRowKeys && selectedRowKeys.length > 0 && (
              <Button
                style={{ backgroundColor: "white" }}
                className="min-h-[40px] text-[#646464] hover:!text-[#646464] transition ease-in-out hover:!border-neutralGray active:!border-neutralGray focus:!border-neutralGray  duration-500 bg-transparent border border-[#646464] rounded-[10] font-popinsSemiBold tracking-wider"
                // className="min-h-[40px] !bg-white outline-button-border  flex-center radius"
                icon={<SyncOutlined />}
                onClick={() => {
                  setIsModalOpen(true);
                  dispatch(actionGetAllBeatMaster());
                  dispatch(actionGetBusinessPartnerTypeMaster());
                }}
              >
                Bulk Update
              </Button>
            )}
            <Link to={"/create-business-partner"}>
              <Button
                className="root-btn text-white hover:text-white min-h-[40px] flex-center radius"
                icon={<Image src={plusIcon} alt="Plus Icon" preview={false} />}
              >
                Add Business Partner
              </Button>
            </Link>
          </div>
        </Flex>

        <CustomInputWithButtons
          handleRefresh={handleRefresh}
          handleSearch={handleSearch}
          handleBlurAndPressEnter={handleBlurAndPressEnter}
          searchValue={search}
          importRoute="/business-partners-import"
          isImportEnabled={true}
          onClickDownloadMenu={onClickDownloadMenu}
        />

        <ConfigProvider
          theme={{
            components: {
              Checkbox: {
                colorPrimary: "#6883fd",
                colorPrimaryHover: "#6883fd",
                colorPrimaryBorder: "#d9d9d9",
              },
            },
          }}
        >
          <Table
            rowSelection={rowSelection}
            sticky
            rowKey="business_partner_id"
            scrollToFirstRowOnChange={true}
            sortDirections={["ascend", "descend"]}
            onChange={handleSortChange}
            bordered
            scroll={{
              x: "max-content",
              y: window.innerWidth < 1500 ? "49vh" : "61vh",
            }}
            pagination={false}
            columns={columns}
            dataSource={businessPartnerData}
            loading={getBusinessPartnerLoader || getAllBusinessPartnerLoader}
          />
        </ConfigProvider>

        <div className="flex justify-between mt-3">
          <p className="text-[15px] font-popinsMedium">
            Total Business Partners :{" "}
            {businessPartnersCount ? businessPartnersCount : 0}
          </p>
          <Pagination
            hideOnSinglePage
            responsive={true}
            current={offsetRef.current + 1}
            className="flex items-center"
            showSizeChanger={false}
            total={businessPartnersCount}
            onChange={(e) => {
              offsetRef.current = e - 1;
              dispatch(
                actionGetBusinessPartner({ offset: offsetRef.current, search })
              );
            }}
          />
        </div>

        <Modal
          className="max-h-[100vh]"
          centered={true}
          footer={false}
          destroyOnClose={handleCloseDeleteModal}
          open={deleteBusinessPartnerModalOpen}
          title=""
          onCancel={handleCloseDeleteModal}
          onOk={handleCloseDeleteModal}
        >
          <p className="text-[19px] text-black font-popinsMedium mt-2">
            Are you sure you want to remove this Business Partner ?
          </p>
          <div className=" flex justify-end mt-[30px]">
            <Button
              onClick={handleCloseDeleteModal}
              type="button"
              className="gray-button text-white hover:text-white min-h-[45px] min-w-[110px]"
            >
              Cancel
            </Button>

            <div className="ml-4">
              <Button
                onClick={() =>
                  dispatch(
                    actionDeleteBusinessPartner({
                      id: businessPartnerId,
                      handleCloseDeleteModal,
                    })
                  )
                }
                loading={deleteBusinessPartnerLoader}
                className="delete-btn min-h-[45px] min-w-[110px]"
              >
                Delete
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          title="Configure Location"
          open={selectLocationModalOpen}
          width={700}
          onOk={handleClose}
          onCancel={handleClose}
          loading={getBusinessPartnerDetailLoader}
          footer={false}
        >
          <LoadScript
            loadingElement={<Spin />}
            libraries={["places"]}
            googleMapsApiKey={GOOGLE_MAP_KEY}
          >
            <div>
              <Form layout="vertical">
                <Form.Item>
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                    <Input
                      placeholder="Enter location"
                      value={locationInput}
                      onChange={(e) => setLocationInput(e.target.value)} // Update state
                    />
                  </Autocomplete>
                </Form.Item>
              </Form>

              <GoogleMap
                streetView={false}
                options={{
                  fullscreenControl: false,
                  streetViewControl: false,
                  zoomControl: false,
                }}
                mapContainerStyle={{ width: "100%", height: "400px" }}
                center={selectedLocation || center}
                zoom={12}
                onClick={handleMapClick}
              >
                {selectedLocation && (
                  <Marker
                    draggable={true}
                    animation={true}
                    onDragEnd={handleMarkerDragEnd}
                    position={selectedLocation}
                  />
                )}
              </GoogleMap>
            </div>
          </LoadScript>

          <div className="flex mt-6 justify-end  items-center gap-x-4">
            <Button
              style={{ borderRadius: 5 }}
              type="button"
              className="gray-button max-h-[32px] text-white hover:text-white min-h-[40px] min-w-[100px]"
              onClick={handleClose}
            >
              Cancel
            </Button>

            <Button
              loading={updateBusinessPartnerLatlngLoader}
              style={{ borderRadius: 5 }}
              type="primary"
              className="root-btn min-h-[40px] min-w-[100px]"
              onClick={handleFinish}
            >
              Apply
            </Button>
          </div>
        </Modal>

        <Modal
          title={null}
          open={isModalOpen}
          width={550}
          onOk={onModalClose}
          onCancel={onModalClose}
          loading={false}
          footer={false}
        >
          <div className="flex items-center gap-x-2">
            <SyncOutlined style={{ color: "#6883FD" }} />
            <h1 className="text-[#6883FD] text-[20px]  font-medium">
              Bulk Update
            </h1>
          </div>
          <Form
            form={form}
            onFinish={(values) => {
              const req = {
                business_partner_id: selectedRowKeys,
                business_partner_type_master_id:
                  values.business_partner_type_master_id
                    ? values.business_partner_type_master_id
                    : null,
                beat_master_id: values.beat_master_id
                  ? values.beat_master_id
                  : null,
              };

              console.log({ req });
              dispatch(
                actionUpdateBulkBusinessPartner({
                  req,
                  handleClose: onModalClose,
                })
              );
            }}
            layout="vertical"
            className="mt-[20px] mb-0"
          >
            <Form.Item
              name="business_partner_type_master_id"
              label="Business Partner Type"
              className="mb-[12px]"
              size="small"
            >
              <Select
                allowClear
                showSearch
                className="mb-5 w-full sales-order-select"
                placeholder="Select Business Partner Type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                loading={getBusinessPartnerTypeLoader}
                options={businessPartnerTypeOptions}
                // disabled={getBusinessPartnerTypeLoader}
              />
            </Form.Item>
            <Form.Item
              name="beat_master_id"
              label="Beat"
              className="mb-[12px]"
              size="small"
            >
              <Select
                allowClear
                showSearch
                className="mb-5 w-full sales-order-select"
                placeholder="Select Beat"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                loading={getAllBeatMasterLoader}
                options={beatOptions}
                // disabled={getBusinessPartnerTypeLoader}
              />
            </Form.Item>

            <div className="flex items-center my-4 justify-center gap-x-5">
              <Form.Item className="mb-0">
                <Button
                  onClick={async () => {
                    setIsModalOpen(false);
                  }}
                  type="button"
                  className="gray-button text-white hover:text-white  min-h-[45px] min-w-[120px]"
                  htmlType="button"
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item className="mb-0">
                <Button
                  loading={updateBulkBusinessPartnerLoader}
                  type="primary"
                  className="root-btn min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Modal>
      </section>
    </>
  );
};

export default BusinessPartners;
