import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/web-config";
import { message } from "antd";
import { toast } from "sonner";
import { handleErrorMessage } from "../../components/helper/common-function";

// Action to get all countries
export const actionGetCountries = createAsyncThunk(
  "actionGetCountries",
  async () => {
    try {
      const res = await axios.get(`${BASE_URL}/country-state-city/countries`);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        return data;
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action to get states based on a provided URL
export const actionGetStates = createAsyncThunk(
  "actionGetStates",
  async (country_id) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/country-state-city/states/${country_id}`
      );
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        return data;
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action to get cities based on a provided URL
export const actionGetCities = createAsyncThunk(
  "actionGetCities",
  async ({ countryCode, stateCode }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/location/get-cities-of-states/${countryCode}/${stateCode}`
      );
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        return data;
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//get area
export const actionGetArea = createAsyncThunk("actionGetArea", async () => {
  try {
    const res = await axios.get(`${BASE_URL}/area-master`);
    const { status, message: customMsg, data } = res.data;
    if (parseInt(status) === 200) {
      return data;
    } else if (parseInt(status) === 404) {
      return "";
    } else {
      toast.error(customMsg, 5);
      throw new Error(`API Error: ${customMsg}`);
    }
  } catch (error) {
    handleErrorMessage(error);
  }
});

//get zone
export const actionGetZone = createAsyncThunk("actionGetZone", async () => {
  try {
    const res = await axios.get(`${BASE_URL}/zone-master`);
    const { status, message: customMsg, data } = res.data;
    if (parseInt(status) === 200) {
      return data;
    } else if (parseInt(status) === 404) {
      return "";
    } else {
      toast.error(customMsg, 5);
      throw new Error(`API Error: ${customMsg}`);
    }
  } catch (error) {
    handleErrorMessage(error);
  }
});


// Action to get All states of particular country
export const actionAllStatesIndex = createAsyncThunk(
  "actionAllStatesIndex",
  async ({ countryId, index }, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${BASE_URL}/country-state-city/states/${countryId}`);
      const { status, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        return res.data;
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);