import { Image } from "antd";
import React from "react";
import leftArrow from "../../assets/img/leftArrowPrimary.svg";
import { useNavigate } from "react-router-dom";

const CustomIconText = ({ image, icon, title = "", backArrow }) => {
  const navigate = useNavigate();

  return (
    <div className="text-brand flex items-center gap-x-3 justify-between">
      {backArrow && (
        <div
          className="flex items-center gap-x-3 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <Image
            src={leftArrow}
            alt="image"
            height={20}
            width={20}
            preview={false}
          />
          <h1 className="lg:text-lg text-base font-popinsRegular">Back</h1>
        </div>
      )}

      <div className="flex items-center gap-x-3">
        {icon
          ? icon
          : image && (
              <Image
                src={image}
                alt="image"
                height={20}
                width={20}
                preview={false}
              />
            )}
        <h1 className="lg:text-lg text-base font-popinsRegular">{title}</h1>
      </div>
    </div>
  );
};

export default CustomIconText;
