import { Button, Form, Image, Input } from "antd";
import React from "react";
import { HiOutlineLockClosed } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import back from "../../assets/img/back.svg";
import { actionChangePassword } from "../../store/services/authService";
// import { actionChangePassword } from "../../store/services/authService";

function ChangePassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { changePasswordLoader } = useSelector((state) => state.auth);

  const onFinish = (values) => {
    console.log({ values });
    dispatch(actionChangePassword({ values, form }));
  };

  return (
    <div className="register-container 2xl:max-w-xl mt-16 max-w-lg mx-auto border border-[#d4d4d4] bg-white rounded-md p-3 w-full">
      <div className="title mb-4 pt-4 flex flex-col justify-center items-center gap-y-2.5">
        <p className="xl:text-[25px] lg:text-[20px]  text-zinc-700 font-popinsSemiBold">
          Change your Password
        </p>
        <p className="text-center text-base text-gray-600">
          Please enter a new password for your account.
        </p>
      </div>
      <Form
        name="change_password"
        className="login-form w-full px-5 pt-3"
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          name="old_password"
          rules={[
            {
              required: true,
              message: "Please input your old password!",
            },
          ]}
        >
          <Input.Password
            placeholder="Old Password"
            className="indent-5"
            prefix={
              <HiOutlineLockClosed
                color="#4567ff"
                className="h-[18px] w-[18px] site-form-item-icon font-bold"
              />
            }
            type={"text"}
          />
        </Form.Item>
        <Form.Item
          name="new_password"
          rules={[
            {
              required: true,
              message: "Please input your new password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value && value.length < 8) {
                  return Promise.reject(
                    "Please enter a valid 8-digit password!"
                  );
                }

                const oldPassword = getFieldValue("old_password");
                if (value && oldPassword && value === oldPassword) {
                  return Promise.reject(
                    "New password cannot be the same as the old password!"
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="New Password"
            className="indent-5"
            prefix={
              <HiOutlineLockClosed
                color="#4567ff"
                className="h-[18px] w-[18px] site-form-item-icon font-bold"
              />
            }
            type={"text"}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            className="bg-brand hover:bg-brand/95  font-popinsMedium tracking-wider  mt-3 md:text-base text-sm border-none rounded-[10px] min-h-[45px]"
            block
            htmlType="submit"
            loading={changePasswordLoader}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default ChangePassword;
