import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Card, Flex, Form, Image, Input, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import {
  actionGetCompanyDetail,
  actionUpdateCompanyDetail,
} from "../../store/services/settingService";

const SFASetting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const { user, currentCompanyUser } = useSelector((state) => state.auth);
  const { getCompanyDetailLoader, companyDetail, updateCompanyDetailLoader } =
    useSelector((state) => state.setting);

  useEffect(() => {
    dispatch(actionGetCompanyDetail());
  }, []);

  useEffect(() => {
    if (companyDetail) {
      form.setFieldsValue({
        location_matching_tolerance: companyDetail.location_matching_tolerance
          ? companyDetail.location_matching_tolerance
          : "",
      });
    }
  }, [companyDetail]);

  const onFinish = (value) => {
    const data = {
      location_matching_tolerance: value.location_matching_tolerance
        ? String(value.location_matching_tolerance)
        : "",
    };

    dispatch(
      actionUpdateCompanyDetail({
        req: data,
        handleCancel,
      })
    );
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  return (
    <section className="main-wrapper">
      <Card
        title={
          <h1 className="lg:text-lg text-base  font-popinsRegular text-brand">
            Settings
          </h1>
        }
        style={{ maxWidth: 700, margin: "auto" }}
        loading={getCompanyDetailLoader}
        className="main-card mt-5"
      >
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className="mt-[10px] mb-0"
        >
          <Form.Item
            name="location_matching_tolerance"
            label={
              <span className="flex items-center gap-x-2">
                Location Matching Tolerance{" "}
                <Tooltip title="This setting is used to determine the acceptable distance range between an employee’s tracked location and a designated business location for matching purposes.">
                  <InfoCircleOutlined
                    style={{ fontSize: "16px", color: "grey" }}
                  />
                </Tooltip>
              </span>
            }
            className="mb-[12px]"
            size="small"
            normalize={(value) => value && value.replace(/[^\d.]/g, "")}
            rules={[
              {
                required: true,
                message: "Please input location matching tolerance!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              suffix={"Meter/s"}
              placeholder="Enter Location Matching Tolerance"
              className="!indent-1 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start items-center mt-[30px]">
            <div>
              <Form.Item className="mb-0">
                <Button
                  loading={updateCompanyDetailLoader}
                  type="primary"
                  className="root-btn min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  Save Settings
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Card>
    </section>
  );
};

export default SFASetting;
