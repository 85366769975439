import React, { useEffect } from "react";
import backarrow from "../../assets/img/backarrow.svg";
import feedback from "../../assets/img/feedback-mechanism.svg";
import { Card, Flex, Image, Row, Spin, Tag } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { actionGetSingleFeedBackMechanism } from "../../store/services/feedBackMechanismService";
import { getFeedBackStatusColor } from "../helper/statusUtils";
import { useDispatch, useSelector } from "react-redux";
import { Icon, PlayCircleOutlined } from "@ant-design/icons";
const ViewFeedBackMechanism = (backArrow) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = location.state || {};

  const { getSingleFeedBackMechanismLoader, getSingleFeedBackMechanismData } =
    useSelector((state) => state.feedBackMechanism);

  

  useEffect(() => {
    if (id) {
      dispatch(actionGetSingleFeedBackMechanism(id));
    }
  }, [id, dispatch]);

  return (
    <>
      <section className="main-section px-4 custom-color" id="overview">
        <Spin spinning={getSingleFeedBackMechanismLoader}>
          <div className="flex items-center gap-x-3 justify-between">
            {backArrow && (
              <div
                className="flex items-center gap-x-3 cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <div className="p-[19px] rounded-[10px] flex items-center border border-[#7C7C7C]/15 bg-white mt-3">
                  <Image
                    src={backarrow}
                    alt="image"
                    height={15}
                    width={20}
                    preview={false}
                  />
                </div>
                <h1 className="lg:text-lg text-base mt-[10px] font-popinsMedium">
                  Back
                </h1>
              </div>
            )}
          </div>

          <Card className="mt-4">
            <div className="">
              <div className="flex justify-between items-center w-full">
                <div className="flex items-center space-x-4">
                  <div className="flex items-center space-x-2">
                    <Image src={feedback} alt="image" preview={false} />
                    <h1 className="text-[17px] text-primary">Feedback</h1>
                  </div>

                  <div className="flex items-center space-x-2">
                    <span className="text-[#7C7C7C]">
                      {getSingleFeedBackMechanismData?.status ? (
                        <Tag
                          color={getFeedBackStatusColor(
                            getSingleFeedBackMechanismData.status
                          )}
                        >
                          {getSingleFeedBackMechanismData.status}
                        </Tag>
                      ) : (
                        "-"
                      )}
                    </span>
                  </div>
                </div>

                {/* Right Section: Feedback Type */}
                <div className="flex items-center space-x-2">
                  <p className="font-popinsSemiBold text-[13px]">
                    Feedback Type:
                  </p>
                  <span className="text-[#7C7C7C]">
                    {getSingleFeedBackMechanismData.feedback_type ?? "-"}
                  </span>
                </div>
              </div>

              <p className="font-popinsSemiBold text-[13px] mt-2">
                Description:
              </p>
              <span className="text-[#7C7C7C]">
                {getSingleFeedBackMechanismData.description ?? "-"}
              </span>
            </div>
          </Card>

          <Card className="mt-4">
            <h1 className="text-[17px] text-primary mb-3">Attachments:</h1>
            <div>
              <Flex wrap gap="middle">
                {getSingleFeedBackMechanismData.attachments &&
                getSingleFeedBackMechanismData.attachments.length > 0 ? (
                  <Image.PreviewGroup>
                    {getSingleFeedBackMechanismData.attachments.map(
                      (attachment, index) => {
                        const isVideo =
                          attachment.upload?.url?.includes(".mp4") ||
                          attachment.upload?.type?.includes("video");

                        return isVideo ? (
                          <div
                            key={index}
                            className="relative rounded shadow-md overflow-hidden"
                            style={{
                              width: 100,
                              height: 100,
                            }}
                          >
                            <video
                              className="rounded shadow-md"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                backgroundColor: "black",
                              }}
                              onClick={() =>
                                window.open(attachment.upload?.url, "_blank")
                              }
                              controls={false}
                            >
                              <source
                                src={attachment.upload?.url || ""}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                            <PlayCircleOutlined
                              style={{
                                fontSize: "32px",
                                color: "white",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                pointerEvents: "none",
                              }}
                            />
                          </div>
                        ) : (
                          <Image
                            key={index}
                            width={100}
                            height={100}
                            src={attachment.upload?.url || ""}
                            alt={`Attachment ${index + 1}`}
                            style={{
                              objectFit: "cover",
                            }}
                            className="rounded shadow-md"
                          />
                        );
                      }
                    )}
                  </Image.PreviewGroup>
                ) : (
                  <p className="text-gray-500">No attachments available.</p>
                )}
              </Flex>
            </div>
          </Card>
        </Spin>
      </section>
    </>
  );
};

export default ViewFeedBackMechanism;
