import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import CustomIconText from "../../common/CustomIconText";
import companysetting from "../../../assets/img/companysetting.svg";
import CompanyDetails from "./CompanyDetails";
import UpdateBusinessHours from "./UpdateBusinessHours";
import { selectHasPermission } from "../../../store/slices/authSlice";
import { useSelector } from "react-redux";

const CompanySetting = () => {
  const [selectItem, setSelectItem] = useState("company-details");
  const location = useLocation();
  const { state } = location;
  const hasPermission = useSelector(selectHasPermission);
  useEffect(() => {
    if (
      state &&
      ["company-details", "business-hour", "currencies"].includes(state)
    ) {
      setSelectItem(state);
    }
  }, [state]);

  const items = [
    {
      label: "Company Details",
      key: "company-details",
      disabled: !hasPermission("company", "read")
    },
    {
      label: "Business Hours",
      key: "business-hour",
      disabled: !hasPermission("company-hours", "read")
    },
    // {
    //   label: "Currencies",
    //   key: "currencies",
    // },
  ];

  const showCompanySettingPage = () => {
    switch (selectItem) {
      case "company-details":
        return <CompanyDetails />;
      case "business-hour":
        return <UpdateBusinessHours />;
      case "currencies":
        return <h1>Currencies</h1>;
      default:
        return null;
    }
  };

  return (
    <section className="main-wrapper">
      <CustomIconText
        image={companysetting}
        title="Company Setting"
        backArrow={false}
      />
      <div className="main-card flex flex-col border-[#DDDAE9] border-2 rounded-md bg-white mt-5 w-full card-size">
        <div className="h-[40px] flex items-center justify-center mt-[18px] mb-[10px]">
          {items.map(({ key, label, disabled }) => (
            <div
              key={key}
              className={`active-tabs flex items-center justify-center flex-1 text-center text-[17px] font-medium ${selectItem === key ? "active" : ""
                } ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
              onClick={() => {
                if (!disabled) setSelectItem(key); // Prevent onClick if disabled
              }}
            >
              <h1 className="text-[18px]">{label}</h1>
            </div>
          ))}
        </div>
        <div className="border border-[#DDDAE9]" />
        <div>{showCompanySettingPage()}</div>
      </div>
    </section>
  );
};

export default CompanySetting;
