export const TASK_PRIORITY = Object.freeze({
  HIGHEST: "Highest",
  HIGH: "High",
  NORMAL: "Normal",
  LOW: "Low",
  LOWEST: "Lowest",
});

export const TASK_STATUS = Object.freeze({
  NOT_STARTED: "Not Started",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  DEFERRED: "Deferred",
  WAITING_FOR_INPUT: "Waiting For Input",
});

export const TASK_REPEAT = Object.freeze({
  NONE: "None",
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
  YEARLY: "Yearly",
});

export const gridSettings = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 12,
  xl: 12,
};
