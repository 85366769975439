import React from "react";
import { useSelector } from "react-redux";
import Dashboard from "../dashboard/Dashboard";
import Login from "./Login";
import CompanyName from "../business/CompanyName";
import { Spin } from "antd";
import Begin from "../begin/Begin";
import { Navigate } from "react-router-dom";

const MainScreen = () => {
  const { user, usersLoader } = useSelector((state) => state.auth);

  if (usersLoader) {
    return <Spin />;
  }

  if (user && user.first_name) {
    if (user.company_users && user.company_users.length > 0) {
      const hasActiveUser = user.company_users.some(
        (companyUser) => companyUser.status === "ACTIVE"
      );

      if (hasActiveUser) {
        return <Begin />;
      } else {
        return <CompanyName />;
      }
    } else {
      return <CompanyName />;
    }
  } else {
    return <Navigate to="/" replace={true} />;
  }
};

export default MainScreen;
