import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import { RiSortAsc, RiSortDesc } from "react-icons/ri";
import {
  Avatar,
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Dropdown,
  Image,
  Input,
  List,
  message,
  Result,
  Row,
  Space,
  Spin,
  Timeline,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FaChevronDown } from "react-icons/fa6";
import beatIcon from "../../assets/img/beatIcon.svg";
import checkinIcon from "../../assets/img/checkin-icon.svg";
import checkoutIcon from "../../assets/img/checkout-icon.svg";
import locationIcon from "../../assets/img/location-icon.svg";
import locationImg from "../../assets/img/locationImg.svg";
import notvisitedIcon from "../../assets/img/not-visited-icon.svg";
import noteIcon from "../../assets/img/note-icon.svg";
import paymentIcon from "../../assets/img/payment-icon.svg";
import orderIcon from "../../assets/img/order-icon.svg";

import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { actionGetAllERPTrackingData } from "../../store/services/salesService";
import { actionGetCompanyAllUsers } from "../../store/services/usersService";
import { getFullName } from "../helper/common-function";
import MapComponent from "./MapComponent";
import { Link, useNavigate } from "react-router-dom";

const EmpTracking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    getAllEmpTrackingLoader,
    allEmpTrackingData,
    getAllUserLocationsLoader,
    allUserLocations,
  } = useSelector((state) => state.sales);
  const { getCompanyAllUserLoader, companyAllUsers } = useSelector(
    (state) => state.users
  );

  const { data = [], check_in_out_logs } = allEmpTrackingData || {};

  const [searchTerm, setSearchTerm] = useState("");
  const [date, setDate] = useState(dayjs());
  const [userId, setUserId] = useState("");
  const [order, setOrder] = useState("desc");
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    dispatch(actionGetCompanyAllUsers());
  }, [dispatch]);

  useEffect(() => {
    if (companyAllUsers && companyAllUsers.length > 0) {
      setUserId(companyAllUsers[0].id);
    }
  }, [companyAllUsers]);
  useEffect(() => {
    if (date && userId)
      dispatch(actionGetAllERPTrackingData({ date, userId, order }));
  }, [dispatch, date, userId, order]);

  const timerRef = useRef(null); // Reference to hold the timer

  // Function to simulate an API call
  const fetchData = (value) => {
    if (value) {
      console.log(`Fetching data for: ${value}`);
      // Here you can make your actual API call
      dispatch(actionGetCompanyAllUsers({ search: value }));
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (e.target.value === "") {
      dispatch(actionGetCompanyAllUsers({ search: e.target.value }));
    }

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      fetchData(value);
    }, 500);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => {
    const customTails = document.querySelectorAll(
      ".custom-tail .ant-timeline-item-tail"
    );

    customTails.forEach((tail, index) => {
      const currentDistance = data[index]?.distance + "km" || "0km"; // Default to "0km" if distance is not available

      tail.style.setProperty(
        "--dynamic-content",
        JSON.stringify(currentDistance)
      );
    });
  }, [data]);

  const items =
    data && data.length > 0
      ? [
          {
            color: "gray",

            dot: (
              <div className="relative w-full">
                <div className="border min-w-[100px] border-[#D8D8D8] text-xs text-[#7C7C7C] font-popinsMedium p-2 bg-white rounded-[5px]">
                  {dayjs(date).format("DD-MM-YYYY")}
                </div>
                <div className="flex  w-full absolute top-0 left-[110%] items-center justify-between">
                  <div className="flex  bg-white/40 items-center gap-x-2 max-h-[34px] border min-w-[200px] border-[#D8D8D8] text-xs text-[#7C7C7C] font-popinsMedium p-2  rounded-[5px]">
                    <Avatar size={30} src={data[0]?.created_by?.profile_pic} />
                    <Typography.Paragraph
                      ellipsis={{ rows: 1, tooltip: true }}
                      className="text-black  !mb-0"
                    >
                      {getFullName(
                        data[0]?.created_by.first_name,
                        data[0]?.created_by.last_name
                      )}
                    </Typography.Paragraph>
                  </div>
                </div>
              </div>
            ),
            children: (
              <div className="border inline-block border-[#D8D8D8] opacity-0 p-1 bg-white rounded-[5px] ">
                12/02/2024
              </div>
            ),
          },
          ...(data.map((d) => ({
            key: d.customer_visit_tracking_id,
            position: "right",
            className: "custom-tail",

            label: dayjs(d.created_at).format("HH:mm"),
            date: d.date || "N/A",
            color: "green",
            dot: d.is_meeting_pending ? (
              <CloseCircleOutlined style={{ fontSize: "16px", color: "red" }} />
            ) : (
              <CheckCircleOutlined
                style={{ fontSize: "16px", color: "green" }}
              />
            ),
            children: (
              <Collapse
                showArrow={false}
                className="tracking-collapse"
                bordered
                style={{ background: "white", border: "1px solid #D8D8D8" }}
                defaultActiveKey={[]}
                expandIcon={({ isActive }) => (
                  <FaChevronDown
                    style={{
                      color: isActive ? "#6883FD" : "#7C7C7C",
                      transition: "transform 0.3s",
                      transform: isActive ? "rotate(180deg)" : "",
                    }}
                  />
                )}
                items={[
                  {
                    key: "1",
                    showArrow: false,
                    label: (
                      <div className="flex items-center justify-between">
                        <div className="flex justify-center flex-col">
                          <h2 className="text-sm font-popinsSemiBold">
                            {/* {d.company_user?.full_name || "Unknown User"}{" "} */}
                            {d.business_partner?.business_partner_name ||
                              "Unknown User"}{" "}
                          </h2>
                          <div className="flex gap-x-2 items-center">
                            <Image
                              preview={false}
                              src={locationImg}
                              alt="location"
                            />
                            <p className="text-xs">
                              {d.business_partner?.address_1 ||
                                "No location specified."}{" "}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center gap-x-2">
                          <div className="flex items-center gap-x-1.5">
                            {/* <Image
                              src={d.is_meeting_pending ? visitedIcon : notvisitedIcon}
                              width={14}
                              height={14}
                              preview={false}
                              alt={d.is_meeting_pending ? "Visit Icon" : "Not Visit Icon"}
                            /> */}
                            <p
                              className="flex items-center gap-x-1 blink"
                              // className={`text-[12px] ${d.is_meeting_pending
                              //   ? "text-[#1EA433]"
                              //   : "text-[#FB2E2E]"
                              //   }`}
                            >
                              {/* {d.is_meeting_pending ? "Visited" : ""} */}
                              {d.is_match === false && (
                                <>
                                  <Image
                                    src={notvisitedIcon}
                                    width={14}
                                    height={14}
                                    preview={false}
                                    alt={"Not Visit Icon"}
                                  />
                                  <span className="text-[#FB2E2E] font-popinsSemiBold text-[13px]">
                                    Location Mismatch!
                                  </span>
                                </>
                              )}
                            </p>
                          </div>
                          <FaChevronDown style={{ color: "#7C7C7C" }} />
                        </div>
                      </div>
                    ),

                    children: (
                      <Space
                        className="p-3"
                        direction="vertical"
                        size="small"
                        style={{ width: "100%" }}
                      >
                        <div className="flex gap-x-3 items-center">
                          <Image
                            width={30}
                            preview={{ mask: <EyeOutlined /> }}
                            className="rounded-sm"
                            src={d.image?.url}
                          />
                          <p>View Picture</p>
                        </div>
                        {/* <Space align="center">
                         */}
                        <div className="flex flex-wrap items-center gap-x-1">
                          <Image
                            src={locationIcon}
                            preview={false}
                            height={15}
                            width={15}
                            alt="location icon"
                          />
                          <p className="text-brand text-xs">
                            Current Location:
                          </p>
                          <p className="text-gray text-xs">
                            {d.current_address || "Location not provided."}
                          </p>
                        </div>
                        {/* </Space> */}
                        {d.sales_orders_count > 0 && (
                          <div className="flex flex-wrap items-center gap-x-1">
                            <Image
                              src={orderIcon}
                              preview={false}
                              height={15}
                              width={15}
                              alt="order icon"
                            />
                            <div
                              // onClick={() => {
                              //   navigate("/sales-orders", {
                              //     state: {
                              //       businessPartnerId: d?.business_partner?.business_partner_id,
                              //       empTrackingDate: date.toISOString(),
                              //       createdById: d?.created_by?.id,
                              //     },
                              //   });
                              //   window.open("/sales-orders", "_blank");
                              // }}
                              onClick={() => {
                                const params = new URLSearchParams({
                                  businessPartnerId:
                                    d?.business_partner?.business_partner_id,
                                  
                                   empTrackingDate: date.toISOString(),
                                  createdById: d?.created_by?.id,
                                });

                                window.open(
                                  `/sales-orders?${params.toString()}`,
                                  "_blank"
                                );
                              }}
                            >
                              <span
                                className={`text-brand text-xs cursor-pointer hover:underline }`}
                              >
                                {d.sales_orders_count}{" "}
                                {d.sales_orders_count == 1 ? "Order" : "Orders"}
                              </span>
                            </div>
                          </div>
                        )}
                        <div className="flex flex-wrap items-center gap-x-1">
                          <Image
                            src={noteIcon}
                            preview={false}
                            height={15}
                            width={15}
                            alt="note icon"
                          />
                          <p className="text-brand text-xs">Note:</p>
                          <p className="text-gray text-xs">
                            {d.note || "No note available."}
                          </p>
                        </div>
                        {d.payment_collected > 0 && (
                          <div className="flex flex-wrap items-center gap-x-1">
                            <Image
                              src={paymentIcon}
                              preview={false}
                              height={15}
                              width={15}
                              alt="note icon"
                            />
                            <p className="text-brand text-xs">
                              Payment Collected:
                            </p>
                            <p className="text-gray text-xs">
                              {d.payment_collected}
                            </p>
                          </div>
                        )}
                      </Space>
                    ),
                  },
                ]}
              />
            ),
          })) || []),
        ]
      : [];

  const checkInLog =
    check_in_out_logs &&
    check_in_out_logs.length > 0 &&
    check_in_out_logs[check_in_out_logs.length - 1].status === "Check In"
      ? check_in_out_logs[check_in_out_logs.length - 1]
      : null;

  const checkInTime = checkInLog ? checkInLog.created_at : null;

  const checkOutLog =
    check_in_out_logs?.[0]?.status === "Check Out"
      ? check_in_out_logs[0]
      : null;

  const checkOutTime = checkOutLog ? checkOutLog.created_at : null;

  const toggleOrder = () => {
    setOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  // const selectedUser = useMemo(
  //   () => companyAllUsers.find(({ id }) => id === userId),
  //   [companyAllUsers, userId]
  // );

  const selectedUser = useMemo(
    () =>
      Array.isArray(companyAllUsers)
        ? companyAllUsers.find(({ id }) => id === userId)
        : undefined,
    [companyAllUsers, userId]
  );

  const avatarSrc =
    (allUserLocations &&
      allUserLocations?.length > 0 &&
      allUserLocations[0]?.profile_pic) ||
    selectedUser?.profile_pic;

  const fullName =
    (allUserLocations &&
      allUserLocations.length > 0 &&
      getFullName(
        allUserLocations[0]?.created_by?.first_name,
        allUserLocations[0]?.created_by?.last_name
      )) ||
    getFullName(selectedUser?.first_name, selectedUser?.last_name);

  return (
    <section className="main-wrapper emp-tracking-container">
      <Row gap="middle" justify="space-between" className="mb-4">
        <Col xs={24} md={6} lg={6} xl={6} xxl={10}>
          <div className="text-brand flex items-center gap-x-3">
            <Image
              src={beatIcon}
              alt="image"
              height={20}
              width={20}
              preview={false}
            />
            <h1 className="lg:text-lg text-base  font-popinsRegular">
              EMP Tracking
            </h1>
          </div>
        </Col>
        <Col xs={24} md={18} lg={18} xl={18} xxl={14}>
          <div className="flex items-center gap-x-2">
            <DatePicker
              allowClear={false}
              format={"DD-MM-YYYY"}
              style={{ backgroundColor: "white", width: "100%", flex: 1 }}
              className="w-full sales-order-datepicker"
              value={date}
              onChange={(d) => setDate(d)}
            />

            <div className="flex flex-1 items-center p-2.5 px-4 text-[16px]  min-h-[45px] bg-white rounded-[10px] border border-[#DDDAE9]">
              <div className="flex items-center gap-x-3">
                <Image
                  height={20}
                  preview={false}
                  width={20}
                  src={checkinIcon}
                  alt="check-in icon"
                />
                <span className="text-brand">Check In : </span>
              </div>
              <p className="text-black font-popinsRegular ml-2">
                {checkInTime ? dayjs(checkInTime).format("HH:mm A") : "-"}
              </p>
            </div>
            <div className="flex flex-1 items-center p-2.5 px-4 text-[16px]  min-h-[45px] bg-white rounded-[10px] border border-[#DDDAE9]">
              <div className="flex items-center gap-x-3">
                <Image
                  height={20}
                  width={20}
                  preview={false}
                  src={checkoutIcon}
                  alt="check-out icon"
                />
                <span className="text-brand">Check Out : </span>
              </div>
              <p className="text-black font-popinsRegular ml-2">
                {checkOutTime ? dayjs(checkOutTime).format("HH:mm A") : "-"}
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Row
        gutter={16}
        align={"top"}
        justify={"space-around"}
        style={{ padding: "20px 0" }}
      >
        <Col xs={24} sm={10} md={10} lg={8} xl={6}>
          <div
            style={{
              maxWidth: "100%",
              margin: "0 auto",
              padding: "20px 0",
              paddingBottom: 0,
              borderRadius: 10,
              boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
              backgroundColor: "#fff",
            }}
          >
            <Space.Compact
              style={{
                width: "100%",
                padding: "0 15px",
                margin: "auto",
              }}
            >
              <Input
                className="search-input !indent-1"
                placeholder="Search"
                onChange={handleSearchChange}
                onPressEnter={(e) => {
                  if (searchTerm) {
                    dispatch(
                      actionGetCompanyAllUsers({ search: e.target.value })
                    );
                  }
                }}
                style={{ marginBottom: 20, borderRadius: 8 }}
              />
            </Space.Compact>

            <List
              loading={getCompanyAllUserLoader}
              style={{ maxHeight: 600, overflow: "auto" }}
              className="hide-scrollbar"
              rowKey="id"
              itemLayout="horizontal"
              id="emp-list"
              dataSource={companyAllUsers || []}
              renderItem={(user, index) => (
                <List.Item
                  className={`${
                    user.id === userId ? "bg-[#E8E6F066]" : "bg-white"
                  } ${
                    index === companyAllUsers.length - 1
                      ? "rounded-bl-[10px] rounded-br-[10px]"
                      : ""
                  }`}
                  onClick={() => setUserId(user.id)}
                  style={{
                    padding: "15px 15px",

                    cursor: "pointer",
                  }}
                >
                  <List.Item.Meta
                    avatar={<Avatar src={user.profile_pic} />}
                    title={getFullName(user.first_name, user.last_name)}
                  />
                </List.Item>
              )}
            />
          </div>
        </Col>
        <Col span={2} />
        <Col xs={24} sm={12} md={12} lg={16} xl={16}>
          {getAllEmpTrackingLoader ? (
            <div className="h-[300px] flex items-center justify-center">
              <Spin />
            </div>
          ) : (
            <div className="relative w-full">
              {showMap ? (
                <>
                  <div className="flex relative top-1.5 justify-between items-end   h-[35px] ">
                    <div className="flex gap-x-2">
                      <div className="border  min-w-[100px] text-center border-[#D8D8D8] text-xs text-[#7C7C7C] font-popinsMedium p-2 bg-white rounded-[5px]">
                        {dayjs(date).format("DD-MM-YYYY")}
                      </div>
                      <div className="flex  w-full  items-center justify-between">
                        <div className="flex  bg-white/40 items-center gap-x-2 max-h-[34px] border min-w-[200px] border-[#D8D8D8] text-xs text-[#7C7C7C] font-popinsMedium p-2  rounded-[5px]">
                          <Avatar size={"small"} src={avatarSrc} />
                          <Typography.Paragraph
                            ellipsis={{ rows: 1, tooltip: true }}
                            className="text-black  !mb-0"
                          >
                            {fullName}
                          </Typography.Paragraph>
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={() => setShowMap(!showMap)}
                      className="border  rounded p-1 h-full border-[#D8D8D8]"
                    >
                      <Tooltip
                        title={order === "asc" ? "Ascending" : "Descending"}
                      >
                        <div
                          className={
                            "min-w-[120px] text-xs h-full rounded flex justify-center items-center cursor-pointer transition duration-500 bg-brand text-white"
                          }
                          onClick={toggleOrder}
                        >
                          {showMap ? "Show on Timeline" : "Show on Map"}
                        </div>
                      </Tooltip>
                    </div>
                  </div>

                  <MapComponent userId={userId} data={data} date={date} />
                </>
              ) : (
                data &&
                data.length > 0 && (
                  <Timeline
                    className="py-5"
                    id="erp-tracking-timeline"
                    mode="left"
                    items={items}
                  />
                )
              )}

              {!showMap && data.length > 0 && (
                <div className="absolute z-50 top-[10px] right-[7%] xl:right-[8%]">
                  <div className="flex gap-x-1 h-[35px] text-xs">
                    <div
                      onClick={() => setShowMap(!showMap)}
                      className="border  rounded p-1 h-full border-[#D8D8D8]"
                    >
                      <Tooltip
                        title={order === "asc" ? "Ascending" : "Descending"}
                      >
                        <div
                          className={
                            "min-w-[100px] h-full rounded flex justify-center items-center cursor-pointer transition duration-500 bg-brand text-white"
                          }
                          onClick={toggleOrder}
                        >
                          {showMap ? "Show on Timeline" : "Show on Map"}
                        </div>
                      </Tooltip>
                    </div>
                    <div className="border  rounded p-1 h-full border-[#D8D8D8]">
                      <Tooltip
                        title={order === "asc" ? "Ascending" : "Descending"}
                      >
                        <div
                          className={
                            "w-[31px] h-full rounded flex justify-center items-center cursor-pointer transition duration-500 bg-brand text-white"
                          }
                          onClick={toggleOrder}
                        >
                          {order === "asc" ? <RiSortAsc /> : <RiSortDesc />}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              )}
              {!showMap && data && data.length === 0 && (
                <>
                  <div className="flex  relative top-1.5 justify-between items-end   h-[35px] ">
                    <div className="flex gap-x-2 ml-6">
                      <div className="border  min-w-[100px] text-center border-[#D8D8D8] text-xs text-[#7C7C7C] font-popinsMedium p-2 bg-white rounded-[5px]">
                        {dayjs(date).format("DD-MM-YYYY")}
                      </div>
                      <div className="flex  w-full  items-center justify-between">
                        <div className="flex  bg-white/40 items-center gap-x-2 max-h-[34px] border min-w-[200px] border-[#D8D8D8] text-xs text-[#7C7C7C] font-popinsMedium p-2  rounded-[5px]">
                          <Avatar size={"small"} src={avatarSrc} />
                          <Typography.Paragraph
                            ellipsis={{ rows: 1, tooltip: true }}
                            className="text-black  !mb-0"
                          >
                            {fullName}
                          </Typography.Paragraph>
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={() => setShowMap(!showMap)}
                      className="border  rounded p-1 h-full border-[#D8D8D8]"
                    >
                      <Tooltip
                        title={order === "asc" ? "Ascending" : "Descending"}
                      >
                        <div
                          className={
                            "w-[100px] text-xs h-full rounded flex justify-center items-center cursor-pointer transition duration-500 bg-brand text-white"
                          }
                          onClick={toggleOrder}
                        >
                          {showMap ? "Show Timeline" : "Show Map"}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <Card className="mt-5">
                    <Result
                      status="info"
                      title="No Tracking Data Available"
                      subTitle="There is no tracking data available for the selected user and date at this moment."
                    />
                  </Card>
                </>
              )}
            </div>
          )}
        </Col>
      </Row>
    </section>
  );
};

export default EmpTracking;
