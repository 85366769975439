import { createBrowserRouter, Navigate } from "react-router-dom";
import ChangeMobile from "../components/auth/ChangeMobile";
import CreateAccount from "../components/auth/CreateAccount";
import ErrorPage from "../components/auth/ErrorPage";
import ForgotPassword from "../components/auth/ForgotPassword";
import Login from "../components/auth/Login";
import LoginOTP from "../components/auth/LoginOTP";
import MainScreen from "../components/auth/MainScreen";
import OtpVerfication from "../components/auth/OtpVerfication";
import Register from "../components/auth/Register";
import ResetPassword from "../components/auth/ResetPassword";
import Begin from "../components/begin/Begin";
import BusinessHoursConfig from "../components/business/BusinessHoursConfig";
import NotFound from "../components/common/NotFound";
import Root from "../components/common/Root";
import Contacts from "../components/crm/contacts/Contacts";
import CreateContact from "../components/crm/contacts/CreateContact";
import Attendees from "../components/crm/events/Attendees";
import CreateEvent from "../components/crm/events/CreateEvent";
import EventInventory from "../components/crm/events/EventInventory";
import EventOverView from "../components/crm/events/EventOverView";
import Events from "../components/crm/events/Events";
import Exhibitors from "../components/crm/events/Exhibitors";
import Sessions from "../components/crm/events/Sessions";
import Speakers from "../components/crm/events/Speakers";
import Sponsor from "../components/crm/events/Sponsor";
import CreateLead from "../components/crm/leads/CreateLead";
import Leads from "../components/crm/leads/Leads";
import CreateOpportunity from "../components/crm/opportunities/CreateOpportunity";
import Opportunities from "../components/crm/opportunities/Opportunities";
import { PrivateRoute, PublicRoute } from "../components/helper/RouteHoc";
import Inventory from "../components/inventory/Inventory";
import AcceptInvitation from "../components/invitation/AcceptInvitation";
import AcceptInvitationCreateAccount from "../components/invitation/AcceptInvitationCreateAccount";
import BusinessPartners from "../components/master-data/business-partner/BusinessPartners";
import CreateBusinessPartner from "../components/master-data/business-partner/CreateBusinessPartner";
import ViewBusinessPartner from "../components/master-data/business-partner/ViewBusinessPartner";
import GeneralMasters from "../components/master-data/general-master/GeneralMasters";
import BeatPlanning from "../components/sales-force/BeatPlanning";
// import MySalesOrder from "../components/sales-orders/MySalesOrder";
import { useSelector } from "react-redux";
import ChangePassword from "../components/auth/ChangePassword";
import AddCollectionManagement from "../components/collection-management/AddCollectionManagement";
import CollectionManagement from "../components/collection-management/CollectionManagement";
import CustomImportComp from "../components/common/CustomImportComp";
import CreateRequstForQuotation from "../components/crm/requestForQuotation/CreateRequstForQuotation";
import RequstForQuotation from "../components/crm/requestForQuotation/RequstForQuotation";
import CreateFeedbcakMechanism from "../components/feedback-mechanism/CreateFeedbcakMechanism";
import FeedBackMechanism from "../components/feedback-mechanism/FeedBackMechanism";
import ViewFeedBackMechanism from "../components/feedback-mechanism/ViewFeedBackMechanism";
import AddExpenseVoucher from "../components/finance/expense-voucher/AddExpenseVoucher";
import ExpenseVoucher from "../components/finance/expense-voucher/ExpenseVoucher";
import ViewExpenseVoucher from "../components/finance/expense-voucher/ViewExpenseVoucher";
import MasterData from "../components/master-data/MasterData";
import AttendeeStatus from "../components/master-data/event-master/AttendeeStatus";
import AttendeeType from "../components/master-data/event-master/AttendeeType";
import EventCategory from "../components/master-data/event-master/EventCategory";
import EventOnlinePlatforms from "../components/master-data/event-master/EventOnlinePlatforms";
import EvenetProducts from "../components/master-data/event-master/EventProducts";
import EventType from "../components/master-data/event-master/EventType";
import ExhibitionCategory from "../components/master-data/event-master/ExhibitionCategory";
import AddressType from "../components/master-data/general-master/AddressType";
import Area from "../components/master-data/general-master/Area";
import BeatGrade from "../components/master-data/general-master/BeatGrade";
import BeatMaster from "../components/master-data/general-master/BeatMaster";
import BusinessPartnerType from "../components/master-data/general-master/BusinessPartnerType";
import DepartmentMaster from "../components/master-data/general-master/DepartmentMaster";
import DepositeMaster from "../components/master-data/general-master/DepositeMaster";
import ExchangeCurrency from "../components/master-data/general-master/ExchangeCurrency";
import ExpenseCategory from "../components/master-data/general-master/ExpenseCategory";
import IndustryMaster from "../components/master-data/general-master/IndustryMaster";
import LeadRating from "../components/master-data/general-master/LeadRating";
import LeadSource from "../components/master-data/general-master/LeadSource";
import LeadStatus from "../components/master-data/general-master/LeadStatus";
import MaterialGroup from "../components/master-data/general-master/MaterialGroup";
import OpportunityStage from "../components/master-data/general-master/OpportunityStage";
import PaymentMethod from "../components/master-data/general-master/PaymentMethod";
import PlantCode from "../components/master-data/general-master/PlantCode";
import TaskStatus from "../components/master-data/general-master/TaskStatus";
import TypeOfCompany from "../components/master-data/general-master/TypeOfCompany";
import UnitConversion from "../components/master-data/general-master/UnitConversion";
import Zone from "../components/master-data/general-master/Zone";
import HsnSacCode from "../components/master-data/product-master/HsnSacCode";
import Material from "../components/master-data/product-master/Product";
import ProductCategory from "../components/master-data/product-master/ProductCategory";
import ProductSubCategory from "../components/master-data/product-master/ProductSubCategory";
import ProductType from "../components/master-data/product-master/ProductType";
import Unit from "../components/master-data/product-master/Unit";
import EmpTracking from "../components/sales-force/EmpTracking";
import AddSalesOrder from "../components/sales-orders/AddSalesOrder";
import SalesInvoices from "../components/sales-orders/SalesInvoices";
import SalesOrders from "../components/sales-orders/SalesOrders";
import ViewSalesInvoice from "../components/sales-orders/ViewSalesInvoice";
import ViewSalesOrder from "../components/sales-orders/ViewSalesOrder";
import AttendanceLogs from "../components/setting/AttendanceLogs";
import CustomFields from "../components/setting/CustomFields";
import Permissions from "../components/setting/Permissions";
import SFASetting from "../components/setting/SFASetting";
import Setting from "../components/setting/Setting";
import CompanySettingWrapper from "../components/setting/company-setting/CompanySettingWrapper";
import AddRole from "../components/setting/roles/AddRole";
import AddRoleProfile from "../components/setting/roles/AddRoleProfile";
import Hierarchy from "../components/setting/roles/Hierarchy";
import ModulePermissions from "../components/setting/roles/ModulePermissions";
import RoleProfile from "../components/setting/roles/RoleProfile";
import Task from "../components/task/Task";
import CreateUser from "../components/user/CreateUser";
import UpdateUser from "../components/user/UpdateUser";
import Users from "../components/user/Users";
import { selectHasPermission } from "../store/slices/authSlice";

const ProtectedRoute = ({ element, requiredPermission }) => {
  const { userLoader } = useSelector((state) => state.auth);
  const hasPermission = useSelector(selectHasPermission);

  if (userLoader) {
    return null;
  }

  if (!requiredPermission) {
    return element;
  }

  return hasPermission(
    requiredPermission.resource,
    requiredPermission.action
  ) ? (
    element
  ) : (
    <Navigate to="/" replace />
  );
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute component={<Root />} />,
    children: [
      {
        path: "/",
        element: <MainScreen />,
        index: true,
      },
      {
        path: "/inventory",
        element: <Inventory />,
      },

      {
        path: "/create-user",
        element: <CreateUser />,
      },
      {
        path: "/update-user",
        element: <UpdateUser />,
      },
      {
        path: "/company-settings",
        element: <ProtectedRoute
          element={<CompanySettingWrapper />}
          requiredPermission={{ resource: "company", action: "read" }}
        />
      },
      {
        path: "/personal-setting",
        element: <UpdateUser />
      },
      {
        path: "/sales-orders",
        element: <ProtectedRoute
          element={<SalesOrders />}
          requiredPermission={{ resource: "sales-order", action: "read" }}
        />
      },
      {
        path: "/sales-orders-import",
        element: (
          <CustomImportComp
            text={"Sales-Orders"}
            navigate={true}
            backText="/sales-orders"
            hasItems={true}
            downloadUrl="sales-order/import-excel-format"
            uploadUrl="sales-order/import"
          />
        ),
      },
      {
        path: "/view-sales-orders",
        element: <ViewSalesOrder />,
      },
      {
        path: "/add-sales-order",
        element: <AddSalesOrder />,
      },
      {
        path: "/update-sales-order",
        element: <AddSalesOrder />,
      },
      {
        path: "/sales-invoices",
        element: <ProtectedRoute
          element={<SalesInvoices />}
          requiredPermission={{ resource: "invoice", action: "read" }}
        />
      },
      {
        path: "/view-sales-invoice",
        element: <ViewSalesInvoice />,
      },
      {
        path: "/purchase-orders",
        element: <h1>purchase-orders</h1>,
      },
      {
        path: "/sales-orders",
        element: <h1>sales-orders</h1>,
      },

      {
        path: "/add-role",
        element: <AddRole />,
      },

      {
        path: "/permissions",
        element: <Permissions />,
      },
      {
        path: "/",
        element: <Setting />,
        children: [
          {
            path: "sfa-settings",
            element: <ProtectedRoute
              element={<SFASetting />}
              requiredPermission={{ resource: "company", action: "read" }}
            />
          },
          {
            path: "role-hierarchy",
            element: <ProtectedRoute
              element={<Hierarchy />}
              requiredPermission={{ resource: "role", action: "read" }}
            />
          },
          {
            path: "role-profile",
            element: <ProtectedRoute
              element={<RoleProfile />}
              requiredPermission={{ resource: "role-profile", action: "read" }}
            />
          },

          {
            path: "add-role-profile",
            element: <AddRoleProfile />,
          },
          {
            path: "update-role-profile",
            element: <AddRoleProfile />,
          },
          {
            path: "users",
            element: <ProtectedRoute
              element={<Users />}
              requiredPermission={{ resource: "company-users", action: "read" }}
            />
          },
        ],
      },

      {
        path: "/events",
        element: <ProtectedRoute
          element={<Events />}
          requiredPermission={{ resource: "events", action: "read" }}
        />
      },
      {
        path: "/create-event",
        element: <CreateEvent />,
      },
      {
        path: "/update-event",
        element: <CreateEvent />,
      },
      {
        path: "/event-overview",
        element: <EventOverView />,
      },
      {
        path: "/attendees",
        element: <Attendees />,
      },
      {
        path: "/exhibitors",
        element: <Exhibitors />,
      },
      {
        path: "/sessions",
        element: <Sessions />,
      },
      {
        path: "/speakers",
        element: <Speakers />,
      },
      {
        path: "/sponsor",
        element: <Sponsor />,
      },
      {
        path: "/event-inventory",
        element: <EventInventory />,
      },
      {
        path: "/module-permissions",
        element: <ModulePermissions />,
      },
      {
        path: "/lead-management",
        element: <ProtectedRoute
          element={<Leads />}
          requiredPermission={{ resource: "lead", action: "read" }}
        />
      },
      {
        path: "/create-lead",
        element: <CreateLead />,
      },
      {
        path: "/update-lead",
        element: <CreateLead />,
      },
      {
        path: "/beat-planning",
        element: <ProtectedRoute
          element={<BeatPlanning />}
          requiredPermission={{ resource: "beat-planning", action: "read" }}
        />
      },
      {
        path: "/emp-tracking",
        element: <ProtectedRoute
          element={<EmpTracking />}
          requiredPermission={{ resource: "customer-visit-tracking", action: "read" }}
        />
      },
      {
        path: "/hr",
        element: <h1>HR</h1>,
      },
      {
        path: "/task",
        element: <ProtectedRoute
          element={<Task />}
          requiredPermission={{ resource: "task", action: "read" }}
        />
      },
      {
        path: "/beat",
        element: <h1>Beat</h1>,
      },
      {
        path: "/credit-notes",
        element: <h1>Credit notes</h1>,
      },
      {
        path: "/expense-voucher",
        element: <ProtectedRoute
          element={<ExpenseVoucher />}
          requiredPermission={{ resource: "expense-voucher", action: "read" }}
        />
      },
      {
        path: "/add-expense-voucher",
        element: <AddExpenseVoucher />,
      },
      {
        path: "/update-expense-voucher",
        element: <AddExpenseVoucher />,
      },
      {
        path: "/view-expense-voucher",
        element: <ViewExpenseVoucher />,
      },
      {
        path: "/collection-management",
        element: <ProtectedRoute
          element={<CollectionManagement />}
          requiredPermission={{ resource: "collection-management", action: "read" }}
        />
      },
      {
        path: "/add-collection-management",
        element: <AddCollectionManagement />,
      },
      {
        path: "/update-collection-management",
        element: <AddCollectionManagement />,
      },
      {
        path: "/delivery",
        element: <h1>Delivery</h1>,
      },

      {
        path: "/general-masters",
        element: <GeneralMasters />,
      },

      {
        path: "/attendance-logs",
        element: <AttendanceLogs />,
      },

      {
        path: "/master-data/",
        element: <MasterData />,
        children: [
          {
            path: "event-type",
            element: <ProtectedRoute
              element={<EventType />}
              requiredPermission={{ resource: "event-type-master", action: "read" }}
            />
          },
          {
            path: "event-category",
            element: <ProtectedRoute
              element={<EventCategory />}
              requiredPermission={{ resource: "event-category-master", action: "read" }}
            />
          },
          {
            path: "event-online-platforms",
            element: <ProtectedRoute
              element={<EventOnlinePlatforms />}
              requiredPermission={{ resource: "event-online-platforms-master", action: "read" }}
            />
          },
          {
            path: "attendee-status",
            element: <ProtectedRoute
              element={<AttendeeStatus />}
              requiredPermission={{ resource: "event-attendee-status-master", action: "read" }}
            />
          },
          {
            path: "attendee-type",
            element: <ProtectedRoute
              element={<AttendeeType />}
              requiredPermission={{ resource: "event-attendee-type-master", action: "read" }}
            />
          },
          {
            path: "exhibitor-category",
            element: <ProtectedRoute
              element={<ExhibitionCategory />}
              requiredPermission={{ resource: "event-exhibitor-category-master", action: "read" }}
            />
          },
          {
            path: "event-products",
            element: <ProtectedRoute
              element={<EvenetProducts />}
              requiredPermission={{ resource: "event-product-master", action: "read" }}
            />
          },
          {
            path: "address-type",
            element: <ProtectedRoute
              element={<AddressType />}
              requiredPermission={{ resource: "address-type-master", action: "read" }}
            />
          },
          {
            path: "address-type-import",
            element: (
              <CustomImportComp
                text={"Address Type"}
                navigate={true}
                backText="/general-masters/address-type"
                downloadUrl="address-type-master/import-excel-format"
                uploadUrl="address-type-master/import"
              />
            ),
          },

          {
            path: "material",
            element: <ProtectedRoute
              element={<Material />}
              requiredPermission={{ resource: "material-master", action: "read" }}
            />
          },
          {
            path: "material-type",
            element: <ProtectedRoute
              element={<ProductType />}
              requiredPermission={{ resource: "product-type-master", action: "read" }}
            />
          },
          {
            path: "hsn-sac-code",
            element: <ProtectedRoute
              element={<HsnSacCode />}
              requiredPermission={{ resource: "hsn-code-master", action: "read" }}
            />
          },
          {
            path: "material-category",
            element: <ProtectedRoute
              element={<ProductCategory />}
              requiredPermission={{ resource: "product-category-master", action: "read" }}
            />
          },
          {
            path: "material-sub-category",
            element: <ProtectedRoute
              element={<ProductSubCategory />}
              requiredPermission={{ resource: "product-sub-category-master", action: "read" }}
            />
          },
          {
            path: "unit",
            element: <ProtectedRoute
              element={<Unit />}
              requiredPermission={{ resource: "unit-master", action: "read" }}
            />
          },
          {
            path: "material-import",
            element: (
              <CustomImportComp
                navigate={true}
                text="Material"
                backText="/material-masters/material"
              />
            ),
          },
          {
            path: "material-type-import",
            element: (
              <CustomImportComp
                text={"Material-type"}
                navigate={true}
                backText="/material-masters/material-type"
                downloadUrl="product-type-master/import-excel-format"
                uploadUrl="product-type-master/import"
              />
            ),
          },
          {
            path: "hsn-sac-code-import",
            element: (
              <CustomImportComp
                text={"hsn-sac-code"}
                navigate={true}
                backText="/material-masters/hsn-sac-code"
                downloadUrl="hsn-code-master/import-excel-format"
                uploadUrl="hsn-code-master/import"
              />
            ),
          },
          {
            path: "material-category-import",
            element: (
              <CustomImportComp
                text={"Material-Category"}
                navigate={true}
                backText="/material-masters/material-category"
                downloadUrl="product-category-master/import-excel-format"
                uploadUrl="product-category-master/import"
              />
            ),
          },
          {
            path: "material-sub-category-import",
            element: (
              <CustomImportComp
                text={"Material-Sub-Category"}
                navigate={true}
                backText="/material-masters/material-sub-category"
                downloadUrl="product-sub-category-master/import-excel-format"
                uploadUrl="product-sub-category-master/import"
              />
            ),
          },
          {
            path: "unit-import",
            element: (
              <CustomImportComp
                text={"Unit"}
                navigate={true}
                backText="/material-masters/unit"
                downloadUrl="unit-master/import-excel-format"
                uploadUrl="unit-master/import"
              />
            ),
          },

          {
            path: "lead-status",
            element: <ProtectedRoute
              element={<LeadStatus />}
              requiredPermission={{ resource: "leads-status", action: "read" }}
            />
          },
          {
            path: "lead-status-import",
            element: (
              <CustomImportComp
                text={"Lead Status"}
                navigate={true}
                backText="/general-masters/lead-status"
                downloadUrl="leads-status/import-excel-format"
                uploadUrl="leads-status/import"
              />
            ),
          },
          {
            path: "lead-source",
            element: <ProtectedRoute
              element={<LeadSource />}
              requiredPermission={{ resource: "lead-source", action: "read" }}
            />
          },
          {
            path: "lead-source-import",
            element: (
              <CustomImportComp
                text={"Lead Source"}
                navigate={true}
                backText="/general-masters/lead-source"
                downloadUrl="lead-source/import-excel-format"
                uploadUrl="lead-source/import"
              />
            ),
          },
          {
            path: "lead-rating",
            element: <ProtectedRoute
              element={<LeadRating />}
              requiredPermission={{ resource: "lead-rating", action: "read" }}
            />
          },
          {
            path: "lead-rating-import",
            element: (
              <CustomImportComp
                text={"Lead Rating"}
                navigate={true}
                backText="/general-masters/lead-rating"
                downloadUrl="lead-rating/import-excel-format"
                uploadUrl="lead-rating/import"
              />
            ),
          },
          {
            path: "industry-master",
            element: <ProtectedRoute
              element={<IndustryMaster />}
              requiredPermission={{ resource: "industry-master", action: "read" }}
            />
          },
          {
            path: "industry-master-import",
            element: (
              <CustomImportComp
                text={"Industry Master"}
                navigate={true}
                backText="/general-masters/industry-master"
                downloadUrl="industry-master/import-excel-format"
                uploadUrl="industry-master/import"
              />
            ),
          },
          {
            path: "opportunity-stage",
            element: <ProtectedRoute
              element={<OpportunityStage />}
              requiredPermission={{ resource: "opportunity-stage", action: "read" }}
            />
          },
          {
            path: "opportunity-stage-import",
            element: (
              <CustomImportComp
                text={"Opportunity Stage"}
                navigate={true}
                backText="/general-masters/opportunity-stage"
                downloadUrl="opportunity-stage/import-excel-format"
                uploadUrl="opportunity-stage/import"
              />
            ),
          },
          {
            path: "beat-grade",
            element: <ProtectedRoute
              element={<BeatGrade />}
              requiredPermission={{ resource: "beat-grade-master", action: "read" }}
            />
          },
          {
            path: "beat-grade-import",
            element: (
              <CustomImportComp
                text={"Beat Grade"}
                navigate={true}
                backText="/general-masters/beat-grade"
                downloadUrl="beat-grade-master/import-excel-format"
                uploadUrl="beat-grade-master/import"
              />
            ),
          },
          {
            path: "business-partner-type",
            element: <ProtectedRoute
              element={<BusinessPartnerType />}
              requiredPermission={{ resource: "business-partner-type-master", action: "read" }}
            />
          },
          {
            path: "business-partner-type-import",
            element: (
              <CustomImportComp
                text={"Business Partner Type"}
                navigate={true}
                backText="/general-masters/business-partner-type"
                downloadUrl="business-partner-type-master/import-excel-format"
                uploadUrl="business-partner-type-master/import"
              />
            ),
          },
          {
            path: "beat-master",
            element: <ProtectedRoute
              element={<BeatMaster />}
              requiredPermission={{ resource: "beat-master", action: "read" }}
            />
          },
          {
            path: "beat-master-import",
            element: (
              <CustomImportComp
                text={"Beat Master"}
                navigate={true}
                backText="/general-masters/beat-master"
                downloadUrl="beat-master/import-excel-format"
                uploadUrl="beat-master/import"
              />
            ),
          },
          {
            path: "department",
            element: <ProtectedRoute
              element={<DepartmentMaster />}
              requiredPermission={{ resource: "department-master", action: "read" }}
            />
          },
          {
            path: "department-import",
            element: (
              <CustomImportComp
                text={"Department"}
                navigate={true}
                backText="/general-masters/department"
                downloadUrl="department-master/import-excel-format"
                uploadUrl="department-master/import"
              />
            ),
          },
          {
            path: "type-of-company",
            element: <ProtectedRoute
              element={<TypeOfCompany />}
              requiredPermission={{ resource: "type-of-company-master", action: "read" }}
            />
          },
          {
            path: "type-of-company-import",
            element: (
              <CustomImportComp
                text={"Type Of Company"}
                navigate={true}
                backText="/general-masters/type-of-company"
                downloadUrl="type-of-company-master/import-excel-format"
                uploadUrl="type-of-company-master/import"
              />
            ),
          },
          {
            path: "zone",
            element: <ProtectedRoute
              element={<Zone />}
              requiredPermission={{ resource: "zone-master", action: "read" }}
            />
          },
          {
            path: "zone-import",
            element: (
              <CustomImportComp
                text={"Zone"}
                navigate={true}
                backText="/general-masters/zone"
                downloadUrl="zone-master/import-excel-format"
                uploadUrl="zone-master/import"
              />
            ),
          },
          {
            path: "area",
            element: <ProtectedRoute
              element={<Area />}
              requiredPermission={{ resource: "area-master", action: "read" }}
            />
          },
          {
            path: "area-import",
            element: (
              <CustomImportComp
                text={"Area"}
                navigate={true}
                backText="/general-masters/area"
                downloadUrl="area-master/import-excel-format"
                uploadUrl="area-master/import"
              />
            ),
          },
          {
            path: "task-status",
            element: <ProtectedRoute
              element={<TaskStatus />}
              requiredPermission={{ resource: "task-status-master", action: "read" }}
            />
          },
          {
            path: "task-status-import",
            element: (
              <CustomImportComp
                text={"Task Status"}
                navigate={true}
                backText="/general-masters/task-status"
                downloadUrl="task-status-master/import-excel-format"
                uploadUrl="task-status-master/import"
              />
            ),
          },
          {
            path: "unit-conversion",
            element: <UnitConversion />,
          },
          {
            path: "unit-conversion-import",
            element: (
              <CustomImportComp
                text={"Unit Conversion"}
                navigate={true}
                backText="/general-masters/unit-conversion"
                downloadUrl="unit-conversion-master/import-excel-format"
                uploadUrl="unit-conversion-master/import"
              />
            ),
          },
          {
            path: "material-group",
            element: <ProtectedRoute
              element={<MaterialGroup />}
              requiredPermission={{ resource: "material-group-master", action: "read" }}
            />
          },
          {
            path: "material-group-import",
            element: (
              <CustomImportComp
                text={"Material Group"}
                navigate={true}
                backText="/general-masters/material-group"
                downloadUrl="material-group-master/import-excel-format"
                uploadUrl="material-group-master/import"
              />
            ),
          },
          {
            path: "expense-category",
            element: <ProtectedRoute
              element={<ExpenseCategory />}
              requiredPermission={{ resource: "expense-category-type-master", action: "read" }}
            />
          },
          {
            path: "expense-category-import",
            element: (
              <CustomImportComp
                text={"Expense Category"}
                navigate={true}
                backText="/general-masters/expense-category"
                downloadUrl="expense-category-type-master/import-excel-format"
                uploadUrl="expense-category-type-master/import"
              />
            ),
          },
          {
            path: "payment-method",
            element: <ProtectedRoute
              element={<PaymentMethod />}
              requiredPermission={{ resource: "payment-method-master", action: "read" }}
            />
          },
          {
            path: "payment-method-import",
            element: (
              <CustomImportComp
                text={"Payment Method"}
                navigate={true}
                backText="/general-masters/payment-method"
                downloadUrl="payment-method-master/import-excel-format"
                uploadUrl="payment-method-master/import"
              />
            ),
          },
          {
            path: "address-type",
            element: <AddressType />,
          },
          {
            path: "address-type-import",
            element: (
              <CustomImportComp
                text={"Address Type"}
                navigate={true}
                backText="/general-masters/address-type"
                downloadUrl="address-type-master/import-excel-format"
                uploadUrl="address-type-master/import"
              />
            ),
          },
          {
            path: "currency-exchange",
            element: <ProtectedRoute
              element={<ExchangeCurrency />}
              requiredPermission={{ resource: "currency-exchange", action: "read" }}
            />
          },
          {
            path: "currency-exchange-import",
            element: (
              <CustomImportComp
                text={"Currency Exchange"}
                navigate={true}
                backText="/general-masters/currency-exchange"
                downloadUrl="exchange-currency-master/import-excel-format"
              />
            ),
          },
          {
            path: "deposit-master",
            element: <ProtectedRoute
              element={<DepositeMaster />}
              requiredPermission={{ resource: "deposit-master", action: "read" }}
            />
          },
          {
            path: "deposit-master-import",
            element: (
              <CustomImportComp
                text={"Deposit Master"}
                navigate={true}
                backText="/general-masters/deposit-master"
                downloadUrl="deposit-master/import-excel-format"
                uploadUrl="deposit-master/import"
              />
            ),
          },
          {
            path: "plant-code",
            element: <ProtectedRoute
              element={<PlantCode />}
              requiredPermission={{ resource: "plant-code-master", action: "read" }}
            />
          },
          {
            path: "plant-code-import",
            element: (
              <CustomImportComp
                text={"Plant Code"}
                navigate={true}
                backText="/general-masters/plant-code"
                downloadUrl="plant-code-master/import-excel-format"
                uploadUrl="plant-code-master/import-excel"
              />
            ),
          },
        ],
      },

      {
        path: "/begin",
        element: (
          <>
            <Begin />,
          </>
        ),
      },
      {
        path: "/opportunity-management",
        element: <ProtectedRoute
          element={<Opportunities />}
          requiredPermission={{ resource: "opportunity", action: "read" }}
        />
      },
      {
        path: "/create-opportunity",
        element: <CreateOpportunity />,
      },
      {
        path: "/update-opportunity",
        element: <CreateOpportunity />,
      },
      {
        path: "/business-partners",
        element: <ProtectedRoute
          element={<BusinessPartners />}
          requiredPermission={{ resource: "business-partner", action: "read" }}
        />,
      },
      {
        path: "/business-partners-import",
        element: (
          <CustomImportComp
            text={"Business-Partner"}
            navigate={true}
            backText="/business-partners"
            downloadUrl="business-partner/import-excel-format"
            uploadUrl="business-partner/import"
          />
        ),
      },
      {
        path: "/create-business-partner",
        element: <CreateBusinessPartner  />,
      },
      {
        path: "/update-business-partner",
        element: <CreateBusinessPartner />,
      },
      {
        path: "/view-business-partner",
        element: <ViewBusinessPartner />,
      },
      {
        path: "/contacts",
        element: <ProtectedRoute
          element={<Contacts />}
          requiredPermission={{ resource: "contact", action: "read" }}
        />
      },
      {
        path: "/create-contact",
        element: <CreateContact />,
      },
      {
        path: "/update-contact",
        element: <CreateContact />,
      },
      {
        path: "/request-for-quotation",
        element: <ProtectedRoute
          element={<RequstForQuotation />}
          requiredPermission={{ resource: "request-for-quotation", action: "read" }}
        />
      },
      {
        path: "/create-request-for-quotation",
        element: <CreateRequstForQuotation />,
      },
      {
        path: "/update-request-for-quotation",
        element: <CreateRequstForQuotation />,
      },
      {
        path: "/change-password",
        element: <ChangePassword />,
      },
      {
        path: "/custom-fields",
        element: <CustomFields />,
      },
      {
        path: "/feedback-mechanism",
        element: <FeedBackMechanism />,
      },
      {
        path: "/create-feedback-mechanism",
        element: <CreateFeedbcakMechanism />,
      },
      {
        path: "/view-feedback-mechanism",
        element: <ViewFeedBackMechanism />,
      },
      {
        path: "/update-feedback-mechanism",
        element: <CreateFeedbcakMechanism />,
      },
    ],
  },

  {
    path: "/error-page",

    element: (
      <>
        <ErrorPage />,
      </>
    ),
  },

  // {
  //   path: "/feedback-mechanism",
  //   element: (
  //     <>
  //       <FeedBackMechanism/>,
  //     </>
  //   ),
  // },

  // {
  //   path: "/feedback-mechanism/",
  //   element: <FeedBackMechanism />,
  // },

  {
    path: "/change-mobile",
    element: <ChangeMobile />,
  },
  {
    path: "/privacy-policy",
    element: (
      <div className="text-center py-5">
        <h1 className="font-popinsSemiBold">Privacy Policy</h1>
      </div>
    ),
  },
  {
    path: "/terms-and-condition",
    element: (
      <div className="text-center py-5">
        <h1 className="font-popinsSemiBold">Terms and Condition</h1>
      </div>
    ),
  },
  {
    path: "/business-hours",
    element: <BusinessHoursConfig />,
  },
  {
    path: "/accept-invitation",
    element: <AcceptInvitation />,
  },

  {
    path: "/accept-invitation-create-account",
    element: <AcceptInvitationCreateAccount />,
  },

  {
    element: <PublicRoute />,
    children: [
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/sign-otp",
        element: <LoginOTP />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
      {
        path: "/login",
        element: <Login />,
      },

      {
        path: "/otp",
        element: <OtpVerfication />,
      },
      {
        path: "/create-account",
        element: <CreateAccount />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);
