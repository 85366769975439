import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../common/PageTitle";
import * as XLSX from "xlsx";
import invoiceIcon from "../../assets/img/invoiceIcon.svg";
import CustomInputWithButtons from "../common/CustomInputWithButtons ";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Dropdown, Image, Pagination, Table } from "antd";

import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import SortIcon from "../common/SortIcon";
import dayjs from "dayjs";
import {
  actionClearAllSalesInvoice,
  actionGetAllSalesInvoices,
  actionGetSalesInvoices,
} from "../../store/services/salesService";
import { useNavigate } from "react-router-dom";

const SalesInvoices = () => {
  const navigate = useNavigate();
  const {
    getSalesInvoicesLoader,
    getAllSalesInvoicesLoader,
    salesInvoicesData,
    allSalesInvoicesCount,
    allSalesInvoices,
    salesInvoiceDetails: {},
  } = useSelector((state) => state.sales);
  const dispatch = useDispatch();
  const offsetRef = useRef(0);

  const [search, setSearch] = useState("");
  const [exportAll, setExportAll] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState(null);

  const getApiCall = (
    offset = 0,
    search = "",
    date = "",
    orderByColumn = "",
    order = ""
  ) => {
    dispatch(
      actionGetSalesInvoices({
        offset,
        orderByColumn,
        order,
        search,
        date,
      })
    );
  };

  useEffect(() => {
    getApiCall(offsetRef.current, search);
  }, [dispatch]);

  const handleRefresh = () => {
    setSearch(null);
    setInvoiceDate(null);
    offsetRef.current = 0;
    getApiCall(offsetRef.current);
  };

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      getApiCall(offsetRef.current, e.target.value, invoiceDate);
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      getApiCall(offsetRef.current, e.target.value, invoiceDate);
    }
  };

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllSalesInvoices());
    setExportAll(true);
  };

  const exportAllToExcel = async () => {
    const data =
      (await allSalesInvoices) &&
      allSalesInvoices.length > 0 &&
      allSalesInvoices.map((data) => ({
        "Invoice Number": data.invoice_number ? data.invoice_number : "-",
        "IRN Number": data.irn_number ? data.irn_number : "-",
        "Invoice Date": data.invoice_date
          ? dayjs(data.invoice_date).format("DD/MM/YYYY")
          : "-",
        "Eway Bill Number": data.eway_bill_number ? data.eway_bill_number : "-",
        Customer: data.customer ? data.customer.business_partner_name : "-",
        "Invoice File Name": data.invoice_file_name
          ? data.invoice_file_name.originalname
          : "-",
        "Ship to Party": data.ship_to_party
          ? data.ship_to_party.business_partner_name
          : "-",
        Note: data.note ? data.note : "-",
        "Total Base Value": data.total_base_value ? data.total_base_value : "-",
        "Total Discount": data.total_discount ? data.total_discount : "-",
        "Total Other Charges": data.total_other_charges
          ? data.total_other_charges
          : "-",
        "Total CGST": data.total_cgst ? data.total_cgst : "-",
        "Total SGST": data.total_sgst ? data.total_sgst : "-",
        "Total UTGST": data.total_utgst ? data.total_utgst : "-",
        "Total Net Value": data.total_net_value ? data.total_net_value : "-",
        "Total Value": data.total_value ? data.total_value : "-",
        "TCS Value": data.tcs_value ? data.tcs_value : "-",
        "Round Off": data.round_off ? data.round_off : "-",
      }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sales Invoices");
    XLSX.writeFile(workbook, "Sales Invoices.xlsx");

    dispatch(actionClearAllSalesInvoice({ setExportAll }));
  };

  useEffect(() => {
    if (exportAll && allSalesInvoices && allSalesInvoices.length > 0) {
      exportAllToExcel();
    }
  }, [allSalesInvoices]);

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.columnKey,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    getApiCall(
      offsetRef.current,
      search,
      invoiceDate,
      sorterParams.field,
      sorterParams.order
    );
  };

  const columns = [
    {
      title: "Invoice Number",
      dataIndex: "invoice_number",
      key: "invoice_number",
      width: 200,
      fixed: "left",

      render: (text, data) => (
        <span
          onClick={() => {
            navigate("/view-sales-invoice", { state: { id: data.invoice_id } });
          }}
          className="text-brand cursor-pointer"
        >
          {text ? text : "-"}
        </span>
      ),
    },
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      key: "invoice_date",
      width: 150,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (text ? dayjs(text).format("DD-MM-YYYY") : "-"),
    },
    {
      title: "Customer",
      dataIndex: ["customer", "business_partner_name"],
      key: "customer",
      ellipsis: true,
      width: 250,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Ship To Party",
      dataIndex: ["ship_to_party", "business_partner_name"],
      key: "ship_to_party",
      ellipsis: true,
      width: 250,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      ellipsis: true,
      width: 250,

      render: (text) => (text ? text : "-"),
    },
    {
      title: "IRN Number",
      dataIndex: "irn_number",
      key: "irn_number",
      ellipsis: true,
      width: 200,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (text ? text : "-"),
    },

    {
      title: "Eway Bill Number",
      dataIndex: "eway_bill_number",
      key: "eway_bill_number",
      ellipsis: true,
      width: 250,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Invoice File Name",
      dataIndex: "invoice_file_name",
      key: "invoice_file_name",
      ellipsis: true,
      width: 250,
      render: (file) => {
        if (!file || !file.url) return "-";

        const isImage = file.mimetype.startsWith("image");
        const isDocument = [
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ].includes(file.mimetype);

        if (isImage) {
          return (
            <Image
              preview={{ mask: <EyeOutlined /> }}
              className="cursor-pointer"
              src={file.url}
              alt={file.originalname}
              style={{ width: 50, height: 50, objectFit: "contain" }}
            />
          );
        } else if (isDocument) {
          return (
            <a
              href={file.url}
              className="text-blue-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              {file.originalname}
            </a>
          );
        } else {
          return file.originalname;
        }
      },
    },
    {
      title: "Total Base Value",
      dataIndex: "total_base_value",
      key: "total_base_value",
      width: 200,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Total Discount",
      dataIndex: "total_discount",
      key: "total_discount",
      width: 150,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Total Other Charges",
      dataIndex: "total_other_charges",
      key: "total_other_charges",
      width: 200,

      render: (text) => (text ? text : "-"),
    },
    {
      title: "Total CGST",
      dataIndex: "total_cgst",
      key: "total_cgst",
      width: 200,

      render: (text) => (text ? text : "-"),
    },
    {
      title: "Total SGST",
      dataIndex: "total_sgst",
      key: "total_sgst",
      width: 200,

      render: (text) => (text ? text : "-"),
    },
    {
      title: "Total UTGST",
      dataIndex: "total_utgst",
      key: "total_utgst",
      width: 200,

      render: (text) => (text ? text : "-"),
    },
    {
      title: "Total Net Value",
      dataIndex: "total_net_value",
      key: "total_net_value",
      width: 200,

      render: (text) => (text ? text : "-"),
    },
    {
      title: "Total Value",
      dataIndex: "total_value",
      key: "total_value",
      width: 200,

      render: (text) => (text ? text : "-"),
    },
    {
      title: "TCS Value",
      dataIndex: "tcs_value",
      key: "tcs_value",
      width: 150,

      render: (text) => (text ? text : "-"),
    },
    {
      title: "Round Off",
      dataIndex: "round_off",
      key: "round_off",
      width: 150,

      render: (text) => (text ? text : "-"),
    },
  ];

  return (
    <section className="main-wrapper">
      <PageTitle image={invoiceIcon} title="Invoices" rightIcon={false} />
      <div className="flex items-center gap-x-3 w-full">
        <div className="w-[100%]">
          <CustomInputWithButtons
            handleRefresh={handleRefresh}
            handleSearch={handleSearch}
            handleBlurAndPressEnter={handleBlurAndPressEnter}
            searchValue={search}
            onClickDownloadMenu={onClickDownloadMenu}
          />
        </div>
      </div>
      <Table
        sticky
        rowKey="invoice_id"
        scrollToFirstRowOnChange={true}
        sortDirections={["ascend", "descend"]}
        onChange={handleSortChange}
        bordered
        scroll={{
          x: "max-content",
          y: window.innerWidth < 1500 ? "49vh" : "61vh",
        }}
        pagination={false}
        columns={columns}
        dataSource={salesInvoicesData}
        loading={getAllSalesInvoicesLoader || getSalesInvoicesLoader}
      />
      <div className="flex justify-end mt-3">
        <Pagination
          hideOnSinglePage
          responsive={true}
          current={offsetRef.current + 1}
          className="flex items-center"
          showSizeChanger={false}
          total={allSalesInvoicesCount}
          onChange={(e) => {
            offsetRef.current = e - 1;
            getApiCall(offsetRef.current, search, invoiceDate);
          }}
        />
      </div>
    </section>
  );
};

export default SalesInvoices;
