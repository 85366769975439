import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Grid,
  Input,
  Radio,
  Select,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { HiOutlineLockClosed } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { actionAddCustomField } from "../../store/services/customFieldService";
import PhoneInputComponent from "../common/PhoneInputComponent";
// import { actionChangePassword } from "../../store/services/authService";

const { useBreakpoint } = Grid;

const fieldTypeOptions = [
  "Text",
  "TextArea",
  "Phone",
  "Number",
  "Date",
  "Checkbox",
  "URL",
  "Email",
  "Dropdown",
  "MultiSelect",
];

const renderFieldInput = (type, options = []) => {
  if (typeof options === "string") {
    options = options.split(",");
  } else if (Array.isArray(options)) {
    options = options.join(",");
  } else if (typeof options === "number") {
    options = options.toString();
  } else {
    options = "";
  }

  switch (type) {
    case "Text":
      return (
        <Input
          readOnly
          placeholder="Enter text"
          value={""}
          className="rounded-md"
        />
      );
    case "Phone":
      return (
        <PhoneInputComponent
          value={""}
          onChange={() => {}}
          setIsValid={() => {}}
          isValid={true}
          setValue={() => {}}
        />
      );
    case "URL":
      return (
        <Input
          value={""}
          readOnly
          placeholder="Enter URL"
          className="rounded-md"
        />
      );
    case "Email":
      return (
        <Input
          value={""}
          readOnly
          placeholder="Enter email"
          className="rounded-md"
        />
      );
    case "Number":
      return (
        <Input
          value={0}
          readOnly
          type="number"
          placeholder="Enter number"
          className="rounded-md"
        />
      );
    case "TextArea":
      return (
        <Input.TextArea
          value={""}
          readOnly
          rows={4}
          placeholder="Enter detailed text"
          className="rounded-md"
        />
      );
    case "Date":
      return (
        <DatePicker
          value={""}
          readOnly
          placeholder="Select date"
          className="w-full rounded-md"
        />
      );
    case "Checkbox":
      return <Checkbox>Checkbox</Checkbox>;
    case "Dropdown":
      return (
        <Select allowClear className="w-full" placeholder="Select an option">
          {options && options.length > 0
            ? options.map((option, index) => (
                <Select.Option key={index} value={option}>
                  {option}
                </Select.Option>
              ))
            : "No Options Found"}
        </Select>
      );
    case "MultiSelect":
      return (
        <Select
          allowClear
          className="w-full"
          mode="multiple"
          placeholder="Select multiple options"
        >
          {options &&
            options.length > 0 &&
            options.map((option, index) => (
              <Select.Option key={index} value={option}>
                {option}
              </Select.Option>
            ))}
        </Select>
      );
    default:
      return null;
  }
};

function CustomFields() {
  const { lg } = useBreakpoint();

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fieldType, setFieldType] = useState(null);

  const labelField = Form.useWatch(
    (values) => `${values.field_name || "Field Name"}`,
    form
  );

  const optionsField = Form.useWatch(
    (values) => `${values.field_options || []}`,
    form
  );

  const onFinish = (values) => {
    if (
      values.field_type === "Dropdown" &&
      (!values.field_options ||
        values.field_options.length === 0 ||
        values.field_options.some((option) => !option))
    ) {
      toast.error("Please input at least one option!");
      return false;
    }

    dispatch(actionAddCustomField({ values, form }));
  };

  useEffect(() => {
    if (fieldType === "Dropdown" || fieldType === "MultiSelect") {
      const currentOptions = form.getFieldValue("field_options") || [];
      if (currentOptions.length === 0) {
        form.setFieldsValue({ field_options: [""] });
      }
    } else {
      form.setFieldsValue({ field_options: [] });
    }
  }, [fieldType]);

  return (
    <div className="custom-fields-container 2xl:max-w-2xl mt-16 max-w-[35rem] mx-auto border border-neutralGray bg-white rounded-md p-3 w-full">
      <div className="title mb-4 pt-4 flex flex-col justify-center items-center gap-y-2.5">
        <p className="xl:text-[25px] text-[20px]  text-zinc-700 font-popinsSemiBold">
          New Custom Field
        </p>
      </div>
      <Form
        labelCol={{
          span: 7,
        }}
        labelAlign="left"
        form={form}
        layout={lg ? "horizontal" : "vertical"}
        onFinish={onFinish}
        className="space-y-6 px-4 pt-5"
      >
        <Form.Item
          label="Module Name"
          name="module_name"
          rules={[{ required: true, message: "Please input the module name!" }]}
        >
          <Select
            placeholder="Select Module"
            options={[
              {
                label: "Material",
                value: "Material",
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Field Name"
          name="field_name"
          rules={[{ required: true, message: "Please input the field name!" }]}
        >
          <Input placeholder="Enter Field Name" />
        </Form.Item>

        <Form.Item
          label="Field Type"
          name="field_type"
          rules={[{ required: true, message: "Please select the field type!" }]}
        >
          <Select
            placeholder="Select Field Type"
            onChange={(value) => setFieldType(value)}
          >
            {fieldTypeOptions.map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {(fieldType === "Dropdown" || fieldType === "MultiSelect") && (
          <Form.Item
            rules={[
              {
                required: true,
                validator: async (_, options) => {
                  const nonEmptyOptions = options.filter(
                    (option) => option && option.trim()
                  );

                  if (nonEmptyOptions.length === 0) {
                    return Promise.reject(
                      new Error("Please input at least one option.")
                    );
                  }
                },
              },
            ]}
            label="Options"
            className="ml-2 remove-scrollbar custom-options-label"
          >
            <Form.List name="field_options">
              {(fields, { add, remove }) => (
                <>
                  <Card
                    title={`Options Count :  ${fields?.length}`}
                    className=" remove-scrollbar options-card border border-neutralGray overflow-auto rounded-md"
                  >
                    {fields.map((field, index) => (
                      <Space
                        key={field.key}
                        align="baseline"
                        className={`${
                          index === fields.length - 1 ? "mb-0" : "mb-3"
                        } flex items-center `}
                      >
                        <Form.Item
                          className="!mb-0"
                          {...field}
                          validateTrigger={["onChange", "onBlur"]}
                          noStyle
                        >
                          <Input
                            placeholder="Enter option "
                            style={{ width: "300px", minHeight: 35 }}
                          />
                        </Form.Item>
                        {fields.length > 1 && (
                          <MinusCircleOutlined
                            className="text-red-500 cursor-pointer"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        )}
                      </Space>
                    ))}
                  </Card>
                  <Button
                    className="mt-5 !rounded-sm"
                    type="dashed"
                    htmlType="button"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add option
                  </Button>
                </>
              )}
            </Form.List>
          </Form.Item>
        )}

        <Form.Item
          tooltip="Enter some text to help users understand the purpose of this custom field.
"
          label="Help Text"
          name="help_text"
        >
          <Input placeholder="Enter Help Text" />
        </Form.Item>

        <Form.Item label="Is Mandatory" name="is_required" initialValue={true}>
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        {fieldType && (
          <>
            <Divider className="font-popinsSemiBold">Field Preview</Divider>

            <div className="border border-neutralGray p-4 flex items-center  rounded-md bg-gray-50">
              <Form.Item
                className="!mb-0 w-full"
                label={labelField}
                labelCol={{
                  span: 7,
                }}
                name="preview_field"
                tooltip={form.getFieldValue("help_text")}
              >
                {renderFieldInput(fieldType, optionsField)}
              </Form.Item>
            </div>
          </>
        )}
      </Form>

      <div className="flex justify-start space-x-4 pl-4 pt-4">
        <Form.Item>
          <Button
            onClick={() => form.submit()}
            loading={false}
            type="primary"
            className="root-btn min-h-[45px] min-w-[120px]"
            htmlType="button"
          >
            Save
          </Button>
        </Form.Item>
      </div>
    </div>
  );
}

export default CustomFields;
