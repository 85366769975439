import {
  Button,
  Checkbox,
  Col,
  Image,
  Radio,
  Row,
  Spin,
  TimePicker,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import BusinessHoursConfig from "../../business/BusinessHoursConfig";
import {
  actionGetMyCompanyHours,
  actionUpdateBusinessHours,
} from "../../../store/services/settingService";

const UpdateBusinessHours = () => {
  const [selectedTimeOption, setSelectedTimeOption] = useState("24/7");
  const [startDay, setStartDay] = useState("MONDAY");
  const dispatch = useDispatch();
  const { addCompanyLoader } = useSelector((state) => state.auth);
  const {
    myCompanyHoursDetail,
    updateBusinessHoursLoader,
    getCompanyHoursLoader,
  } = useSelector((state) => state.setting);
  const [businessTime, setBusinessTime] = useState("same-day");
  const [checkedList, setCheckedList] = useState(["MONDAY"]);
  const [timeRange, setTimeRange] = useState([
    dayjs("00:00", "HH:mm"),
    dayjs("23:59", "HH:mm"),
  ]);
  const b = {
    Monday: [null, null],
    Tuesday: [null, null],
    Wednesday: [null, null],
    Thursday: [null, null],
    Friday: [null, null],
    Saturday: [null, null],
    Sunday: [null, null],
  };
  const [dailyTimes, setDailyTimes] = useState(b);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(actionGetMyCompanyHours());
  }, [dispatch]);

  const handleGetCompanyDetails = () => {
    if (myCompanyHoursDetail) {
      setSelectedTimeOption(myCompanyHoursDetail.company_hour_type);
      setStartDay(myCompanyHoursDetail.company_start_day_of_week);
      const { start_time, end_time } =
        myCompanyHoursDetail.company_hours_mapping[0];

      //business timing set
      const isSame =
        myCompanyHoursDetail.company_hours_mapping &&
        myCompanyHoursDetail.company_hours_mapping.length > 0 &&
        myCompanyHoursDetail.company_hours_mapping.every(
          (hour) => hour.start_time === start_time && hour.end_time === end_time
        );
      setBusinessTime(isSame ? "same-day" : "different-hour");

      // Business Days only week select
      const checkedDays = myCompanyHoursDetail.company_hours_mapping.map(
        (item) => {
          return (
            item.day_of_week.charAt(0).toUpperCase() +
            item.day_of_week.slice(1).toLowerCase()
          );
        }
      );

      setCheckedList(checkedDays);

      // same-hour day (time select)
      if (myCompanyHoursDetail.company_hours_mapping.length > 0) {
        const firstDay = myCompanyHoursDetail.company_hours_mapping[0];
        const defaultStartTime = dayjs(firstDay.start_time, "HH:mm");
        const defaultEndTime = dayjs(firstDay.end_time, "HH:mm");
        isSame && setTimeRange([defaultStartTime, defaultEndTime]);
      }

      //diffrent hours evenry day time select
      const updatedDailyTimes = { ...b };
      myCompanyHoursDetail.company_hours_mapping.forEach((item) => {
        const day =
          item.day_of_week.charAt(0).toUpperCase() +
          item.day_of_week.slice(1).toLowerCase();
        updatedDailyTimes[day] = [
          dayjs(item.start_time, "HH:mm"),
          dayjs(item.end_time, "HH:mm"),
        ];
      });

      setDailyTimes(updatedDailyTimes);
    }
  };

  useEffect(() => {
    if (myCompanyHoursDetail) {
      handleGetCompanyDetails();
    }
  }, [myCompanyHoursDetail]);

  const countNonNullTimes = () => {
    let count = 0;
    Object.values(dailyTimes).forEach((times) => {
      times &&
        times.length > 0 &&
        times.forEach((time) => {
          if (time !== null) {
            count++;
          }
        });
    });
    return count;
  };

  const onChange = (newCheckedList) => {
    if (selectedTimeOption === "24/5") {
      if (newCheckedList.length > 5) {
        toast.error("Maximum of 5 Days can be selected", 5);
        return;
      }
    }

    // Remove the time data for deselected days
    const newDailyTimes = { ...dailyTimes };
    weeks.forEach((day) => {
      if (!newCheckedList.includes(day)) {
        delete newDailyTimes[day];
      }
    });

    setCheckedList(newCheckedList);
    setDailyTimes(newDailyTimes);
  };

  const handleDailyTimeChange = (day, time) => {
    setDailyTimes((prev) => ({
      ...prev,
      [day]: time,
    }));
  };

  // Change Tab Function
  const handleItemClick = (option) => {
    setSelectedTimeOption(option);
    setStartDay("MONDAY");
    setCheckedList(["Monday"]);
    setTimeRange([null, null]);
    setDailyTimes(b);
    setBusinessTime("same-day");
    if (
      myCompanyHoursDetail &&
      myCompanyHoursDetail.company_hour_type === option
    ) {
      handleGetCompanyDetails();
    }
  };

  // Handler for Radio component
  const onRadioChange = (e) => {
    setStartDay(e.target.value);
  };

  const weeks = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const daysOfWeek = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];

  const hours_24x7 = daysOfWeek.map((day) => ({
    day_of_week: day,
    start_time: "00:00",
    end_time: "23:59",
  }));

  // Function to map selected days to the required format
  const getSelectedDaysMapping = (
    selectedDays,
    sTime = "00:00",
    eTime = "23:59"
  ) => {
    return selectedDays.map((day) => ({
      day_of_week: day.toUpperCase(),
      start_time: sTime,
      end_time: eTime,
    }));
  };

 /*  const handleSubmit = () => {
    const req = {
      // company_id: 1,
      company_hour_type: selectedTimeOption,
      company_start_day_of_week: startDay.toUpperCase(),
      company_hours_mapping:
        selectedTimeOption === "24/7"
          ? hours_24x7
          : selectedTimeOption === "24/5"
          ? getSelectedDaysMapping(checkedList)
          : businessTime === "same-day"
          ? getSelectedDaysMapping(
              checkedList,
              dayjs(timeRange[0]).format("HH:mm"),
              dayjs(timeRange[1]).format("HH:mm")
            )
          : Object.entries(dailyTimes)
              .filter(
                ([day, times]) =>
                  checkedList.includes(day) &&
                  times &&
                  times.length > 0 &&
                  times.some((time) => time !== null)
              )
              .map(([day, times]) => ({
                day_of_week: day.toUpperCase(),
                start_time: dayjs(times[0]).format("HH:mm"),
                end_time: dayjs(times[1]).format("HH:mm"),
              })),
    };

    if (selectedTimeOption === "24/5") {
      if (checkedList && checkedList.length < 5) {
        toast.error("Please Select at least 5 business days!");
        return false;
      } else {
        dispatch(
          actionUpdateBusinessHours({
            req,
            navigate,
            from: "businessHours",
          })
        );
      }
    } else if (selectedTimeOption === "CUSTOM") {
      if (checkedList && checkedList.length <= 0) {
        toast.error("Please Select at least 1 business days!");
        return false;
      } else {
        const nonNullCount = countNonNullTimes();
        if (
          selectedTimeOption === "CUSTOM" &&
          businessTime === "different-hour" &&
          nonNullCount < 2
        ) {
          toast.error(`You must add at least 1 daily times.`);
          return;
        } else if (
          businessTime === "same-day" &&
          !timeRange[0] &&
          !timeRange[1]
        ) {
          toast.error(`You must add at least 1 daily times.`);
        } else {
          dispatch(
            actionUpdateBusinessHours({ req, navigate, from: "businessHours" })
          );
        }
      }
    } else {
      dispatch(
        actionUpdateBusinessHours({ req, navigate, from: "businessHours" })
      );
    }
  }; */


  const handleSubmit = () => {
    const req = {
      company_hour_type: selectedTimeOption,
      company_start_day_of_week: startDay.toUpperCase(),
      company_hours_mapping:
        selectedTimeOption === "24/7"
          ? hours_24x7
          : selectedTimeOption === "24/5"
          ? getSelectedDaysMapping(checkedList)
          : businessTime === "same-day"
          ? getSelectedDaysMapping(
              checkedList,
              dayjs(timeRange[0]).format("HH:mm"),
              dayjs(timeRange[1]).format("HH:mm")
            )
          : Object.entries(dailyTimes)
              .filter(
                ([day, times]) =>
                  checkedList.includes(day) &&
                  times &&
                  times.length > 0 &&
                  times.some((time) => time !== null)
              )
              .map(([day, times]) => ({
                day_of_week: day.toUpperCase(),
                start_time: dayjs(times[0]).format("HH:mm"),
                end_time: dayjs(times[1]).format("HH:mm"),
              })),
    };
  
    if (selectedTimeOption === "24/5") {
      if (checkedList && checkedList.length < 5) {
        toast.error("Please Select at least 5 business days!");
        return false;
      }
    } else if (selectedTimeOption === "CUSTOM") {
      if (checkedList && checkedList.length <= 0) {
        toast.error("Please Select at least 1 business day!");
        return false;
      }
      
      if (businessTime === "different-hour") {
        const selectedDaysWithoutTime = checkedList.filter(day => 
          !dailyTimes[day] || !dailyTimes[day][0] || !dailyTimes[day][1]
        );
  
        if (selectedDaysWithoutTime.length > 0) {
          toast.error(`Please select time for: ${selectedDaysWithoutTime.join(', ')}`);
          return false;
        }
      } else if (businessTime === "same-day" && (!timeRange[0] || !timeRange[1])) {
        toast.error(`You must add business hours for all selected days.`);
        return false;
      }
    }
  
    dispatch(
      actionUpdateBusinessHours({ req, navigate, from: "businessHours" })
    );
  };

  const leng = countNonNullTimes();

  return (
    <Spin spinning={getCompanyHoursLoader}>
      <div className="company-business-hours  min-h-screen">
        <div className="flex  pt-5  flex-col items-center justify-center">
      
        </div>
        <div className="lg:w-[100%] w-11/12 py-8  mx-auto">
          <Row justify={"center"}>
            {/* Business Hours */}

            <Col span={24} md={24} lg={22} xl={14} className="py-4">
              <h1 className="text-2xl font-popinsRegular text-black text-center">
                Business Hours
              </h1>
              <div className="border  bg-white  border-bColor  radius my-3 flex-center  px-3">
                <div
                  className={`flex-1 text-center  py-4   mx-auto ${
                    selectedTimeOption === "24/7" ? "active-border" : ""
                  }`}
                >
                  <p
                    onClick={() => handleItemClick("24/7")}
                    className={`md:text-[15px] text-xs border-r-[0.9px] border-bColor cursor-pointer   font-popinsRegular `}
                  >
                    24 Hours X 7 Days
                  </p>
                </div>
                <div
                  className={`flex-1 text-center py-4 mx-auto ${
                    selectedTimeOption === "24/5" ? "active-border" : ""
                  }`}
                >
                  <p
                    onClick={() => handleItemClick("24/5")}
                    className={`md:text-[15px] text-xs   border-r-[0.9px] border-bColor  relative cursor-pointer   font-popinsRegular `}
                  >
                    24 Hours X 5 Days
                  </p>
                </div>
                <div
                  className={`flex-1 text-center py-4 mx-auto  ${
                    selectedTimeOption === "CUSTOM" ? "active-border" : ""
                  }`}
                >
                  <p
                    onClick={() => handleItemClick("CUSTOM")}
                    className={`md:text-[15px] text-xs   cursor-pointer   font-popinsRegular`}
                  >
                    Custom Hours
                  </p>
                </div>
              </div>
            </Col>
            {/* Business Hours */}

            {/*  Week Start  */}

            <Col span={24} md={24} lg={24} xl={22} className="py-4">
              <h1 className="text-2xl font-popinsRegular text-black text-center">
                Week starts on
              </h1>
              <div className="border week-start-container  bg-white  border-bColor  radius my-3 flex-center px-6">
                <Radio.Group
                  defaultValue={"MONDAY"}
                  onChange={onRadioChange}
                  value={startDay}
                  className="px-0 mx-auto flex-nowrap  py-3 rounded-[10px]  "
                >
                  <Radio
                    value={"MONDAY"}
                    className="border-r border-bColor md:text-[15px] text-xs font-popinsRegular"
                  >
                    Monday
                  </Radio>
                  <Radio
                    value={"TUESDAY"}
                    className="border-r border-bColor md:text-[15px] text-xs font-popinsRegular"
                  >
                    Tuesday
                  </Radio>
                  <Radio
                    value={"WEDNESDAY"}
                    className="border-r border-bColor md:text-[15px] text-xs font-popinsRegular"
                  >
                    Wednesday
                  </Radio>
                  <Radio
                    value={"THURSDAY"}
                    className="border-r border-bColor md:text-[15px] text-xs font-popinsRegular"
                  >
                    Thursday
                  </Radio>
                  <Radio
                    value={"FRIDAY"}
                    className="border-r border-bColor md:text-[15px] text-xs font-popinsRegular"
                  >
                    Friday
                  </Radio>
                  <Radio
                    value={"SATURDAY"}
                    className="border-r border-bColor md:text-[15px] text-xs font-popinsRegular"
                  >
                    Saturday
                  </Radio>
                  <Radio
                    value={"SUNDAY"}
                    className="md:text-[15px] text-xs font-popinsRegular"
                  >
                    Sunday
                  </Radio>
                </Radio.Group>
              </div>
            </Col>

            {/*  Business timing  */}
            {selectedTimeOption === "CUSTOM" && (
              <Col span={24} md={24} lg={24} xl={22} className="py-4">
                <h1 className="text-2xl font-popinsRegular text-black text-center">
                  Business timing
                </h1>

                <Radio.Group
                  onChange={(e) => {
                    setBusinessTime(e.target.value);
                  }}
                  value={businessTime}
                  className=" w-full  py-3 rounded-[10px]  "
                >
                  <div className="grid lg:grid-cols-2 gap-x-4 grid-cols-1">
                    <div className="border week-start-container bg-white border-bColor  radius my-3 ">
                      <Row
                        align={"middle"}
                        justify={"space-between"}
                        className="px-5"
                      >
                        <Col span={12} className=" py-2">
                          <Radio
                            value={"same-day"}
                            className="md:text-[15px] text-xs font-popinsRegular flex items-center"
                          >
                            Same hours every day
                          </Radio>
                        </Col>
                        <Col
                          span={12}
                          className="ml-auto py-2 timepicker-parent"
                        >
                          <TimePicker.RangePicker needConfirm={false}
                            format={"HH:mm"}
                            onChange={(time, n) => {
                              setTimeRange(time);
                            }} 
                            value={timeRange}
                            className="w-full"
                            variant="borderless"
                            placeholder={["Start Time", "End Time"]}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className="border week-start-container  flex-center bg-white  border-bColor  radius my-3  px-6">
                      <div className="py-3 text-center">
                        <Radio
                          value={"different-hour"}
                          className="md:text-[15px] text-xs font-popinsRegular flex items-center"
                        >
                          Different hour every day
                        </Radio>
                      </div>
                    </div>
                  </div>
                </Radio.Group>
              </Col>
            )}

            {/*  Business timing */}

            {/*  Business Days */}
            {(selectedTimeOption === "24/5" ||
              selectedTimeOption === "CUSTOM") && (
              <Col span={24} md={24} lg={24} xl={22} className="py-4">
                <h1 className="text-2xl font-popinsRegular text-black text-center">
                  Business Days
                </h1>

                {businessTime === "same-day" ? (
                  <div className="border week-start-container  bg-white  border-bColor  radius my-3 flex-center px-6">
                    <Checkbox.Group
                      style={{
                        width: "100%",
                      }}
                      options={weeks}
                      value={checkedList}
                      onChange={onChange}
                      className="px-0 mx-auto  py-3 rounded-[10px]"
                    />
                  </div>
                ) : (
                  <div className="border week-start-container  bg-white  border-bColor  radius my-3 flex-center px-6">
                    <Checkbox.Group
                      style={{ width: "100%" }}
                      value={checkedList}
                      onChange={onChange}
                      className="flex items-center   flex-nowrap px-0 mx-auto py-3 rounded-[10px]"
                    >
                      {weeks.map((day, index) => (
                        <div
                          key={index}
                          className="flex-1 gap-y-3 flex-center flex-col"
                        >
                          <Checkbox value={day}>{day}</Checkbox>
                          <TimePicker.RangePicker
                            disabled={leng === 14 || !checkedList.includes(day)}
                            variant="borderless"
                            placeholder={["Start Time", "End Time"]}
                            format={"HH:mm"}
                            onChange={(time) => handleDailyTimeChange(day, time)}
                            value={dailyTimes[day]}
                            allowEmpty={[true, true]}
                            className="ml-4 p-0"
                          />
                        </div>
                      ))}
                    </Checkbox.Group>
                  </div>
                )}
              </Col>
            )}

            {/*  Business Days */}
          </Row>
          <div className="flex items-center justify-center gap-x-3 my-4">
            {/* <Button
            type="primary"
            onClick={handleSkip}
            className="gray-button min-w-[150px] md:text-base text-sm  text-black hover:text-black border-none radius font-popinsMedium tracking-wider min-h-[45px] h-full flex-center"
          >
            Skip
          </Button> */}
            <Button
              loading={updateBusinessHoursLoader}
              onClick={handleSubmit}
              type="primary"
              className="filled-button min-w-[150px] md:text-base text-sm  border-none radius font-popinsMedium tracking-wider min-h-[45px] h-full flex-center"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default UpdateBusinessHours;
