import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Spin,
  Tree,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import add from "../../../assets/img/add-role.svg";
import parent from "../../../assets/img/parent.svg";
import CustomIconText from "../../common/CustomIconText";
import {
  actionAddRole,
  actionGetSingleRole,
  actionUpdateRole,
} from "../../../store/services/settingService";
import { getAllKeys } from "../../helper/common-function";

const AddRole = ({
  hierarchyForm,
  handleCancel,
  editId,
  firstChild,
  selectesParentRole,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    updateRoleLoader,
    addRoleLoader,
    roleDetail,
    getSingleRoleLoader,
    roleHierachy,
  } = useSelector((state) => state.setting);

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [modalParent, setModalParent] = useState(false);
  const [parentRole, setParentRole] = useState("");
  const [treeData, setTreeData] = useState([]);

  const handleModalClose = () => {
    setModalParent(false);
    setParentRole("");
    resetTreeData();
  };

  useEffect(() => {
    if (editId) {
      dispatch(actionGetSingleRole(editId));
    }
  }, [editId, dispatch]);

  useEffect(() => {
    if (selectesParentRole) {
      hierarchyForm.setFieldsValue({
        parent_id: selectesParentRole.title,
        title: selectesParentRole.key,
      });
    }
    if (editId) {
      hierarchyForm.setFieldsValue({
        name: roleDetail.name,
        description: roleDetail.description,
        parent_id: roleDetail.parent?.name,
        title: roleDetail.parent?.id,
      });
      setParentRole(roleDetail.parent?.name);
    }
  }, [roleDetail, editId, hierarchyForm, selectesParentRole]);

  useEffect(() => {
    if (roleHierachy && roleHierachy.length > 0) {
      const transformedData = transformData(roleHierachy);
      setTreeData(transformedData);
      setExpandedKeys(getAllKeys(transformedData));
    }
  }, [roleHierachy]);

  const transformData = (data, isRoot = true) => {
    return data.map((item) => ({
      title: item.name,
      key: item.id,
      children: item.children ? transformData(item.children, false) : [],
      disabled: isRoot,
    }));
  };

  const handleDisable = (id, data) => {
    const newData = JSON.parse(JSON.stringify(data)); // Deep copy the data
    newData.forEach((node) => disableNode(node, id));
    setTreeData(newData);
  };

  const disableNode = (node, id) => {
    if (node.key === id) {
      node.disabled = true;
      node.children.forEach((child) => disableAllDescendants(child));
    } else {
      node.children.forEach((child) => disableNode(child, id));
    }
  };

  const disableAllDescendants = (node) => {
    node.disabled = true;
    node.children.forEach((child) => disableAllDescendants(child));
  };

  const resetTreeData = () => {
    if (roleHierachy && roleHierachy.length > 0) {
      const transformedData = transformData(roleHierachy);
      setTreeData(transformedData);
      setExpandedKeys(getAllKeys(transformedData));
    }
  };

  const onFinish = async (values) => {
    const req = {
      name: values.name,
      description: values.description,
      parent_id: hierarchyForm && hierarchyForm.getFieldValue("title"),
    };
    if (editId) {
      dispatch(
        actionUpdateRole({
          editId,
          req,
          handleCancel,
          setParentRole,
        })
      );
    } else
      dispatch(
        actionAddRole({
          req,
          handleCancel,
          setParentRole,
        })
      );
  };

  return (
    <Spin spinning={getSingleRoleLoader}>
      <section className="main-section">
        <div className="mb-4">
          <CustomIconText
            image={add}
            title={editId ? "Update role" : "New role"}
          />

          <p className="text-sm  mt-2 text-[#7c7c7c] max-w-[65%]">
            This page will allow you to create a new role based on your
            Organizational hierarchy. Create a new role and associate it with a
            higher role.
          </p>
        </div>
        <Form
          form={hierarchyForm}
          layout="vertical"
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Role Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Role Name!",
                  },
                ]}
              >
                <Input placeholder="Enter Role Name" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Parent Role"
                name="parent_id"
                rules={[
                  {
                    required: !hierarchyForm.getFieldValue("parent_id")
                      ? true
                      : false,
                    message: "Please Select Parent Role!",
                  },
                ]}
              >
                <Input
                  allowClear
                  disabled={firstChild}
                  value={parentRole}
                  onChange={() => console.log("hello")}
                  style={{ cursor: "pointer" }}
                  className="cursor-pointer"
                  onClick={() => {
                    setModalParent(true);
                    handleDisable(editId, treeData);
                  }}
                  placeholder="Select Parent Role"
                  readOnly
                  suffix={
                    !hierarchyForm.getFieldValue("parent_id") ? (
                      <Image preview={false} src={parent} alt="parent user" />
                    ) : (
                      <CloseOutlined
                        onClick={() => {
                          hierarchyForm.resetFields(["parent_id"]);
                          hierarchyForm.resetFields(["title"]);
                          setParentRole("");
                        }}
                      />
                    )
                  }
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Description" name="description">
                <Input.TextArea placeholder="Enter Description" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                hidden
                label="Role Name"
                name="title"
                rules={[
                  {
                    required: false,
                    message: "Please Enter Role Name!",
                  },
                ]}
              >
                <Input placeholder="Enter Role Name" />
              </Form.Item>
            </Col>

            <Col
              span={24}
              className="flex items-center my-4 justify-center gap-x-6"
            >
              <Form.Item>
                <Button
                  onClick={handleCancel}
                  type="button"
                  className="gray-button text-white hover:text-white  min-h-[45px] min-w-[120px]"
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  loading={addRoleLoader || updateRoleLoader}
                  type="primary"
                  className="root-btn  min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  {editId ? "Update" : "Save"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Modal
          title={
            <p className="font-popinsRegular tracking-wide text-lg">
              Roles List
            </p>
          }
          width={700}
          style={{ top: 20 }}
          centered
          open={modalParent}
          onOk={() => handleModalClose()}
          onCancel={() => handleModalClose()}
          footer={false}
        >
          <Tree
            className="py-4"
            expandedKeys={expandedKeys}
            showLine
            showIcon={false}
            onExpand={(keys) => setExpandedKeys(keys)}
            treeData={treeData}
            titleRender={(nodeData, index) => {
              return (
                <div
                  style={{
                    opacity: !nodeData.isFirstParent ? 1 : 0.5,
                    cursor: !nodeData.isFirstParent ? "pointer" : "auto",
                  }}
                  onClick={() => {
                    if (!nodeData.isFirstParent) {
                      hierarchyForm.setFieldsValue({
                        parent_id: nodeData.title,
                        title: nodeData.key,
                      });
                      setParentRole(nodeData.title);
                      setModalParent(false);
                    }
                  }}
                >
                  {nodeData.title}
                </div>
              );
            }}
          />
        </Modal>
      </section>
    </Spin>
  );
};

export default AddRole;
