import React, { useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import CustomIconText from "../common/CustomIconText";
import settingImg from "../../assets/img/setting.svg";
import { Button, Card, Col, Form, Input, Modal, Row, Tooltip } from "antd";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetCompanyDetail,
  actionUpdateCompanyDetail,
} from "../../store/services/settingService";

const Setting = () => {
  /*  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const { user, currentCompanyUser } = useSelector((state) => state.auth);
  const { getCompanyDetailLoader, companyDetail, updateCompanyDetailLoader } =
    useSelector((state) => state.setting);
  const handleCustomFields = () => {
    toast.info("Hello World!");
  };

  const handleNavigation = (url = "material-masters", type) => {
    navigate(`/${url}`, {
      state: { type },
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  useEffect(() => {
    if (isModalOpen) {
      dispatch(actionGetCompanyDetail());
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (companyDetail) {
      form.setFieldsValue({
        location_matching_tolerance: companyDetail.location_matching_tolerance
          ? companyDetail.location_matching_tolerance
          : "",
      });
    }
  }, [companyDetail]);

  const onFinish = (value) => {
    const data = {
      location_matching_tolerance: value.location_matching_tolerance
        ? String(value.location_matching_tolerance)
        : "",
    };

    dispatch(
      actionUpdateCompanyDetail({
        req: data,
        handleCancel,
      })
    );
  };

  const settingsData = [
    {
      title: "General",
      links: [
        {
          type: "action",
          text: "Personal Setting",
          action: () =>
            navigate("/setting/update-user", {
              state: {
                userId: currentCompanyUser?.id,
                accountType: "OWN_ACCOUNT",
              },
            }),
        },
        { text: "Users", url: "/users" },
        { text: "Company Setting", url: "/company-setting" },
      ],
    },
    {
      title: "Location",
      links: [
        {
          text: "Zone",
          type: "action",
          action: () => handleNavigation("general-masters", "zone"),
        },
        {
          text: "Area",
          type: "action",
          action: () => handleNavigation("general-masters", "area"),
        },
        {
          text: "SFA Settings",
          type: "action",
          action: showModal,
        },
      ],
    },
  ];

  const configurations = [
    {
      title: "Security Control",
      links: [
        { text: "Role Profile", url: "/role-profile" },
        { text: "Roles", url: "/role-hierarchy" },
        // { text: "Login History", url: "/login-logs" },
        // { text: "Audit Log", url: "/audit-log" },
      ],
    },
    {
      title: "Customization",
      links: [
        // { type: "action", text: "Custom Fields", action: handleCustomFields },
        {
          text: "Material",
          type: "action",
          action: () => handleNavigation("material-masters", "product"),
        },
        {
          text: "Material Types",
          type: "action",
          action: () => handleNavigation("material-masters", "product-type"),
        },
        {
          text: "Material Category",
          type: "action",
          action: () =>
            handleNavigation("material-masters", "product-category"),
        },
        {
          text: "Material Sub Category",

          type: "action",
          action: () =>
            handleNavigation("material-masters", "product-sub-category"),
        },
        {
          text: "Units",

          type: "action",
          action: () => handleNavigation("material-masters", "unit"),
        },
        {
          text: "HSN / SAC Code",

          type: "action",
          action: () => handleNavigation("material-masters", "hsn-sac-code"),
        },
        // { text: "Clusters", url: "/clusters" },
      ],
    },
  ];

  const renderSettingCards = (data) =>
    data.map((setting, index) => (
      <Col
        sm={12}
        xs={24}
        md={12}
        lg={6}
        xl={6}
        xxl={5}
        className="lg:mb-0 mb-3"
        key={index}
      >
        <Card
          bordered
          style={{
            border: "1px solid #dddae9",
            minHeight: 350,
            borderRadius: 10,
          }}
        >
          <p className="text-xl font-popinsSemiBold">{setting.title}</p>
          <div className="flex flex-col pt-4 gap-y-2.5">
            {setting.links.map((link, linkIndex) => {
              return link.type === "action" ? (
                <div
                  onClick={link.action}
                  key={linkIndex}
                  className="cursor-pointer text-base text-[#7C7C7C] font-popinsRegular hover:text-brand"
                >
                  {link.text}
                </div>
              ) : (
                <Link
                  key={linkIndex}
                  to={link.url}
                  className="text-base text-[#7C7C7C] font-popinsRegular hover:text-brand"
                >
                  {link.text}
                </Link>
              );
            })}
          </div>
        </Card>
      </Col>
    )); */

  return (
    <>
      <Outlet />
      {/*  <section className="main-section">
        <CustomIconText title={"Preferences"} image={settingImg} />

        <Row gutter={24} className="my-5">
          {renderSettingCards(settingsData)}
        </Row>
        <Row gutter={24} className="my-10">
          {renderSettingCards(configurations)}
        </Row>
      </section>
      <Modal
        loading={getCompanyDetailLoader}
        centered
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={false}
        width={500}
        title=""
        open={isModalOpen}
      >
        <div className="flex">
          <h1 className="text-[#6883FD] text-[18px]  ml-[8px] mt-[5px] font-medium">
            Settings
          </h1>
        </div>

        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="location_matching_tolerance"
            label={
              <span className="flex items-center gap-x-2">
                Location Matching Tolerance{" "}
                <Tooltip title="This setting is used to determine the acceptable distance range between an employee’s tracked location and a designated business location for matching purposes.">
                  <InfoCircleOutlined
                    style={{ fontSize: "16px", color: "grey" }}
                  />
                </Tooltip>
              </span>
            }
            className="mb-[12px]"
            size="small"
            normalize={(value) => value && value.replace(/[^\d.]/g, "")}
            rules={[
              {
                required: true,
                message: "Please input location matching tolerance!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              suffix={"Meter/s"}
              placeholder="Enter Location Matching Tolerance"
              className="!indent-1 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-center mt-[20px]">
            <div>
              <Form.Item className="mb-0">
                <Button
                  loading={updateCompanyDetailLoader}
                  type="primary"
                  className="root-btn min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  Save Settings
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal> */}
    </>
  );
};

export default Setting;
