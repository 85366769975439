import { Button, Result } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const error =
    location && location.state ? location.state : "An error occurred";
  return <Result status="500" title="500" subTitle={`${error}`} />;
};

export default ErrorPage;
