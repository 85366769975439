import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Table,
  Typography,
  Select,
} from "antd";
import * as XLSX from "xlsx";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import plusIcon from "../../../assets/img/add-button.svg";
import deletevent from "../../../assets/img/deleteevent.svg";
import unitproduct from "../../../assets/img/unitproduct.svg";
import editedevent from "../../../assets/img/editedevent.svg";
import typeofcompany from "../../../assets/img/typeofcompany.svg";
import editevent from "../../../assets/img/editevent.svg";
import {
  actionAddTypeOfCompanyMaster,
  actionDeleteTypeOfCompanyMaster,
  actionEditTypeOfCompanyMaster,
  actionGetTypeOfCompanyMaster,
  actionGetSingleTypeOfCompanyMaster,
} from "../../../store/services/generalMasterService";
import SortIcon from "../../common/SortIcon";
import { isValidSearchString } from "../../helper/common-function";
import MasterSearchbar from "../../common/MasterSearchbar";

const TypeOfCompany = ({ setSelectItem }) => {
  const [editableForm] = Form.useForm();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    typeOfCompanyData,
    getSingleTypeOfCompanyData,
    getSingleTypeOfCompanyLoader,
    getTypeOfCompanyLoader,
    addTypeOfCompanyLoader,
    deleteTypeOfCompanyLoader,
    updateTypeOfCompanyLoader,
  } = useSelector((state) => state.generalMaster);
  const [search, setSearch] = useState("");
  const [edited, setEdited] = useState(false);
  const [eventTypeData, setEventTypeData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isEditing = (record) => record.type_of_company_id === editingKey;

  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    dispatch(actionGetTypeOfCompanyMaster({ search }));
  }, [dispatch]);

  const handleAddTypeOfCompany = (values) => {
    dispatch(actionAddTypeOfCompanyMaster({ values, handleCancel }));
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "number" ? <Input /> : <Input variant="borderless" />;
    return (
      <td {...restProps} className="input-height">
        {editing ? (
          <Form.Item
            className="mb-0 editable-order"
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                // message: `${title} is required.`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: false, tooltip: true }}
            className="px-[11px]"
          >
            {children}
          </Typography.Paragraph>
        )}
      </td>
    );
  };

  const edit = (record) => {
    dispatch(actionGetSingleTypeOfCompanyMaster(record.type_of_company_id));
    setEdited(!edited);
  };

  useEffect(() => {
    if (getSingleTypeOfCompanyData && edited) {
      editableForm.setFieldsValue({
        company_type: getSingleTypeOfCompanyData.company_type,
      });
      setEditingKey(getSingleTypeOfCompanyData.type_of_company_id);
    }
  }, [getSingleTypeOfCompanyData]);

  const save = async (id) => {
    try {
      const row = await editableForm.validateFields();
      const { company_type } = row;
      const req = { company_type };
      dispatch(
        actionEditTypeOfCompanyMaster({ id, req, setEditingKey, setEdited })
      );
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  // const showModal = () => {
  //   setIsModalOpen(true);
  // };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "srno",
      key: "1",
      width: "18%",
      editable: false,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Type of Company",
      dataIndex: "company_type",
      key: "2",
      width: "65%",
      editable: true,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Action",
      key: "4",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <div className="flex items-center ">
            {editable ? (
              <div className="flex items-center">
                {updateTypeOfCompanyLoader[record.type_of_company_id] ||
                getSingleTypeOfCompanyLoader ? (
                  <LoadingOutlined />
                ) : (
                  <Typography.Link
                    onClick={() => save(record.type_of_company_id)}
                  >
                    <img src={editedevent} alt="" />
                  </Typography.Link>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-x-2 justify-center">
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => edit(record)}
                >
                  <img src={editevent} alt="" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              <Popconfirm
                okButtonProps={{
                  loading: deleteTypeOfCompanyLoader[record.type_of_company_id],
                }}
                description="Are you sure you want to delete?"
                onConfirm={() =>
                  dispatch(
                    actionDeleteTypeOfCompanyMaster({
                      id: record.type_of_company_id,
                      setEditingKey,
                    })
                  )
                }
              >
                <img src={deletevent} alt="" className="cursor-pointer" />
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "company_type" || col.dataIndex === ""
            ? "text"
            : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleSearch = (e) => {
    if (isValidSearchString(e.target.value)) {
      setSearch(e.target.value);
      if (e.target.value === "") {
        dispatch(
          actionGetTypeOfCompanyMaster({
            search: e.target.value,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search && isValidSearchString(e.target.value)) {
      dispatch(
        actionGetTypeOfCompanyMaster({
          search: e.target.value,
        })
      );
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetTypeOfCompanyMaster({
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  const getData = async (data) => {
    const newData =
      (await data) &&
      data.length > 0 &&
      data.map((d) => ({
        "Company Type": d.company_type ? d.company_type : "-",
      }));
    return newData;
  };

  const exportAllToExcel = async () => {
    if (
      !typeOfCompanyData ||
      (typeOfCompanyData && typeOfCompanyData.length === 0)
    ) {
      return;
    }
    const data = await getData(typeOfCompanyData);
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Type Of Company");
    XLSX.writeFile(workbook, "type-of-company.xlsx");
  };

  return (
    <div className="event-input">
      <div className="flex justify-between mt-[15px]">
        <h1 className="text-[#6883FD] text-[20px] font-medium">
          Type Of Company
        </h1>
        <div>
          <Button
            onClick={showModal}
            className="root-btn text-white hover:text-white py-5 flex-center radius"
            icon={<Image src={plusIcon} alt="Plus Icon" preview={false} />}
          >
            Add Type Of Company
          </Button>
          <Modal
            centered
            onOk={() => handleCancel()}
            onCancel={() => handleCancel()}
            footer={false}
            width={500}
            title=""
            open={isModalOpen}
          >
            <div className="flex">
              <img src={typeofcompany} alt="" />
              <h1 className="text-[#6883FD] text-[20px] ml-[8px]  mt-[5px] font-medium">
                Type Of Company
              </h1>
            </div>

            <Form
              form={form}
              onFinish={handleAddTypeOfCompany}
              layout="vertical"
              className="mt-[20px] mb-0"
            >
              <Form.Item
                name="company_type"
                label="Type Of Company"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please input type of comapany!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter Type Of Company"
                  className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>

              <div className="flex justify-center mt-[40px]">
                <Form.Item className="mb-0">
                  <Button
                    onClick={handleCancel}
                    type="button"
                    className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      loading={addTypeOfCompanyLoader}
                      type="primary"
                      className="root-btn min-h-[45px] min-w-[120px]"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
      <div className="pt-[25px]">
        <MasterSearchbar
          handleSearch={handleSearch}
          handleBlurAndPressEnter={handleBlurAndPressEnter}
          importRoute={"/master-data/type-of-company-import"}
          search={search}
          onClickDownloadMenu={exportAllToExcel}
        />
      </div>

      <div className="pt-5 pb-[75px]">
        <Form form={editableForm} component={false}>
          <Table
            scroll={{ y: 500 }}
            pagination={false}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            onChange={handleSortChange}
            loading={getTypeOfCompanyLoader}
            bordered
            dataSource={typeOfCompanyData}
            rowKey="type_of_company_id"
            columns={mergedColumns}
            rowClassName="editable-row"
          />
        </Form>
      </div>
    </div>
  );
};

export default TypeOfCompany;
