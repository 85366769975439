// import React, { useState } from "react";
// import { Button, Flex, Image } from "antd";
// import plusIcon from "../../assets/img/add-button.svg";
// import { Link } from "react-router-dom";
// import { useSelector } from "react-redux";
// import ForbiddenModal from "./ForbiddenModal";
// import usePermission from "../../hooks/use-permission";

// const PageTitle = ({
//   image,
//   icon,
//   title,
//   buttonText,
//   buttonLink,
//   rightIcon = true,
//   handleClick,
//   type = "link",
//   resource,
//   action = "create",
// }) => {
//   const [showForbidden, setShowForbidden] = useState(false);

//   const { hasPermission } = usePermission();

//   const renderButton = () => {
//     const buttonProps = {
//       className: "root-btn text-white hover:text-white py-5 flex-center radius",
//       icon: <Image src={plusIcon} alt="Plus Icon" preview={false} />,
//       children: buttonText,
//     };

//     if (hasPermission(resource, action)) {
//       if (type === "btn") {
//         return <Button {...buttonProps} onClick={handleClick} />;
//       } else {
//         return (
//           <Link to={buttonLink}>
//             <Button {...buttonProps} />
//           </Link>
//         );
//       }
//     } else {
//       return <Button {...buttonProps} onClick={() => setShowForbidden(true)} />;
//     }
//   };

//   return (
//     <>
//       <Flex gap="middle" justify="space-between" className="mb-4">
//         <div className="text-brand flex items-center gap-x-3">
//           {icon ? (
//             icon
//           ) : (
//             <Image
//               src={image}
//               alt="image"
//               height={20}
//               width={20}
//               preview={false}
//             />
//           )}
//           <h1 className="lg:text-lg text-base font-popinsRegular">{title}</h1>
//         </div>

//         {rightIcon && renderButton()}
//       </Flex>

//       {showForbidden && (
//         <ForbiddenModal
//           open={showForbidden}
//           onCancel={() => setShowForbidden(false)}
//         />
//       )}
//     </>
//   );
// };

// export default PageTitle;

import React from "react";
import { Button, Flex, Image } from "antd"; // Replace 'your-ui-library' with your actual library
import plusIcon from "../../assets/img/add-button.svg";
import { Link } from "react-router-dom";

const PageTitle = ({
  image,
  icon,
  title,
  buttonText,
  buttonLink,
  rightIcon = true,
  handleClick,
  type = "link",
}) => {
  return (
    <Flex gap="middle" justify="space-between" className="mb-4">
      <div className="text-brand flex items-center gap-x-3">
        {icon ? (
          icon
        ) : (
          <Image
            src={image}
            alt="image"
            height={20}
            width={20}
            preview={false}
          />
        )}
        <h1 className="lg:text-lg text-base  font-popinsRegular">{title}</h1>
      </div>

      {rightIcon &&
        (type === "btn" ? (
          <Button
            onClick={handleClick}
            className="root-btn text-white hover:text-white py-5 flex-center radius"
            icon={<Image src={plusIcon} alt="Plus Icon" preview={false} />}
          >
            {buttonText}
          </Button>
        ) : (
          <Link to={buttonLink}>
            <Button
              className="root-btn text-white hover:text-white py-5 flex-center radius"
              icon={<Image src={plusIcon} alt="Plus Icon" preview={false} />}
            >
              {buttonText}
            </Button>
          </Link>
        ))}
    </Flex>
  );
};

export default PageTitle;
