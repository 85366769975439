import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import * as XLSX from "xlsx";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import plusIcon from "../../../assets/img/add-button.svg";
import deletevent from "../../../assets/img/deleteevent.svg";
import paymentMethod from "../../../assets/img/paymentMethod.svg";
import editedevent from "../../../assets/img/editedevent.svg";
import editevent from "../../../assets/img/editevent.svg";
import {
  actionAddPaymentMethodMaster,
  actionDeletePaymentMethodMaster,
  actionEditPaymentMethodMaster,
  actionGetSinglePaymentMethodMaster,
  actionGetPaymentMethodMaster,
} from "../../../store/services/generalMasterService";
import SortIcon from "../../common/SortIcon";
import { isValidSearchString } from "../../helper/common-function";
import MasterSearchbar from "../../common/MasterSearchbar";

const PaymentMethod = ({ setSelectItem }) => {
  const [editableForm] = Form.useForm();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    paymentMethodMasterData,
    getSinglePaymentMethodMasterData,
    getSinglePaymentMethodMasterLoader,
    getPaymentMethodLoader,
    addPaymentMethodMasterLoader,
    deletePaymentMethodMasterLoader,
    updatePaymentMethodMasterLoader,
  } = useSelector((state) => state.generalMaster);

  const [search, setSearch] = useState("");
  const [edited, setEdited] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isEditing = (record) => record.payment_method_master_id === editingKey;

  useEffect(() => {
    dispatch(actionGetPaymentMethodMaster({ search }));
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value;
    if (isValidSearchString(value)) {
      setSearch(value);
      if (value === "") {
        dispatch(
          actionGetPaymentMethodMaster({
            search: value,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    const value = e.target.value;
    if (search && isValidSearchString(value)) {
      dispatch(
        actionGetPaymentMethodMaster({
          search: value,
        })
      );
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "number" ? (
        <Input />
      ) : dataIndex === "description" ? (
        <Input maxLength={255} style={{ textIndent: 4 }} showCount />
      ) : (
        <Input variant="borderless" />
      );
    return (
      <td {...restProps} className="input-height">
        {editing ? (
          <Form.Item
            className="mb-0 editable-order"
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: title === "Payment Method" ? true : false,
                message: ``,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: false, tooltip: true }}
            className="px-[11px]"
          >
            {children}
          </Typography.Paragraph>
        )}
      </td>
    );
  };

  const edit = (record) => {
    dispatch(
      actionGetSinglePaymentMethodMaster(record.payment_method_master_id)
    );
    setEdited(!edited);
  };

  useEffect(() => {
    if (getSinglePaymentMethodMasterData && edited) {
      editableForm.setFieldsValue({
        payment_method: getSinglePaymentMethodMasterData.payment_method,
        description: getSinglePaymentMethodMasterData.description,
      });
      setEditingKey(getSinglePaymentMethodMasterData.payment_method_master_id);
    }
  }, [getSinglePaymentMethodMasterData]);

  const save = async (id) => {
    try {
      const row = await editableForm.validateFields();
      const { payment_method, description } = row;
      const req = { payment_method, description };
      dispatch(
        actionEditPaymentMethodMaster({ id, req, setEditingKey, setEdited })
      );
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "srno",
      key: "1",
      width: "12%",
      editable: false,
      render: (text, record, index) => index + 1,
    },

    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "2",
      width: "22%",
      editable: true,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "3",
      width: "48%",
      editable: true,
      ellipsis: true,
      render: (data) => (data ? data : "-"),
      sorter: true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Action",
      key: "4",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);

        console.log(editable, record);

        return (
          <div className="flex items-center">
            {editable ? (
              <div className="flex items-center">
                {updatePaymentMethodMasterLoader[
                  record.payment_method_master_id
                ] ? (
                  <LoadingOutlined />
                ) : (
                  <Typography.Link
                    onClick={() => save(record.payment_method_master_id)}
                  >
                    <img src={editedevent} alt="" />
                  </Typography.Link>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-x-2 justify-center">
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => edit(record)}
                >
                  <img src={editevent} alt="" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              <Popconfirm
                okButtonProps={{
                  loading:
                    deletePaymentMethodMasterLoader[
                      record.payment_method_master_id
                    ],
                }}
                description="Are you sure you want to delete?"
                onConfirm={() =>
                  dispatch(
                    actionDeletePaymentMethodMaster({
                      id: record.payment_method_master_id,
                      setEditingKey,
                    })
                  )
                }
              >
                <img src={deletevent} alt="" className="cursor-pointer" />
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "srno" ? " payment_method" : "description",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleAddPaymentMethod = (values) => {
    dispatch(actionAddPaymentMethodMaster({ values, handleCancel }));
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetPaymentMethodMaster({
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  const getData = async (data) => {
    const newData =
      (await data) &&
      data.length > 0 &&
      data.map((d) => ({
        "Payment Method": d.payment_method ? d.payment_method : "-",
        Description: d.description ? d.description : "-",
      }));
    return newData;
  };

  const exportAllToExcel = async () => {
    if (
      !paymentMethodMasterData ||
      (paymentMethodMasterData && paymentMethodMasterData.length === 0)
    ) {
      return;
    }
    const data = await getData(paymentMethodMasterData);
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Payment Method");
    XLSX.writeFile(workbook, "payment-method.xlsx");
  };

  return (
    <div className="event-input">
      <div className="flex justify-between mt-[15px]">
        <h1 className="text-[#6883FD] text-[20px] font-medium">
          Payment Method
        </h1>
        <div>
          <Button
            onClick={showModal}
            className="root-btn text-white hover:text-white py-5 flex-center radius"
            icon={<Image src={plusIcon} alt="Plus Icon" preview={false} />}
          >
            Add Payment Method
          </Button>
          <Modal
            centered
            onOk={() => handleCancel()}
            onCancel={() => handleCancel()}
            footer={false}
            width={500}
            title=""
            open={isModalOpen}
          >
            <div className="flex">
              <img src={paymentMethod} alt="" />
              <h1 className="text-[#6883FD] text-[20px] ml-[8px] mt-[5px] font-medium">
                Payment Method
              </h1>
            </div>
            <Form
              form={form}
              onFinish={handleAddPaymentMethod}
              layout="vertical"
              className="mt-[20px] mb-0"
            >
              <Form.Item
                name="payment_method"
                label="Payment Method"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please input payment method!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter Payment Method"
                  className="!indent-1 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                className="mb-[12px]"
                size="small"
              >
                <Input.TextArea
                  rows={3}
                  showCount
                  style={{ background: "white", resize: "none" }}
                  maxLength={255}
                  placeholder="Enter Description"
                  className="!indent-1 !pt-1 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>

              <div className="flex justify-center mt-[40px]">
                <Form.Item className="mb-0">
                  <Button
                    onClick={handleCancel}
                    type="button"
                    className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      loading={addPaymentMethodMasterLoader}
                      type="primary"
                      className="root-btn min-h-[45px] min-w-[120px]"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
      <div className="pt-[25px]">
        <MasterSearchbar
          handleSearch={handleSearch}
          handleBlurAndPressEnter={handleBlurAndPressEnter}
          importRoute={"/master-data/payment-method-import"}
          search={search}
          // isImportEnabled={false}
          onClickDownloadMenu={exportAllToExcel}
        />
      </div>

      <div className="pt-5 pb-[75px]">
        <Form form={editableForm} component={false}>
          <Table
            onChange={handleSortChange}
            scroll={{ y: 500 }}
            pagination={false}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            loading={getPaymentMethodLoader}
            bordered
            dataSource={paymentMethodMasterData}
            rowKey="payment_method_master_id"
            columns={mergedColumns}
            rowClassName="editable-row"
          />
        </Form>
      </div>
    </div>
  );
};

export default PaymentMethod;
