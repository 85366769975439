import CustomInputWithButtons from "../common/CustomInputWithButtons ";
import PageTitle from "../common/PageTitle";
import salesOrder from "../../assets/img/salesOrder.svg";
import {
  Table,
  Dropdown,
  Popconfirm,
  Pagination,
  Tag,
  Modal,
  Form,
  Row,
  Select,
  Button,
  Image,
  Typography,
  Col,
  Divider,
  DatePicker,
  Avatar,
} from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { MdDoNotDisturbAlt, MdOutlineChangeCircle } from "react-icons/md";
import DeleteModal from "../common/DeleteModal";
import * as XLSX from "xlsx";
import React, { useEffect, useRef, useState } from "react";
import SortIcon from "../common/SortIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  actionChangeSalesOrderStatus,
  actionClearAllSalesOrders,
  actionDeleteSalesOrder,
  actionGetAllSalesOrders,
  actionGetSalesOrderDetails,
  actionGetSalesOrders,
} from "../../store/services/salesService";
import { actionGetAllBusinessPartner } from "../../store/services/masterDataService";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { getFirstLetterCapital, getFullName } from "../helper/common-function";
import { actionGetCompanyAllUsers } from "../../store/services/usersService";

export const getStatusClass = (status) => {
  switch (status) {
    case "PENDING":
      return "border-[#C69619] text-[#C69619]";
    case "DONE":
      return "border-[#15A02B] text-[#15A02B]";
    case "FAILED":
      return "border-[#DD1B1B] text-[#DD1B1B]";
    default:
      return "border-[#7C7C7C] text-[#7C7C7C]";
  }
};
export const getSOStatusClass = (status) => {
  switch (status) {
    case "PENDING":
      return "border-[#C69619] text-[#C69619]";
    case "DELIVERED":
      return "border-[#15A02B] text-[#15A02B]";
    case "PARTIALLY DELIVERED":
      return "border-[#7C7C7C] text-[#7C7C7C]";
    default:
      return "border-[#7C7C7C] text-[#7C7C7C]";
  }
};
// Function to get color based on so-status
export const getSOStatusColor = (status) => {
  switch (status) {
    case "PENDING":
      return "orange";
    case "DELIVERED":
      return "green";
    case "PARTIALLY DELIVERED":
      return "default";
    default:
      return "default";
  }
};
// Function to get color based on status
export const getStatusColor = (status) => {
  switch (status) {
    case "PENDING":
      return "orange";
    case "DONE":
      return "green";
    case "FAILED":
      return "red";
    default:
      return "default";
  }
};

const SalesOrders = () => {
  const offsetRef = useRef(0);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {
    getSalesOrdersLoader,
    getAllSalesOrdersLoader,
    deleteSalesOrderLoader,
    allSalesOrdersCount,
    allSalesOrders,
    salesOrdersData,
    changeSalesOrderStatusLoader,
    getSalesOrderDetailLoader,
    salesOrderDetails,
  } = useSelector((state) => state.sales);
  const { companyAllUsers, getCompanyAllUserLoader } = useSelector(
    (state) => state.users
  );

  const [statusForm] = Form.useForm();
  const [search, setSearch] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [salesOrderId, setSalesOrderId] = useState("");
  const [exportAll, setExportAll] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [selectedBusinessPartner, setSelectedBusinessPartner] = useState(null);
  const [selectedCompanyUser, setSelectedCompanyUser] = useState(null);
  const [dot, setDot] = useState(false);
  const { allBusinessPartnerData, getAllBusinessPartnerLoader } = useSelector(
    (state) => state.masterData
  );

  const params = new URLSearchParams(location.search);

  const businessPartnerId = Number(params.get("businessPartnerId"));
  const empTrackingDate = params.get("empTrackingDate");
  const createdById = Number(params.get("createdById"));

  const {
    business_partner_type_master_id: businessPartnerState = "",
    date = [],
    createdById: createdByState = "",
    so_status: soStatusState = "",
  } = form.getFieldsValue();

  const [endDateState = "", startDateState = ""] = date || [];

  useEffect(() => {
    if (businessPartnerId || createdById || empTrackingDate) {
      const updatedFields = {};

      if (businessPartnerId) {
        updatedFields.business_partner_type_master_id = businessPartnerId;
      }

      if (createdById) {
        updatedFields.createdById = createdById;
      }

      if (empTrackingDate) {
        updatedFields.date = [dayjs(empTrackingDate), dayjs(empTrackingDate)];
      }

      if (Object.keys(updatedFields).length > 0) {
        form.setFieldsValue(updatedFields);
        setDot(true);

        getApiCall(
          0,
          "",
          "",
          "",
          empTrackingDate || "",
          empTrackingDate || "",
          "",
          businessPartnerId || "",
          createdById || ""
        );
      }
    }
  }, [businessPartnerId, createdById, empTrackingDate]);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const getApiCall = (
    offset = 0,
    search = "",
    orderByColumn = "",
    order = "",
    startDate = "",
    endDate = "",
    status = "",
    businessPartner = "",
    companyUser = ""
  ) => {
    dispatch(
      actionGetSalesOrders({
        offset,
        orderByColumn,
        order,
        search,
        startDate: startDate ? dayjs(startDate).format("DD-MM-YYYY") : "",
        endDate: endDate ? dayjs(endDate).format("DD-MM-YYYY") : "",
        status,
        businessPartner,
        companyUser,
      })
    );
  };

  useEffect(() => {
    if (businessPartnerId && startDate && endDate && createdById) {
      setSelectedBusinessPartner(businessPartnerId);
      setSelectedCompanyUser(createdById);
      getApiCall(
        0,
        "",
        "",
        "",
        startDate,
        endDate,
        "",
        businessPartnerId,
        createdById
      );
    }
  }, [businessPartnerId, startDate, endDate, createdById]);

  useEffect(() => {
    if (!businessPartnerId && !startDate && !endDate && !selectedCompanyUser) {
      getApiCall(offsetRef.current, search);
    }
  }, [dispatch]);

  const handleRefresh = () => {
    setSearch(null);
    offsetRef.current = 0;
    getApiCall(offsetRef.current);
  };

  console.log(form.getFieldsValue());

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      getApiCall(
        offsetRef.current,
        e.target.value,
        "",
        "",
        startDateState,
        endDateState,
        soStatusState,
        businessPartnerState,
        createdByState
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      getApiCall(
        offsetRef.current,
        e.target.value,
        "",
        "",
        startDateState,
        endDateState,
        soStatusState,
        businessPartnerState,
        createdByState
      );
    }
  };

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllSalesOrders());
    setExportAll(true);
  };

  useEffect(() => {
    if (salesOrderDetails && salesOrderId) {
      statusForm.setFieldsValue({
        status: salesOrderDetails.status,
      });
    }
  }, [salesOrderId, salesOrderDetails]);

  const columns = [
    {
      title: "SO Number",
      dataIndex: "sales_order_number",
      key: "sales_order_number",
      width: 200,
      sorter: true,
      // fixed: "left",
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span
          onClick={() => {
            navigate("/view-sales-orders", {
              state: { id: data.sales_order_id },
            });
          }}
          className="text-brand cursor-pointer"
        >
          {text}
        </span>
      ),
    },
    {
      title: "Business Partner / Customer",
      dataIndex: ["customer", "business_partner_name"],
      key: "customer",
      ellipsis: true,
      width: 200,
      sorter: true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      width: 150,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => dayjs(text).format("DD-MM-YYYY"),
    },
    {
      title: "Ship to Party",
      dataIndex: ["ship_to_party", "business_partner_name"],
      key: "ship_to_party",
      ellipsis: true,
      width: 250,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      ellipsis: true,
      width: 200,
      render: (text) => (
        <div className="flex items-center">
          <div>
            <Avatar
              style={{
                verticalAlign: "middle",
              }}
              src={text.profile_pic}
              size={30}
            />
          </div>
          <Typography.Paragraph
            ellipsis={{ rows: 1, tooltip: true }}
            className="text-black  !mb-0 ml-1"
          >
            {getFullName(text.first_name, text.last_name)}
          </Typography.Paragraph>
        </div>
      ),
    },
    {
      title: "Plant Code",
      dataIndex: ["plant_code", "plant_code"],
      key: "ship_to_party",
      ellipsis: true,
      width: 250,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "SAP Sales Order Number",
      dataIndex: "sap_sales_order_number",
      key: "sap_sales_order_number",
      ellipsis: true,
      width: 250,
      /*   sorter: true,
      sortIcon: () => <SortIcon />, */
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Tag
          color={getStatusColor(text)}
          // className="py-1 px-2.5 text-sm font-popinsRegular"
        >
          {text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()}
        </Tag>
      ),
    },

    {
      title: "SO Status",
      dataIndex: "so_status",
      key: "so_status",
      width: 200,
      render: (text) => (
        <Tag
          color={getSOStatusColor(text)}
          // className="py-1 px-2.5 text-sm font-popinsRegular"
        >
          {text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()}
        </Tag>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 100,
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () => {
                    navigate("/update-sales-order", {
                      state: data.sales_order_id,
                    });
                  },
                },

                {
                  label: "Delete",
                  icon: <DeleteOutlined />,
                  danger: true,
                  key: "Delete",

                  onClick: () => {
                    setIsDeleteModalOpen((prev) => !prev);
                    setSalesOrderId(data?.sales_order_id);
                  },
                },
              ].filter(Boolean),
            }}
          >
             <EllipsisOutlined className="cursor-pointer" />
          </Dropdown>
        </>
      ),
    },
  ];

  const handleCloseViewModal = () => {
    setIsViewModalOpen(false);
    setSalesOrderId("");
  };

  const exportAllToExcel = async () => {
    const data =
      (await allSalesOrders) &&
      allSalesOrders.length > 0 &&
      allSalesOrders.map((data) => ({
        "SO Number": data.sales_order_number ? data.sales_order_number : "-",
        "Business Partner / Customer": data.customer
          ? data.customer.business_partner_name
          : "-",
        Date: data.created_at
          ? dayjs(data.created_at).format("DD/MM/YYYY")
          : "-",
        "Ship to Party": data.ship_to_party
          ? data.ship_to_party?.business_partner_name
          : "-",
        Status: data.status ? getFirstLetterCapital(data.status) : "-",
        "SO Status": data.so_status
          ? getFirstLetterCapital(data.so_status)
          : "-",
        Note: data.note ? data.note : "-",
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sales Orders");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "Sales Orders.xlsx");
    dispatch(actionClearAllSalesOrders({ setExportAll }));
  };

  useEffect(() => {
    if (exportAll && allSalesOrders && allSalesOrders.length > 0) {
      exportAllToExcel();
    }
  }, [allSalesOrders]);

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.columnKey,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    getApiCall(
      offsetRef.current,
      search,
      sorterParams.field,
      sorterParams.order,
      startDateState,
      endDateState,
      soStatusState,
      businessPartnerState,
      createdByState
    );
  };

  const handleCloseStatusModal = () => {
    setIsStatusModalOpen(false);
    setSalesOrderId("");
  };

  useEffect(() => {
    dispatch(actionGetAllBusinessPartner());
    dispatch(actionGetCompanyAllUsers());
  }, [dispatch]);

  const businesssPartner =
    allBusinessPartnerData &&
    allBusinessPartnerData.length > 0 &&
    allBusinessPartnerData.map((status) => ({
      label: status.business_partner_name,
      value: status.business_partner_id,
    }));

  const companyUser =
    companyAllUsers &&
    companyAllUsers.length > 0 &&
    companyAllUsers.map((user) => ({
      label: getFullName(user?.first_name, user.last_name),
      value: user.id,
    }));

  const handleApplyFilters = (values) => {
    const startDate = values.date ? values.date[0] : null;
    const endDate = values.date ? values.date[1] : null;
    getApiCall(
      0,
      search,
      "",
      "",
      startDate,
      endDate,
      values.so_status,
      values.business_partner_type_master_id,
      values.createdById
    );
    setIsModalOpen(false);
    startDate ||
    endDate ||
    values.so_status ||
    values.business_partner_type_master_id ||
    values.createdById
      ? setDot(true)
      : setDot(false);
  };

  const handleReset = () => {
    setSearch("");
    setSelectedStatus(null);
    setStartDate(null);
    setEndDate(null);
    setDot(false);
    setSelectedBusinessPartner(null);
    setSelectedCompanyUser(null);
    setIsModalOpen(false);
    offsetRef.current = 0;

    // Reset form fields
    form.resetFields();

    getApiCall(offsetRef.current, "", "", "", "", "", null, null, null);

    console.log("filter reset");
  };

  return (
    <section className="main-wrapper">
      <PageTitle
        image={salesOrder}
        title="Sales Orders"
        buttonText="Create New SO"
        buttonLink="/add-sales-order"
      />
      <div className="w-full">
        <Row gutter={[10]}>
          <Col span={24}>
            <div className="w-full">
              <CustomInputWithButtons
                handleRefresh={handleRefresh}
                handleSearch={handleSearch}
                handleBlurAndPressEnter={handleBlurAndPressEnter}
                searchValue={search}
                filterValues={dot}
                isAdvanceFilter={true}
                onClickDownloadMenu={onClickDownloadMenu}
                onClickFilter={() => setIsModalOpen(true)}
                importRoute="/sales-orders-import"
                isImportEnabled={true}
              />
            </div>
          </Col>
          {/*    <Col span={3}>
            <Select
              allowClear
              showSearch
              className="mb-5 w-full sales-order-select"
              placeholder="Select Business Partner Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(value) => {
                setSelectedBusinessPartner(value);
                getApiCall(
                  offsetRef.current,
                  search,
                  "",
                  "",
                  startDate,
                  endDate,
                  selectedStatus,
                  // selectedBusinessPartner
                  value,
                  selectedCompanyUser
                );
              }}
              value={selectedBusinessPartner}
              loading={getAllBusinessPartnerLoader}
              options={businesssPartner}
              // disabled={getBusinessPartnerTypeLoader}
            />
          </Col>
          <Col span={3}>
            <Select
              allowClear
              showSearch
              className="mb-5 w-full sales-order-select"
              placeholder="Select Created By"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(value) => {
                setSelectedCompanyUser(value);
                getApiCall(
                  offsetRef.current,
                  search,
                  "",
                  "",
                  startDate,
                  endDate,
                  selectedStatus,
                  selectedBusinessPartner,
                  // selectedCompanyUser
                  value
                );
              }}
              value={selectedCompanyUser}
              loading={getCompanyAllUserLoader}
              options={companyUser}
              // disabled={getCompanyAllUserLoader}
            />
          </Col>
          <Col span={3}>
            <Select
              allowClear
              showSearch
              placeholder="Select SO Status"
              optionFilterProp="children"
              className="mb-5 w-full sales-order-select"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                // {
                //   label: "Pending",
                //   value: "PENDING",
                // },
                // {
                //   label: "Completed",
                //   value: "COMPLETED",
                // },
                // {
                //   label: "Cancelled",
                //   value: "CANCELLED",
                // },
                // {
                //   label: "Partially Invoiced",
                //   value: "PARTIALLY_INVOICED",
                // },
                {
                  label: "Pending",
                  value: "PENDING",
                },
                {
                  label: "Delivered",
                  value: "DELIVERED",
                },
                {
                  label: "Partially Delivered",
                  value: "PARTIALLY DELIVERED",
                },
              ]}
              onChange={(value) => {
                setSelectedStatus(value);
                getApiCall(
                  offsetRef.current,
                  search,
                  "",
                  "",
                  startDate,
                  endDate,
                  value,
                  selectedBusinessPartner,
                  selectedCompanyUser
                );
              }}
            />
          </Col>
          <Col span={5}>
            <DatePicker.RangePicker
              // format={"DD-MM-YYYY"}
              style={{ backgroundColor: "white" }}
              className="mb-5 w-full sales-order-datepicker"
              value={[startDate, endDate]}
              onChange={handleDateChange}
            />
          </Col>
          <Col span={1}>
            <Button
              type="primary"
              className="root-btn !rounded-sm min-h-[35px] mt-2"
              style={{ borderRadius: 5 }}
              onClick={() => {
                setSearch("");
                setSelectedStatus(null);
                setStartDate(null);
                setEndDate(null);
                setSelectedBusinessPartner(null);
                setSelectedCompanyUser(null);
                offsetRef.current = 0;
                if (
                  search ||
                  selectedStatus ||
                  startDate ||
                  endDate ||
                  selectedBusinessPartner ||
                  selectedCompanyUser
                ) {
                  getApiCall(offsetRef.current);
                }
              }}
            >
              Reset
            </Button>
          </Col> */}
        </Row>
      </div>

      <Table
        sticky
        rowKey="sales_order_id"
        scrollToFirstRowOnChange={true}
        sortDirections={["ascend", "descend"]}
        onChange={handleSortChange}
        bordered
        scroll={{
          x: "100vw",
          y: window.innerHeight - 150, // fills the screen height
        }}
        pagination={false}
        columns={columns}
        dataSource={salesOrdersData}
        loading={getAllSalesOrdersLoader || getSalesOrdersLoader}
      />
      <div className="flex justify-end mt-3">
        <Pagination
          hideOnSinglePage
          responsive={true}
          current={offsetRef.current + 1}
          className="flex items-center"
          showSizeChanger={false}
          total={allSalesOrdersCount}
          onChange={(e) => {
            offsetRef.current = e - 1;
            getApiCall(
              offsetRef.current,
              search,
              "",
              "",
              startDateState,
              endDateState,
              soStatusState,
              businessPartnerState,
              createdByState
            );
          }}
        />
      </div>

      <DeleteModal
        title="Are you sure you want to delete this sales order?"
        description="All the associations will be removed for this Collection"
        loading={deleteSalesOrderLoader}
        onCancel={() => {
          setIsDeleteModalOpen(false);
          setSalesOrderId("");
        }}
        onConfirm={() =>
          dispatch(
            actionDeleteSalesOrder({
              id: salesOrderId,
              setIsDeleteModalOpen,
            })
          )
        }
        isVisible={isDeleteModalOpen}
      />

      <Modal
        loading={getSalesOrderDetailLoader}
        centered
        onOk={handleCloseViewModal}
        onCancel={handleCloseViewModal}
        footer={false}
        width={900}
        title={
          <div className="text-brand flex items-center gap-x-3">
            <Image
              src={salesOrder}
              alt="Sales Order"
              height={20}
              width={20}
              preview={false}
            />
            <h1
              className=" text-base  font-popinsRegular"
              style={{ fontWeight: 500 }}
            >
              Sales Order Detail
            </h1>
          </div>
        }
        open={isViewModalOpen}
      >
        <Row gutter={[18, 12]} className="mt-8">
          <DetailsItem
            label="SO Number"
            value={salesOrderDetails?.sales_order_number}
          />
          <DetailsItem
            label="Business Partner / Customer"
            value={salesOrderDetails?.customer?.business_partner_name}
          />

          <DetailsItem
            label="Date"
            value={
              salesOrderDetails?.created_at
                ? dayjs(salesOrderDetails.created_at).format("DD/MM/YYYY")
                : "-"
            }
          />
          <DetailsItem
            label="Ship to Party"
            value={
              salesOrderDetails?.ship_to_party?.business_partner_name || "-"
            }
          />
          <DetailsItem
            label="Plant Code"
            value={salesOrderDetails?.plant_code?.plant_code || "-"}
          />
          <DetailsItem
            label="SAP Sales Order Number"
            value={salesOrderDetails?.sap_sales_order_number || "-"}
          />
          <DetailsItem label="Status" value={salesOrderDetails?.status} />
          <DetailsItem label="SO Status" value={salesOrderDetails?.so_status} />

          <Table
            className="mt-4 expense-items-table"
            sticky
            rowKey="sales_order_item_id"
            scrollToFirstRowOnChange={true}
            bordered
            virtual
            scroll={{
              y: 600,
              x: "max-content",
            }}
            pagination={false}
            columns={[
              {
                title: "Material",
                key: "material_code",
                dataIndex: "material_code",
                render: (text) => text?.material_name,
              },
              {
                title: "Quantity",
                key: "quantity",
                dataIndex: "quantity",
              },
              {
                title: "Unit of Measure",
                key: "unit_of_measure",
                dataIndex: "unit_of_measure",
                render: (text) => text?.code,
              },
            ]}
            loading={getSalesOrderDetailLoader}
            dataSource={salesOrderDetails.items}
          />
        </Row>
      </Modal>

      {/* filter modal open */}
      <Modal
        centered
        // destroyOnClose={() => form.resetFields()}
        onOk={handleClose}
        onCancel={handleClose}
        footer={false}
        width={500}
        open={isModalOpen}
      >
        <h1 className="text-[#6883FD] text-[20px]  mt-[5px] font-medium">
          Filter
        </h1>
        <Form
          form={form}
          onFinish={handleApplyFilters}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="business_partner_type_master_id"
            label="Business Partner Type"
            className="mb-[12px]"
            size="small"
          >
            <Select
              allowClear
              showSearch
              className="mb-5 w-full sales-order-select"
              placeholder="Select Business Partner Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              loading={getAllBusinessPartnerLoader}
              options={businesssPartner}
              // disabled={getBusinessPartnerTypeLoader}
            />
          </Form.Item>

          <Form.Item
            name="createdById"
            label="Created By"
            className="mb-[12px]"
            size="small"
          >
            <Select
              allowClear
              showSearch
              className="mb-5 w-full sales-order-select"
              placeholder="Select Created By"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              loading={getCompanyAllUserLoader}
              options={companyUser}
              // disabled={getCompanyAllUserLoader}
            />
          </Form.Item>

          <Form.Item
            name="so_status"
            label="Sales Order Status"
            className="mb-[12px]"
            size="small"
          >
            <Select
              allowClear
              showSearch
              placeholder="Select SO Status"
              optionFilterProp="children"
              className="mb-5 w-full sales-order-select"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  label: "Pending",
                  value: "PENDING",
                },
                {
                  label: "Delivered",
                  value: "DELIVERED",
                },
                {
                  label: "Partially Delivered",
                  value: "PARTIALLY DELIVERED",
                },
              ]}
            />
          </Form.Item>

          <Form.Item
            name="date"
            label="Start date and End Date"
            className="mb-[12px]"
            size="small"
          >
            <DatePicker.RangePicker
              value={[startDateState, endDateState]}
              format={"DD-MM-YYYY"}
              style={{ backgroundColor: "white" }}
              className="mb-5 w-full sales-order-datepicker"
            />
          </Form.Item>

          <div className="flex justify-center mt-[40px]">
            <Form.Item className="mb-0">
              <Button
                onClick={handleClose}
                type="button"
                className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  // loading={addEventCategoryLoader}
                  type="primary"
                  className="root-btn min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  // onClick={handleReset}
                  // loading={addEventCategoryLoader}
                  type="primary"
                  className="root-btn min-h-[45px] min-w-[120px]"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </section>
  );
};

export default SalesOrders;

function DetailsItem({ label, value, ...props }) {
  return (
    <Col span={24}>
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Typography.Text
          className="text-[#7C7C7C]"
          style={{
            fontSize: "14px",
            fontWeight: 500,
            width: "40%",
            paddingRight: "16px",
          }}
        >
          {label} :
        </Typography.Text>
        {label === "Status" ? (
          <span
            className={`${getStatusClass(
              value
            )} border-[0.5px] py-1 rounded-[5px] px-2.5 text-sm`}
          >
            {value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}
          </span>
        ) : (
          <Typography.Text
            className="typo-black"
            style={{ fontSize: "14px", color: "black", width: "60%" }}
          >
            {value}
          </Typography.Text>
        )}
      </div>
    </Col>
  );
}
