import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Row,
  Select,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import lock from "../../assets/img/lock.svg";
import rightAngle from "../../assets/img/right-angle.svg";
import leftAngle from "../../assets/img/left-angle.svg";
import { actionCreateAccount } from "../../store/services/authService";
import {
  actionGetCountries,
  actionGetStates,
} from "../../store/services/locationService";
import { currentYear } from "../helper/common-function";
import PhoneInputComponent from "../common/PhoneInputComponent";

const AcceptInvitationCreateAccount = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [value, setValue] = useState({
    fullPhoneNumber: "",
    iso: "in",
    phone: "",
  });

  const dispatch = useDispatch();
  const { createAccountLoader } = useSelector((state) => state.auth);
  const { countries, states, cities, getCountryLoader, statesLoader } =
    useSelector((state) => state.location);

  const location = useLocation();

  const { senderEmail, user_token, receiver } =
    location && location.state ? location.state : {};

  useEffect(() => {
    if (!location.state) {
      navigate("/login");
    }
  }, [location.state, navigate]);

  useEffect(() => {
    dispatch(actionGetCountries());
  }, [dispatch]);

  useEffect(() => {
    if (countries && countries.length) {
      const india = countries.find((country) => country.iso2 === "IN");
      if (india) {
        form.setFieldsValue({ country_id: india.id });
        dispatch(actionGetStates(india.id));
      }
    }
  }, [countries, dispatch, form]);

  const country =
    (countries &&
      countries.length &&
      countries?.map(({ name, iso2, id, ...data }) => ({
        ...data,
        label: name,
        value: id,
        country_code: iso2,
        id: id,
      }))) ||
    [];

  const state =
    (states &&
      states.length &&
      states?.map(({ name, state_code, id, ...data }) => ({
        ...data,
        label: name,
        value: id,
        state_code,
      }))) ||
    [];

  // get State on select of Country Input
  const handleGetState = (val, data) => {
    dispatch(actionGetStates(data.value));
    form.setFieldsValue({
      state: null,
    });
  };

  const onFinishFailed = ({ values, errorFields }) => {
    console.log(errorFields);
  };

  const handleChangePhoneNumber = (valid) => {
    setIsValid(valid);
  };

  const onFinish = (values) => {
    const valid =
      value && value.phone && value.phone.length < 15 ? true : false;

    const { terms_and_condition, ...items } = values;

    if (valid) {
      setIsValid(true);
      const req = {
        ...items,
        phone: value.phone,
        country_code: value.iso,
        social_type: "INVITED",
        token: user_token,
      };
      dispatch(
        actionCreateAccount({
          values: req,
          navigate,
          phone: value.fullPhoneNumber,
          from: "ACCEPT_PAGE",
        })
      );
    } else {
      setIsValid(false);
    }
  };

  return (
    <Spin spinning={false}>
      <div className="min-h-screen flex flex-col justify-center  w-full h-full register-container relative pt-6">
        <img
          src={rightAngle}
          className="absolute z-0 right-0 bottom-0 md:block hidden"
          alt="right-angle"
        />
        <img
          src={leftAngle}
          className="absolute z-0 left-0 top-0 md:block hidden"
          alt="left-angle"
        />
        <Card
          type="inner"
          className="my-auto  2xl:max-w-2xl md:max-w-[61%]  w-[95%] mx-auto border rounded-[20px] custom-bg-title"
          title={
            <div className="py-[20px]">
              <div className="flex  items-center">
                <div className="">
                  <div className="relative lg:w-[100px] lg:h-[100px] w-[80px] h-[80px] flex justify-center items-center lg:text-[50px] text-[40px]  bg-secondary rounded-full text-white">
                    {senderEmail && senderEmail.slice(0, 2).toUpperCase()}
                    <div
                      style={{ boxShadow: "0px 0px 4px 0px #00000040" }}
                      className="px-5 py-2 absolute -right-5 -top-2 z-40 rounded-[18px] bg-white/90 shadow-2xl"
                    >
                      <img src={lock} alt="lock" />
                    </div>
                  </div>
                </div>
                <div className="flex-col flex pl-5">
                  <p className="lg:text-[19px] sm:text-[15px] font-[400] text-base whitespace-normal leading-6 text-[#000] mt-2">
                    Invited by
                  </p>
                  <p className="lg:text-[23px] font-semibold text-xl mt-1 text-[#000] leading-5">
                    {senderEmail ? senderEmail : ""}
                  </p>
                </div>
              </div>
            </div>
          }
        >
          <p className="lg:text-[17px] font-semibold text-sm text-[#000] py-2">
            Join Our Organization
          </p>
          <p className="lg:text-[17px] text-sm text-[#727272] pb-5 lg:pb-9">
            A new CRM account will be created for the email address {receiver}.
          </p>
          <Form onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
            <Row gutter={18}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: "Please Input Your First Name!",
                    },
                  ]}
                >
                  <Input placeholder="First Name" className="py-[10px]" />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  name="last_name"
                  className=""
                  rules={[
                    { required: true, message: "Please Input Your Last Name!" },
                  ]}
                >
                  <Input placeholder="Last Name" className="py-[10px]" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} className="mb-1">
                <PhoneInputComponent
                  value={value.fullPhoneNumber}
                  onChange={handleChangePhoneNumber}
                  setIsValid={setIsValid}
                  isValid={isValid}
                  setValue={setValue}
                />
              </Col>
              <Col xs={24} md={12} className="mb-1">
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please Input Your Password!",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Password"
                    className="indent-5"
                    type={"text"}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  name="country_id"
                  className=""
                  rules={[
                    { required: true, message: "Please select country!" },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    loading={getCountryLoader}
                    onSelect={handleGetState}
                    placeholder="Select Country"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={country}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="state_id"
                  rules={[{ required: true, message: "Please select state!" }]}
                >
                  <Select
                    allowClear
                    showSearch
                    loading={statesLoader}
                    placeholder="Select State"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={state}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <p className="pt-6 md:text-[14px] sm:text-[12px] text-[12px] font-[500] text-black">
                  Your data will be stored in india data center.
                </p>

                <Form.Item
                  name="terms_and_conditions"
                  className="mt-2"
                  style={{
                    paddingTop: "10px",
                  }}
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error(
                                "You must agree to the Terms of Service and Privacy Policy."
                              )
                            ),
                    },
                  ]}
                >
                  <Checkbox className="text-sm font-medium my-3">
                    <p className="md:text-[14px] sm:text-[12px] text-[11px] font-[500] text-black">
                      I agree to the{" "}
                      <span className="text-blue-400 ">Terms of Service</span>{" "}
                      and <span className="text-blue-400">Privacy policy </span>
                      of CRM Harbour
                    </p>
                  </Checkbox>
                </Form.Item>
                <div className="flex gap-4 pt-4">
                  <Form.Item>
                    <Button
                      loading={createAccountLoader}
                      // disabled={!checked ? true : false}
                      type="primary"
                      className="filled-s-button  md:text-base text-sm  border-none rounded-[10px] min-h-[45px]"
                      htmlType="submit"
                    >
                      Create Account
                    </Button>
                  </Form.Item>
                  {/* <Form.Item>
                    <Button
                      loading={createAccountLoader}
                      className="outline-button-border md:text-base text-sm  min-h-[45px]"
                      htmlType="submit"
                    >
                      Back
                    </Button>
                  </Form.Item> */}
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
        <footer className="lg:text-base  h-[30%] py-10 pb-5  text-center md:text-sm text-sm whitespace-nowrap  ">
          © {currentYear}, CRM Harbor Pvt. Ltd. All Rights Reserved.
        </footer>
      </div>
    </Spin>
  );
};

export default AcceptInvitationCreateAccount;
