import { createSlice } from "@reduxjs/toolkit";
import { actionAddCustomField } from "../services/customFieldService";

const customFieldSlice = createSlice({
  name: "customField",
  initialState: {
    addCustomFieldLoader: false,
  },
  reducers: {},
  extraReducers: (buider) => {
    buider
      // get Role State
      .addCase(actionAddCustomField.pending, (state) => {
        state.addCustomFieldLoader = true;
      })
      .addCase(actionAddCustomField.fulfilled, (state, action) => {
        state.addCustomFieldLoader = false;
      })
      .addCase(actionAddCustomField.rejected, (state) => {
        state.addCustomFieldLoader = false;
      });
  },
});
export default customFieldSlice.reducer;
