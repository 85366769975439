import L from "leaflet";
import "leaflet-polylinedecorator";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef, useState } from "react";
import {
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "react-leaflet-markercluster/dist/styles.min.css";
import { useDispatch, useSelector } from "react-redux";
import { actionGetUserLocationTracking } from "../../store/services/salesService";
import { Card, Result, Spin } from "antd";

function MapComponent({ date, userId }) {
  const mapRef = useRef();
  const polylineRef = useRef();
  const decoratorRef = useRef();
  const dispatch = useDispatch();
  const { getAllUserLocationsLoader, allUserLocations } = useSelector(
    (state) => state.sales
  );

  useEffect(() => {
    if (userId && date) {
      dispatch(actionGetUserLocationTracking({ date, userId }));
    }
  }, [dispatch, userId, date]);

  const locations =
    allUserLocations &&
    allUserLocations?.map((location) => ({
      id: location.user_location_tracking_id,
      lat: location.latitude,
      lng: location.longitude,
      address: location.current_address,
    }));

  const [mapLoaded, setMapLoaded] = useState(false);
  const routeCoordinates =
    locations?.map((loc) => [parseFloat(loc.lat), parseFloat(loc.lng)]) || [];

  const handleMapLoad = (map) => {
    mapRef.current = map;
    setMapLoaded(true);
  };

  useEffect(() => {
    if (mapLoaded && mapRef.current && routeCoordinates.length > 0) {
      // Remove existing polyline and decorator if they exist
      if (polylineRef.current) {
        mapRef.current.removeLayer(polylineRef.current);
      }
      if (decoratorRef.current) {
        mapRef.current.removeLayer(decoratorRef.current);
      }

      // Create new polyline
      polylineRef.current = L.polyline(routeCoordinates, {
        color: "#6883FD",
      }).addTo(mapRef.current);

      // Remove the polyline decorator
      // Commenting out the decorator creation
      // decoratorRef.current = L.polylineDecorator(polylineRef.current, {
      //   patterns: [
      //     {
      //       offset: "100%", // Start at the end of the polyline
      //       repeat: 0, // Do not repeat
      //       symbol: L.Symbol.arrowHead({
      //         pixelSize: 10,
      //         pathOptions: { stroke: true, color: "#6883FD", weight: 3 },
      //       }),
      //     },
      //   ],
      // }).addTo(mapRef.current);
    }
  }, [mapLoaded, routeCoordinates]);

  if (getAllUserLocationsLoader) {
    return <Spin />;
  }

  if (!locations || locations.length === 0) {
    return (
      <Card className="mt-5">
        <Result
          status="info"
          title="No Data Available"
          subTitle="There is no data available for the selected user and date at this moment."
        />
      </Card>
    );
  }

  return getAllUserLocationsLoader ? (
    <Spin />
  ) : (
    locations && locations.length > 0 && (
      <MapContainer
        className="mt-6"
        center={[parseFloat(locations[0]?.lat), parseFloat(locations[0]?.lng)]}
        zoom={18}
        maxZoom={20}
        style={{ height: 700, width: "100%" }}
        ref={mapRef}
        attributionControl={false}
        whenReady={handleMapLoad}
        zoomControl={false}
      >
        {mapLoaded && (
          <>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              maxNativeZoom={19}
              maxZoom={20}
            />

            <MarkerClusterGroup
              maxClusterRadius={80}
              spiderfyOnMaxZoom={false}
              disableClusteringAtZoom={16}
            >
              {locations.map((location, index) => {
                console.log(
                  index === locations.length - 1,
                  "index === location.length - 1"
                );
                const markerLabel = L.divIcon({
                  style: {
                    border: "none",
                    boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
                    backgroundColor:
                      index === 0
                        ? "green"
                        : index === locations.length - 1
                        ? "red"
                        : "rgba(0, 0, 0, 0.6)",
                  },
                  className: "leaflet-div-icon marker-icon border-0",
                  html: `<div style="border:0;background-color: ${
                    index === 0
                      ? "green"
                      : index === locations.length - 1
                      ? "red"
                      : "rgba(0, 0, 0, 0.7)"
                  }; color: white; padding: 5px; border-radius: 50%; text-align: center; font-size: 12px; font-weight: bold;">
                       ${index + 1}
                    </div>`,
                  iconSize: [30, 30],
                  iconAnchor: [15, 15],
                });

                return (
                  <Marker
                    key={location.id}
                    position={[
                      parseFloat(location.lat),
                      parseFloat(location.lng),
                    ]}
                    icon={markerLabel}
                  >
                    <Popup>Address: {location.address}</Popup>
                  </Marker>
                );
              })}
            </MarkerClusterGroup>

            <Polyline positions={routeCoordinates} color="#6883FD" />
          </>
        )}
      </MapContainer>
    )
  );
}

export default MapComponent;
