import { createSlice } from "@reduxjs/toolkit";
import { actionAddOpportunity, actionClearAllOpportunities, actionDeleteOpportunity, actionGetAllOpportunities, actionGetOpportunities, actionGetOpportunityDetail, actionUpdateOpportunity } from "../services/opportunityService";

const opportunitySlice = createSlice({
    name:"opportunities",
    initialState : {
        addOpportunityLoader:false,
        getOportunitiesLoader:false,
        oportunitiesDataCount: "",
        oportunitiesData: [],
        allOpportunitiesData: [],
        getAllOpportunitiesLoader:false,
        getOpportunityDetailLoader:false,
        opportunityDetailData: "",
        deleteOpportunityLoader:false,
        updateOpportunityLoader:false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        // add opportunity State
      .addCase(actionAddOpportunity.pending, (state) => {
        state.addOpportunityLoader = true;
      })
      .addCase(actionAddOpportunity.fulfilled, (state, action) => {
        state.addOpportunityLoader = false;
      })
      .addCase(actionAddOpportunity.rejected, (state) => {
        state.addOpportunityLoader = false;
      })

      // Get Opportunities state
      .addCase(actionGetOpportunities.pending, (state) => {
        state.getOportunitiesLoader = true;
      })
      .addCase(actionGetOpportunities.fulfilled, (state, action) => {
        state.getOportunitiesLoader = false;
        state.oportunitiesData = action.payload?.data;
        state.oportunitiesDataCount = action.payload?.total_records;
      })
      .addCase(actionGetOpportunities.rejected, (state) => {
        state.getOportunitiesLoader = false;
      })

      // Clear All Opportunities Data State
      .addCase(actionClearAllOpportunities.fulfilled, (state, action) => {
        state.allOpportunitiesData = action.payload;
      })

       // Get All Opportunities State
       .addCase(actionGetAllOpportunities.pending, (state) => {
        state.getAllOpportunitiesLoader = true;
      })
      .addCase(actionGetAllOpportunities.fulfilled, (state, action) => {
        state.getAllOpportunitiesLoader = false;
        state.allOpportunitiesData = action.payload.data;
      })
      .addCase(actionGetAllOpportunities.rejected, (state) => {
        state.getAllOpportunitiesLoader = false;
      })

      // Get single Opportunity detail State
      .addCase(actionGetOpportunityDetail.pending, (state) => {
        state.getOpportunityDetailLoader = true;
      })
      .addCase(actionGetOpportunityDetail.fulfilled, (state, action) => {
        state.getOpportunityDetailLoader = false;
        state.opportunityDetailData = action.payload.data;
      })
      .addCase(actionGetOpportunityDetail.rejected, (state) => {
        state.getOpportunityDetailLoader = false;
      })

      // Delete Opportunity state
      .addCase(actionDeleteOpportunity.pending, (state) => {
        state.deleteOpportunityLoader = true;
      })
      .addCase(actionDeleteOpportunity.fulfilled, (state, action) => {
        state.deleteOpportunityLoader = false;

        state.oportunitiesData = state.oportunitiesData.filter(
          (opportunity) => opportunity.opportunity_id !== action.payload
        );
      })
      .addCase(actionDeleteOpportunity.rejected, (state) => {
        state.deleteOpportunityLoader = false;
      })

       // Update Opportunity state
       .addCase(actionUpdateOpportunity.pending, (state) => {
        state.updateOpportunityLoader = true;
      })
      .addCase(actionUpdateOpportunity.fulfilled, (state, action) => {
        state.updateOpportunityLoader = false;
      })
      .addCase(actionUpdateOpportunity.rejected, (state) => {
        state.updateOpportunityLoader = false;
      });
    }
});
export default opportunitySlice.reducer;