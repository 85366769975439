import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Form,
  Image,
  Input,
  Row,
  Select,
  Table,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import addButtonWhite from "../../assets/img/add-button-white.svg";
import addSoIcon from "../../assets/img/create-so-icon.svg";
import delteIcon from "../../assets/img/deleteevent.svg";
import itemsIcon from "../../assets/img/items-icon.svg";
import { actionGetBusinessPartnerTypeMaster } from "../../store/services/generalMasterService";
import {
  actionAddBusinessPartner,
  actionGetAllBusinessPartner,
} from "../../store/services/masterDataService";
import {
  actionClearProductsAllData,
  actionGetAllProductsMaster,
  actionGetUnitMaster,
} from "../../store/services/productMasterService";
import {
  actionAddSalesOrder,
  actionGetAllPlantCodes,
  actionGetSalesOrderDetails,
  actionUpdateSalesOrder,
} from "../../store/services/salesService";
import AddPopover from "../common/AddPopover";
import CustomIconText from "../common/CustomIconText";
import { gridSettings } from "../helper/constants";
import DebounceSelect from "../common/DebounceSelect";

const AddSalesOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { state: salesOrderId } = useLocation();

  const [businessPartnerForm] = Form.useForm();
  const [shipToPartyForm] = Form.useForm();

  const [isBusinessParterPopoverOpen, setIsBusinessParterPopoverOpen] =
    useState(false);
  const [isShipPopoverOpen, setIsShipPopoverOpen] = useState(false);
  const {
    addSalesOrderLoader,
    updateSalesOrderLoader,
    getSalesOrderDetailLoader,
    salesOrderDetails,
    getAllPlantCodesLoader,
    allPlantCodes,
  } = useSelector((state) => state.sales);

  const { getBusinessPartnerTypeLoader, businessPartnerTypeData } = useSelector(
    (state) => state.generalMaster
  );
  const { unitMasterData, getUnitMasterLoader } = useSelector(
    (state) => state.productMaster
  );
  const {
    allBusinessPartnerData,
    getAllBusinessPartnerLoader,
    addBusinessPartnerLoader,
  } = useSelector((state) => state.masterData);

  const { getAllProductsLoader, productAllData } = useSelector(
    (state) => state.productMaster
  );

  useEffect(() => {
    dispatch(actionGetAllBusinessPartner());
    // dispatch(actionGetAllProductsMaster());
    dispatch(actionGetUnitMaster());
    dispatch(actionGetAllPlantCodes());
  }, [dispatch]);

  useEffect(() => {
    if (salesOrderId) {
      dispatch(actionGetSalesOrderDetails(salesOrderId));
    }
  }, [salesOrderId]);

  useEffect(() => {
    if (salesOrderDetails && salesOrderId) {
      form.setFieldsValue({
        customer_business_partner_id:
          salesOrderDetails?.customer?.business_partner_id,
        ship_to_party_business_partner_id:
          salesOrderDetails?.ship_to_party?.business_partner_id,
        plant_code_master_id:
          salesOrderDetails?.plant_code?.plant_code_master_id,
        note: salesOrderDetails?.note,
        items: Array.isArray(salesOrderDetails.items)
          ? salesOrderDetails?.items.map((item, index) => ({
              quantity: item.quantity,
              material_master_id: {
                label: item.material_code?.material_name,
                value: item.material_code?.material_master_id,
                base_unit_of_measure: item?.unit_of_measure,
              },
              unit_of_measure_id: item.unit_of_measure?.unit_master_id,
            }))
          : [],
      });
    }
  }, [salesOrderDetails, form, salesOrderId]);

  const addNewClick = () => {
    dispatch(actionGetBusinessPartnerTypeMaster());
  };

  // business partner data
  const businessPartnerOptions = useMemo(() => {
    return allBusinessPartnerData && allBusinessPartnerData.length > 0
      ? allBusinessPartnerData.map((data) => ({
          key: data.business_partner_id,
          label: data.business_partner_name,
          value: data.business_partner_id,
        }))
      : [];
  }, [allBusinessPartnerData]);

  const plantCodeOptions = useMemo(() => {
    return allPlantCodes && allPlantCodes.length > 0
      ? allPlantCodes.map((data) => ({
          key: data.plant_code_master_id,
          label: data.plant_code,
          value: data.plant_code_master_id,
        }))
      : [];
  }, [allPlantCodes]);

  const unitOptions = useMemo(() => {
    return unitMasterData && unitMasterData.length > 0
      ? unitMasterData.map((data) => ({
          key: data.unit_master_id,
          label: data.code,
          value: data.unit_master_id,
        }))
      : [];
  }, [unitMasterData]);

  // business partner type data
  const businessPartnerTypesOptions = useMemo(() =>
    businessPartnerTypeData && businessPartnerTypeData.length > 0
      ? businessPartnerTypeData.map((data) => ({
          key: data.business_partner_type_master_id,
          label: data.business_partner_type_name,
          value: data.business_partner_type_master_id,
        }))
      : []
  );

  const materialOptions = useMemo(() => {
    return (
      (productAllData &&
        productAllData.length > 0 &&
        productAllData.map((item) => ({
          value: item.material_master_id,
          label: item.material_name,
          base_unit_of_measure: item?.base_unit_of_measure,
        }))) ||
      []
    );
  }, [productAllData]);

  const handleSelectMaterial = (otherOptions, index) => {
    // console.log({ value, otherOptions, index });
    // console.log({ currentItems: form.getFieldsValue().items });

    // return;
    form.setFieldsValue({
      items: form.getFieldsValue().items.map((item, i) =>
        i === index
          ? {
              ...item,
              unit_of_measure_id:
                otherOptions?.base_unit_of_measure?.unit_master_id,
            }
          : item
      ),
    });

    dispatch(actionClearProductsAllData({ setExportAll: false }));
  };

  const columns = (fields, remove) => [
    {
      title: <span className="text-[14px]">Material</span>,
      dataIndex: "material_master_id",
      align: "middle",
      width: "33%",
      render: (_, record, index) => (
        <Form.Item
          className="!mb-0"
          rules={[{ required: true, message: "Please select material" }]}
          name={[index, "material_master_id"]}
        >
          {/* <Select
            allowClear
            showSearch
            optionFilterProp="children"
            loading={getAllProductsLoader}
            disabled={getAllProductsLoader}
            onSelect={(value, option) =>
              handleSelectMaterial(value, option, index)
            }
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={materialOptions}
            placeholder="Select Material"
            style={{ width: "100%" }}
          /> */}
          <DebounceSelect
            allowClear
            showSearch
            placeholder="Select Material"
            onSelect={(params, option) => {
              console.log({ ...params }, { ...option });
              handleSelectMaterial(option, index);
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: <span className="text-[14px]">Qty</span>,
      dataIndex: "quantity",
      width: "28%",
      render: (_, record, index) => (
        <Form.Item
          className="!mb-0"
          rules={[{ required: true, message: "Please enter quantity" }]}
          name={[index, "quantity"]}
        >
          <Input allowClear placeholder="Quantity" />
        </Form.Item>
      ),
    },
    {
      title: <span className="text-[14px]">Unit</span>,
      dataIndex: "unit_of_measure_id",
      align: "middle",
      width: "32%",
      render: (_, record, index) => (
        <Form.Item
          className="!mb-0"
          rules={[{ required: true, message: "Please select unit" }]}
          name={[index, "unit_of_measure_id"]}
        >
          <Select
            allowClear
            showSearch
            loading={getUnitMasterLoader}
            disabled={getUnitMasterLoader}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={unitOptions}
            placeholder="Select Unit"
            style={{ width: "100%" }}
          />
        </Form.Item>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 100,
      align: "middle",
      render: (_, record, index) => (
        <div
          onClick={() => remove(record.name)} // Remove the row
          className={`flex justify-center items-center gap-2 cursor-pointer ${
            fields.length === 1 ? "opacity-50 pointer-events-none" : ""
          }`}
        >
          <img src={delteIcon} alt="Delete" className="cursor-pointer" />
        </div>
      ),
    },
  ];

  const onFinish = (values) => {
    const req = {
      ...values,
      items: values.items.map((item) => ({
        ...item,
        quantity: parseFloat(item.quantity),
        material_master_id:
          typeof item.material_master_id == "object"
            ? item?.material_master_id?.value
            : item.material_master_id,
      })),
    };

    salesOrderId
      ? dispatch(actionUpdateSalesOrder({ req, navigate, id: salesOrderId }))
      : dispatch(actionAddSalesOrder({ req, navigate }));
  };

  const closeBusinessPartnerPopover = () => {
    setIsBusinessParterPopoverOpen(false);
    businessPartnerForm.resetFields();
  };

  const closeShipToPartyPopover = () => {
    setIsShipPopoverOpen(false);
    shipToPartyForm.resetFields();
  };

  const showBusinessPartnerContent = () => (
    <>
      <div className="min-w-[350px]">
        <Form
          form={businessPartnerForm}
          onFinish={(values) =>
            dispatch(
              actionAddBusinessPartner({
                req: values,
                form,
                handleCancel: closeBusinessPartnerPopover,
                field: "customer_business_partner_id",
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="business_partner_name"
            label="Business Partner Name"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input business partner name!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Business Partner Name"
              className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>
          <Form.Item
            name="business_partner_type_master_id"
            label="Business Partner Type"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please select business partner type!",
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              placeholder="Select Business Partner Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={businessPartnerTypesOptions}
              disabled={getBusinessPartnerTypeLoader}
              loading={getBusinessPartnerTypeLoader}
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={() => closeBusinessPartnerPopover()}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addBusinessPartnerLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  const showShipToPartyContent = () => (
    <>
      <div className="min-w-[350px]">
        <Form
          form={shipToPartyForm}
          onFinish={(values) =>
            dispatch(
              actionAddBusinessPartner({
                req: values,
                form,
                handleCancel: closeShipToPartyPopover,
                field: "ship_to_party_business_partner_id",
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="business_partner_name"
            label="Business Partner Name"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input business partner name!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Business Partner Name"
              className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>
          <Form.Item
            name="business_partner_type_master_id"
            label="Business Partner Type"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please select business partner type!",
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              placeholder="Select Business Partner Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={businessPartnerTypesOptions}
              disabled={getBusinessPartnerTypeLoader}
              loading={getBusinessPartnerTypeLoader}
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={closeShipToPartyPopover}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addBusinessPartnerLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  return (
    <section
      className={`main-wrapper ${
        getSalesOrderDetailLoader ? "pointer-events-none opacity-75" : ""
      }`}
    >
      <div className="mb-4">
        <CustomIconText backArrow={true} />
      </div>
      <Form
        className={`${false ? "pointer-events-none opacity-70" : ""}`}
        scrollToFirstError
        form={form}
        layout="vertical"
        name="basic"
        initialValues={{
          items: [{}],
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Card
          title={
            <div className="flex items-center gap-x-3">
              <Image height={22} width={22} src={addSoIcon} preview={false} />{" "}
              <p
                className="text-brand text-[18px] !font-popinsRegular"
                style={{
                  fontWeight: 400,
                }}
              >
                {salesOrderId ? "Update" : "Create"} SO
              </p>
            </div>
          }
          loading={getSalesOrderDetailLoader}
          className="main-card mt-5"
        >
          <Row gutter={24}>
            <Col {...gridSettings}>
              <AddPopover
                content={showBusinessPartnerContent}
                isOpen={isBusinessParterPopoverOpen}
                onOpenChange={(visible) => {
                  if (!visible) {
                    setIsBusinessParterPopoverOpen(false);
                    businessPartnerForm.resetFields();
                  }
                }}
                text="Add New"
                showModal={() => {
                  setIsBusinessParterPopoverOpen(!isBusinessParterPopoverOpen);
                  addNewClick();
                }}
              />
              <Form.Item
                label="Business Partner / Customer"
                name="customer_business_partner_id"
                rules={[
                  {
                    required: true,
                    message: "Please select business partner!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Business Partner"
                  optionFilterProp="children"
                  className=""
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={businessPartnerOptions}
                  disabled={getAllBusinessPartnerLoader}
                  loading={getAllBusinessPartnerLoader}
                />
              </Form.Item>
            </Col>
            <Col {...gridSettings}>
              <AddPopover
                content={showShipToPartyContent}
                isOpen={isShipPopoverOpen}
                onOpenChange={(visible) => {
                  if (!visible) {
                    setIsShipPopoverOpen(false);
                    shipToPartyForm.resetFields();
                  }
                }}
                text="Add New"
                showModal={() => {
                  setIsShipPopoverOpen(!isShipPopoverOpen);
                  addNewClick();
                }}
              />
              <Form.Item
                label="Ship To Party"
                name="ship_to_party_business_partner_id"
                rules={[
                  {
                    required: true,
                    message: "Please select ship to party!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Ship To Party"
                  optionFilterProp="children"
                  className=""
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={businessPartnerOptions}
                  disabled={getAllBusinessPartnerLoader}
                  loading={getAllBusinessPartnerLoader}
                />
              </Form.Item>
            </Col>
            <Col {...gridSettings}>
              <Form.Item
                label="Plant Code"
                name="plant_code_master_id"
                rules={[
                  {
                    required: true,
                    message: "Please select plant code!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Plant Code"
                  optionFilterProp="children"
                  className=""
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={plantCodeOptions}
                  disabled={getAllPlantCodesLoader}
                  loading={getAllPlantCodesLoader}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Note" name="note">
                <Input.TextArea rows={2} placeholder="Enter Note" />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card loading={getSalesOrderDetailLoader} className="main-card mt-5 ">
          <div className="flex items-center gap-x-2">
            <Image
              height={18}
              width={18}
              src={itemsIcon}
              preview={false}
              alt="Items Icon"
            />
            <p className="text-[#2B2526] text-lg">
              Items <span className="text-red-500 ml-0">*</span>
            </p>
          </div>

          <ConfigProvider
            theme={{
              components: {
                Table: {
                  cellPaddingBlock: 10,
                },
                Select: { controlHeight: 30, algorithm: true },
              },
            }}
          >
            <Form.List name="items">
              {(fields, { add, remove }) => (
                <>
                  <Table
                    columns={columns(fields, remove)}
                    dataSource={fields}
                    pagination={false}
                    bordered
                    className="mt-4 sales-orders-table"
                    scroll={{ x: "max-content", y: 400 }}
                  />

                  <div
                    onClick={() => add()}
                    className={`inline-flex mt-5 cursor-pointer  items-center gap-x-2 ${
                      false || false ? "opacity-50 pointer-events-none" : ""
                    }`}
                  >
                    <Image
                      preview={false}
                      src={addButtonWhite}
                      height={20}
                      width={20}
                      alt="add-button-white"
                    />
                    <span className="text-brand text-base">Add New</span>
                  </div>
                </>
              )}
            </Form.List>
          </ConfigProvider>

          <div className="flex items-center gap-x-5 py-3 justify-end">
            <Button
              onClick={() => navigate(-1)}
              type="button"
              className="gray-button text-white hover:text-white min-h-[40px] min-w-[120px]"
            >
              Cancel
            </Button>
            <Form.Item className="mb-0">
              <Button
                loading={addSalesOrderLoader || updateSalesOrderLoader}
                type="primary"
                className="root-btn min-h-[40px] min-w-[120px]"
                htmlType="submit"
              >
                {salesOrderId ? "Update" : "Save"}
              </Button>
            </Form.Item>
          </div>
        </Card>
      </Form>
    </section>
  );
};

export default AddSalesOrder;
