import {
  Button,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Pagination,
  Table,
  Tag,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { MdLogin } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import { SolutionOutlined } from "@ant-design/icons";
import {
  actionClearAllAttendanceLogs,
  actionGetAllAttendanceLogs,
  actionGetAttendanceLogs,
} from "../../store/services/usersService";
import CustomInputWithButtons from "../common/CustomInputWithButtons ";
import PageTitle from "../common/PageTitle";
import CustomIconText from "../common/CustomIconText";

/* const fetchLocation = async (latitude, longitude) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAP_KEY}`;
  try {
    const response = await axios.get(url);
    console.log({ response });
    return response;
      if (data.status === 'OK') {
      return data.results[0].formatted_address;
    } else {
      toast.error(`Error: ${data.status}`);
      return 'Address not found';
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return "Error fetching address";
  }
}; */

const AttendanceLogs = () => {
  const location = useLocation();
  const { userId: id } = location.state;
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const {
    attendanceLogs,
    getAttendanceLogsLoader,
    attendanceLogsCount,
    allAttendanceLogs,
    getAllAttendanceLogsLoader,
  } = useSelector((state) => state.users);

  const offsetRef = useRef(0);

  const [search, setSearch] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [exportAll, setExportAll] = useState(false);
  const [dot, setDot] = useState(false);

  useEffect(() => {
    dispatch(
      actionGetAttendanceLogs({ id, offset: offsetRef.current, search })
    );
  }, [dispatch]);

  const handleNavigate = () => {
    if (latitude && longitude) {
      const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
      window.open(googleMapsUrl, "_blank");
    } else {
      console.error("Latitude or Longitude not available");
    }
  };

  const columns = [
    {
      title: "Image",
      width: 100,
      dataIndex: "image",
      key: "image",
      render: (text) => (
        <Image src={text} alt="Order Image" width={50} height={50} />
      ),
    },
    {
      title: "View on Map",
      dataIndex: "location",
      key: "location",
      width: 100,
      ellipsis: true,
      render: (text, data) => {
        const handleNavigate = () => {
          const latitude = data.latitude;
          const longitude = data.longitude;

          if (latitude && longitude) {
            const googleMapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(
              latitude
            )},${encodeURIComponent(longitude)}`;
            window.open(googleMapsUrl, "_blank");
          } else {
            console.error("Latitude or Longitude not available");
          }
        };

        if (!data.latitude || !data.longitude) {
          return <span>-</span>;
        }

        return (
          <span onClick={handleNavigate} className="cursor-pointer text-brand">
            View on Map
          </span>
        );
      },
    },
    {
      title: "CheckOut Type",
      dataIndex: "checkout_type",
      width: 250,
      key: "checkout_type",
      render: (type) => {
        return (
          (type || type != null   ) && (
            <span className={`tag-${type.replace(/\s+/g, "-").toLowerCase()}`}>
              {type != null || type ? type : "-"}
            </span>
          )
        );
      },
    },
    {
      title: "Status",
      width: 150,
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color;
        switch (status) {
          case "Check In":
            color = "blue";
            break;
          case "Check Out":
            color = "purple";
            break;
          default:
            color = "gray";
        }
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: "Timestamp",
      width: 250,
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => {
        const newText = dayjs(text);
        const formattedDate = dayjs(newText).format("DD MMM YYYY HH:mm A");
        return <span>{formattedDate}</span>;
      },
      // render: (text) => dayjs(text).format("MM MMM YYYY HH:mm A"),
    },
  ];

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      dispatch(
        actionGetAttendanceLogs({
          id,
          startDate,
          endDate,
          offset: offsetRef.current,
          search: e.target.value,
        })
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      dispatch(
        actionGetAttendanceLogs({
          id,
          startDate,
          endDate,
          offset: offsetRef.current,
          search: e.target.value,
        })
      );
    }
  };

  const handleRefresh = () => {
    setSearch(null);

    offsetRef.current = 0;
    dispatch(
      actionGetAttendanceLogs({
        id,
        offset: offsetRef.current,
      })
    );
  };

  const exportAllToExcel = async () => {
    const data =
      (await allAttendanceLogs) &&
      allAttendanceLogs.length > 0 &&
      allAttendanceLogs.map((data) => ({
        /*   User:
          data.company_user && data.company_user.full_name
            ? data.company_user.full_name
            : "-", */
        "Latitude/Longitude": data.latitude + ", " + data.latitude,
        "CheckOut Type":
          data.checkout_type || data.checkout_type != null
            ? data.checkout_type
            : "-",
        Status: data.status ? data.status : "-",
        Timestamp: `${
          data.created_at
            ? dayjs(data.created_at).format("MM MMM YYYY HH:mm A")
            : ""
        }`,
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet2");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "Attendance Logs.xlsx");
    dispatch(actionClearAllAttendanceLogs({ setExportAll }));
  };

  useEffect(() => {
    if (exportAll && allAttendanceLogs && allAttendanceLogs.length > 0) {
      exportAllToExcel();
    }
  }, [allAttendanceLogs]);

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllAttendanceLogs(id));
    setExportAll(true);
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetAttendanceLogs({
        offset: offsetRef.current,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
    // setSortedInfo(sorterParams);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleReset = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    setIsModalOpen(false);
    setDot(false);
    form.resetFields();
    dispatch(
      actionGetAttendanceLogs({ id, search, offset: offsetRef.current })
    );
  };

  return (
    <section className="main-wrapper">
      <div className="mb-4">
        <CustomIconText
          icon={<SolutionOutlined style={{ fontSize: 20 }} />}
          title="Attendance Logs"
          backArrow={true}
        />
      </div>

      <CustomInputWithButtons
        handleRefresh={handleRefresh}
        handleSearch={handleSearch}
        handleBlurAndPressEnter={handleBlurAndPressEnter}
        searchValue={search}
        isAdvanceFilter={true}
        filterValues={dot}
        onClickFilter={() => setIsModalOpen(true)}
        onClickDownloadMenu={onClickDownloadMenu}
      />
      <Table
        sortDirections={["ascend", "descend"]}
        onChange={handleSortChange}
        bordered
        rowKey={"company_user_attendance_log_id"}
        scroll={{
          x: "max-content",
          y: window.innerWidth < 1500 ? "49vh" : "61vh",
        }}
        pagination={false}
        columns={columns}
        dataSource={attendanceLogs}
        loading={getAttendanceLogsLoader}
      />

      <div className="flex justify-end mt-3">
        <Pagination
          hideOnSinglePage
          responsive={true}
          current={offsetRef.current + 1}
          className="flex items-center"
          showSizeChanger={false}
          total={attendanceLogsCount}
          onChange={(e) => {
            offsetRef.current = e - 1;
            dispatch(
              actionGetAttendanceLogs({
                id,
                offset: offsetRef.current,
                search,
              })
            );
          }}
        />
      </div>

      <Modal
        centered
        // destroyOnClose={() => form.resetFields()}
        onOk={handleClose}
        onCancel={handleClose}
        footer={false}
        width={500}
        open={isModalOpen}
      >
        <h1 className="text-[#6883FD] text-[20px]  mt-[5px] font-medium">
          Filter
        </h1>
        <Form
          form={form}
          onFinish={(values) => {
            dispatch(
              actionGetAttendanceLogs({
                id,
                offset: 0,
                search,
                startDate: startDate ? startDate : "",
                endDate: endDate ? endDate : "",
              })
            );
            setIsModalOpen(false);
            startDate || endDate ? setDot(true) : setDot(false);
          }}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="start_date"
            label="Start date"
            className="mb-[12px]"
            size="small"
          >
            <DatePicker
              format="YYYY-MM-DD"
              value={startDate}
              onChange={(date, formattedDate) => setStartDate(formattedDate)}
              placeholder="Start Date"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            name="end_date"
            label="End date"
            className="mb-[12px]"
            size="small"
          >
            <DatePicker
              format="YYYY-MM-DD"
              value={endDate}
              onChange={(date, formattedDate) => setEndDate(formattedDate)}
              placeholder="End Date"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <div className="flex justify-center mt-[40px]">
            <Form.Item className="mb-0">
              <Button
                onClick={handleClose}
                type="button"
                className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  // loading={addEventCategoryLoader}
                  type="primary"
                  className="root-btn min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  onClick={handleReset}
                  // loading={addEventCategoryLoader}
                  type="primary"
                  className="root-btn min-h-[45px] min-w-[120px]"
                >
                  Reset
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </section>
  );
};

export default AttendanceLogs;
