//TODO: Leads Masters Start Here
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/web-config";
import { toast } from "sonner";
import axios from "axios";
import { handleGetResponse } from "./commonService";
import { handleDeleteResponse } from "./commonService";
import { handleErrorMessage } from "../../components/helper/common-function";

// lead status starts here
//  Action to get All Lead Status
export const actionGetLeadStatusMaster = createAsyncThunk(
  "actionGetLeadStatusMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `leads-status?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//get single lead status
export const actionGetSingleLeadStatusMaster = createAsyncThunk(
  "actionGetSingleLeadStatusMaster",
  (id) => {
    return handleGetResponse(`leads-status/${id}`);
  }
);

//add lead status
export const actionAddLeadStatusMaster = createAsyncThunk(
  "actionAddLeadStatusMaster",
  async ({ values, handleCancel, form }) => {
    try {
      const response = await axios.post(`${BASE_URL}/leads-status`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            lead_status_master_id: data?.lead_status_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// delete lead status
export const actionDeleteLeadStatusMaster = createAsyncThunk(
  "actionDeleteLeadStatusMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`leads-status/${id}`, id, setEditingKey);
  }
);

// update lead status
export const actionEditLeadStatusMaster = createAsyncThunk(
  "actionEditLeadStatusMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/leads-status/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
// lead status end here

// lead source starts here
//get lead source master
export const actionGetLeadSourceMaster = createAsyncThunk(
  "actionGetLeadSourceMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `lead-source?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//get single lead source
export const actionGetSingleLeadSourceMaster = createAsyncThunk(
  "actionGetSingleLeadSourceMaster",
  (id) => {
    return handleGetResponse(`lead-source/${id}`);
  }
);

//add lead source
export const actionAddLeadSourceMaster = createAsyncThunk(
  "actionAddLeadSourceMaster",
  async ({ sourceForm, values, handleCancel, form, oppoForm }) => {
    try {
      const response = await axios.post(`${BASE_URL}/lead-source`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            lead_source_master_id: data?.lead_source_master_id,
          });

        oppoForm &&
          oppoForm.setFieldsValue({
            opportunity_source_id: data?.lead_source_master_id,
          });

        sourceForm &&
          sourceForm.setFieldsValue({
            lead_source_id: data?.lead_source_master_id,
          });

        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// delete lead status
export const actionDeleteLeadSourceMaster = createAsyncThunk(
  "actionDeleteLeadSourceMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`lead-source/${id}`, id, setEditingKey);
  }
);

//update lead source
export const actionEditLeadSourceMaster = createAsyncThunk(
  "actionEditLeadSourceMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/lead-source/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
// lead source ends here

// lead rating start here
export const actionGetLeadRatingMaster = createAsyncThunk(
  "actionGetLeadRatingMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `lead-rating?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//get single lead rating
export const actionGetSingleLeadRatingMaster = createAsyncThunk(
  "actionGetSingleLeadRatingMaster",
  (id) => {
    return handleGetResponse(`lead-rating/${id}`);
  }
);

// delete lead rating
export const actionDeleteLeadRatingMaster = createAsyncThunk(
  "actionDeleteLeadRatingMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`lead-rating/${id}`, id, setEditingKey);
  }
);

//add lead rating
export const actionAddLeadRatingMaster = createAsyncThunk(
  "actionAddLeadRatingMaster",
  async ({ values, handleCancel, form }) => {
    try {
      const response = await axios.post(`${BASE_URL}/lead-rating`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            lead_rating_master_id: data?.lead_rating_master_id,
          });

        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//update lead rating
export const actionEditLeadRatingMaster = createAsyncThunk(
  "actionEditLeadRatingMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/lead-rating/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// industry start here
export const actionGetIndustryMaster = createAsyncThunk(
  "actionGetIndustryMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `industry-master?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

export const actionAddIndustryMaster = createAsyncThunk(
  "actionAddIndustryMaster",
  async ({ values, form, handleCancel }) => {
    try {
      const response = await axios.post(`${BASE_URL}/industry-master`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        form &&
          form.setFieldsValue({ industry_master_id: data?.industry_master_id });
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
// industry  ends here

//TODO: Leads Masters Ends Here
export const actionGetOpportunityStageMaster = createAsyncThunk(
  "actionGetOpportunityStageMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `opportunity-stage?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

export const actionAddOpportunityStageMaster = createAsyncThunk(
  "actionAddOpportunityStageMaster",
  async ({ values, handleCancel, oppoForm }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/opportunity-stage`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        oppoForm &&
          oppoForm.setFieldsValue({
            opportunity_stage_master_id: data?.opportunity_stage_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg);
      }
    } catch (err) {
      handleErrorMessage(err);
    }
  }
);

// delete lead status
export const actionDeleteOpportunityStageMaster = createAsyncThunk(
  "actionDeleteOpportunityStageMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`opportunity-stage/${id}`, id, setEditingKey);
  }
);

export const actionGetSingleOpportunityStageMaster = createAsyncThunk(
  "actionGetSingleOpportunityStageMaster",
  (id) => {
    return handleGetResponse(`opportunity-stage/${id}`);
  }
);

export const actionEditOpportunityStageMaster = createAsyncThunk(
  "actionEditOpportunityStageMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/opportunity-stage/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Industry
// delete lead status
export const actionDeleteIndustryMaster = createAsyncThunk(
  "actionDeleteIndustryMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`industry-master/${id}`, id, setEditingKey);
  }
);

export const actionGetSingleIndustryMaster = createAsyncThunk(
  "actionGetSingleIndustryMaster",
  (id) => {
    return handleGetResponse(`industry-master/${id}`);
  }
);

export const actionEditIndustryMaster = createAsyncThunk(
  "actionEditIndustryMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/industry-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionGetBusinessPartnerTypeMaster = createAsyncThunk(
  "actionGetBusinessPartnerTypeMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `business-partner-type-master?getall=YES${search ? `&search=${search}` : ""
      }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${order ? `&order=${order}` : ""
      }`
    );
  }
);

export const actionAddBusinessPartnerTypeMaster = createAsyncThunk(
  "actionAddBusinessPartnerTypeMaster",
  async ({ values, handleCancel }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/business-partner-type-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg);
      }
    } catch (error) {
      // toast.error(err?.message);
      handleErrorMessage(error);
    }
  }
);

// delete lead status
export const actionDeleteBusinessPartnerTypeMaster = createAsyncThunk(
  "actionDeleteBusinessPartnerTypeMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(
      `business-partner-type-master/${id}`,
      id,
      setEditingKey
    );
  }
);

export const actionGetSingleBusinessPartnerTypeMaster = createAsyncThunk(
  "actionGetSingleBusinessPartnerTypeMaster",
  (id) => {
    return handleGetResponse(`business-partner-type-master/${id}`);
  }
);

export const actionEditBusinessPartnerTypeMaster = createAsyncThunk(
  "actionEditBusinessPartnerTypeMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/business-partner-type-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//get beat grade
export const actionGetBeatGradeMaster = createAsyncThunk(
  "actionGetBeatGradeMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `beat-grade-master?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//get single beat grade
export const actionGetSingleBeatGradeMaster = createAsyncThunk(
  "actionGetSingleBeatGradeMaster",
  (id) => {
    return handleGetResponse(`beat-grade-master/${id}`);
  }
);

//add beat grade master
export const actionAddBeatGradeMaster = createAsyncThunk(
  "actionAddBeatGradeMaster",
  async ({ values, handleCancel, form }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/beat-grade-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            beat_grade_id: data.beat_grade_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// delete beat grade master
export const actionDeleteBeatGradeMaster = createAsyncThunk(
  "actionDeleteBeatGradeMasterMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`beat-grade-master/${id}`, id, setEditingKey);
  }
);

//update beat grade master
export const actionEditBeatGradeMaster = createAsyncThunk(
  "actionEditBeatGradeMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/beat-grade-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//get Department
export const actionGetDepartment = createAsyncThunk(
  "actionGetDepartment",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `department-master?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//get single Department
export const actionGetSingleDepartment = createAsyncThunk(
  "actionGetSingleDepartment",
  (id) => {
    return handleGetResponse(`department-master/${id}`);
  }
);

//add Department
export const actionAddDepartment = createAsyncThunk(
  "actionAddDepartment",
  async ({ values, handleCancel, form }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/department-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            department_id: data?.department_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// delete Department
export const actionDeleteDepartment = createAsyncThunk(
  "actionDeleteDepartment",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`department-master/${id}`, id, setEditingKey);
  }
);

//update Department
export const actionEditDepartment = createAsyncThunk(
  "actionEditDepartment",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/department-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      handleErrorMessage(error);
    }
  }
);

//get all type of company
export const actionGetTypeOfCompanyMaster = createAsyncThunk(
  "actionGetTypeOfCompanyMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `type-of-company-master?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//Add Type of company
export const actionAddTypeOfCompanyMaster = createAsyncThunk(
  "actionAddTypeOfCompanyMaster",
  async ({ values, handleCancel, form }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/type-of-company-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            type_of_company: data?.type_of_company_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      // return false;
      handleErrorMessage(error);
    }
  }
);

//delete type of company
export const actionDeleteTypeOfCompanyMaster = createAsyncThunk(
  "actionDeleteTypeOfCompanyMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(
      `type-of-company-master/${id}`,
      id,
      setEditingKey
    );
  }
);

//get single type of company
export const actionGetSingleTypeOfCompanyMaster = createAsyncThunk(
  "actionGetSingleTypeOfCompanyMaster",
  (id) => {
    return handleGetResponse(`type-of-company-master/${id}`);
  }
);

//edit type of comapny
export const actionEditTypeOfCompanyMaster = createAsyncThunk(
  "actionEditTypeOfCompanyMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/type-of-company-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      handleErrorMessage(error);
    }
  }
);

//get all beat master
export const actionGetAllBeatMaster = createAsyncThunk(
  "actionGetAllBeatMaster",
  async ({
    search = "",
    orderByColumn = "",
    order = "",
    grade = "",
    zone = "",
  } = {}) => {
    return handleGetResponse(
      `beat-master?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}${grade ? `&beat_grade_id=${grade}` : ""
      }${zone ? `&zone_id=${zone}` : ""}`
    );
  }
);

// pagination - get beat master
export const actionGetBeatMaster = createAsyncThunk(
  "actionGetBeatMaster",
  async ({ offset = 0,
    search = "",
    orderByColumn = "",
    order = "",
    grade = "",
    zone = "" }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/beat-master?limit=10&offset=${offset}${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
        }${order ? `&order=${order}` : ""}${grade ? `&beat_grade_id=${grade}` : ""
        }${zone ? `&zone_id=${zone}` : ""}`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// clear all beat master
export const actionClearAllBeatMasterData = createAsyncThunk(
  "actionClearAllBeatMasterData",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

//add beat master
export const actionAddBeatMaster = createAsyncThunk(
  "actionAddBeatMaster",
  async ({ values, handleCancel, form }) => {
    try {
      const response = await axios.post(`${BASE_URL}/beat-master`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            beat_master_id: data?.beat_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      // return false;
      handleErrorMessage(error);
    }
  }
);

//delete beat master
export const actionDeleteBeatMaster = createAsyncThunk(
  "actionDeleteBeatMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`beat-master/${id}`, id, setEditingKey);
  }
);

//get single beat master
export const actionGetSingleBeatMaster = createAsyncThunk(
  "actionGetSingleBeatMaster",
  (id) => {
    return handleGetResponse(`beat-master/${id}`);
  }
);

//edit beat master
export const actionEditBeatMaster = createAsyncThunk(
  "actionEditBeatMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/beat-master/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      handleErrorMessage(error);
    }
  }
);

//zone master start here
//get all zone service start
export const actionGetAllZoneMaster = createAsyncThunk(
  "actionGetAllZoneMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `zone-master?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

// pagination - get zone master
export const actionGetZoneMaster = createAsyncThunk(
  "actionGetZoneMaster",
  async ({ offset = 0,
    search = "",
    orderByColumn = "",
    order = "" }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/zone-master?limit=10&offset=${offset}${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
        }${order ? `&order=${order}` : ""}`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// clear all zone master
export const actionClearAllZoneMasterData = createAsyncThunk(
  "actionClearAllZoneMasterData",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

//add zone service start
export const actionAddZoneMaster = createAsyncThunk(
  "actionAddZoneMaster",
  async ({ values, handleCancel, form }) => {
    try {
      const response = await axios.post(`${BASE_URL}/zone-master`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            zone_id: data?.zone_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete zone service start
export const actionDeleteZoneMaster = createAsyncThunk(
  "actionDeleteZoneMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`zone-master/${id}`, id, setEditingKey);
  }
);

//get single zone service start
export const actionGetSingleZoneMaster = createAsyncThunk(
  "actionGetSingleZoneMaster",
  (id) => {
    return handleGetResponse(`zone-master/${id}`);
  }
);

//update zone service start here
export const actionEditZoneMasterMaster = createAsyncThunk(
  "actionEditZoneMasterMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/zone-master/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      handleErrorMessage(error);
    }
  }
);

//Area master start here
//get all area service start
export const actionGetAllAreaMaster = createAsyncThunk(
  "actionGetAllAreaMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `area-master?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

// pagination - get area master
export const actionGetAreaMaster = createAsyncThunk(
  "actionGetAreaMaster",
  async ({ offset = 0,
    search = "",
    orderByColumn = "",
    order = "" }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/area-master?limit=10&offset=${offset}${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
        }${order ? `&order=${order}` : ""}`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// clear all zone master
export const actionClearAllAreaMasterData = createAsyncThunk(
  "actionClearAllAreaMasterData",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

//add area service
export const actionAddAreaMaster = createAsyncThunk(
  "actionAddAreaMaster",
  async ({ values, handleCancel, form }) => {
    try {
      const response = await axios.post(`${BASE_URL}/area-master`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            area_id: data?.area_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete area service start
export const actionDeleteAreaMaster = createAsyncThunk(
  "actionDeleteAreaMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`area-master/${id}`, id, setEditingKey);
  }
);

//get single area service
export const actionGetSingleAreaMaster = createAsyncThunk(
  "actionGetSingleAreaMaster",
  (id) => {
    return handleGetResponse(`area-master/${id}`);
  }
);

//update area start here
export const actionEditAreaMasterMaster = createAsyncThunk(
  "actionEditAreaMasterMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/area-master/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      handleErrorMessage(error);
    }
  }
);

// area ends here

// task status starts here
export const actionGetTaskStatusMaster = createAsyncThunk(
  "actionGetTaskStatusMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `task-status-master?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

export const actionAddTaskStatusMaster = createAsyncThunk(
  "actionAddTaskStatusMaster",
  async ({ values, handleCancel }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/task-status-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete area service start
export const actionDeleteTaskStatusMaster = createAsyncThunk(
  "actionDeleteTaskStatusMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`task-status-master/${id}`, id, setEditingKey);
  }
);

//get single area service
export const actionGetTaskStatusMasterDetail = createAsyncThunk(
  "actionGetTaskStatusMasterDetail",
  (id) => {
    return handleGetResponse(`task-status-master/${id}`);
  }
);

export const actionEditTaskStatusMaster = createAsyncThunk(
  "actionEditTaskStatusMaster",
  async ({ id, req, setEditingKey, setEdited }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/task-status-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// task status ends here

//material group start here
//get all material group
export const actionGetMaterialGroupMaster = createAsyncThunk(
  "actionGetMaterialGroupMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `material-group-master?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//get single material group
export const actionGetSingleMaterialGroupMaster = createAsyncThunk(
  "actionGetSingleMaterialGroupMaster",
  (id) => {
    return handleGetResponse(`material-group-master/${id}`);
  }
);

//add material group
export const actionAddMaterialGroupMaster = createAsyncThunk(
  "actionAddMaterialGroupMaster",
  async ({ values, handleCancel, form }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/material-group-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            material_group_master_id: data?.material_group_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// delete material group
export const actionDeleteMaterialGroupMaster = createAsyncThunk(
  "actionDeleteMaterialGroupMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(
      `material-group-master/${id}`,
      id,
      setEditingKey
    );
  }
);

// update material group
export const actionEditMaterialGroupMaster = createAsyncThunk(
  "actionEditMaterialGroupMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/material-group-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      handleErrorMessage(error);
    }
  }
);

//payment method  start here
//get all payment method
export const actionGetPaymentMethodMaster = createAsyncThunk(
  "actionGetPaymentMethodMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `payment-method-master?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//get single payment method
export const actionGetSinglePaymentMethodMaster = createAsyncThunk(
  "actionGetSinglePaymentMethodMaster",
  (id) => {
    return handleGetResponse(`payment-method-master/${id}`);
  }
);

//add payment method
export const actionAddPaymentMethodMaster = createAsyncThunk(
  "actionAddPaymentMethodMaster",
  async ({ values, handleCancel, form }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/payment-method-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            payment_method_master_id: data?.payment_method_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// delete payment method
export const actionDeletePaymentMethodMaster = createAsyncThunk(
  "actionDeletePaymentMethodMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(
      `payment-method-master/${id}`,
      id,
      setEditingKey
    );
  }
);

// update payment method
export const actionEditPaymentMethodMaster = createAsyncThunk(
  "actionEditPaymentMethodMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/payment-method-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//address type start here
//get all Address type
export const actionGetAddressTypeMaster = createAsyncThunk(
  "actionGetAddressTypeMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `address-type-master?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//get single  Address type
export const actionGetSingleAddressTypeMaster = createAsyncThunk(
  "actionGetSingleAddressTypeMaster",
  (id) => {
    return handleGetResponse(`address-type-master/${id}`);
  }
);

//add  Address type
export const actionAddAddressTypeMaster = createAsyncThunk(
  "actionAddAddressTypeMaster",
  async ({ values, handleCancel, form }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/address-type-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            address_type_master_id: data?.address_type_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// delete  Address type
export const actionDeleteAddressTypeMaster = createAsyncThunk(
  "actionDeleteAddressTypeMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`address-type-master/${id}`, id, setEditingKey);
  }
);

// update  Address type
export const actionEditAddressTypeMaster = createAsyncThunk(
  "actionEditAddressTypeMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/address-type-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//expense category
//get all expense category
export const actionGetExpenseCategoryMaster = createAsyncThunk(
  "actionGetExpenseCategoryMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `expense-category-type-master?getall=YES${search ? `&search=${search}` : ""
      }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${order ? `&order=${order}` : ""
      }`
    );
  }
);

//get single  expense category
export const actionGetSingleExpenseCategoryMaster = createAsyncThunk(
  "actionGetSingleExpenseCategoryMaster",
  (id) => {
    return handleGetResponse(`expense-category-type-master/${id}`);
  }
);
export const actionGetAllExpenseCategoryMaster = createAsyncThunk(
  "actionGetAllExpenseCategoryMaster",
  (id) => {
    return handleGetResponse(`expense-category-type-master?getall=YES`);
  }
);
export const actionClearExpenseCategoryAllData = createAsyncThunk(
  "actionClearExpenseCategoryAllData",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

//add  expense category
export const actionAddExpenseCategoryMaster = createAsyncThunk(
  "actionAddExpenseCategoryMaster",
  async ({ values, handleCancel, form }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/expense-category-type-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            expense_category_type_master_id:
              data?.expense_category_type_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// delete expense category
export const actionDeleteExpenseCategoryMaster = createAsyncThunk(
  "actionDeleteExpenseCategoryMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(
      `expense-category-type-master/${id}`,
      id,
      setEditingKey
    );
  }
);

// update expense category
export const actionEditExpenseCategoryMaster = createAsyncThunk(
  "actionEditExpenseCategoryMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/expense-category-type-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//unit conversion
//get all unit conversion
export const actionGetUnitConversionMaster = createAsyncThunk(
  "actionGetUnitConversionMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `unit-conversion-master?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//get single unit conversion
export const actionGetSingleUnitConversionMaster = createAsyncThunk(
  "actionGetSingleUnitConversionMaster",
  async (id) => {
    return handleGetResponse(`unit-conversion-master/${id}`);
  }
);

//add unit conversion
export const actionAddUnitConversionMaster = createAsyncThunk(
  "actionAddUnitConversionMaster",
  async ({ values, handleCancel, form }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/unit-conversion-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);

        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// delete unit conversion
export const actionDeleteUnitConversionMaster = createAsyncThunk(
  "actionDeleteUnitConversionMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(
      `unit-conversion-master/${id}`,
      id,
      setEditingKey
    );
  }
);

//upadate unit conversion
export const actionEditUnitConversionMaster = createAsyncThunk(
  "actionEditUnitConversionMaster",
  async ({ id, req, handleCancel }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/unit-conversion-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//exchange curency
// get all exchange currency start here
export const actionGetAllExchageCurrencyMaster = createAsyncThunk(
  "actionGetAllExchageCurrencyMaster",
  async ({
    search = "",
    is_dropdown = "",
    orderByColumn = "",
    order = "",
  } = {}) => {
    return handleGetResponse(
      `currency-exchange?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}${is_dropdown ? `&is_dropdown=${is_dropdown}` : ""
      }`
    );
  }
);

export const actionGetExchageCurrencyMaster = createAsyncThunk(
  "actionGetExchageCurrencyMaster",
  async ({
    search = "",
    orderByColumn = "",
    order = "",
    offset = "",
    is_dropdown = "",
  } = {}) => {
    return handleGetResponse(
      `currency-exchange?limit=10&offset=${offset}${search ? `&search=${search}` : ""
      }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${order ? `&order=${order}` : ""
      }${is_dropdown ? `&is_dropdown=${is_dropdown}` : ""}`
    );
  }
);

export const actionClearExchangeCurrencyAllData = createAsyncThunk(
  "actionClearExchangeCurrencyAllData",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);
//sync currency exchange
export const actionSyncExchangeCurrencyMaster = createAsyncThunk(
  "actionSyncExchangeCurrencyMaster",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/currency-exchange/sync`);
      const { status, message: customMsg, data } = response.data;
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//get single exchange curency
export const actionGetSingleExchangeCurrencyMaster = createAsyncThunk(
  "actionGetSingleExchangeCurrencyMaster",
  async (id) => {
    return handleGetResponse(`currency-exchange/${id}`);
  }
);

// delete exchange currency
export const actionDeleteExchangeCurrencyMaster = createAsyncThunk(
  "actionDeleteExchangeCurrencyMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`currency-exchange/${id}`, id, setEditingKey);
  }
);

//upadate exchange currency
export const actionEditExchangeCurrencyMaster = createAsyncThunk(
  "actionEditExchangeCurrencyMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/currency-exchange/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// deposit masters starts here
export const actionGetDepositsMaster = createAsyncThunk(
  "actionGetDepositsMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `deposit-master?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

export const actionAddDepositMaster = createAsyncThunk(
  "actionAddDepositMaster",
  async ({ values, handleCancel, form }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/deposit-master`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({ deposit_master_id: data?.deposit_master_id });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//get single deposite master
export const actionGetSingleDepositeMaster = createAsyncThunk(
  "actionGetSingleDepositeMaster",
  async (id) => {
    return handleGetResponse(`deposit-master/${id}`);
  }
);

// delete deposite master
export const actionDeleteDepositeMaster = createAsyncThunk(
  "actionDeleteDepositeMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`deposit-master/${id}`, id, setEditingKey);
  }
);

//upadate deposite master
export const actionEditDepositeMaster = createAsyncThunk(
  "actionEditDepositeMaster",
  async ({ id, req, handleCancel, setEditingKey }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/deposit-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// deposit masters ends  here

//Plant code start here
//get all plat code start here
export const actionGetAllPlantCodeMaster = createAsyncThunk(
  "actionGetAllPlantCodeMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `plant-code-master?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

export const actionGetPlantCodeMaster = createAsyncThunk(
  "actionGetPlantCodeMaster",
  async ({ offset = 0, search = "", orderByColumn = "", order = "" }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/plant-code-master?limit=10&offset=${offset}${search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionClearPlantCodeAllData = createAsyncThunk(
  "actionClearPlantCodeAllData",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

//get single plant code
export const actionGetSinglePlantCodeMaster = createAsyncThunk(
  "actionGetSinglePlantCodeMaster",
  async (id) => {
    return handleGetResponse(`plant-code-master/${id}`);
  }
);

//add unit conversion
export const actionAddPlantCodeMaster = createAsyncThunk(
  "actionAddPlantCodeMaster",
  async ({ values, handleCancel, form }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/plant-code-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);

        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete plant code
export const actionDeletePlantCodeMaster = createAsyncThunk(
  "actionDeletePlantCodeMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`plant-code-master/${id}`, id, setEditingKey);
  }
);

//upadate plant code
export const actionEditPlantCodeMaster = createAsyncThunk(
  "actionEditPlantCodeMaster",
  async ({ id, req, handleCancel }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/plant-code-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
