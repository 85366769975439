import React from "react";
import { Modal, Button, Form } from "antd";

const DeleteModal = ({
  isVisible,
  onCancel,
  onConfirm,
  loading = false,
  title = "Are you sure you want to remove this item?",
  description = "This action cannot be undone.",
  confirmText = "Delete",
  cancelText = "Cancel",
  width = 600,
  ...props
}) => {
  return (
    <Modal
      {...props}
      centered
      onOk={onCancel}
      onCancel={onCancel}
      afterClose={onCancel}
      footer={null}
      destroyOnClose={onCancel}
      width={width}
      open={isVisible}
      className="delete-modal"
    >
      <div>
        <h1 className="text-[#000000;] text-[20px] font-popinsMedium mt-2">
          {title}
        </h1>
        <p className="text-[15px] text-[#7A7A7A] pt-3">{description}</p>
        <div className="flex items-center pt-8 justify-end w-full gap-x-3">
          <Button
            onClick={onCancel}
            type="button"
            className="gray-button gray-hover text-white hover:text-white min-h-[45px] min-w-[120px]"
          >
            {cancelText}
          </Button>

          <Button
            loading={loading}
            type="primary"
            className="danger-btn min-h-[45px] min-w-[120px]"
            onClick={onConfirm}
          >
            {confirmText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
