import { createSlice } from "@reduxjs/toolkit";
import {} from "../services/locationService";
import {
  actionAddRole,
  actionAddRoleProfile,
  actionAssignRoleProfilePermission,
  actionDeleteRole,
  actionDeleteRoleProfile,
  actionGetAllRoleProfile,
  actionGetRoleHierachy,
  actionGetRoleProfile,
  actionGetRoles,
  actionGetSingleRole,
  actionGetSingleRoleProfile,
  actionUpdateRole,
  actionUpdateRoleProfile,
  actionGetCompanyDetail,
  actionUpdateCompanyDetail,
  actionGetMyCompanyHours,
  actionUpdateBusinessHours,
} from "../services/settingService";

const settingSlice = createSlice({
  name: "setting",
  initialState: {
    addRoleLoader: false,
    updateRoleLoader: false,
    deleteRoleLoader: false,
    getRolesLoader: false,
    getRolesHierachyLoader: false,
    getSingleRoleLoader: false,
    addRoleProfileLoader: false,
    updateRoleProfileLoader: false,
    getSingleRoleProfileLoader: false,
    deleteRoleProfileLoader: false,
    getAllRoleProfileLoader: false,
    assignRoleProfilePermissionLoader: false,
    getCompanyDetailLoader: false,
    updateCompanyDetailLoader: false,
    roles: "",
    roleAllProfile: "",
    getRoleProfileLoader: false,
    getCompanyHoursLoader: false,
    companyDetail: "",
    updateBusinessHoursLoader: false,
    roleProfile: "",
    roleProfileCounts: 0,
    roleHierachy: "",
    roleDetail: "",
    roleProfileDetail: "",
    myCompanyHoursDetail: "",
  },
  reducers: {},
  extraReducers: (buider) => {
    buider
      // get Role State
      .addCase(actionGetRoleHierachy.pending, (state) => {
        state.getRolesHierachyLoader = true;
      })
      .addCase(actionGetRoleHierachy.fulfilled, (state, action) => {
        state.roles = action.payload.data;
        state.roleHierachy = action.payload.data;
        state.getRolesHierachyLoader = false;
      })
      .addCase(actionGetRoleHierachy.rejected, (state) => {
        state.getRolesHierachyLoader = false;
      })

      .addCase(actionGetRoles.pending, (state) => {
        state.getRolesHierachyLoader = true;
      })
      .addCase(actionGetRoles.fulfilled, (state, action) => {
        state.roles = action.payload.data;
        state.getRolesLoader = false;
      })
      .addCase(actionGetRoles.rejected, (state) => {
        state.getRolesLoader = false;
      })

      .addCase(actionGetSingleRole.pending, (state) => {
        state.getSingleRoleLoader = true;
      })
      .addCase(actionGetSingleRole.fulfilled, (state, action) => {
        state.roleDetail = action.payload.data;
        state.getSingleRoleLoader = false;
      })
      .addCase(actionGetSingleRole.rejected, (state) => {
        state.getSingleRoleLoader = false;
      })

      // add Role State
      .addCase(actionAddRole.pending, (state) => {
        state.addRoleLoader = true;
      })
      .addCase(actionAddRole.fulfilled, (state, action) => {
        state.addRoleLoader = false;
      })
      .addCase(actionAddRole.rejected, (state) => {
        state.addRoleLoader = false;
      })

      // update Role State
      .addCase(actionUpdateRole.pending, (state) => {
        state.updateRoleLoader = true;
      })
      .addCase(actionUpdateRole.fulfilled, (state, action) => {
        state.updateRoleLoader = false;
      })
      .addCase(actionUpdateRole.rejected, (state) => {
        state.updateRoleLoader = false;
      })

      // delete Role State
      .addCase(actionDeleteRole.pending, (state) => {
        state.deleteRoleLoader = true;
      })
      .addCase(actionDeleteRole.fulfilled, (state, action) => {
        state.deleteRoleLoader = false;
      })
      .addCase(actionDeleteRole.rejected, (state) => {
        state.deleteRoleLoader = false;
      })

      // get Role profile State
      .addCase(actionGetRoleProfile.pending, (state) => {
        state.getRoleProfileLoader = true;
      })
      .addCase(actionGetRoleProfile.fulfilled, (state, action) => {
        state.roleProfile = action.payload.data;
        state.roleProfileCounts = action.payload.total_records;
        state.getRoleProfileLoader = false;
      })
      .addCase(actionGetRoleProfile.rejected, (state) => {
        state.getRoleProfileLoader = false;
      })
      //Get all Role Profile Data
      .addCase(actionGetAllRoleProfile.pending, (state) => {
        state.getAllRoleProfileLoader = true;
      })
      .addCase(actionGetAllRoleProfile.fulfilled, (state, action) => {
        state.roleAllProfile = action?.payload?.data;
        state.getAllRoleProfileLoader = false;
      })
      .addCase(actionGetAllRoleProfile.rejected, (state) => {
        state.getAllRoleProfileLoader = false;
      })
      // get Single Role profile State
      .addCase(actionGetSingleRoleProfile.pending, (state) => {
        state.getSingleRoleProfileLoader = true;
      })
      .addCase(actionGetSingleRoleProfile.fulfilled, (state, action) => {
        state.roleProfileDetail = action.payload.data;
        state.getSingleRoleProfileLoader = false;
      })
      .addCase(actionGetSingleRoleProfile.rejected, (state) => {
        state.getSingleRoleProfileLoader = false;
      })

      // Add Role profile State
      .addCase(actionAddRoleProfile.pending, (state) => {
        state.addRoleProfileLoader = true;
      })
      .addCase(actionAddRoleProfile.fulfilled, (state, action) => {
        state.addRoleProfileLoader = false;
      })
      .addCase(actionAddRoleProfile.rejected, (state) => {
        state.addRoleProfileLoader = false;
      })

      // Delete Role profile State
      .addCase(actionDeleteRoleProfile.pending, (state) => {
        state.deleteRoleProfileLoader = true;
      })
      .addCase(actionDeleteRoleProfile.fulfilled, (state, action) => {
        state.deleteRoleProfileLoader = false;
        state.roleProfile = state.roleProfile.filter(
          (r) => r.id !== action.meta.arg.req.id
        );
        state.roleAllProfile = state.roleAllProfile.filter(
          (r) => r.id !== action.meta.arg.req.id
        );
      })
      .addCase(actionDeleteRoleProfile.rejected, (state) => {
        state.deleteRoleProfileLoader = false;
      })

      // update Role State
      .addCase(actionUpdateRoleProfile.pending, (state) => {
        state.updateRoleProfileLoader = true;
      })
      .addCase(actionUpdateRoleProfile.fulfilled, (state, action) => {
        state.updateRoleProfileLoader = false;
      })
      .addCase(actionUpdateRoleProfile.rejected, (state) => {
        state.updateRoleProfileLoader = false;
      })

      // Assign Role Permission
      .addCase(actionAssignRoleProfilePermission.pending, (state) => {
        state.assignRoleProfilePermissionLoader = true;
      })
      .addCase(actionAssignRoleProfilePermission.fulfilled, (state, action) => {
        state.assignRoleProfilePermissionLoader = false;
      })
      .addCase(actionAssignRoleProfilePermission.rejected, (state) => {
        state.assignRoleProfilePermissionLoader = false;
      })

      // actionGetCompanyDetail State
      .addCase(actionGetCompanyDetail.pending, (state) => {
        state.getCompanyDetailLoader = true;
      })
      .addCase(actionGetCompanyDetail.fulfilled, (state, action) => {
        state.companyDetail = action.payload.data;
        state.getCompanyDetailLoader = false;
      })
      .addCase(actionGetCompanyDetail.rejected, (state) => {
        state.getCompanyDetailLoader = false;
      })

      //action update company details
      .addCase(actionUpdateCompanyDetail.pending, (state) => {
        state.updateCompanyDetailLoader = true;
      })
      .addCase(actionUpdateCompanyDetail.fulfilled, (state, action) => {
        state.companyDetail = action.payload;
        state.updateCompanyDetailLoader = false;
      })
      .addCase(actionUpdateCompanyDetail.rejected, (state) => {
        state.updateCompanyDetailLoader = false;
      })

      //action update company details
      .addCase(actionUpdateBusinessHours.pending, (state) => {
        state.updateBusinessHoursLoader = true;
      })
      .addCase(actionUpdateBusinessHours.fulfilled, (state, action) => {
        state.myCompanyHoursDetail = action.payload;
        state.updateBusinessHoursLoader = false;
      })
      .addCase(actionUpdateBusinessHours.rejected, (state, action) => {
        state.updateBusinessHoursLoader = false;
      })

      //get company hours start here
      .addCase(actionGetMyCompanyHours.pending, (state) => {
        state.getCompanyHoursLoader = true;
      })
      .addCase(actionGetMyCompanyHours.fulfilled, (state, action) => {
        state.myCompanyHoursDetail = action.payload.data;
        state.getCompanyHoursLoader = false;
      })
      .addCase(actionGetMyCompanyHours.rejected, (state) => {
        state.getCompanyHoursLoader = false;
      })



      
  },
});
export default settingSlice.reducer;
