// ReusableModal.js

import React from "react";
import { Modal } from "antd";

const CommonModal = ({
  open,
  width,
  title,
  centered,
  onCancel,
  onOk,
  children,
  handleReset,
  loading = false,
}) => {
  return (
    <Modal
      loading={loading}
      destroyOnClose={handleReset}
      className="common-modal"
      width={width}
      title={title}
      centered={centered}
      footer={false}
      open={open}
      onCancel={onCancel}
      onOk={onOk}
    >
      {children}
    </Modal>
  );
};

export default CommonModal;
