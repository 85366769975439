import { createSlice } from "@reduxjs/toolkit";
import { actionDownloadFile } from "../services/commonService";

const commonSlice = createSlice({
  name: "common",
  initialState: {
    downloadFileLoader: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      //Download File Slices
      .addCase(actionDownloadFile.pending, (state, action) => {
        const uploadId = action.meta.arg.upload_id
          ? action.meta.arg.upload_id
          : action.meta.arg;
        state.downloadFileLoader[uploadId] = true;
      })
      .addCase(actionDownloadFile.fulfilled, (state, action) => {
        const uploadId = action.meta.arg.upload_id || undefined;
        state.downloadFileLoader[uploadId] = false;
      })
      .addCase(actionDownloadFile.rejected, (state, action) => {
        const uploadId = action.meta.arg.upload_id || undefined;
        state.downloadFileLoader[uploadId] = false;
      });
  },
});
export default commonSlice.reducer;
