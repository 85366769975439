import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Row,
  Select,
  Spin,
  Upload,
  Popover,
  Tooltip,
  Avatar,
  Switch,
} from "antd";
import { toast } from "sonner";
import React, { useEffect, useState } from "react";
import CustomIconText from "../../common/CustomIconText";
import contact from "../../../assets/img/contact.svg";
import plusSvg from "../../../assets/img/plus.svg";
import gallery from "../../../assets/img/gallery.svg";
import attechment from "../../../assets/img/attechment.svg";
import editPrimaryWhite from "../../../assets/img/editPrimaryWhite.svg";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { TbFileUpload } from "react-icons/tb";
import { BASE_URL_UPLOAD } from "../../../config/web-config";
const { Dragger } = Upload;
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetCountries,
  actionGetStates,
} from "../../../store/services/locationService";
import ImgCrop from "antd-img-crop";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { actionGetCompanyAllUsers } from "../../../store/services/usersService";
import {
  actionAddBusinessPartner,
  actionGetAllBusinessPartner,
} from "../../../store/services/masterDataService";
import {
  actionAddDepartment,
  actionAddLeadSourceMaster,
  actionGetBusinessPartnerTypeMaster,
  actionGetDepartment,
  actionGetLeadSourceMaster,
} from "../../../store/services/generalMasterService";
import { extractFilename } from "../../helper/common-function";
import {
  actionAddContact,
  actionGetContactDetail,
  actionUpdateContact,
} from "../../../store/services/contactService";
import { actionGetProductCategoryMaster } from "../../../store/services/productMasterService";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file, size) => {
  const fileSizeMB = file.size / (1024 * 1024);

  // Check if file size is within the limit
  if (fileSizeMB > size) {
    toast.error(`File size should be less than ${size} MB.`);
    return false; // Prevent upload
  }

  return true;
};

const CreateContact = () => {
  const [form] = Form.useForm();
  const [addBusinessPartnerForm] = Form.useForm();
  const [addLeadSourceForm] = Form.useForm();
  const [addDepartmentForm] = Form.useForm();
  const location = useLocation();
  const { contactId } = location.state || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addBusinessPartnerPopoverOpen, setAddBusinessPartnerPopoverOpen] =
    useState(false);
  const [addLeadSourcePopoverOpen, setAddLeadSourcePopoverOpen] =
    useState(false);
  const [addDepartmentPopoverOpen, setAddDepartmentPopoverOpen] =
    useState(false);
  const [countrySelected, setCountrySelected] = useState(false);
  const [contactProfilePicUrl, setContactProfilePicUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [convertToLead, setConvertToLead] = useState(false);
  const [businessCard, setBusinessCard] = useState([]);

  const { countries, states, cities, getCountryLoader, statesLoader } =
    useSelector((state) => state.location);
  const { getCompanyAllUserLoader, companyAllUsers } = useSelector(
    (state) => state.users
  );
  const {
    addBusinessPartnerLoader,
    allBusinessPartnerData,
    getAllBusinessPartnerLoader,
  } = useSelector((state) => state.masterData);
  const {
    businessPartnerTypeData,
    getBusinessPartnerTypeLoader,
    leadSourceData,
    getLeadSourceLoader,
    addLeadSourceLoader,
    addDepartmentLoader,
    departmentData,
    getDepartmentLoader,
  } = useSelector((state) => state.generalMaster);
  const {
    addContactLoader,
    updateContactLoader,
    getContactDetailLoader,
    contactDetailData,
  } = useSelector((state) => state.contact);
  const { productCategoryData, getProductCategoryLoader } = useSelector(
    (state) => state.productMaster
  );

  useEffect(() => {
    dispatch(actionGetCountries());
    dispatch(actionGetCompanyAllUsers());
    dispatch(actionGetAllBusinessPartner());
    dispatch(actionGetLeadSourceMaster());
    dispatch(actionGetDepartment());
    dispatch(
      actionGetProductCategoryMaster({
        orderByColumn: "category_name",
        order: "ASC",
      })
    );
    if (contactId) {
      dispatch(actionGetContactDetail(contactId));
    }
  }, [dispatch]);

  //set data for update case
  useEffect(() => {
    if (contactId && contactDetailData) {
      form.setFieldsValue({
        first_name: contactDetailData.first_name,
        last_name: contactDetailData.last_name,
        company_name: contactDetailData.company_name,
        contact_owner_id: contactDetailData.contact_owner?.id,
        business_partner_id:
          contactDetailData.business_partner?.business_partner_id,
        email: contactDetailData.email,
        phone: contactDetailData.phone,
        alternative_phone: contactDetailData.alternative_phone,
        secondary_phone: contactDetailData.secondary_phone,
        assistant: contactDetailData.assistant,
        lead_source_id: contactDetailData?.lead_source?.lead_source_master_id,
        job_title: contactDetailData.job_title,
        website: contactDetailData.website,
        department_id: contactDetailData.department?.department_master_id,
        home_phone: contactDetailData.home_phone,
        fax: contactDetailData.fax,
        date_of_birth:
          contactDetailData.date_of_birth &&
          dayjs(contactDetailData.date_of_birth, "YYYY-MM-DD"),
        asst_phone: contactDetailData.asst_phone,
        skype_id: contactDetailData.skype_id,
        secondary_email: contactDetailData.secondary_email,
        twitter: contactDetailData.twitter,
        // reporting_to_id: contactDetailData.reporting_to?.id,
        country_id: contactDetailData.country?.id,
        state_id: contactDetailData.state?.id,
        city: contactDetailData.city,
        street: contactDetailData.street,
        zip_code: contactDetailData.zip_code,
        description: contactDetailData.description,
        remark: contactDetailData.remark,
        // product_category: contactDetailData?.product_category?.product_category_master_id,
        product_category: contactDetailData.product_category.map(
          (item) => item.product_category.product_category_master_id
        ),
        convert_to_lead: !contactDetailData?.convert_to_lead
          ? convertToLead
          : false,
      });
      if (contactDetailData.country?.id) {
        setCountrySelected(true);
      }
      setContactProfilePicUrl(contactDetailData?.profile_pic);
      const ImagesArray = [];

      contactDetailData.business_card &&
        contactDetailData.business_card &&
        contactDetailData.business_card.forEach((data, index) => {
          ImagesArray.push({
            id: index,
            url: data,
            name: extractFilename(data),
          });
        });

      setBusinessCard(ImagesArray);
    }
  }, [contactId, contactDetailData, form]);

  useEffect(() => {
    if (contactDetailData?.country?.id) {
      dispatch(actionGetStates(contactDetailData?.country?.id));
    }
  }, [contactDetailData]);

  const handleChangeContactProfilePic = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setContactProfilePicUrl(info.file.response.url);
        toast.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      setLoading(false);
      toast.error(`${info.file.name} file upload failed.`);
    }
  };

  // business card props
  const businessCardProps = {
    name: "file",
    multiple: true,
    action: BASE_URL_UPLOAD,
    listType: "picture",
    showUploadList: true,
    fileList: businessCard,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
    },
    onChange(info) {
      const { status, fileList } = info;
      if (status !== "uploading") {
        setBusinessCard(fileList);
      }
      if (status === "done") {
        toast.success(`File uploaded successfully.`);
      } else if (status === "error") {
        toast.error(`File upload failed.`);
      }
    },
    onDrop(e) { },
  };

  // add Business partner
  const handleCancelAddBusinessPartnerPopover = () => {
    setAddBusinessPartnerPopoverOpen(false);
    addBusinessPartnerForm.resetFields();
  };
  const showAddBusinessPartnerPopoverOpen = () => {
    setAddBusinessPartnerPopoverOpen(true);
    dispatch(actionGetBusinessPartnerTypeMaster());
  };

  // business partner data
  const businessPartner =
    allBusinessPartnerData && allBusinessPartnerData.length > 0
      ? allBusinessPartnerData.map((data) => ({
        key: data.business_partner_id,
        label: data.business_partner_name,
        value: data.business_partner_id,
      }))
      : [];

  // business partner type data
  const businessPartnerType =
    businessPartnerTypeData && businessPartnerTypeData.length > 0
      ? businessPartnerTypeData.map((data) => ({
        key: data.business_partner_type_master_id,
        label: data.business_partner_type_name,
        value: data.business_partner_type_master_id,
      }))
      : [];

  const addBusinessPartnerPopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          form={addBusinessPartnerForm}
          onFinish={(values) =>
            dispatch(
              actionAddBusinessPartner({
                req: values,
                form,
                handleCancel: handleCancelAddBusinessPartnerPopover,
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="business_partner_name"
            label="Business Partner Name"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input business partner name!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Business Partner Name"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>
          <Form.Item
            name="business_partner_type_master_id"
            label="Business Partner Type"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please select business partner type!",
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              // mode="multiple"
              placeholder="Select Business Partner Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={businessPartnerType}
              disabled={getBusinessPartnerTypeLoader}
              loading={getBusinessPartnerTypeLoader}
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={() => handleCancelAddBusinessPartnerPopover()}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addBusinessPartnerLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  //add lead source
  const handleCancelAddLeadSourcePopover = () => {
    setAddLeadSourcePopoverOpen(false);
    addLeadSourceForm.resetFields();
  };
  const showAddLeadSourcePopoverOpen = () => {
    setAddLeadSourcePopoverOpen(true);
  };
  const addLeadSourcePopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          form={addLeadSourceForm}
          onFinish={(values) =>
            dispatch(
              actionAddLeadSourceMaster({
                values,
                sourceForm: form,
                handleCancel: handleCancelAddLeadSourcePopover,
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="lead_source"
            label="Lead Source"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input lead source!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Lead Source"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={() => handleCancelAddLeadSourcePopover()}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addLeadSourceLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  //add Department
  const handleCanceladdDepartmentPopover = () => {
    setAddDepartmentPopoverOpen(false);
    addDepartmentForm.resetFields();
  };
  const showAddDepartmentPopoverOpen = () => {
    setAddDepartmentPopoverOpen(true);
  };
  const addDepartmentPopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          form={addDepartmentForm}
          onFinish={(values) =>
            dispatch(
              actionAddDepartment({
                values,
                form,
                handleCancel: handleCanceladdDepartmentPopover,
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="department_name"
            label="Department"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input department!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Department"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={() => handleCanceladdDepartmentPopover()}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addDepartmentLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  // Contact owner
  const contactOwners =
    companyAllUsers && companyAllUsers.length > 0
      ? companyAllUsers.map((user) => {
        const fullName = `${user.first_name ? user.first_name : ""} ${user.last_name ? user.last_name : ""
          }`.trim();

        return {
          key: user.id,
          label: (
            <>
              <Avatar
                src={user.profile_pic}
                style={{
                  verticalAlign: "middle",
                }}
                className="mr-2"
              />
              {fullName}
            </>
          ),
          value: user.id,
          fullName, // Store the full name as a string
        };
      })
      : [];

  // source data
  const sourceData =
    leadSourceData && leadSourceData.length > 0
      ? leadSourceData.map((lead) => ({
        key: lead.lead_source_master_id,
        label: lead.lead_source,
        value: lead.lead_source_master_id,
      }))
      : [];

  // department data
  const departmentsData =
    departmentData && departmentData.length > 0
      ? departmentData.map((department) => ({
        key: department.department_master_id,
        label: department.department_name,
        value: department.department_master_id,
      }))
      : [];

  // state and country

  const handleGetState = (val, data) => {
    dispatch(actionGetStates(data.value));
    setCountrySelected(true);
    form.setFieldsValue({
      state_id: null,
    });
  };

  const handleClearState = () => {
    setCountrySelected(false);
    form.setFieldsValue({
      state_id: null,
    });
  };

  const country =
    (countries &&
      countries.length &&
      countries?.map(({ name, iso2, id, ...data }) => ({
        ...data,
        label: name,
        value: id,
        // country_code: iso2,
        id: id,
      }))) ||
    [];

  const state =
    (states &&
      states.length &&
      states?.map(({ name, state_code, id, ...data }) => ({
        ...data,
        label: name,
        value: id,
        // state_code,
      }))) ||
    [];

  //report to data
  const reportToData =
    companyAllUsers && companyAllUsers.length > 0
      ? companyAllUsers.map((user) => {
        const fullName = `${user.first_name ? user.first_name : ""} ${user.last_name ? user.last_name : ""
          }`.trim();

        return {
          key: user.id,
          label: (
            <>
              <Avatar
                src={user.profile_pic}
                style={{
                  verticalAlign: "middle",
                }}
                className="mr-2"
              />
              {fullName}
            </>
          ),
          value: user.id,
          fullName, // Store the full name as a string
        };
      })
      : [];

  // product category of Inquiry for
  const productCategories =
    productCategoryData && productCategoryData.length > 0
      ? productCategoryData.map((productCategory) => ({
        key: productCategory.product_category_master_id,
        label: productCategory.category_name,
        value: productCategory.product_category_master_id,
      }))
      : [];

  const handleAddOrUpdateContact = (values) => {
    const imagesArr = [];

    businessCard &&
      businessCard.length > 0 &&
      businessCard?.forEach((d) => {
        if (d.response) {
          const newUrl = d.response.filename;
          imagesArr.push(newUrl);
        } else if (d.url) {
          const parts = extractFilename(d.url);
          imagesArr.push(parts);
        } else {
          imagesArr.push(d.path);
        }
      });

    const data = {
      ...values,
      profile_pic: contactProfilePicUrl
        ? extractFilename(contactProfilePicUrl)
        : null,
      date_of_birth: values.date_of_birth
        ? dayjs(values.date_of_birth).format("YYYY-MM-DD")
        : "",
      convert_to_lead: contactId
        ? !contactDetailData?.convert_to_lead
          ? convertToLead
          : false
        : convertToLead,
      contact_owner_id: values.contact_owner_id
        ? values.contact_owner_id
        : null,
      business_partner_id: values.business_partner_id
        ? values.business_partner_id
        : null,
      lead_source_id: values.lead_source_id ? values.lead_source_id : null,
      department_id: values.department_id ? values.department_id : null,
      country_id: values.country_id ? values.country_id : null,
      state_id: values.state_id ? values.state_id : null,
      product_category: values.product_category ? values.product_category : "",
      business_card: imagesArr,
    };

    const req = {
      ...Object.fromEntries(
        Object.entries(data).filter(
          ([_, v]) =>
            v !== undefined &&
            v !== null &&
            !(typeof v === "string" && v.trim() === "") &&
            (!Array.isArray(v) || v.length > 0)
        )
      ),
    };

    contactId
      ? dispatch(actionUpdateContact({ id: contactId, req: data, navigate }))
      : dispatch(actionAddContact({ req: data, navigate }));
  };

  return (
    <Spin spinning={getContactDetailLoader}>
      <section className="main-wrapper">
        <CustomIconText
          image={contact}
          title={contactId ? "Update Contact" : "Create Contact"}
          backArrow={true}
        />
        <Form
          scrollToFirstError={true}
          form={form}
          layout="vertical"
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={handleAddOrUpdateContact}
          autoComplete="off"
        >
          <Card className="main-card mt-5 w-full">
            <h1 className="lg:text-lg text-base font-popinsRegular text-brand mb-2">
              Contact Information
            </h1>
            <Row gutter={24}>
              <Col span={24} align="middle">
                <Form.Item
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      required: false,
                      message: "Please enter profile pic!",
                    },
                  ]}
                >
                  {contactProfilePicUrl ? (
                    <div className="relative border-brand  h-[102px]  w-[102px] rounded-[6px] mb-2">
                      <img
                        src={contactProfilePicUrl}
                        alt="avatar"
                        className="h-full w-full rounded-[6px]"
                      />

                      <ImgCrop rotationSlider aspect={1 / 1} fillColor="white">
                        <Upload
                          id=""
                          accept=".png,.jpg,.jpeg,.pdf,.doc"
                          name="file"
                          listType="picture-card"
                          className="border-0 absolute main-upload bottom-0 right-0 avatar-uploader"
                          showUploadList={false}
                          action={BASE_URL_UPLOAD}
                          openFileDialogOnClick={true}
                          headers={{
                            Authorization:
                              "Bearer " + localStorage.getItem("crmWebToken"),
                          }}
                          onChange={handleChangeContactProfilePic}
                        >
                          <div className=" px-1.5 py-1 border-0 absolute right-0 bottom-0 bg-brand rounded-tl-[6px] rounded-br-[6px] cursor-pointer flex">
                            <Image
                              width={15}
                              height={15}
                              src={editPrimaryWhite}
                              className="rounded-lg"
                              preview={false}
                            />
                          </div>
                        </Upload>
                      </ImgCrop>
                    </div>
                  ) : (
                    <ImgCrop aspect={1 / 1} rotationSlider fillColor="white">
                      <Upload
                        name="file"
                        listType="picture-card"
                        className="avatar-uploader mb-2"
                        showUploadList={false}
                        action={BASE_URL_UPLOAD}
                        openFileDialogOnClick={true}
                        headers={{
                          Authorization:
                            "Bearer " + localStorage.getItem("crmWebToken"),
                        }}
                        onChange={handleChangeContactProfilePic}
                      >
                        <button
                          style={{
                            border: 0,
                            background: "none",
                          }}
                          type="button"
                        >
                          {loading ? <LoadingOutlined /> : <PlusOutlined />}
                          <div
                            style={{
                              marginTop: 8,
                            }}
                          >
                            Profile
                          </div>
                        </button>
                      </Upload>
                    </ImgCrop>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="First Name"
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input first name!",
                    },
                  ]}
                >
                  <Input placeholder="Enter First Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Last Name"
                  name="last_name"
                  rules={[
                    {
                      required: false,
                      message: "Please input last name!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Last Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: false,
                      message: "Please input valid email!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Email Address" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Secondary Email"
                  name="secondary_email"
                  rules={[
                    {
                      type: "email",
                      required: false,
                      message: "Please input valid email!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Secondary Email Address" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="phone"
                  normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                  label="Phone"
                  size="small"
                  rules={[
                    {
                      required: true,
                      message: "Please enter phone number!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && value.length > 15) {
                          return Promise.reject(
                            "Please enter a valid  Number!"
                          );
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    // rows={3}
                    style={{ background: "white" }}
                    placeholder="Enter Phone Number"
                    className="indent-5 begin-input bg-white pt-2 focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="secondary_phone"
                  normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                  label="Secondary Phone"
                  size="small"
                  rules={[
                    {
                      required: false,
                      message: "Please enter Mobile number!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && value.length > 15) {
                          return Promise.reject(
                            "Please enter a valid  Number!"
                          );
                        }

                        // You can add additional conditions if needed
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    // rows={3}
                    style={{ background: "white" }}
                    placeholder="Enter Other Phone Number"
                    className="indent-5 begin-input bg-white pt-2 focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="alternative_phone"
                  normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                  label="Alternative Phone"
                  size="small"
                  rules={[
                    {
                      required: false,
                      message: "Please enter other phone number!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && value.length > 15) {
                          return Promise.reject(
                            "Please enter a valid  Number!"
                          );
                        }

                        // You can add additional conditions if needed
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    // rows={3}
                    style={{ background: "white" }}
                    placeholder="Enter Other Phone Number"
                    className="indent-5 begin-input bg-white pt-2 focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Assistant"
                  name="assistant"
                  rules={[
                    {
                      required: false,
                      message: "Please input assistant!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Assistant Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="asst_phone"
                  normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                  label="Assistant Phone"
                  size="small"
                  rules={[
                    {
                      required: false,
                      message: "Please enter assistant phone number!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && value.length > 15) {
                          return Promise.reject(
                            "Please enter a valid  Number!"
                          );
                        }

                        // You can add additional conditions if needed
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    // rows={3}
                    style={{ background: "white" }}
                    placeholder="Enter Assistant Phone"
                    className="indent-5 begin-input bg-white pt-2 focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Company Name"
                  name="company_name"
                  rules={[
                    {
                      required: false,
                      message: "Please input company name!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Company Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Job Title / Designation"
                  name="job_title"
                  rules={[
                    {
                      required: false,
                      message: "Please input job title!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Job Title" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Website"
                  name="website"
                  rules={[
                    {
                      // type: "url",
                      // message: "Please enter a valid URL!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Website URL" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Contact Owner"
                  name="contact_owner_id"
                  rules={[
                    {
                      required: false,
                      message: "Please input contact owner!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Owner"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.fullName ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    loading={getCompanyAllUserLoader}
                    disabled={getCompanyAllUserLoader}
                    options={contactOwners}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Popover
                  placement="bottomRight"
                  content={addBusinessPartnerPopover}
                  trigger="click"
                  open={addBusinessPartnerPopoverOpen}
                  onOpenChange={(visible) => {
                    if (!visible) {
                      setAddBusinessPartnerPopoverOpen(false);
                      addBusinessPartnerForm.resetFields();
                    }
                  }}
                >
                  <div
                    className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                    onClick={showAddBusinessPartnerPopoverOpen}
                  >
                    <Image
                      src={plusSvg}
                      alt="image"
                      height={20}
                      width={20}
                      preview={false}
                    />
                    <h1 className="text-[14px] font-popinsRegular ml-1">
                      Add New
                    </h1>
                  </div>
                </Popover>
                <Form.Item
                  label="Business Partner"
                  name="business_partner_id"
                  rules={[
                    {
                      required: false,
                      message: "Please select business partner!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Business Partner"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={businessPartner}
                    disabled={getAllBusinessPartnerLoader}
                    loading={getAllBusinessPartnerLoader}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Popover
                  placement="bottomRight"
                  content={addLeadSourcePopover}
                  trigger="click"
                  open={addLeadSourcePopoverOpen}
                  onOpenChange={(visible) => {
                    if (!visible) {
                      setAddLeadSourcePopoverOpen(false);
                      addLeadSourceForm.resetFields();
                    }
                  }}
                >
                  <div
                    className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                    onClick={showAddLeadSourcePopoverOpen}
                  >
                    <Image
                      src={plusSvg}
                      alt="image"
                      height={20}
                      width={20}
                      preview={false}
                    />
                    <h1 className="text-[14px] font-popinsRegular ml-1">
                      Add Lead Source
                    </h1>
                  </div>
                </Popover>
                <Form.Item
                  label="Lead Source"
                  name="lead_source_id"
                  rules={[
                    {
                      required: false,
                      message: "Please select source!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Lead Source"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={sourceData}
                    loading={getLeadSourceLoader}
                    disabled={getLeadSourceLoader}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Popover
                  placement="bottomRight"
                  content={addDepartmentPopover}
                  trigger="click"
                  open={addDepartmentPopoverOpen}
                  onOpenChange={(visible) => {
                    if (!visible) {
                      setAddDepartmentPopoverOpen(false);
                      addDepartmentForm.resetFields();
                    }
                  }}
                >
                  <div
                    className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                    onClick={showAddDepartmentPopoverOpen}
                  >
                    <Image
                      src={plusSvg}
                      alt="image"
                      height={20}
                      width={20}
                      preview={false}
                    />
                    <h1 className="text-[14px] font-popinsRegular ml-1">
                      Add Department
                    </h1>
                  </div>
                </Popover>
                <Form.Item
                  label="Department"
                  name="department_id"
                  rules={[
                    {
                      required: false,
                      message: "Please select department!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Department"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={departmentsData}
                    loading={getDepartmentLoader}
                    disabled={getDepartmentLoader}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Fax"
                  normalize={(value) => value && value.replace(/[^\d]/g, "")}
                  name="fax"
                  rules={[
                    {
                      required: false,
                      message: "Please input fax!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Fax Number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Date of Birth"
                  name="date_of_birth"
                  rules={[
                    {
                      required: false,
                      message: "Please select date of birth!",
                    },
                  ]}
                >
                  <DatePicker
                    className="w-full"
                    placeholder="Select date of birth"
                    maxDate={dayjs()}
                  // onChange={onStartDateChange}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Skype ID"
                  name="skype_id"
                  rules={[
                    {
                      required: false,
                      message: "Please input skype id!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Skype ID" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Twitter"
                  name="twitter"
                  rules={[
                    {
                      required: false,
                      message: "Please input twitter!",
                    },
                  ]}
                >
                  <Input placeholder="Enter twitter" />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card className="main-card mt-5 w-full">
            <h1 className="lg:text-lg text-base font-popinsRegular text-brand mb-2">
              Address Information
            </h1>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Country"
                  name="country_id"
                  rules={[
                    {
                      required: false,
                      message: "Please input country!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    loading={getCountryLoader}
                    onClear={handleClearState}
                    onSelect={handleGetState}
                    placeholder="Select Country"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={country}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="State"
                  name="state_id"
                  rules={[
                    {
                      required: false,
                      message: "Please select state!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    loading={statesLoader}
                    disabled={!countrySelected}
                    placeholder="Select State"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={state}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="City"
                  name="city"
                  rules={[
                    {
                      required: false,
                      message: "Please input city!",
                    },
                  ]}
                >
                  <Input placeholder="Enter City" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Street"
                  name="street"
                  rules={[
                    {
                      required: false,
                      message: "Please input street!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Street" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Zip"
                  name="zip_code"
                  rules={[
                    {
                      required: false,
                      message: "Please input zip code!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Zip" />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card className="main-card mt-5 w-full">
            <h1 className="lg:text-lg text-base font-popinsRegular text-brand mb-2">
              Description Information
            </h1>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      required: false,
                      message: "Please input description!",
                    },
                  ]}
                >
                  <Input.TextArea placeholder="Enter Description" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="remark"
                  label="Remark"
                  className="mb-[12px]"
                  size="small"
                >
                  <Input.TextArea
                    rows={2}
                    // style={{ background: "white" }}
                    placeholder="Enter Remark"
                    className="indent-5 begin-input bg-white pt-2 focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Inquiry For"
                  name="product_category"
                  rules={[
                    {
                      required: false,
                      message: "Please select item!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    mode="multiple"
                    loading={getProductCategoryLoader}
                    disabled={getProductCategoryLoader}
                    placeholder="Select Items"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={productCategories}
                  />
                </Form.Item>
              </Col>
              {(!contactId ||
                (contactId && !contactDetailData?.convert_to_lead)) && (
                  <Col span={12} className=" flex items-center">
                    <div className="flex items-center justify-between w-full">
                      <p className="text-[14px] font-popinsMedium">
                        Convert to Lead
                      </p>
                      <Switch
                        onChange={(checked) => setConvertToLead(checked)}
                        checked={convertToLead}
                      />
                    </div>
                  </Col>
                )}
              <Col span={24} className="my-[12px]">
                <Form.Item
                  label={
                    <>
                      <p>Business Card</p> <br />
                      <span className="text-[#FB2E2E] text-sm">
                        (Image, PDF or Doc up to 50mb)
                      </span>
                    </>
                  }
                  name="business_card"
                  rules={[
                    {
                      required: false,
                      message: "Please select business card!",
                    },
                  ]}
                >
                  <Dragger
                    className="dragger-custom show-remove-icon"
                    beforeUpload={(file) => beforeUpload(file, 50)}
                    accept=".png,.jpg,.jpeg,.pdf,.doc,.docx"
                    {...businessCardProps}
                  >
                    <div className="py-[30px]">
                      <Image src={gallery} alt="gallery" preview={false} />
                      <p className="text-[16px] font-popinsRegular text-[#7C7C7C]">
                        Facility to Attach Documents
                      </p>
                    </div>
                  </Dragger>
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Row gutter={24}>
            <Col
              span={24}
              className="flex items-center my-4 justify-center gap-x-5"
            >
              <Form.Item>
                <Button
                  onClick={async () => {
                    navigate(-1);
                  }}
                  type="button"
                  className="gray-button text-white hover:text-white  min-h-[45px] min-w-[120px]"
                  htmlType="button"
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  loading={addContactLoader || updateContactLoader}
                  type="primary"
                  className="root-btn min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  {contactId ? "Update" : "Save"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </section>
    </Spin>
  );
};

export default CreateContact;
