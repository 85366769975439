import {
  Button,
  Card,
  Carousel,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  TimePicker,
  Typography,
} from "antd";
import Slider from "react-slick";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EventsIcon from "../../../assets/img/eventsIcon.svg";
import plusSvg from "../../../assets/img/plus.svg";
import CustomIconText from "../../common/CustomIconText";
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionAddEventCategoryMaster,
  actionAddEventOnlinePlatformMaster,
  actionAddEventTypeMaster,
  actionGetEventCategoryMaster,
  actionGetEventOnlinePlatformMaster,
  actionGetEventTypeMaster,
} from "../../../store/services/masterDataService";
import {
  actionAddEvent,
  actionGetEventDetail,
  actionUpdateEvent,
} from "../../../store/services/eventService";
import dayjs from "dayjs";

const CreateEvent = () => {
  const [form] = Form.useForm();
  const [eventTypeModalForm] = Form.useForm();
  const [eventCategoryModalForm] = Form.useForm();
  const [onlinePLatformModalForm] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [switchValue, setSwitchValue] = useState(false);
  const [eventType, setEventType] = useState("");
  const [isEventTypeModalOpen, setIsEventTypeModalOpen] = useState(false);
  const [isEventCategoryModalOpen, setIsEventCategoryModalOpen] =
    useState(false);
  const [isOnlinePlatformOpen, setIsOnlinePlatformModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [description, setDescription] = useState("");
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const containerRef = useRef(null);
  const [disableSessionEndTime, setDisableSessionEndTime] = useState(true);
  const [startTime, setStartTime] = useState("");

  const location = useLocation();
  const { eventId } = location.state || {};

  const {
    eventCategoryData,
    getEventCategoryLoader,
    eventTypeData,
    getEventTypeLoader,
    addEventTypeLoader,
    addEventCategoryLoader,
    addEventOnlinePlatformLoader,
    eventOnlinePlatformData,
    getEventOnlinePlatformLoader,
  } = useSelector((state) => state.masterData);

  const {
    addEventsLoader,
    eventsDetailLoader,
    eventDetailData,
    updateEventsLoader,
  } = useSelector((state) => state.events);

  useEffect(() => {
    dispatch(actionGetEventCategoryMaster());
    dispatch(actionGetEventTypeMaster());
    if (eventId) {
      dispatch(actionGetEventDetail({ id: eventId }));
    }
  }, [dispatch, eventId]);

  useEffect(() => {
    if (!eventId && !eventType && eventTypeData && eventTypeData.length > 0) {
      setEventType(eventTypeData[0].event_type_master_id);
    }
  }, [eventTypeData]);

  useEffect(() => {
    if (eventId && eventDetailData) {
      if (eventDetailData.virtual_event) {
        setSwitchValue(eventDetailData.virtual_event);
        dispatch(actionGetEventOnlinePlatformMaster());
      }
      setEventType(eventDetailData.event_type?.event_type_master_id);
      // setDescription(eventDetailData.event_description);
      form.setFieldsValue({
        event_name: eventDetailData.event_name,
        event_category_master_id:
          eventDetailData.event_category?.event_category_master_id,
        start_date: dayjs(eventDetailData.start_date, "YYYY-MM-DD"),
        start_time: dayjs(eventDetailData.start_time, "HH:mm"),
        end_date: dayjs(eventDetailData.end_date, "YYYY-MM-DD"),
        end_time: dayjs(eventDetailData.end_time, "HH:mm"),
        event_online_platform_master_id:
          eventDetailData.event_online_platform
            ?.event_online_platform_master_id,
        event_organizer: eventDetailData.event_organizer,
        event_website: eventDetailData.event_website,
        organizer_contact: eventDetailData.organizer_contact,
        address: eventDetailData.address,
      });

      eventDetailData.start_time &&
        setStartTime(dayjs(eventDetailData.start_time, "HH:mm"));
      eventDetailData.start_date &&
        setStartDate(dayjs(eventDetailData.start_date, "YYYY-MM-DD"));
    }
  }, [eventId, eventDetailData, form]);

  const eventCategoryDataAll = [];
  const onlinePlatformDataAll = [];

  eventCategoryData &&
    eventCategoryData.length > 0 &&
    eventCategoryData.map((data) =>
      eventCategoryDataAll.push({
        label: data?.event_category,
        value: data?.event_category_master_id,
      })
    );
  eventOnlinePlatformData &&
    eventOnlinePlatformData.length > 0 &&
    eventOnlinePlatformData.map((data) =>
      onlinePlatformDataAll.push({
        label: data.platform_name,
        value: data.event_online_platform_master_id,
      })
    );

  const onFinish = (values) => {
    const data = {
      ...values,
      event_type_master_id: eventType,
      virtual_event: switchValue ? switchValue : undefined,
      start_date: eventDetailData
        ? eventDetailData?.event_status === "Upcoming"
          ? dayjs(values.start_date).format("YYYY-MM-DD")
          : null
        : dayjs(values.start_date).format("YYYY-MM-DD"),
      start_time: eventDetailData
        ? eventDetailData?.event_status === "Upcoming"
          ? dayjs(values.start_time).format("HH:mm")
          : null
        : dayjs(values.start_time).format("HH:mm"),
      end_date: eventDetailData
        ? eventDetailData?.event_status === "Upcoming"
          ? dayjs(values.end_date).format("YYYY-MM-DD")
          : null
        : dayjs(values.end_date).format("YYYY-MM-DD"),
      end_time: eventDetailData
        ? eventDetailData?.event_status === "Upcoming"
          ? dayjs(values.end_time).format("HH:mm")
          : null
        : dayjs(values.end_time).format("HH:mm"),
      event_description: description,
    };

    const req = {
      ...Object.fromEntries(
        Object.entries(data).filter(
          ([_, v]) =>
            v !== undefined &&
            v !== null &&
            !(typeof v === "string" && v.trim() === "") &&
            (!Array.isArray(v) || v.length > 0)
        )
      ),
    };

    eventId
      ? dispatch(actionUpdateEvent({ id: eventId, req, navigate }))
      : dispatch(actionAddEvent({ req, navigate }));
  };

  const showEventTypeModal = () => {
    setIsEventTypeModalOpen(true);
  };

  const handleCancelEventTypeModal = () => {
    setIsEventTypeModalOpen(false);
    eventTypeModalForm.resetFields();
  };

  const handleAddEventType = (values) => {
    dispatch(
      actionAddEventTypeMaster({
        values,
        handleCancel: handleCancelEventTypeModal,
      })
    );
  };

  const showEventCategoryModal = () => {
    setIsEventCategoryModalOpen(true);
  };

  const handleCancelEventCategoryModal = () => {
    setIsEventCategoryModalOpen(false);
    eventCategoryModalForm.resetFields();
  };
  const showOnlinePlatformModal = () => {
    setIsOnlinePlatformModalOpen(true);
  };

  const handleCancelOnlinePLatformModal = () => {
    setIsOnlinePlatformModalOpen(false);
    onlinePLatformModalForm.resetFields();
  };

  const handleAddEventOnlinePlatform = (values) => {
    dispatch(
      actionAddEventOnlinePlatformMaster({
        values,
        handleCancel: handleCancelOnlinePLatformModal,
      })
    );
  };

  const disabledEndDate = (endValue) => {
    if (!startDate || !endValue) {
      return false;
    }
    return (
      endValue.isBefore(startDate, "day") || endValue.isSame(!startDate, "day")
    );
  };

  const onStartDateChange = (value) => {
    setStartDate(value);
    form.setFieldsValue({ endDate: null });
  };

  const handleEditorChange = (newContent, editor) => {
    setDescription(newContent);
  };

  const slidesToShowValue =
    eventTypeData && eventTypeData.length > 4 ? 4.5 : eventTypeData.length;
  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: slidesToShowValue,
    slidesToScroll: 4,
    infinite: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: slidesToShowValue,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow:
            eventTypeData && eventTypeData.length > 3
              ? 3.5
              : eventTypeData.length,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            eventTypeData && eventTypeData.length > 3
              ? 3.5
              : eventTypeData.length,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            eventTypeData && eventTypeData.length > 2
              ? 2.5
              : eventTypeData.length,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            eventTypeData && eventTypeData.length > 1
              ? 1.5
              : eventTypeData.length,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(false),
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
    setDisableSessionEndTime(false);
  };

  const disabledEndTime = () => {
    const startTime = form.getFieldValue("start_time");
    const startDate = form.getFieldValue("start_date");
    const endDate = form.getFieldValue("end_date");

    // Check if start date and end date are the same
    if (startDate && endDate && startDate.isSame(endDate, "day")) {
      if (!startTime) {
        return {};
      }

      const startHour = dayjs(startTime).hour();
      const startMinute = dayjs(startTime).minute();

      return {
        disabledHours: () => {
          let hours = [];
          for (let i = 0; i < startHour; i++) {
            hours.push(i);
          }
          return hours;
        },
        disabledMinutes: (selectedHour) => {
          if (selectedHour === startHour) {
            let minutes = [];
            for (let i = 0; i <= startMinute; i++) {
              minutes.push(i);
            }
            return minutes;
          }
          return [];
        },
      };
    }

    return {};
  };

  return (
    <Spin spinning={eventsDetailLoader}>
      <section className="main-wrapper">
        <CustomIconText
          image={EventsIcon}
          title={eventId ? "Update Event" : "Create Event"}
          backArrow={true}
        />
        <Card className="main-card mt-5 w-full">
          <Form
            scrollToFirstError={true}
            form={form}
            layout="vertical"
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Row gutter={24}>
              <Col span={24}>
                <div className="flex items-center">
                  <h1 className="text-[14px] font-popinsRegular ml-1">
                    Event Type
                  </h1>
                  <div
                    className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                    onClick={showEventTypeModal}
                  >
                    <Image
                      src={plusSvg}
                      alt="image"
                      height={20}
                      width={20}
                      preview={false}
                    />
                    <h1 className="text-[14px] font-popinsRegular ml-1">
                      Add Type
                    </h1>
                  </div>
                </div>
                <div className="slider-container">
                  <Slider {...settings}>
                    {eventTypeData &&
                      eventTypeData.map((data, index) => (
                        <div key={index} className="py-3">
                          <div
                            className={`p-3 mr-3 rounded-xl border ${
                              eventType === data.event_type_master_id
                                ? "border-[#6883FD] bg-custom-gradient"
                                : "border-[#DDDAE9] bg-[#FCFBFD]"
                            } min-w-90 max-w-90 min-h-24 cursor-pointer`}
                            onClick={() =>
                              isDragging
                                ? ""
                                : setEventType(data.event_type_master_id)
                            }
                            style={{ cursor: isDragging ? "grabbing" : "grab" }}
                          >
                            <h3 className="font-popinsMedium">
                              {data.event_type}
                            </h3>
                            <Typography.Paragraph
                              ellipsis={{ rows: 2 }}
                              className="font-popinsRegular text-xs mt-1"
                            >
                              {data.description}
                            </Typography.Paragraph>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Event Name"
                  name="event_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your event name!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Your Event Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <div
                  className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                  onClick={showEventCategoryModal}
                >
                  <Image
                    src={plusSvg}
                    alt="image"
                    height={20}
                    width={20}
                    preview={false}
                  />
                  <h1 className="text-[14px] font-popinsRegular ml-1">
                    Add Category
                  </h1>
                </div>
                <Form.Item
                  label="Event Category"
                  name="event_category_master_id"
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Event Category"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    disabled={getEventCategoryLoader}
                    options={eventCategoryDataAll}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Start Date"
                  name="start_date"
                  rules={[
                    {
                      required: true,
                      message: "Please select start date!",
                    },
                  ]}
                >
                  <DatePicker
                    disabled={
                      eventId
                        ? eventDetailData?.event_status === "Upcoming"
                          ? false
                          : true
                        : false
                    }
                    className="w-full"
                    onChange={onStartDateChange}
                    minDate={dayjs(new Date())}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Start Time"
                  name="start_time"
                  rules={[
                    {
                      required: true,
                      message: "Please select start time!",
                    },
                  ]}
                >
                  <TimePicker
                    disabled={
                      eventId
                        ? eventDetailData?.event_status === "Upcoming"
                          ? false
                          : true
                        : false
                    }
                    onChange={handleStartTimeChange}
                    className="w-full"
                    format={"HH:mm"}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="End Date"
                  name="end_date"
                  dependencies={["start_date"]}
                  rules={[
                    {
                      required: true,
                      message: "Please select end date!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          (getFieldValue("start_date") &&
                            (value.isAfter(getFieldValue("start_date")) ||
                              value.isSame(getFieldValue("start_date"))))
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("End date must be after start date!")
                        );
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    disabled={
                      eventId
                        ? eventDetailData?.event_status === "Upcoming"
                          ? false
                          : true
                        : false
                    }
                    className="w-full"
                    disabledDate={disabledEndDate}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  dependencies={["start_time"]}
                  label="End Time"
                  name="end_time"
                  rules={[
                    {
                      required: true,
                      message: "Please select end time!",
                    },

                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const startDate = getFieldValue("start_date");
                        const endDate = getFieldValue("end_date");
                        const startTime = getFieldValue("start_time");
                        if (
                          startDate &&
                          endDate &&
                          startDate.isSame(endDate, "day")
                        ) {
                          if (
                            startTime &&
                            (value.isBefore(startTime, "minute") ||
                              value.isSame(startTime, "minute"))
                          ) {
                            return Promise.reject(
                              new Error("End time must be after start time!")
                            );
                          }
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <TimePicker
                    disabled={
                      eventId
                        ? eventDetailData?.event_status === "Upcoming"
                          ? false
                          : true
                        : false
                    }
                    disabledTime={disabledEndTime}
                    className="w-full"
                    format={"HH:mm"}
                  />
                </Form.Item>
              </Col>
              <Col span={switchValue ? 12 : 24}>
                <div className="flex items-center my-10">
                  <h1 className="text-[#7C7C7C]">Virtual Event</h1>
                  <Switch
                    value={switchValue}
                    className="ml-2"
                    onChange={(e) => {
                      setSwitchValue(e);
                      e && dispatch(actionGetEventOnlinePlatformMaster());
                    }}
                  />
                </div>
              </Col>
              {switchValue && (
                <Col span={12}>
                  <div
                    className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                    onClick={showOnlinePlatformModal}
                  >
                    <Image
                      src={plusSvg}
                      alt="image"
                      height={20}
                      width={20}
                      preview={false}
                    />
                    <h1 className="text-[14px] font-popinsRegular ml-1">
                      Add Platform
                    </h1>
                  </div>
                  <Form.Item
                    label="Online Platform"
                    name="event_online_platform_master_id"
                    rules={[
                      {
                        required: true,
                        message: "Please select online platform!",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Online Platform"
                      optionFilterProp="children"
                      className=""
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={onlinePlatformDataAll}
                      disabled={getEventOnlinePlatformLoader}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col span={24} className="mb-4">
                <h1 className="text-[#7C7C7C]">Organizer Details</h1>
              </Col>
              <Col span={12}>
                <Form.Item label="Event Organizer" name="event_organizer">
                  <Input placeholder="Enter Your Event Organizer" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Organizer Contact"
                  normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                  name="organizer_contact"
                >
                  <Input placeholder="Enter Your Organizer Contact" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Location" name="address">
                  <Input placeholder="Enter Your Location" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Event Website"
                  name="event_website"
                  rules={[
                    {
                      type: "url",
                      message: "Please enter a valid URL!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Your Event Website" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Editor
                  apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                  initialValue={
                    eventDetailData && eventDetailData.event_description
                  }
                  init={{
                    // plugins:
                    //   "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate  mentions  tableofcontents footnotes autocorrect typography inlinecss markdown",
                    // toolbar:
                    //   "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                    plugins: "link lists",
                    toolbar:
                      "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough |  link | bullist numlist",
                    tinycomments_mode: "embedded",
                    tinycomments_author: "CRM HARBOR",
                    branding: false,
                    selector: "textarea",
                    menubar: false,
                  }}
                  onEditorChange={handleEditorChange}
                />
              </Col>
              <Col
                span={24}
                className="flex items-center my-4 justify-center gap-x-5"
              >
                <Form.Item>
                  <Button
                    onClick={async () => {
                      navigate(-1);
                    }}
                    type="button"
                    className="gray-button text-white hover:text-white  min-h-[45px] min-w-[120px]"
                    htmlType="button"
                  >
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={addEventsLoader || updateEventsLoader}
                    type="primary"
                    className="root-btn min-h-[45px] min-w-[120px]"
                    htmlType="submit"
                    disabled={addEventsLoader || updateEventsLoader}
                  >
                    {eventId ? "Update" : "Save"}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Modal
            centered
            onOk={() => handleCancelEventTypeModal()}
            onCancel={() => handleCancelEventTypeModal()}
            footer={false}
            width={500}
            title=""
            open={isEventTypeModalOpen}
          >
            <h1 className="text-[#6883FD] text-[20px]  mt-[5px] font-medium">
              Event Type
            </h1>
            <Form
              form={eventTypeModalForm}
              onFinish={handleAddEventType}
              layout="vertical"
              className="mt-[20px] mb-0"
            >
              <Form.Item
                name="event_type"
                label="Event Type"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please input event type!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter Event Type"
                  className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: false,
                    // message: "Please input Description!",
                  },
                ]}
              >
                <Input.TextArea
                  rows={3}
                  style={{ background: "white" }}
                  placeholder="Enter Description"
                  className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>

              <div className="flex justify-center mt-[40px]">
                <Form.Item className="mb-0">
                  <Button
                    onClick={() => handleCancelEventTypeModal()}
                    type="button"
                    className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      loading={addEventTypeLoader}
                      type="primary"
                      className="root-btn min-h-[45px] min-w-[120px]"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
          <Modal
            centered
            destroyOnClose={() => form.resetFields()}
            onOk={() => handleCancelEventCategoryModal()}
            onCancel={() => handleCancelEventCategoryModal()}
            footer={false}
            width={500}
            open={isEventCategoryModalOpen}
          >
            <h1 className="text-[#6883FD] text-[20px]  mt-[5px] font-medium">
              Event Category
            </h1>
            <Form
              form={eventCategoryModalForm}
              onFinish={(values) =>
                dispatch(
                  actionAddEventCategoryMaster({
                    values,
                    setIsModalOpen: handleCancelEventCategoryModal,
                  })
                )
              }
              layout="vertical"
              className="mt-[20px] mb-0"
            >
              <Form.Item
                name="event_category"
                label="Event Category Name"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please input event category name!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter Category Name"
                  className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>

              <div className="flex justify-center mt-[40px]">
                <Form.Item className="mb-0">
                  <Button
                    onClick={() => handleCancelEventCategoryModal()}
                    type="button"
                    className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      loading={addEventCategoryLoader}
                      type="primary"
                      className="root-btn min-h-[45px] min-w-[120px]"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
          <Modal
            centered
            onOk={() => handleCancelOnlinePLatformModal()}
            onCancel={() => handleCancelOnlinePLatformModal()}
            footer={false}
            width={500}
            open={isOnlinePlatformOpen}
          >
            <h1 className="text-[#6883FD] text-[20px]  mt-[5px] font-medium">
              Event Online Platform
            </h1>
            <Form
              form={onlinePLatformModalForm}
              onFinish={handleAddEventOnlinePlatform}
              layout="vertical"
              className="mt-[20px] mb-0"
            >
              <Form.Item
                name="platform_name"
                label="Enter Online Platform"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please input event online platform!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter Event Online Platform"
                  className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>

              <div className="flex justify-center mt-[40px]">
                <Form.Item className="mb-0">
                  <Button
                    onClick={() => handleCancelOnlinePLatformModal()}
                    type="button"
                    className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      type="primary"
                      loading={addEventOnlinePlatformLoader}
                      className="root-btn min-h-[45px] min-w-[120px]"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
        </Card>
      </section>
    </Spin>
  );
};

export default CreateEvent;
