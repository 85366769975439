import {
  DeleteOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  List,
  Row,
  Select,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import requestForQuotation from "../../assets/img/request-for-quotation.svg";
import {
  actionAddFeedBackMechanism,
  actionGetSingleFeedBackMechanism,
  actionUpdateFeedBackMechanism,
} from "../../store/services/feedBackMechanismService";
import CustomIconText from "../common/CustomIconText";
import feedBackMechanism from "../../assets/img/feedback-mechanism.svg";

import { extractFilename, renderFileIcon } from "../helper/common-function";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import useFileUpload from "../../hooks/use-file-upload";
import CustomUploadDragger from "../common/CustomUploadDragger";
import { actionDownloadFile } from "../../store/services/commonService";
const { Dragger } = Upload;

const beforeUpload = (file, size) => {
  const fileSizeMB = file.size / (1024 * 1024);

  // Check if file size is within the limit
  if (fileSizeMB > size) {
    toast.error(`File size should be less than ${size} MB.`);
    return false; // Prevent upload
  }

  return true;
};

const CreateRequstForQuotation = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [attatchments, setAttatchments] = useState([]);
  const {
    addFeedBackMechanismLoader,
    getSingleFeedBackMechanismData,
    updateFeedBackMechanismLoader,
  } = useSelector((state) => state.feedBackMechanism);
  const { feedBackMechanismId } = location.state || {};

  // const { isUploading, handleUpload } = useFileUpload({ size: 104857600 });

  const { isUploading, handleUpload } = useFileUpload({ size: 52428800 }); // 50 MB

  // business card props
  /*   const businessCardProps = {
    name: "file",
    multiple: true,
    action: BASE_URL_UPLOAD,
    listType: "picture",
    showUploadList: true,
    fileList: businessCard,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
    },
    beforeUpload: () => false,
    onChange(info) {
      const { status, fileList } = info;

      console.log({ info });
      if (status !== "uploading") {
        setBusinessCard(fileList);
      }
      if (status === "done") {
        toast.success(`File uploaded successfully.`);
      } else if (status === "error") {
        toast.error(`File upload failed.`);
      }
    },
    onDrop(e) {},
  }; */

  useEffect(() => {
    if (feedBackMechanismId) {
      dispatch(actionGetSingleFeedBackMechanism(feedBackMechanismId));
    }
  }, [feedBackMechanismId, dispatch]);

  useEffect(() => {
    if (getSingleFeedBackMechanismData && feedBackMechanismId) {
      form.setFieldsValue({
        feedback_type: getSingleFeedBackMechanismData?.feedback_type,
        description: getSingleFeedBackMechanismData?.description,
      });
      const ImagesArray = [];

      console.log({ att: getSingleFeedBackMechanismData.attachments });

      setAttatchments(
        getSingleFeedBackMechanismData.attachments &&
          getSingleFeedBackMechanismData.attachments.length > 0 &&
          getSingleFeedBackMechanismData.attachments.map((att) => att.upload)
      );
    }
  }, [feedBackMechanismId, getSingleFeedBackMechanismData, form]);

  const handleCancel = () => {
    navigate("/feedback-mechanism");
  };

  // console.log({ businessCard });

  const onFinish = (values) => {
    const request = {
      ...values,
      feedback_attachment_id:
        attatchments && attatchments?.length > 0
          ? attatchments.map((card) => card.upload_id)
          : [],
    };

    console.log({ request });

    feedBackMechanismId
      ? dispatch(
          actionUpdateFeedBackMechanism({
            id: feedBackMechanismId,
            req: request,
            navigate,
          })
        )
      : dispatch(
          actionAddFeedBackMechanism({
            req: request,
            navigate,
          })
        );
  };

  return (
    <section className="main-wrapper">
      <CustomIconText
        image={feedBackMechanism}
        title={feedBackMechanismId ? "Update Feedback " : "Create Feedback"}
        backArrow={true}
      />
      <Card className="main-card mt-5 w-full">
        <Form
          scrollToFirstError={true}
          form={form}
          layout="vertical"
          initialValues={{
            status: "DRAFT",
          }}
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={24}>
            {/* Feedback Type */}
            <Col span={12}>
              <Form.Item
                className="mb-[12px]"
                label="Feedback Type"
                name="feedback_type"
                rules={[
                  {
                    required: true,
                    message: "Please select feedback type!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  placeholder="Select Feedback Type"
                  className="bg-white rounded-[10px]"
                  options={[
                    { label: "Bug Report", value: "Bug Report" },
                    { label: "Feature Request", value: "Feature Request" },
                    { label: "General Feedback", value: "General Feedback" },
                  ]}
                />
              </Form.Item>
            </Col>

            {/* Description */}
            <Col span={24}>
              <Form.Item
                className="mb-[12px]"
                label="Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please enter a description!",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Enter Description"
                  rows={3}
                  className="bg-white rounded-[10px]"
                />
              </Form.Item>
            </Col>

            {/* Attachment */}
            <Col span={24} className="my-[12px]">
              <Form.Item
                label={
                  <>
                    <p>Attachments</p>
                    <br />
                    <span className="text-[#FB2E2E] text-sm">
                      (Upload up to 50mb)
                    </span>
                    <br />
                    <span className="text-sm pl-2 text-gray-500">
                      Allowed file types: .jpeg, .jpg, .png, .gif, .mp4
                    </span>
                  </>
                }
                name="feedback_attachment_id"
                rules={[
                  {
                    required: false,
                    message: "Please select business card!",
                  },
                ]}
              >
                <CustomUploadDragger
                  gapY={2}
                  text="Add Attachment"
                  accept=".jpeg,.jpg,.png,.gif,.mp4"
                  onDrop={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    const files = Array.from(e.dataTransfer.files);
                    if (files && files.length > 0) {
                      handleUpload(files, setAttatchments);
                    }
                  }}
                  onChange={async (e) => {
                    const files = Array.from(e.target.files);

                    if (files.length > 0) {
                      await handleUpload(files, setAttatchments);
                    }
                  }}
                  loading={isUploading}
                />

                {attatchments && attatchments.length > 0 && (
                  <AttachmentList
                    items={attatchments}
                    onDelete={(id) => {
                      setAttatchments((prev) =>
                        prev.filter((attachment) => attachment.upload_id !== id)
                      );
                    }}
                    onView={() => {}}
                  />
                )}
              </Form.Item>
            </Col>

            {/* Submit & Cancel Buttons */}
            <Col
              span={24}
              align="middle"
              className="flex justify-center mt-[40px]"
            >
              <Form.Item className="mb-0">
                <Button
                  onClick={handleCancel}
                  type="button"
                  className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                >
                  Cancel
                </Button>
              </Form.Item>

              <div className="ml-4">
                <Form.Item className="mb-0">
                  <Button
                    loading={
                      addFeedBackMechanismLoader ||
                      updateFeedBackMechanismLoader
                    }
                    type="primary"
                    className="root-btn min-h-[45px] min-w-[120px]"
                    htmlType="submit"
                  >
                    {feedBackMechanismId ? "Save" : "Submit"}
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
    </section>
  );
};

export default CreateRequstForQuotation;

const AttachmentList = ({ items, onDelete }) => {
  const dispatch = useDispatch();
  const { downloadFileLoader } = useSelector((state) => state.common);

  return (
    <>
      <List
        className="mt-3 bg-slate-50 max-h-[300px] gray-scrollbar overflow-auto"
        bordered
        dataSource={items}
        renderItem={(item) => (
          <List.Item
            actions={[
              downloadFileLoader[item.upload_id] ? (
                <LoadingOutlined />
              ) : (
                <DownloadOutlined
                  onClick={() => dispatch(actionDownloadFile(item))}
                  color="red"
                  className="text-black/80"
                />
              ),
              <DeleteOutlined
                onClick={() => onDelete(item.upload_id)}
                color="red"
                className="text-red-500"
              />,
            ]}
          >
            <div className="flex items-center gap-x-3">
              {renderFileIcon(item?.url)}
              <span className="tracking-wide">{extractFilename(item.url)}</span>
            </div>
          </List.Item>
        )}
      />
    </>
  );
};
