import { EditOutlined, EllipsisOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  ConfigProvider,
  Dropdown,
  Form,
  Image,
  Modal,
  Pagination,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import beatIcon from "../../assets/img/beatIcon.svg";
import closeIcon from "../../assets/img/close-icon.svg";
import {
  actionChangeBeatPlanningStatus,
  actionClearAllBeatPlanning,
  actionDeleteBeatPlanning,
  actionGetAllBeatPlanning,
  actionGetBeatPlanning,
  actionGetBeatPlanningDetail,
} from "../../store/services/salesService";
import CustomInputWithButtons from "../common/CustomInputWithButtons ";
import EventDescriptionCard from "../common/EventDescriptionCard";
import PageTitle from "../common/PageTitle";
import SortIcon from "../common/SortIcon";
import { getFirstLetterCapital, getFullName } from "../helper/common-function";
import { getBeatPlanStatusColor } from "../helper/statusUtils";
import CreateBeatPlanning from "./CreateBeatPlanning";
import { actionGetCompanyAllUsers } from "../../store/services/usersService";

const dayMap = {
  M: "Monday",
  T: "Tuesday",
  W: "Wednesday",
  Th: "Thursday",
  F: "Friday",
  S: "Saturday",
  Su: "Sunday",
};

const getFullDayName = (abbreviation) => {
  return dayMap[abbreviation] || "Unknown";
};

const BeatPlanning = () => {
  const offsetRef = useRef(0);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [beatPlanId, setBeatPlanId] = useState("");

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [exportAll, setExportAll] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const clearDataRef = useRef(null);

  const {
    getBeatPlanningLoader,
    getAllBeatPlanningLoader,
    beatPlanData,
    beatPlansCounts,
    allBeatPlanData,
    deleteBeatPlanningLoader,
    getBeatPlanningDetailLoader,
    beatPlanningDetail,
    changeStatusBeatPlanningLoader,
  } = useSelector((state) => state.sales);

  useEffect(() => {
    dispatch(actionGetBeatPlanning({ search }));
  }, [dispatch]);

  const columns = [
    {
      title: "Beat Plan Name",
      key: "title",
      fixed: "left",
      dataIndex: "title",
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 200,

      render: (text, data) => (
        <div
          onClick={() => {
            setCreateModalOpen(true);
            setBeatPlanId(data.beat_planning_id);
            dispatch(actionGetBeatPlanningDetail(data.beat_planning_id));
          }}
          className="flex items-center  text-brand cursor-pointer"
        >
          <p className="ml-2">{getFirstLetterCapital(text)}</p>
        </div>
      ),
    },

    {
      title: "Start Date",
      key: "start_date",
      dataIndex: "start_date",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 150,
      render: (text) => (
        <span className="ml-2">{dayjs(text).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "Recurrence Plan",
      key: "plan",
      dataIndex: "plan",
      width: 400,
      render: (text, data) => (
        <Typography.Paragraph className="ml-2 !mb-0">
          {getRepeatPlanText(data)}
        </Typography.Paragraph>
      ),
    },
    {
      title: "End Date",
      key: "recurring_ends_on_date",
      dataIndex: "recurring_ends_on_date",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text) => (
        <span className="ml-2">
          {text ? dayjs(text).format("DD-MM-YYYY") : "-"}
        </span>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 150,
      render: (text, data) => {
        const oppositeStatus = text === "Active" ? "Inactive" : "Active";

        return (
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: changeStatusBeatPlanningLoader ? (
                    <Spin size="small" />
                  ) : (
                    oppositeStatus
                  ),
                },
              ],
              onClick: (key) => {
                dispatch(
                  actionChangeBeatPlanningStatus({
                    status: oppositeStatus,
                    id: data.beat_planning_id,
                  })
                );
              },
            }}
            placement="bottom"
            overlayClassName="status-dropdown"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Tag color={getBeatPlanStatusColor(text)}>{text}</Tag>
            </a>
          </Dropdown>
        );
      },
    },
    {
      title: "Assigned Employees",
      key: "beat_planning_company_users",
      dataIndex: "beat_planning_company_users",
      width: 200,
      /* filters: users,
      onFilter: (value, record) => console.log("Filterd API Called!"), */
      render: (text) => (
        <Avatar.Group
          size={30}
          max={{
            count: 3,
            style: {
              color: "white",
              backgroundColor: "#6883FD",
              cursor: "pointer",
              fontSize: 12,
            },
            popover: { trigger: "click" },
          }}
        >
          {text &&
            text.length > 0 &&
            text.map((item, index) => (
              <Tooltip
                title={getFullName(item?.first_name, item.last_name)}
                key={index}
              >
                <Avatar key={index} src={item?.profile_pic} />
              </Tooltip>
            ))}
        </Avatar.Group>
      ),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      width: 100,
      render: (text, data) => {
        const isActive = data.status === "Active";
        const actionText = isActive ? "Deactivate" : "Activate";
        const newStatus = isActive ? "Inactive" : "Active";
        const confirmMessage = `Are you sure you want to change the status from ${data.status} to ${newStatus}?`;

        return (
          <Dropdown
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () => {
                    setCreateModalOpen(true);
                    setBeatPlanId(data.beat_planning_id);
                    dispatch(
                      actionGetBeatPlanningDetail(data.beat_planning_id)
                    );
                  },
                },
              ].filter(Boolean),
            }}
          >
            
              <EllipsisOutlined className="cursor-pointer" />
            
          </Dropdown>
        );
      },
    },
  ];

  const handleRefresh = () => {
    setSearch(null);
    offsetRef.current = 0;
    dispatch(
      actionGetBeatPlanning({
        offset: offsetRef.current,
      })
    );
  };

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      dispatch(
        actionGetBeatPlanning({
          offset: offsetRef.current,
          search: e.target.value,
        })
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      dispatch(
        actionGetBeatPlanning({
          offset: offsetRef.current,
          search: e.target.value,
        })
      );
    }
  };

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllBeatPlanning());
    setExportAll(true);
  };

  const exportAllToExcel = async () => {
    const data =
      ((await allBeatPlanData) &&
        allBeatPlanData.length > 0 &&
        allBeatPlanData.map((data) => {
          return {
            "Beat Plan Name": data.title ? data.title : "-",
            "Start date": data.start_date
              ? dayjs(data.start_date).format("D MMM YYYY")
              : "-",
            "Recurrence Plan": getRepeatPlanText(data),
            "End date": data.recurring_ends_on_date
              ? dayjs(data.recurring_ends_on_date).format("D MMM YYYY")
              : "-",
            Status: data.status,
            "Assigned Employees":
              data.beat_planning_company_users &&
              data.beat_planning_company_users.length > 0
                ? data.beat_planning_company_users
                    .map((com) => com.full_name)
                    .join(", ")
                : "-",
            "Allocated Beats":
              data.beat_planning_beats && data.beat_planning_beats.length > 0
                ? data.beat_planning_beats
                    .map((com) => com.beat_name)
                    .join(", ")
                : "-",
          };
        })) ||
      [];

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet2");
    XLSX.writeFile(workbook, "beat-planning.xlsx");
    dispatch(actionClearAllBeatPlanning({ setExportAll }));
  };

  useEffect(() => {
    if (exportAll && allBeatPlanData && allBeatPlanData.length > 0) {
      exportAllToExcel();
    }
  }, [allBeatPlanData]);

  const handleCloseDeleteModal = () => {
    setBeatPlanId("");
    setIsDeleteModalOpen(false);
  };

  const setClearDataRef = (clearDataFunction) => {
    clearDataRef.current = clearDataFunction;
  };

  const handleCloseCreateModal = () => {
    if (clearDataRef.current) {
      clearDataRef.current();
    }
    setCreateModalOpen(false);
    setBeatPlanId("");
  };

  const handleSortChange = (pagination, filters, sorter) => {
    console.log({ filters });
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetBeatPlanning({
        offset: offsetRef.current,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  return (
    <section className="main-wrapper">
      <PageTitle
        image={beatIcon}
        title="Beat Planning"
        buttonText="Create Beat Plan/s"
        handleClick={() => setCreateModalOpen(true)}
        type="btn"
      />

      <CustomInputWithButtons
        handleRefresh={handleRefresh}
        handleSearch={handleSearch}
        handleBlurAndPressEnter={handleBlurAndPressEnter}
        searchValue={search}
        onClickDownloadMenu={onClickDownloadMenu}
      />

      <ConfigProvider
        theme={{
          components: {
            Checkbox: {
              colorPrimary: "#6883FD",
              algorithm: true,
              colorPrimaryHover: "#6883FD",
              colorPrimaryBorder: "#d9d9d9",
            },
          },
        }}
      >
        <Table
          className="small-table"
          sticky={true}
          rowKey={(record) => record.beat_planning_id}
          sortDirections={["ascend", "descend"]}
          onChange={handleSortChange}
          bordered
          scroll={{
            x: "max-content",
            y: window.innerWidth < 1500 ? "49vh" : "61vh",
          }}
          pagination={false}
          columns={columns}
          dataSource={(beatPlanData || []).slice(0, 10)}
          loading={getBeatPlanningLoader || getAllBeatPlanningLoader}
        />
      </ConfigProvider>

      <div className="flex justify-end mt-3">
        <Pagination
          hideOnSinglePage
          responsive={true}
          current={offsetRef.current + 1}
          className="flex items-center"
          showSizeChanger={false}
          total={beatPlansCounts}
          onChange={(e) => {
            offsetRef.current = e - 1;
            dispatch(
              actionGetBeatPlanning({
                offset: offsetRef.current,
                search,
              })
            );
          }}
        />
      </div>

      {/* View Modal */}
      {/*      <Modal
        maskClosable={false}
        centered={true}
        // loading={getBeatPlanDetailLoader}
        footer={false}
        open={viewModalOpen}
        title=""
        width={600}
        destroyOnClose={handleCloseModal}
        onCancel={handleCloseModal}
        onOk={handleCloseModal}
      >
        {beatPlanDetail && (
          <>
            <div className="text-brand flex items-center gap-x-3">
              <Image
                src={beatIcon}
                alt="image"
                height={20}
                width={20}
                preview={false}
              />

              <h1 className="lg:text-lg text-base  font-popinsRegular">
                View Beat Plan
              </h1>
            </div>
            <div className="flex flex-col pt-6 gap-y-4">
              <EventDescriptionCard
                title="Beat Name"
                value={beatPlanDetail.title}
              />
              <EventDescriptionCard
                title="Plan Start Date"
                value={beatPlanDetail.start_date}
              />
              <EventDescriptionCard title="Plan" value={beatPlanDetail.plan} />
              <EventDescriptionCard
                title="Plan End Date"
                value={beatPlanDetail.end_date}
              />
            </div>
          </>
        )}
      </Modal>
 */}
      {/* ! Delete Modal */}
      <Modal
        className="delete-modal"
        centered
        onOk={handleCloseDeleteModal}
        onCancel={handleCloseDeleteModal}
        afterClose={handleCloseDeleteModal}
        footer={false}
        width={600}
        title=""
        open={isDeleteModalOpen}
      >
        <div className="">
          <h1 className="text-[#000000;] text-[20px] font-popinsMedium mt-2">
            Are you sure you want to Delete this beat plan?
          </h1>
          <div className="flex items-center pt-8 justify-end w-full gap-x-3">
            <Form.Item className="mb-0">
              <Button
                onClick={handleCloseDeleteModal}
                type="button"
                className="gray-button gray-hover text-white hover:text-white  min-h-[45px] min-w-[120px]"
                htmlType="submit"
              >
                Cancel
              </Button>
            </Form.Item>

            <Form.Item className="mb-0">
              <Button
                loading={deleteBeatPlanningLoader}
                type="primary"
                className="danger-btn  min-h-[45px] min-w-[120px]"
                htmlType="submit"
                onClick={() => {
                  dispatch(
                    actionDeleteBeatPlanning({
                      id: beatPlanId,
                      handleCloseDeleteModal,
                    })
                  );
                }}
              >
                Delete
              </Button>
            </Form.Item>
          </div>
        </div>
      </Modal>

      {/* Create Modal */}
      <Modal
        destroyOnClose={true}
        closeIcon={<Image preview={false} src={closeIcon} alt="close-icon" />}
        className="create-modal"
        centered
        loading={getBeatPlanningDetailLoader}
        onOk={handleCloseCreateModal}
        onCancel={handleCloseCreateModal}
        afterClose={handleCloseCreateModal}
        footer={false}
        width={900}
        title=""
        open={createModalOpen}
      >
        <CreateBeatPlanning
          data={beatPlanningDetail}
          id={beatPlanId}
          setBeatPlanId={setBeatPlanId}
          setCreateModalOpen={setCreateModalOpen}
          createModalOpen={createModalOpen}
          setClearDataRef={setClearDataRef}
          handleCloseCreateModal={handleCloseCreateModal}
        />
      </Modal>
    </section>
  );
};

export default BeatPlanning;

export const getRepeatPlanText = (data) => {
  let repeatText = "";

  const days =
    data.recurring_days &&
    data.recurring_days.length > 0 &&
    data.recurring_days
      .map((abbreviation) => getFullDayName(abbreviation))
      .join(", ");
  switch (data.recurring_type) {
    case "weekly":
      repeatText = `Weekly on ${days}`;
      break;
    case "monthly":
      repeatText = `Monthly on day ${dayjs(data.start_date).date()}`;
      break;
    case "daily":
      repeatText = "Daily";
      break;
    case "yearly":
      repeatText = `Yearly on ${dayjs(data.start_date).format("MMMM D")}`;
      break;

    case "Does not repeat":
      repeatText = `On ${dayjs(data.start_date).format("MMMM D, YYYY")}`;
      break;

    default:
      repeatText = "No recurrence information available";
  }

  if (data.recurring_type !== "Does not repeat") {
    if (data.recurring_ends_on_type === "occurrences") {
      repeatText += `, ${data.recurring_ends_on_occurrences} times`;
    } else if (data.recurring_ends_on_type === "date") {
      repeatText += ` until ${dayjs(data.recurring_ends_on_date).format(
        "MMMM D, YYYY"
      )}`;
    }
  }

  return repeatText;
};
