import { Button, Result } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Link to="/">
          {/*   <Button
            type="primary"
            className="filled-s-button text-white hover:text-white"
          >
            Back Home
          </Button> */}
          <Button
            type="primary"
            className="filled-s-button mt-3 text-sm  border-none rounded-[10px]  min-h-[40px]"
            htmlType="submit"
          >
            Back to Home
          </Button>
        </Link>
      }
    />
  );
};

export default NotFound;
