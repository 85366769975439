import { Col, Input, Layout, Menu, Row, Typography } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const initialItems = [
  {
    label: <h1 className=" text-[15px]">Lead Status</h1>,
    key: "lead-status",
  },
  {
    label: (
      <Typography.Paragraph ellipsis={{ rows: 1 }} className="text-[15px]">
        Lead Source
      </Typography.Paragraph>
    ),
    key: "lead-source",
  },
  {
    label: (
      <Typography.Paragraph ellipsis={{ rows: 1 }} className="text-[15px]">
        Lead Rating
      </Typography.Paragraph>
    ),
    key: "lead-rating",
  },
  {
    label: (
      <Typography.Paragraph ellipsis={{ rows: 1 }} className="text-[15px]">
        Industry Master
      </Typography.Paragraph>
    ),
    key: "industry-master",
  },
  {
    label: (
      <Typography.Paragraph ellipsis={{ rows: 1 }} className="text-[15px]">
        Opportunity Stage
      </Typography.Paragraph>
    ),
    key: "opportunity-stage",
  },
  {
    label: (
      <Typography.Paragraph ellipsis={{ rows: 1 }} className="text-[15px]">
        Beat Grade
      </Typography.Paragraph>
    ),
    key: "beat-grade",
  },
  {
    label: (
      <Typography.Paragraph ellipsis={{ rows: 1 }} className="text-[15px]">
        Business Partner type
      </Typography.Paragraph>
    ),
    key: "business-partner-type",
  },
  {
    label: (
      <Typography.Paragraph ellipsis={{ rows: 1 }} className="text-[15px]">
        Beat Master
      </Typography.Paragraph>
    ),
    key: "beat-master",
  },
  {
    label: (
      <Typography.Paragraph ellipsis={{ rows: 1 }} className="text-[15px]">
        Department
      </Typography.Paragraph>
    ),
    key: "department",
  },
  {
    label: (
      <Typography.Paragraph ellipsis={{ rows: 1 }} className="text-[15px]">
        Type Of Company
      </Typography.Paragraph>
    ),
    key: "type-of-company",
  },
  {
    label: (
      <Typography.Paragraph ellipsis={{ rows: 1 }} className="text-[15px]">
        Zone
      </Typography.Paragraph>
    ),
    key: "zone",
  },
  {
    label: (
      <Typography.Paragraph ellipsis={{ rows: 1 }} className="text-[15px]">
        Area
      </Typography.Paragraph>
    ),
    key: "area",
  },
  {
    label: (
      <Typography.Paragraph ellipsis={{ rows: 1 }} className="text-[15px]">
        Task Status
      </Typography.Paragraph>
    ),
    key: "task-status",
  },
  {
    label: (
      <Typography.Paragraph ellipsis={{ rows: 1 }} className="text-[15px]">
        Unit Conversion
      </Typography.Paragraph>
    ),
    key: "unit-conversion",
  },
  // {
  //   label: (
  //     <Typography.Paragraph ellipsis={{ rows: 1 }} className="text-[15px]">
  //       Material Group
  //     </Typography.Paragraph>
  //   ),
  //   key: "material-group",
  // },
  {
    label: (
      <Typography.Paragraph ellipsis={{ rows: 1 }} className="text-[15px]">
        Expense Category
      </Typography.Paragraph>
    ),
    key: "expense-category",
  },
  {
    label: (
      <Typography.Paragraph ellipsis={{ rows: 1 }} className="text-[15px]">
        Payment Method
      </Typography.Paragraph>
    ),
    key: "payment-method",
  },
  {
    label: (
      <Typography.Paragraph ellipsis={{ rows: 1 }} className="text-[15px]">
        Address Type
      </Typography.Paragraph>
    ),
    key: "address-type",
  },
  {
    label: (
      <Typography.Paragraph ellipsis={{ rows: 1 }} className="text-[15px]">
        Currency Exchange
      </Typography.Paragraph>
    ),
    key: "currency-exchange",
  },
  {
    label: (
      <Typography.Paragraph ellipsis={{ rows: 1 }} className="text-[15px]">
        Deposit Master
      </Typography.Paragraph>
    ),
    key: "deposit-master",
  },
  {
    label: (
      <Typography.Paragraph ellipsis={{ rows: 1 }} className="text-[15px]">
        Plant Code
      </Typography.Paragraph>
    ),
    key: "plant-code",
  },
];

const GeneralMaster = () => {
  const [selectItem, setSelectItem] = useState("lead-status");
  const location = useLocation();
  const navigete = useNavigate();

  const [items, setItems] = useState(initialItems);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    setSelectItem(path || "material");
  }, [location]);

  useEffect(() => {
    if (searchTerm === "") {
      setItems(initialItems);
    } else {
      const filteredItems = initialItems.filter((item) =>
        item.label.props.children
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
      setItems(filteredItems);
    }
  }, [searchTerm]);

  const moduleConnections = useMemo(
    () => ({
      leadStatus: ["lead-status", "lead-status-import"],
      leadSource: ["lead-source", "lead-source-import"],
      leadRating: ["lead-rating", "lead-rating-import"],
      industryMaster: ["industry-master", "industry-master-import"],
      opportunityStage: ["opportunity-stage", "opportunity-stage-import"],
      beatGrade: ["beat-grade", "beat-grade-import"],
      businessPartner: [
        "business-partner-type",
        "business-partner-type-import",
      ],
      beatMaster: ["beat-master", "beat-master-import"],
      department: ["department", "department-import"],
      typeOfCompany: ["type-of-company", "type-of-company-import"],
      zone: ["zone", "zone-import"],
      area: ["area", "area-import"],
      taskStatus: ["task-status", "task-status-import"],
      unitConversion: ["unit-conversion", "unit-conversion-import"],
      materialGroup: ["material-group", "material-group-import"],
      expenseCategory: ["expense-category", "expense-category-import"],
      paymentMethod: ["payment-method", "payment-method-import"],
      addressType: ["address-type", "address-type-import"],
      currencyExchange: ["currency-exchange", "currency-exchange-import"],
      depositeMaster: ["deposit-master", "deposit-master-import"],
      plantCode: ["plant-code", "plant-code-import"],
    }),
    []
  );

  const getSelectedKey = useCallback(() => {
    return (
      Object.entries(moduleConnections).find(([, values]) =>
        values.includes(selectItem)
      )?.[1][0] || "product"
    );
  }, [selectItem, moduleConnections]);

  const handleSelectItem = useCallback((key) => {
    setSelectItem(key);
  }, []);

  return (
    <div className="bg-white border border-full border-[#DDDAE9] h-full max-h-screen overflow-auto no-scrollbar">
      <Row className="flex bg-white">
        <Col xxl={5} xl={5} lg={8} sm={24} className="border-bColor border-r">
          <Layout className="inner-permission-layout">
            <h1 className="pl-[27px] text-[20px] font-medium pt-[14px]">
              General Master
            </h1>
            <div className="flex items-center justify-center mt-4 mb-2">
              <Input
                className="w-11/12"
                value={searchTerm}
                placeholder="Search..."
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="master-menu">
              <Menu
                onSelect={({ key }) => setSelectItem(key)}
                className="pt-2 pb-4  event-master-menus"
                theme={"light"}
                mode="inline"
                selectedKeys={[getSelectedKey()]}
                items={items.map((item) => ({
                  ...item,
                  onClick: () => {
                    navigete(`/general-masters/${item.key}`);
                  },
                }))}
              />
            </div>
          </Layout>
        </Col>

        <Col xxl={19} xl={19} lg={16} sm={24} className="px-[20px]">
          {/* {renderComponent()} */}
          <Outlet />
        </Col>
      </Row>
    </div>
  );
};

export default GeneralMaster;
