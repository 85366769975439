import React from "react";

const EventDescriptionCard = ({ title, value }) => {
  return (
    <div className="flex  items-center w-full gap-y-1">
      <p className="text-xs text-[#818181] w-[30%]">{title}:</p>
      <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
        {value ? value : "-"}
      </p>
    </div>
  );
};

export default EventDescriptionCard;
