import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  handleGetResponse,
  handlePatchResponse,
  handlePostResponse,
} from "./commonService";
import axios from "axios";
import { BASE_URL } from "../../config/web-config";
import { message } from "antd";
import { toast } from "sonner";
import { handleErrorMessage } from "../../components/helper/common-function";

// Action for Add Role
// Role Action Start
export const actionAddRole = createAsyncThunk(
  "actionAddRole",
  async (
    { req, setParentRole, handleCancel },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await axios.post(`${BASE_URL}/role`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        setParentRole && setParentRole("");
        dispatch(actionGetRoleHierachy());
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action for Update Role
export const actionUpdateRole = createAsyncThunk(
  "actionUpdateRole",
  async (
    { req, setParentRole, handleCancel, editId },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await axios.patch(`${BASE_URL}/role/${editId}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        setParentRole && setParentRole("");
        dispatch(actionGetRoleHierachy());
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action for Delete Role
export const actionDeleteRole = createAsyncThunk(
  "actionDeleteRole",
  async ({ req, handleCancel, form }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/role/delete-and-transfer-role`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        form && form.resetFields();
        dispatch(actionGetRoleHierachy());
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action for Get Role Hierachy
export const actionGetRoleHierachy = createAsyncThunk(
  "actionGetRoleHierachy",
  async () => {
    return handleGetResponse("role/role-hirachy");
  }
);

// Action for Get Single Role Hierachy
export const actionGetSingleRole = createAsyncThunk(
  "actionGetSingleRole",
  async (id) => {
    return handleGetResponse(`role/${id}`);
  }
);

// Action for Get All Roles Hierachy

export const actionGetRoles = createAsyncThunk("actionGetRoles", async (id) => {
  return handleGetResponse(`role`);
});

// Role Action Start

//TODO: Role Profile Action Start
// Action for Get Role Profile

// Action to get company users
export const actionGetRoleProfile = createAsyncThunk(
  "actionGetRoleProfile",
  async ({ offset, search, orderByColumn, order }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/role-profile?limit=10&offset=${offset}${
          search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${
          order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionGetAllRoleProfile = createAsyncThunk(
  "actionGetAllRoleProfile",
  async () => {
    try {
      const res = await axios.get(`${BASE_URL}/role-profile?getall=YES`);
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action for Get Single Role Profile
export const actionGetSingleRoleProfile = createAsyncThunk(
  "actionGetSingleRoleProfile",
  async (id) => {
    return handleGetResponse(`role-profile/${id}`);
  }
);

// Action for Add Role Profile
export const actionAddRoleProfile = createAsyncThunk(
  "actionAddRoleProfile",
  async ({ values, navigate }) => {
    return handlePostResponse(
      "role-profile",
      values,
      navigate,
      "/role-profile"
    );
  }
);
// Action for Add Role Profile
export const actionUpdateRoleProfile = createAsyncThunk(
  "actionUpdateRoleProfile",
  async ({ id, values, navigate }) => {
    return handlePatchResponse(
      `role-profile/${id}`,
      values,
      navigate,
      "/role-profile"
    );
  }
);

// Action for Delete Single Role Hierachy
export const actionDeleteRoleProfile = createAsyncThunk(
  "actionDeleteRoleProfile",
  async ({ req, handleClearData }, { dispatch }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/role-profile/delete-and-transfer-role-profile`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleClearData && handleClearData();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action for Delete Single Role Hierachy
export const actionAssignRoleProfilePermission = createAsyncThunk(
  "actionAssignRoleProfilePermission",
  async ({ req, setUnsavedChanges }, { dispatch }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/role-profile/assign-role-profile-permissions`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setUnsavedChanges && setUnsavedChanges(false);
        window.location.reload();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// company setting start here
//Action for get company Details
export const actionGetCompanyDetail = createAsyncThunk(
  "actionGetCompanyDetail",
  async () => {
    return handleGetResponse(`company`);
  }
);

//update company details
export const actionUpdateCompanyDetail = createAsyncThunk(
  "actionUpdateCompanyDetail",
  async ({ req, setType, handleCancel }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/company`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setType && setType("VIEW");
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//action get company hours start here
//get company hours
export const actionGetMyCompanyHours = createAsyncThunk(
  "actionGetMyCompanyHours",
  async () => {
    return handleGetResponse(`company-hours/mycompany`);
  }
);

// update
export const actionUpdateBusinessHours = createAsyncThunk(
  "actionUpdateBusinessHours",
  async (data, { dispatch }) => {
    const { req } = data;
    try {
      const response = await axios.post(`${BASE_URL}/company-hours`, req);
      const { status, message: customMsg, data: resData } = response.data;

      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        return resData;
      } else {
        toast.error(customMsg, 5);
        throw new Error(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
