import { Image } from "antd";
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import icon from "../../assets/img/upload-dragger.svg";
import loadingGif from "../../assets/img/upload-gif.gif";

const CustomUploadDragger = ({
  image = icon,
  imageHW = 20,
  text = "",
  onDrop,
  title = "",
  gapY = 4,
  containerHeight = 100,
  onChange,
  loading = false,
  accept = ".jpeg,.jpg,.png",
  gif = false,
  ...props
}) => {
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
  };

  return (
    <>
      <div
        {...props}
        onDrop={onDrop}
        onDragOver={handleDragOver}
        className={`${
          loading ? "opacity-50 pointer-events-none" : ""
        }  border rounded-md border-dashed border-[#B2ACC8] bg-[#FBFCFF] flex items-center justify-center flex-col`}
        style={{ minHeight: `${containerHeight}px` }}
      >
        {loading && gif ? (
          <Image preview={false} src={loadingGif} alt="loading" />
        ) : loading ? (
          <label className="px-3 mt-2 flex flex-col justify-center items-center gap-y-4  cursor-pointer">
            <LoadingOutlined className="text-[20px]" color="#7C7C7C" />
            <p className="text-sm font-popinsRegular text-[#000]">
              Uploading...
            </p>
          </label>
        ) : (
          <label
            style={{ minHeight: `${containerHeight}px` }}
            htmlFor="file-upload"
            className={`px-3 min-h-24 h-full w-full flex flex-col justify-center items-center gap-y-${gapY}  cursor-pointer`}
          >
            <Image
              height={imageHW}
              preview={false}
              width={imageHW}
              src={image}
              alt="upload icon"
            />
            {text && (
              <p className="text-sm font-popinsRegular text-black">{text}</p>
            )}
            {title}
          </label>
        )}
      </div>
      <input
        {...props}
        disabled={loading}
        style={{ display: "none" }}
        id="file-upload"
        type="file"
        multiple
        hidden
        onChange={onChange}
        className="hidden"
        accept={accept}
      />
    </>
  );
};

export default CustomUploadDragger;
