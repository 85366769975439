import React, { useState, useRef, useEffect } from "react";

export default function OtpInput({ onOtpChange }) {
  const [code, setCode] = useState(["", "", "", ""]);
  const inputRefs = useRef([]);

  useEffect(() => {
    // Call the callback function whenever code changes
    onOtpChange(code.join(""));
  }, [code, onOtpChange]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && index >= 0 && index <= 3) {
      const newCode = [...code];
      newCode[index] = value.slice(-1); // Take only the last digit entered
      setCode(newCode);

      // Move focus to the next input field
      if (value !== "" && index < 3 && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && code[index] === "") {
      // Move focus to the previous input field on Backspace press
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text/plain").trim();
    if (pasteData.length === 4) {
      const newCode = [...pasteData.split("").slice(0, 4)];
      setCode(newCode);

      // Move focus to the first input field after paste
      if (inputRefs.current[0]) {
        inputRefs.current[0].focus();
      }
    }
  };

  const inputs = Array.from({ length: 4 }, (_, index) => (
    <input
      key={index}
      aria-controls="false"
      ref={(el) => (inputRefs.current[index] = el)}
      type="number"
      maxLength="1"
      value={code[index]}
      onChange={(e) => handleChange(e, index)}
      onKeyDown={(e) => handleKeyDown(e, index)}
      onPaste={(e) => handlePaste(e, index)}
      className="border border-[#dddae9] w-[17%] rounded-lg outline-none text-center mx-1  h-[50px] "
    />
  ));

  return inputs;
}
