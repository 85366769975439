import { createSlice } from "@reduxjs/toolkit";
import {
  actionAddLead,
  actionAddLeadSourceMaster,
  actionAddLeadStatusMaster,
  actionClearAllLeads,
  actionConvertLeadToOpportunity,
  actionDeleteLead,
  actionGetAllLeads,
  actionGetAllLeadsStatus,
  actionGetLeadDetail,
  actionGetLeads,
  actionGetLeadSourceMaster,
  actionGetLeadStatusMaster,
  actionLeadChangeStatus,
  actionUpdateLead,
} from "../services/leadsService";

const leadsSlice = createSlice({
  name: "lead",
  initialState: {
    getLeadsLoader: false,
    getAllLeadsLoader: false,
    getLeadDetailLoader: false,
    addLeadLoader: false,
    updateLeadLoader: false,
    deleteLeadLoader: false,
    leadsData: [],
    allLeadsData: [],
    leadDetailData: "",
    leadsDataCount: "",

    //TODO: Lead Status
    getLeadStatusLoader: false,
    addLeadStatusLoader: false,
    leadStatusData: [],

    //TODO: lead Source
    addLeadSourceLoader: false,
    getLeadSourceLoader: false,
    leadSourceData: false,

    changeLeadStatusLoader: false,
    convertToOpportunityLoader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get Leads reducers
      .addCase(actionGetLeads.pending, (state) => {
        state.getLeadsLoader = true;
      })
      .addCase(actionGetLeads.fulfilled, (state, action) => {
        state.getLeadsLoader = false;
        state.leadsData = action.payload.data;
        state.leadsDataCount = action.payload.total_records;
      })
      .addCase(actionGetLeads.rejected, (state) => {
        state.getLeadsLoader = false;
      })

      // Clear All Leads Data
      .addCase(actionClearAllLeads.fulfilled, (state, action) => {
        state.allLeadsData = action.payload;
      })
      // actionGetAllLeads State
      .addCase(actionGetAllLeads.pending, (state) => {
        state.getAllLeadsLoader = true;
      })
      .addCase(actionGetAllLeads.fulfilled, (state, action) => {
        state.getAllLeadsLoader = false;
        state.allLeadsData = action.payload.data;
      })
      .addCase(actionGetAllLeads.rejected, (state) => {
        state.getAllLeadsLoader = false;
      })

      // Add Lead reducers
      .addCase(actionAddLead.pending, (state) => {
        state.addLeadLoader = true;
      })
      .addCase(actionAddLead.fulfilled, (state) => {
        state.addLeadLoader = false;
      })
      .addCase(actionAddLead.rejected, (state) => {
        state.addLeadLoader = false;
      })
      // Gte  Lead detail  reducers
      .addCase(actionGetLeadDetail.pending, (state) => {
        state.getLeadDetailLoader = true;
      })
      .addCase(actionGetLeadDetail.fulfilled, (state, action) => {
        state.getLeadDetailLoader = false;
        state.leadDetailData = action.payload.data;
      })
      .addCase(actionGetLeadDetail.rejected, (state) => {
        state.getLeadDetailLoader = false;
      })

      // Delete Lead reducers
      .addCase(actionDeleteLead.pending, (state) => {
        state.deleteLeadLoader = true;
      })
      .addCase(actionDeleteLead.fulfilled, (state, action) => {
        state.deleteLeadLoader = false;

        state.leadsData = state.leadsData.filter(
          (lead) => lead.lead_id !== action.payload
        );
      })
      .addCase(actionDeleteLead.rejected, (state) => {
        state.deleteLeadLoader = false;
      })

      // Update Lead reducers
      .addCase(actionUpdateLead.pending, (state) => {
        state.updateLeadLoader = true;
      })
      .addCase(actionUpdateLead.fulfilled, (state, action) => {
        state.updateLeadLoader = false;
      })
      .addCase(actionUpdateLead.rejected, (state) => {
        state.updateLeadLoader = false;
      })

      // change event attendee status
      .addCase(actionLeadChangeStatus.pending, (state) => {
        state.changeLeadStatusLoader = true;
      })
      .addCase(actionLeadChangeStatus.fulfilled, (state, action) => {
        state.changeLeadStatusLoader = false;
        state.leadsData =
          state.leadsData &&
          state.leadsData.map((data) =>
            data.lead_id === action.payload.lead_id
              ? {
                  ...data,
                  lead_status: action.payload.lead_status,
                }
              : data
          );
      })
      .addCase(actionLeadChangeStatus.rejected, (state) => {
        state.changeLeadStatusLoader = false;
      })

      // convert  Lead to opportunity
      .addCase(actionConvertLeadToOpportunity.pending, (state) => {
        state.convertToOpportunityLoader = true;
      })
      .addCase(actionConvertLeadToOpportunity.fulfilled, (state, action) => {
        state.leadsData = state.leadsData.filter(
          (lead) => lead.lead_id !== action.payload?.lead?.lead_id
        );
        state.convertToOpportunityLoader = false;
      })
      .addCase(actionConvertLeadToOpportunity.rejected, (state) => {
        state.convertToOpportunityLoader = false;
      });

    //TODO: Leads Masters Ends Here
  },
});
export default leadsSlice.reducer;
