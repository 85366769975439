import { SearchOutlined, StopOutlined } from "@ant-design/icons";
import { Badge, Button, Dropdown, Input, Image, Popconfirm, Space } from "antd";
import React from "react";
import { IoIosRefresh } from "react-icons/io";
import { CiFilter } from "react-icons/ci";
import importIcon from "../../assets/img/import-icon.svg";
import { RiDownloadLine } from "react-icons/ri";
import TooltipWrapper from "./TooltipWrapper";
import { useNavigate } from "react-router-dom";

const CustomInputWithButtons = ({
  icons = true,
  handleRefresh,
  handleDownload,
  handleSearch,
  handleBlurAndPressEnter,
  searchValue,
  isDeactivate,
  onClickDeactivate,
  items,
  filterValues = false,
  isAdvanceFilter = false,
  onClickDownloadMenu,
  onClickFilter = () => {},
  isImportEnabled = false,
  isAllowDownload = true,
  importRoute = "",
  importIconHeight = 17,
}) => {
  const regex = /^[a-zA-Z0-9 @.-]*$/;
  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    const value = e.target.value;
    if (regex.test(value)) {
      handleSearch(e);
    }
  };

  const handleBlurAndEnter = (e) => {
    const value = e.target.value.trim();
    if (regex.test(value)) {
      handleBlurAndPressEnter(e);
    }
  };

  return (
    <section className="main-section">
      <Space.Compact className="w-full custom-input-sec relative flex mb-5">
        <Input
          value={searchValue}
          onChange={handleSearchChange}
          onBlur={handleBlurAndEnter}
          onPressEnter={handleBlurAndEnter}
          size="large"
          style={{ borderRight: "none" }}
          className=" hover:border-gray-400 bg-transparent"
          id="none-border"
          allowClear
          prefix={<SearchOutlined className="text-[#7C7C7C]" />}
          addonAfter={
            icons ? (
              <div className="flex h-full items-center ">
                {isDeactivate && (
                  <TooltipWrapper title={"Deactivate"}>
                    <Popconfirm
                      placement="topLeft"
                      title="Deactivate"
                      description="Are you sure to deactivate All users?"
                      onConfirm={onClickDeactivate}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        style={{ backgroundColor: "white" }}
                        className="h-full  default-button bg-white  icon-btn"
                      >
                        <div>
                          <StopOutlined size={17} style={{ color: "red" }} />
                        </div>
                      </Button>
                    </Popconfirm>
                  </TooltipWrapper>
                )}
                {isAdvanceFilter && (
                  <TooltipWrapper title={"Filter"}>
                    <Button
                      style={{ backgroundColor: "white" }}
                      onClick={onClickFilter}
                      className="h-full  default-button bg-white  icon-btn"
                    >
                      <Badge
                        // dot={filterValues && Object.keys(filterValues).length}
                        dot={filterValues}
                        overflowCount={9}
                      >
                        <CiFilter size={17} color="#6883fd" />
                      </Badge>
                    </Button>
                  </TooltipWrapper>
                )}

                <TooltipWrapper title="Refresh">
                  <Button
                    style={{ backgroundColor: "white" }}
                    className="h-full  default-button  icon-btn"
                    onClick={handleRefresh}
                  >
                    <IoIosRefresh size={17} color="#6883fd" />
                  </Button>
                </TooltipWrapper>
                <TooltipWrapper title="Download">
                  {items ? (
                    <Dropdown
                      menu={{
                        items,
                        onClick: onClickDownloadMenu,
                      }}
                    >
                      <Button
                        style={{ backgroundColor: "white" }}
                        onClick={handleDownload}
                        className="h-full  default-button  icon-btn"
                      >
                        <RiDownloadLine size={17} color="#6883fd" />
                      </Button>
                    </Dropdown>
                  ) : (
                    isAllowDownload && (
                      <Button
                        disabled={!isAllowDownload}
                        style={{ backgroundColor: "white" }}
                        onClick={onClickDownloadMenu}
                        className="h-full  default-button  icon-btn"
                      >
                        <RiDownloadLine size={17} color="#6883fd" />
                      </Button>
                    )
                  )}
                </TooltipWrapper>

                {isImportEnabled && (
                  <TooltipWrapper title="Import">
                    <Button
                      onClick={() => navigate(importRoute)}
                      disabled={false}
                      style={{ backgroundColor: "white" }}
                      className="h-full  default-button px-5 !w-[50px]  icon-btn"
                      icon={
                        <Image
                          height={importIconHeight}
                          width={importIconHeight}
                          src={importIcon}
                          alt="import-icon"
                          preview={false}
                        />
                      }
                    />
                  </TooltipWrapper>
                )}
              </div>
            ) : (
              ""
            )
          }
          placeholder="Search here..."
        />
      </Space.Compact>
    </section>
  );
};

export default CustomInputWithButtons;
