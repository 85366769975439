import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "sonner";
import { handleErrorMessage } from "../../components/helper/common-function";
import { BASE_URL } from "../../config/web-config";

export const actionAddCustomField = createAsyncThunk(
  "actionAddCustomField",
  async ({ values, form }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/custom-fields`, values);
      const { status, message: customMsg, data } = response.data;

      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form && form.resetFields();
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
