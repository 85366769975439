import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { Dropdown, Image, Modal, Pagination, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { RiDownloadLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import attatchmentIcon from "../../assets/img/attatchment-icon.svg";
import CollectionManagementIcon from "../../assets/img/collection-management-primary.svg";
import receipt from "../../assets/img/payment-receipt.svg";
import { actionDownloadFile } from "../../store/services/commonService";
import {
  actionClearAllCollectionManagement,
  actionDeleteCollectionManagement,
  actionGetAllCollectionManagement,
  actionGetCollectionManagement,
  actionGetCollectionManagementDetails,
} from "../../store/services/financeService";
import CustomInputWithButtons from "../common/CustomInputWithButtons ";
import DeleteModal from "../common/DeleteModal";
import PageTitle from "../common/PageTitle";
import SortIcon from "../common/SortIcon";
import {
  getFirstLetterCapital,
  renderFileIcon,
} from "../helper/common-function";
import dayjs from "dayjs";

const CollectionManagement = () => {
  const offsetRef = useRef(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [exportAll, setExportAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [collectionId, setCollectionId] = useState("");
  const { myCurrency } = useSelector((state) => state.auth);

  const {
    collectionManagementData,
    getCollectionManagementLoader,
    getAllCollectionManagementLoader,
    allCollectionManagements,
    allCollectionManagementCount,
    deleteCollectionManagementLoader,
    getCollectionManagementDetailLoader,
    collectionManagementDetails,
  } = useSelector((state) => state.finance);

  const getApiCall = (
    offset = 0,
    search = "",
    orderByColumn = "",
    order = ""
  ) => {
    dispatch(
      actionGetCollectionManagement({
        offset,
        orderByColumn,
        order,
        search,
      })
    );
  };

  useEffect(() => {
    getApiCall(offsetRef.current);
  }, [dispatch]);

  const columns = [
    {
      title: "Payment Receipt No",
      dataIndex: "payment_number",
      key: "payment_number",
      width: 250,
      fixed: "left",
      render: (text, data) => (
        <span
          onClick={() => {
            setIsModalOpen(!isModalOpen);
            dispatch(
              actionGetCollectionManagementDetails(
                data.collection_management_id
              )
            );
          }}
          className="text-primary cursor-pointer"
        >
          {text}
        </span>
      ),
    },
    {
      title: "Date",
      sorter: true,
      sortIcon: () => <SortIcon />,
      dataIndex: "payment_date",
      key: "payment_date",
      width: 200,
      render: (text, data) => (
        <span>
          {data.payment_date
            ? dayjs(data.payment_date).format("DD-MM-YYYY")
            : "-"}
        </span>
      ),
    },
    {
      title: "Business Partner",
      dataIndex: ["business_partner", "business_partner_name"],
      sorter: true,
      sortIcon: () => <SortIcon />,
      key: "business_partner",
      width: 250,
      ellipsis: true,
    },
    {
      title: `Amount (${myCurrency?.currency})`,
      dataIndex: "amount_received",
      key: "amount_received",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text) => `${myCurrency?.currency_symbol}${text}`,
    },
    {
      title: `Bank Charges`,
      dataIndex: "bank_charges",
      key: "bank_charges",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text) => (text ? myCurrency?.currency_symbol + text : "-"),
    },
    {
      title: "Deposited Via",
      dataIndex: ["payment_mode", "payment_method"],
      sorter: true,
      sortIcon: () => <SortIcon />,
      key: "payment_mode",
      width: 200,
      ellipsis: true,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Deposited In",
      dataIndex: ["deposit_to", "name"],
      sorter: true,
      sortIcon: () => <SortIcon />,
      key: "deposit_to",
      width: 200,
      ellipsis: true,
      render: (text) => (text ? getFirstLetterCapital(text) : "-"),
    },
    {
      title: "Payment Reference No",
      dataIndex: "reference",
      key: "reference",
      width: 200,
      ellipsis: true,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Notes",
      dataIndex: "notes",
      width: 200,
      ellipsis: true,
      key: "notes",
      render: (text) => (text ? text : "-"),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "8",
      width: 100,
      align: "center",
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () =>
                    navigate("/update-collection-management", {
                      state: { collectionId: data.collection_management_id },
                    }),
                },
                {
                  label: "Delete",
                  icon: <DeleteOutlined />,
                  danger: true,
                  key: "Delete",

                  onClick: () => {
                    setIsDeleteModalOpen((prev) => !prev);
                    setCollectionId(data?.collection_management_id);
                  },
                },
              ],
            }}
          >
            <div className=" p-2 w-[35px] ">
 <div className="flex justify-center items-center">
                <EllipsisOutlined />
              </div>            </div>
          </Dropdown>
        </>
      ),
    },
  ];

  const handleRefresh = () => {
    setSearch(null);
    offsetRef.current = 0;
    setSelectedUsers([]);
    setCreatedBy([]);
    getApiCall(offsetRef.current);
  };

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      // Call Action
      getApiCall(
        offsetRef.current,
        e.target.value,
        "",
        "",
        selectedUsers,
        createdBy
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      getApiCall(
        offsetRef.current,
        e.target.value,
        "",
        "",
        selectedUsers,
        createdBy
      );
    }
  };

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllCollectionManagement());
    setExportAll(true);
  };

  const exportAllToExcel = async () => {
    const data =
      (await allCollectionManagements) &&
      allCollectionManagements.length > 0 &&
      allCollectionManagements.map((data) => ({
        "Payment Receipt No.": data?.payment_number
          ? data?.payment_number
          : "-",
        "Payment Date": data.payment_date ? data.payment_date : "-",
        "Business Partner": data.business_partner
          ? data.business_partner?.business_partner_name
          : "-",
        Amount: data.amount_received ? data.amount_received : "-",
        "Deposited Via": data.payment_mode ? data.payment_mode?.name : "-",
        "Deposited In": data.deposit_to ? data.deposit_to?.payment_method : "-",
        Reference: data.reference ? data.reference : "-",
        Notes: data.notes ? data.notes : "-",
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Collection Management");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "Collection Management.xlsx");
    dispatch(actionClearAllCollectionManagement({ setExportAll }));
  };

  useEffect(() => {
    if (
      exportAll &&
      allCollectionManagements &&
      allCollectionManagements.length > 0
    ) {
      exportAllToExcel();
    }
  }, [allCollectionManagements]);

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.columnKey,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    getApiCall(
      offsetRef.current,
      search,
      sorterParams.field,
      sorterParams.order
    );
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setCollectionId("");
  };

  function formatAmountWithCommas(amount) {
    // Ensure the input is a number and format it with commas
    return parseFloat(amount).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return (
    <>
      <section className="main-wrapper">
        <PageTitle
          image={CollectionManagementIcon}
          title="Collection Management"
          buttonText="Record Payment"
          // buttonText="Add Collection Management"
          buttonLink="/add-collection-management"
        />

        <CustomInputWithButtons
          handleRefresh={handleRefresh}
          handleSearch={handleSearch}
          handleBlurAndPressEnter={handleBlurAndPressEnter}
          searchValue={search}
          onClickDownloadMenu={onClickDownloadMenu}
        />

        <Table
          sticky
          rowKey="collection_management_id"
          scrollToFirstRowOnChange={true}
          sortDirections={["ascend", "descend"]}
          onChange={handleSortChange}
          bordered
          scroll={{
            x: "max-content",
            y: window.innerWidth < 1500 ? "49vh" : "61vh",
          }}
          pagination={false}
          columns={columns}
          dataSource={collectionManagementData}
          loading={
            getCollectionManagementLoader || getAllCollectionManagementLoader
          }
        />

        <div className="flex justify-end mt-3">
          <Pagination
            hideOnSinglePage
            responsive={true}
            current={offsetRef.current + 1}
            className="flex items-center"
            showSizeChanger={false}
            total={allCollectionManagementCount}
            onChange={(e) => {
              offsetRef.current = e - 1;
              getApiCall(offsetRef.current, search);
            }}
          />
        </div>
      </section>
      <Modal
        loading={getCollectionManagementDetailLoader}
        centered
        onOk={handleClose}
        onCancel={handleClose}
        footer={false}
        width={1000}
        title={
          <div className="text-brand flex items-center gap-x-3">
            <Image
              src={receipt}
              alt="image"
              height={20}
              width={20}
              preview={false}
            />

            <h1
              className=" text-base  font-popinsRegular"
              style={{ fontWeight: 500 }}
            >
              Payment Receipt
            </h1>
          </div>
        }
        open={isModalOpen}
      >
        <div className="grid lg:grid-cols-12 grid-cols-1 pt-4  gap-4">
          <div className="flex  flex-col  gap-y-3 py-2 justify-center lg:col-span-8 h-full">
            <div className="flex justify-between w-full lg:w-[75%] items-center">
              <p className="">Payment Receipt No</p>
              <p className="">{collectionManagementDetails?.payment_number}</p>
            </div>
            <div className="flex justify-between w-full lg:w-[75%] items-center">
              <p className="">Payment Date</p>
              <p className="">
                {dayjs(collectionManagementDetails?.payment_date).format(
                  "DD-MM-YYYY"
                )}
              </p>
            </div>
            <div className="flex justify-between w-full lg:w-[75%] items-center">
              {" "}
              <p>Payment Reference Number</p>
              <p>
                {collectionManagementDetails?.reference
                  ? collectionManagementDetails?.reference
                  : "-"}
              </p>
            </div>
            <div className="flex justify-between w-full lg:w-[75%] items-center">
              {" "}
              <p>Deposited Via</p>
              <p>
                {collectionManagementDetails?.payment_mode != null
                  ? collectionManagementDetails?.payment_mode?.payment_method
                  : "-"}
              </p>
            </div>
            <div className="flex justify-between w-full lg:w-[75%] items-center">
              {" "}
              <p>Amount Received In Words</p>
              <p>{collectionManagementDetails?.amount_received_in_words}</p>
            </div>

            <div className="pt-5">
              <div className="flex  items-center gap-x-2">
                <Image
                  src={attatchmentIcon}
                  className="text-[18px]"
                  alt="Icon"
                />

                <h1 className="text-lg text-primary underline">Attachments</h1>
              </div>
              <div className="flex items-start gap-y-3  flex-col  mt-3 max-h-[300px] overflow-x-hidden overflow-y-auto gray-scrollbar">
                {collectionManagementDetails.attachments &&
                collectionManagementDetails.attachments.length > 0 ? (
                  collectionManagementDetails.attachments.map((att, index) => {
                    const sizeInBytes = att.upload.size;
                    const sizeInKB = sizeInBytes / 1024;
                    const sizeInMB = sizeInBytes / (1024 * 1024);
                    const displaySize =
                      sizeInMB >= 1
                        ? `${sizeInMB.toFixed(2)} MB`
                        : `${sizeInKB.toFixed(2)} KB`;
                    return (
                      <div
                        key={index}
                        className="flex items-center w-full gap-x-3"
                      >
                        {/* <Image
                          width={20}
                          height={20}
                          src={att?.upload.url}
                          alt="attatchment icon"
                        /> */}

                        {renderFileIcon(att?.upload.url)}

                        <div className="flex flex-col gap-y-0 max-w-[87%]">
                          <a
                            href={att?.upload.url}
                            target="_blank"
                            className="text-primary text-[13px] truncate "
                          >
                            {att?.upload?.filename}
                          </a>
                          <span className="text-[11px] text-[#7C7C7C]">
                            File Size: {displaySize}
                          </span>
                        </div>

                        <RiDownloadLine
                          onClick={() =>
                            dispatch(actionDownloadFile(att?.upload))
                          }
                          className="ml-auto cursor-pointer"
                          size={18}
                          color="#6883fd"
                        />
                      </div>
                    );
                  })
                ) : (
                  <p>No Attachments Found</p>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center lg:col-span-4 max-h-[100px] p-3 rounded-[10px] bg-[#15A02B] text-white">
            <p className="text-[15px]">Amount Received</p>
            <p className="text-xl font-popinsMedium">
              {`${myCurrency?.currency_symbol}${formatAmountWithCommas(
                collectionManagementDetails?.amount_received
              )}`}
            </p>
          </div>
        </div>
      </Modal>

      <DeleteModal
        title="Are you sure you want to remove this ?"
        description="All the associations will be removed for this Collection"
        loading={deleteCollectionManagementLoader}
        onCancel={() => {
          setIsDeleteModalOpen(false);
          setCollectionId("");
        }}
        onConfirm={() =>
          dispatch(
            actionDeleteCollectionManagement({
              collectionId,
              setIsDeleteModalOpen,
            })
          )
        }
        isVisible={isDeleteModalOpen}
      />
    </>
  );
};

export default CollectionManagement;
